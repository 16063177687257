import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessInfoAddress from "./BusinessInfoAddress";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import {
  doAddressKey,
  doCreateApplication,
  doGetAddress,
  doGetProvinceList,
  updateVaultMember,
} from "../AboutYou/Actions/AboutYouActions";
import { HTTP_STATUS, S_PERSONAL } from "../../Communication/Constants";
import { profileEsc } from "../../Redux/Actions/ApplicantAction";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import { getBusinessAddress } from "../../Utils/CommonUtilities";

class BusinessInfoAddressContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedEntity: null };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      doDisableBack,
      provinceList,
      products,
      getActiveProduct,
      doProfileEsc,
      doUpdateApplicant,
      doGetApplicant,
      vaultMember,
      doUpdateVaultMember,
      doUpdateProductToVault,
    } = this.props;
    doDisableBack(true);
    if (provinceList && provinceList.length <= 0) {
      const { doGetProvinceData } = this.props;
      doGetProvinceData();
    }
    const activeProduct = getActiveProduct(products);
    if (vaultMember?.orderId) {
      doProfileEsc({ orderId: vaultMember.orderId, retry: 1 }, (profileRes) => {
        console.log(profileRes);
        if (profileRes.status === HTTP_STATUS.OK) {
          if (profileRes.data.status === "Complete") {
            const updatedVaultProductList = products;
            const idx = updatedVaultProductList.findIndex(
              (x) => x === activeProduct
            );
            updatedVaultProductList[idx].parties = profileRes.data.parties;
            doUpdateProductToVault(updatedVaultProductList);
            const updateRequest = {
              member: {
                businessNumber: profileRes.data.entity?.businessNumber,
              },
              business: {
                incorporationNumber: profileRes.data.entity?.number,
              },
              contact: {},
              address: getBusinessAddress(profileRes.data.entity?.addresses),
            };
            doUpdateApplicant(
              updateRequest,
              activeProduct.applicantId,
              (res) => {
                console.log("update result", res);

                delete vaultMember.orderId;
                doUpdateVaultMember(vaultMember, () => {
                  doGetApplicant({ id: activeProduct.applicantId }, () => {
                    console.log("esc profile pushed");
                  });
                });
              }
            );
          }
        }
      });
    }
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      doUpdateApplicant,
      applicantData,
      doDisableBack,
      products,
      getActiveProduct,
      doCreateNewApplicant,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (activeProduct.applicationId) {
      doUpdateApplicant(data, applicantData.id, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          doDisableBack(false);
          continuehandler(
            null,
            AppConstants.APPLICATIONSTEP.BUSINESS_INFO_ADDRESS
          );
        }
      });
    } else {
      doCreateNewApplicant(
        {
          bundleProductId: activeProduct.productId,
          isCommercial: applicantData.isCommercial,
          isSoleProprietor: applicantData.isSoleProprietor,
          dateOfBirth: "1900-01-01",
          ownerApplicantId: sessionStorage.getItem(S_PERSONAL),
        },
        (res) => {
          console.log(res);
          if (res.status === HTTP_STATUS.OK) {
            doDisableBack(false);
            continuehandler(
              {
                applicationId: res.data.applicationId,
                doximProducts: res.data.doximProducts,
              },
              AppConstants.APPLICATIONSTEP.BUSINESS_INFO_ADDRESS
            );
          }
        }
      );
    }
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
      provinceList,
      doGetAddress,
      doGetProvinceData,
      doAddressKey,
      applicantData,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessInfoAddress
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
          finInfo={finInfo}
          provinceList={provinceList}
          doGetAddress={doGetAddress}
          doGetProvinceData={doGetProvinceData}
          doAddressKey={doAddressKey}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessInfoAddressContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessInfoAddressContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  vaultMember: state.ApplicationReducer.vaultMember,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
    doAddressKey: (callback) => dispatch(doAddressKey(callback)),
    doGetAddress: (params, callback) =>
      dispatch(doGetAddress(params, callback)),
    doCreateNewApplicant: (request, callback) =>
      dispatch(doCreateApplication(request, callback)),
    doProfileEsc: (payLoad, callback) =>
      dispatch(profileEsc(payLoad, callback)),
    doUpdateVaultMember: (member, callback) =>
      dispatch(updateVaultMember(member, callback)),
  };
};

const BusinessInfoAddressWrapper = withBusinessHOC(
  BusinessInfoAddressContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessInfoAddressWrapper);
