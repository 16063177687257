import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";
import PopupModal from "../Common/PopupModal/PopupModal";
import { BUSINESS_MEMBER } from "../../Constants/Routes";
import { HTTP_STATUS } from "../../Communication/Constants";

class BusinessMemberReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      memberList: [],
      show: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_REVIEW,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    console.log("active product", activeProduct);
    if (prevProps !== this.props) {
      const memberList = activeProduct?.foundMemberList ?? [];
      this.setState({
        memberList,
      });
    }
  }

  setFormData = () => {};

  toggleModal = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;

    handleContinue({ property });
  };
  handleContinue = () => {
    const { doGetAcoountDetails } = this.props;
    const { property } = this.state;
    let valid = true;

    doGetAcoountDetails((getAccountActionRes) => {
      if (getAccountActionRes.status === HTTP_STATUS.OK) {
        if (valid) {
          this.setState({ showModal: true });
        }
      }
    });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_REVIEW);
  };

  handleChoice = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    return formIsValid;
  };

  formatPhoneNumber(phoneNumberString) {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return "";
  }

  displayMemberRoles = (member) => {
    const roles = [];
    if (member?.owner) roles.push(STRINGS.BUSINESS_MEMBER.FORM.OWNER_LABEL);
    if (member?.officeDirector) {
      roles.push(STRINGS.BUSINESS_MEMBER.FORM.OFFICER_DIRECTOR_LABEL);
    }
    if (member?.authorizedSignatory)
      roles.push(STRINGS.BUSINESS_MEMBER.FORM.AUTHORIZED_SIGNATORY_LABEL);

    return roles.join(", ");
  };

  render() {
    const { steps, activeStepID, activeProduct, handleEditPage } = this.props;
    const { show, memberList } = this.state;
    const { finInfo, showModal } = this.state;
    console.log(steps, activeStepID);

    const memberListComponent = memberList.map((member, index) => (
      <>
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {`#${index + 1}`} {STRINGS.BUSINESS_MEMBER.FORM.GROUP_LABEL}
                <button
                  type="button"
                  className="[ btn edit-btn ]"
                  onClick={() => handleEditPage(BUSINESS_MEMBER)}
                  aria-label="edit"
                >
                  <span className="[ edit-icon ]" />
                  <span className="[ edit-btn__label ]">
                    {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                  </span>
                </button>
              </h3>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.FIRSTNAME_LABEL}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {member.firstName}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.LASTNAME_LABEL}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {member.lastName}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.EMAIL_LABEL}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {member.email}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.MOBILE_NO_LABEL}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {this.formatPhoneNumber(`${member.mobileNo}`)}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.MEMBER_ROLES}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {this.displayMemberRoles(member)}
              </div>
            </div>
          </div>
        </div>
      </>
    ));
    const additionalListComponent = activeProduct?.additionalMemberList?.map(
      (member, index) => (
        <>
          <div className="[ details-section ] [ mb-3 ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {`#${memberList.length + index + 1}`}{" "}
                  {STRINGS.BUSINESS_MEMBER.FORM.GROUP_LABEL}
                  <button
                    type="button"
                    className="[ btn edit-btn ]"
                    onClick={() => handleEditPage(BUSINESS_MEMBER)}
                    aria-label="edit"
                  >
                    <span className="[ edit-icon ]" />
                    <span className="[ edit-btn__label ]">
                      {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                    </span>
                  </button>
                </h3>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.BUSINESS_MEMBER.FORM.FIRSTNAME_LABEL}
                </div>
              </div>
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-description ]">
                  {member.firstName}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.BUSINESS_MEMBER.FORM.LASTNAME_LABEL}
                </div>
              </div>
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-description ]">
                  {member.lastName}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.BUSINESS_MEMBER.FORM.EMAIL_LABEL}
                </div>
              </div>
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-description ]">
                  {member.email}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.BUSINESS_MEMBER.FORM.MOBILE_NO_LABEL}
                </div>
              </div>
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-description ]">
                  {this.formatPhoneNumber(`${member.mobileNo}`)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.BUSINESS_MEMBER.FORM.MEMBER_ROLES}
                </div>
              </div>
              <div className="[ col-sm-6 ]">
                <div className="[ details-section__item-description ]">
                  {this.displayMemberRoles(member)}
                </div>
              </div>
            </div>
          </div>
        </>
      )
    );
    const actionComponent = (
      <div className="[ business-member-review ] [ confirm-account-container ] [ business-review-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_REVIEW.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_MEMBER_REVIEW.SUB_HEADER}
          </div>
          <div className="[ form-description ]">
            {STRINGS.BUSINESS_MEMBER_REVIEW.REMINDER}
          </div>
          <div className="[ form-description ]">
            {STRINGS.BUSINESS_REVIEW.REVIEW_DETAILS_MSG2}
          </div>
          {memberListComponent}
          {additionalListComponent}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            title={STRINGS.BUSINESS_MEMBER_REVIEW.MODAL.SUCCESS_TITLE}
            description={
              STRINGS.BUSINESS_MEMBER_REVIEW.MODAL.SUCCESS_DESCRIPTION
            }
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessMemberReview.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessMemberReview.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessMemberReview;
