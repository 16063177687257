/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import AppConstants from "Constants/AppConstants";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AuthBody from "../Common/AuthBody/AuthBody";
import PopupModal from "../Common/PopupModal/PopupModal";
import Stepper from "../Common/Stepper/Stepper";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";
import { isScanningAllowed } from "../../Utils/CommonUtilities";
import Button from "react-bootstrap/Button";
import NumberFormat from "react-number-format";
import formatStringByPattern from "format-string-by-pattern";

class ThirdParty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountPurpose: "",
      isThirdPartyAccount: false,
      thirdPartyName: "",
      thirdPartyRelationship: "",
      isThirdPartyAccountError: "",
      thirdPartyNameError: "",
      accountPurposeError: "",
      thirdPartyRelationshipError: [],
      isMobile: false,
      showHelp: false,
      origUdfList: {},
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.THIRD_PARTY, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {}

  setFormData = (response) => {
    console.log("setFormData", response);
    const { getActiveProduct, vaultProductList } = this.props;
    const activeProduct = getActiveProduct(vaultProductList);
    this.setState({
      origUdfList: response.udfList,
      isMobile: isScanningAllowed(),
      isThirdPartyAccount: activeProduct?.isThirdPartyAccount ? true : false,
      thirdPartyName: activeProduct?.thirdPartyName ?? "",
      thirdPartyRelationship: activeProduct?.thirdPartyRelationship ?? "",
    });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.THIRD_PARTY);
  };

  changeHandler = (event) => {
    const complianceData = this.state;
    const inputField = event?.target.name;
    let eventValue = event?.target.value;

    // Capitalize only the first letter of each word, preserving the rest of the letters as entered
    if (inputField === "thirdPartyName") {
      eventValue = eventValue
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    complianceData[inputField] = eventValue;
    complianceData[`${inputField}Error`] = "";
    this.setState({ complianceData });
  };

  handleChoice = (name, choice) => {
    console.log("handleChoice", name, choice);
    const complianceData = this.state;
    complianceData[name] = choice;
    if (choice === false) {
      if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT) {
        this.setState({
          thirdPartyNameError: [],
          thirdPartyRelationshipError: [],
        });
        complianceData.thirdPartyName = "";
        complianceData.thirdPartyRelationship = "";
      }
    }
    this.setState({ complianceData });

    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT) {
      this.setState({ isThirdPartyAccountError: [] });
    }
  };

  handleSubmit = (e) => {
    const { handleUpdateThirdPartyData } = this.props;

    e.preventDefault();

    const formIsValid = this.verifyData();
    if (formIsValid) {
      const formdata = this.state;
      handleUpdateThirdPartyData(formdata);
    }
  };

  verifyData = () => {
    console.log("verifyData");
    let formIsValid = true;
    const {
      isThirdPartyAccount,
      thirdPartyName,
      thirdPartyRelationship,
    } = this.state;
    const { finInfo } = this.props;
    let thirdPartyNameRes = "";
    let indicateNameRes = "";
    let thirdPartyRelationshipError = [];

    const isThirdPartyAccountRes = checkErrors(
      isThirdPartyAccount,
      VALIDATIONS.COMPLIANCE.isThirdPartyAccount
    );

    this.setState({
      isThirdPartyAccountError: isThirdPartyAccountRes,
    });

    if (isThirdPartyAccount) {
      thirdPartyNameRes = checkErrors(
        thirdPartyName,
        VALIDATIONS.COMPLIANCE.thirdPartyName
      );
      this.setState({
        thirdPartyNameError: thirdPartyNameRes,
      });

      thirdPartyRelationshipError = checkErrors(
        thirdPartyRelationship,
        VALIDATIONS.COMPLIANCE.RELATIONSHIP
      );
      this.setState({ thirdPartyRelationshipError });
    }

    if (
      isThirdPartyAccountRes.length > 0 ||
      thirdPartyNameRes.length > 0 ||
      thirdPartyRelationshipError.length > 0
    ) {
      formIsValid = false;
    }

    return formIsValid;
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const {
      isThirdPartyAccount,
      thirdPartyName,
      thirdPartyRelationship,
      isThirdPartyAccountError,
      thirdPartyNameError,
      thirdPartyRelationshipError,
      isMobile,
      showHelp,
      helpText,
    } = this.state;

    const {
      indicateWhoArray,
      showModal,
      type,
      modal,
      toggleModal,
      steps,
      activeStepID,
      contKycFlag,
    } = this.props;

    const actionComponent = (
      <div className="[ compliance-container ]">
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? this.handlePopupModalBtnClick
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}

        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.THIRD_PARTY.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.THIRDPARTY_ACCOUNT}
              {!contKycFlag ? (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(STRINGS.COMPLIANCE.HELP.THIRD_PARTY)
                  }
                />
              ) : (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(STRINGS.COMPLIANCE.HELP.THIRD_PARTY_KYC)
                  }
                />
              )}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isThirdPartyAccount
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handleChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT,
                      true
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isThirdPartyAccount === false
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handleChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT,
                      false
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">{isThirdPartyAccountError}</div>

            {isThirdPartyAccount ? (
              <div className="[ drawer-content-container ]">
                <div className="[ info-title ]">
                  {STRINGS.COMPLIANCE.FORM.THIRDPARTY_TITLE}
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME}
                  </div>
                  <input
                    type="text"
                    id="thirdPartyName"
                    name="thirdPartyName"
                    className={
                      thirdPartyNameError.length > 0
                        ? "[ form-control ] [ invalid-input ]"
                        : "[ form-control ]"
                    }
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME_PLACEHOLDER
                    }
                    value={thirdPartyName}
                    onChange={this.changeHandler}
                  />
                  <div className="[ error-msg ] ">{thirdPartyNameError}</div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_RELATIONSHIP}
                  </div>
                  <select
                    className="[ form-control ] [ select__dropdown ]"
                    id="thirdPartyRelationship"
                    name="thirdPartyRelationship"
                    value={thirdPartyRelationship}
                    onChange={this.changeHandler}
                  >
                    <option
                      value=""
                      className="[ default ] [ dropdown__option ]"
                    >
                      {
                        STRINGS.COMPLIANCE.FORM
                          .THIRDPARTY_RELATIONSHIP_PLACEHOLDER
                      }
                    </option>
                    {indicateWhoArray?.map((obj) => {
                      return (
                        <option
                          key={obj.code}
                          value={obj.description}
                          className="[ dropdown__option ]"
                        >
                          {obj.description}
                        </option>
                      );
                    })}
                  </select>
                  <div className="[ error-msg ] ">
                    {thirdPartyRelationshipError}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
        {showHelp && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

ThirdParty.propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.object).isRequired,
  accountPurposeArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  indicateWhoArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleUpdateThirdPartyData: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  finInfo: PropTypes.arrayOf(PropTypes.object),
  occupationCodes: PropTypes.arrayOf(PropTypes.object),
  incomeSourcesList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ThirdParty.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  finInfo: {},
  statusList: [],
};

export default ThirdParty;
