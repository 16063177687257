import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import updateComplianceData from "Redux/Actions/UpdateApplicantAction";
import { toggleBackFlag } from "Redux/Actions/ApplicationAction";
import AppConstants from "Constants/AppConstants";
import * as ROUTES from "Constants/Routes";

import ThirdParty from "./ThirdParty";
import { isNewMember, showCompliance } from "../../Utils/LogicUtilities";
import {
  getIncomeSourcesList,
  getStatusList,
} from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import { findCode } from "../../Utils/CommonUtilities";
import {
  getIndicateWhoList,
  getAccountPurposeList,
} from "../Compliance/Actions/ComplianceActions";
import {
  getApplication,
  updateApplication,
} from "../../Redux/Actions/ApplicationAction";
import {
  HTTP_STATUS,
  TERMDEPOSITEPRODUCT,
} from "../../Communication/Constants";
import {
  getGlobalVault,
  updateGlobalVault,
} from "../JointApplicant/Actions/JointApplicantAction";
import STRINGS from "../../Constants/Strings";

class ThirdPartyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      application: {},
    };
  }

  componentDidMount() {
    const {
      vaultProductList,
      getActiveProduct,
      indicateWhoList,
      dogetIndicateWhoList,
    } = this.props;

    console.log("componentDidMount");
    if (indicateWhoList?.length === 0) {
      dogetIndicateWhoList();
    }
  }

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handleUpdateThirdPartyData = (formdata) => {
    console.log("handleUpdateThirdPartyData", formdata);
    const {
      continuehandler,
      doUpdateProductToVault,
      vaultProductList,
      finInfo,
      doUpdateApplication,
      doGetApplicationProperty,
      doGetGlobalVault,
      doUpdateGlobalVault,
    } = this.props;

    vaultProductList[0].isThirdPartyAccount = formdata.isThirdPartyAccount;
    vaultProductList[0].thirdPartyName = formdata.thirdPartyName;
    vaultProductList[0].thirdPartyRelationship =
      formdata.thirdPartyRelationship;

    doUpdateProductToVault(vaultProductList, () => {
      const requestBody = {
        udfList: { ...formdata.origUdfList },
      };
      if (formdata.isThirdPartyAccount) {
        if (
          vaultProductList[0].type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT
        ) {
          requestBody.udfList[finInfo.thirdPartyDemandProductFlag] = true;
        } else if (vaultProductList[0].type === TERMDEPOSITEPRODUCT) {
          requestBody.udfList[finInfo.thirdPartyTermProductFlag] = true;
        }
      } else if (
        vaultProductList[0].type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT
      ) {
        requestBody.udfList[finInfo.thirdPartyDemandProductFlag] = false;
      } else if (vaultProductList[0].type === TERMDEPOSITEPRODUCT) {
        requestBody.udfList[finInfo.thirdPartyTermProductFlag] = false;
      }

      // update global vault for joint
      doGetApplicationProperty(
        { applicationId: vaultProductList[0].applicationId },
        (res) => {
          console.log("slots", res.slots);
          res.slots.forEach((slot) => {
            if (slot.type !== "main") {
              const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${slot.extra.msisdn}`;
              doGetGlobalVault({ vaultKey: vKey }, (globalVault) => {
                doUpdateGlobalVault(
                  {
                    vaultKey: vKey,
                    body: {
                      ...globalVault,
                      vaultProducts: [
                        {
                          ...globalVault.vaultProducts[0],
                          isThirdPartyAccount: formdata.isThirdPartyAccount,
                          thirdPartyName: formdata.thirdPartyName,
                          thirdPartyRelationship:
                            formdata.thirdPartyRelationship,
                        },
                      ],
                    },
                  },
                  (gResponse) => {
                    console.log(gResponse);
                  }
                );
              });
            }
          });
        }
      );

      continuehandler(requestBody, AppConstants.APPLICATIONSTEP.THIRD_PARTY);
    });
  };

  render() {
    const { modalType, modal, showModal } = this.state;
    const {
      accountPurposeState,
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      EmploymentAndIncomeStatus,
      getActiveProduct,
      vaultProductList,
      applicantData,
    } = this.props;

    return (
      <ThirdParty
        handleUpdateThirdPartyData={this.handleUpdateThirdPartyData}
        accountPurposeArray={accountPurposeState.accountPurposeList}
        indicateWhoArray={accountPurposeState.indicateWhoList}
        bindFormData={accountPurposeState.data ? accountPurposeState.data : ""}
        type={modalType}
        modal={modal}
        showModal={showModal}
        toggleModal={this.toggleModal}
        backClick={this.backClick}
        getDataFromServer={getDataFromServer}
        handleBack={handleBack}
        steps={steps}
        activeStepID={activeStepID}
        finInfo={finInfo}
        incomeSourcesList={EmploymentAndIncomeStatus.incomeSourcesList}
        getActiveProduct={getActiveProduct}
        vaultProductList={vaultProductList}
        contKycFlag={!isNewMember(applicantData)}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dogetAccountPurposeList: () => dispatch(getAccountPurposeList()),
    dogetIndicateWhoList: () => dispatch(getIndicateWhoList()),
    doUpdateComplianceData: (formDetails, aplicationId, callback) =>
      dispatch(updateComplianceData(formDetails, aplicationId, callback)),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    statusList: () => dispatch(getStatusList()),
    incomeSourcesList: () => dispatch(getIncomeSourcesList()),
    doUpdateApplication: (applicationId, payLoad, callback) =>
      dispatch(updateApplication(applicationId, payLoad, callback)),
    doGetGlobalVault: (request, callback) =>
      dispatch(getGlobalVault(request, callback)),
    doUpdateGlobalVault: (request, callback) =>
      dispatch(updateGlobalVault(request, callback)),
  };
};

const mapStateToProps = (state) => ({
  accountPurposeList: state.ComplianceReducer.accountPurposeList,
  indicateWhoList: state.ComplianceReducer.indicateWhoList,
  accountPurposeState: state.ComplianceReducer,
  applicantData: state.ApplicationReducer.response,
  actionBack: state.ApplicationReducer.actionBack,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  vaultProductList: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  finInfo: state.MarketplaceReducer.finInfo,
  EmploymentAndIncomeStatus: state.EmploymentAndIncomeReducer,
  occupationCodes: state.ApplicationReducer.occupationCodes,
  processTemplate: state.ApplicationReducer.processTemplate,
});

ThirdPartyContainer.propTypes = {
  dogetIndicateWhoList: PropTypes.func.isRequired,
  dogetAccountPurposeList: PropTypes.func.isRequired,
  accountPurposeState: PropTypes.objectOf(PropTypes.any).isRequired,
  continuehandler: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doToggleBackFlag: PropTypes.func.isRequired,
  accountPurposeList: PropTypes.arrayOf(PropTypes.object),
  indicateWhoList: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.objectOf(PropTypes.any),
  EmploymentAndIncomeStatus: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  occupationCodes: PropTypes.arrayOf(PropTypes.object),
  getActiveProduct: PropTypes.func.isRequired,
};
ThirdPartyContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  accountPurposeList: [],
  indicateWhoList: [],
  finInfo: {},
  occupationCodes: [],
};

const ThirdPartyWrapper = withApplicantHOC(ThirdPartyContainer);

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyWrapper);
