import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessDocumentReview from "./BusinessDocumentReview";
import {
  getDocumentList,
  addDocument,
  deleteDocument,
} from "../BusinessUpload/Actions/DocumentAction";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import PopupModal from "../Common/PopupModal/PopupModal";
import STRINGS from "../../Constants/Strings";
import { doConfirmApplication } from "../ConfirmAccountCreate/Actions/ConfirmAccountCreateAction";
import { isNewMember } from "../../Utils/LogicUtilities";
import { HTTP_STATUS } from "../../Communication/Constants";
import { kycInvite } from "../../Redux/Actions/ApplicantAction";

class BusinessDocumentReviewContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      choice: "",
    };
  }

  componentDidMount() {
    console.log("BusinessDocumentReviewContainer");
  }

  /**
   *
   * @param {string} redirectToPage
   * handleEditPage makes URL navigation
   * using key as value
   */

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEdit } = this.props;
    doToggleEdit(true);
    history.push(redirectToPage);
  };

  handleChange = () => {};

  handleContinue = () => {
    const {
      continuehandler,
      doConfirmApp,
      products,
      getActiveProduct,
      doKycInvite,
      finInfo,
      personalData,
      applicationCompleteList,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    if (applicationCompleteList?.length === 0) {
      let primaryApplicantFirstName = "";
      let primaryApplicantLastName = "";
      if (personalData?.id) {
        primaryApplicantFirstName = personalData.member.name;
        primaryApplicantLastName = personalData.member.surname;
      } else {
        primaryApplicantFirstName = activeProduct?.personal?.firstName;
        primaryApplicantLastName = activeProduct?.personal?.lastName;
      }

      if (activeProduct?.foundMemberList) {
        activeProduct.foundMemberList.forEach((raw, idx) => {
          if (
            raw.authorizedSignatory &&
            !raw.kycInviteSent &&
            !raw.selectedMember
          ) {
            activeProduct.foundMemberList[idx].kycInviteSent = true;
            doKycInvite(
              {
                toAddress: raw.email,
                primaryApplicantFirstName,
                primaryApplicantLastName,
                companyName: finInfo.name,
                productName: activeProduct.productName,
              },
              (res) => {
                console.log(res);
              }
            );
          }
        });
      }

      if (activeProduct?.additionalMemberList) {
        activeProduct.additionalMemberList.forEach((raw, idx) => {
          if (
            raw.authorizedSignatory &&
            !raw.kycInviteSent &&
            !raw.selectedMember
          ) {
            activeProduct.additionalMemberList[idx].kycInviteSent = true;
            doKycInvite(
              {
                toAddress: raw.email,
                primaryApplicantFirstName,
                primaryApplicantLastName,
                companyName: finInfo.name,
                productName: activeProduct.productName,
              },
              (res) => {
                console.log(res);
              }
            );
          }
        });
      }
    }

    doConfirmApp(
      {
        applicationId: activeProduct.applicationId,
      },
      (res) => {
        if (res.status === HTTP_STATUS.OK) {
          const modal = {};
          modal.title = "Thank you! You are all done.";
          modal.description =
            "You application is in review. A representative will get in touch with you soon.";
          modal.type = AppConstants.MODALTYPE.SUCCESS;
          this.setState({
            modal,
            showModal: true,
          });
        }
      }
    );
  };

  handleExit = () => {
    const { continuehandler } = this.props;
    continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_DOC_REVIEW);
  };

  handlePopupModalBtnClick = () => {
    const { removeCurrentActiveApplication } = this.props;
    removeCurrentActiveApplication(this.handleExit);
  };

  render() {
    console.log("render BUSINESS UPLOAD");
    const { choice, documentList, showModal, type, modal } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      finInfo,
      applicantData,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={this.handlePopupModalBtnClick}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <BusinessDocumentReview
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          handleEditPage={this.handleEditPage}
          activeProduct={activeProduct}
          finInfo={finInfo}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessDocumentReviewContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessDocumentReviewContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantData: state.ApplicationReducer.response,
  personalData: state.ApplicationReducer.personalData,
  actionBack: state.ApplicationReducer.actionBack,
  finInfo: state.MarketplaceReducer.finInfo,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetDocumentList: (applicationId, productId, callback) =>
      dispatch(getDocumentList(applicationId, productId, callback)),
    doAddDocument: (applicationId, productId, payload, callback) =>
      dispatch(addDocument(applicationId, productId, payload, callback)),
    doDeleteDocument: (applicationId, documentId, callback) =>
      dispatch(deleteDocument(applicationId, documentId, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doConfirmApp: (confirmRequest, callback) =>
      dispatch(doConfirmApplication(confirmRequest, callback)),
    doKycInvite: (callback) => dispatch(kycInvite(callback)),
  };
};

const BusinessDocumentReviewWrapper = withBusinessHOC(
  BusinessDocumentReviewContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessDocumentReviewWrapper);
