import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { HTTP_STATUS } from "Communication/Constants";
import * as ROUTES from "Constants/Routes";
import AppConstants from "../../Constants/AppConstants";
import STRINGS from "../../Constants/Strings";
import TermsAndConditions from "./TermsAndConditions";
import { doConsent, fetchJsonData } from "./Actions/TermsActions";
import { setInSessionJointApplicant } from "../JointApplicant/Actions/JointApplicantAction";
import JointApplicant from "../JointApplicant/JointApplicant";
import withApplicantHOC from "../../Hoc/ApplicantHOC/ApplicantHOC";

class TermsAndConditionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkTermsArray: [],
      checkboxArray: [],
      steps: [],
      activeStepID: 1,
    };
  }

  componentDidMount() {
    const {
      fetchJsonDatas,
      inSessionJointApplicant,
      applicantData,
      history,
    } = this.props;
    console.log("componentDidMount", applicantData);
    //  API call for fetching
    fetchJsonDatas();
    if (inSessionJointApplicant) {
      let steps = [];
      let index = 1;
      AppConstants.JOINT_APPLICANT.FLOW.forEach((flow) => {
        steps.push({
          Id: index,
          Name: STRINGS.STEPS[flow.componentName],
        });
        index += 1;
      });
      this.setState({
        steps,
      });
    } else if (applicantData?.acquireType === "search") {
      history.push(ROUTES.GETTING_STARTED);
    }
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    const {
      termsState,
      history,
      inSessionJointApplicant,
      doGetAcoountDetails,
      products,
    } = this.props;
    const activeProduct = products[0];

    if (prevProps !== this.props && !inSessionJointApplicant) {
      if (termsState?.success && termsState.success.status === HTTP_STATUS.OK) {
        if (activeProduct.bankingType === AppConstants.BANKING_TYPE.BUSINESS) {
          history.push(ROUTES.BEFORE_WE_START);
        } else {
          history.push(ROUTES.GETTING_STARTED);
        }
      }
    }
  }

  submitConsent = (consentArray) => {
    const {
      doConsents,
      inSessionJointApplicant,
      doSetInSessionJointApplicant,
      history,
    } = this.props;
    const dataObject = {};

    consentArray.map((data) => Object.assign(dataObject, data));

    if (inSessionJointApplicant) {
      const inSessionData = { ...inSessionJointApplicant, consent: dataObject };
      doSetInSessionJointApplicant(inSessionData);
      history.push(ROUTES.GETTING_STARTED);
    } else {
      doConsents(dataObject);
    }
  };

  render() {
    const {
      history,
      showVault,
      inSessionJointApplicant,
      products,
      getActiveProduct,
      bankingType,
      finInfo,
    } = this.props;
    const { linkTermsArray, checkboxArray, steps, activeStepID } = this.state;
    const activeProduct = getActiveProduct(products);
    return (
      <TermsAndConditions
        showVault={showVault}
        history={history}
        submitConsent={this.submitConsent}
        linkTermsArray={linkTermsArray}
        checkboxArray={checkboxArray}
        inSessionJointApplicant={inSessionJointApplicant}
        steps={steps}
        activeStepID={activeStepID}
        activeProduct={activeProduct}
        bankingType={bankingType}
        finInfo={finInfo}
      />
    );
  }
}

TermsAndConditionsContainer.propTypes = {
  doConsents: PropTypes.func,
  fetchJsonDatas: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  showVault: PropTypes.bool,
  termsState: PropTypes.oneOfType([PropTypes.object]),
  doSetInSessionJointApplicant: PropTypes.func.isRequired,
  inSessionJointApplicant: PropTypes.oneOfType([PropTypes.object]),
  applicantData: PropTypes.oneOfType([PropTypes.object]),
};

TermsAndConditionsContainer.defaultProps = {
  doConsents: () => {},
  fetchJsonDatas: () => {},
  history: {},
  showVault: false,
  termsState: {},
};
const mapDispatchToProps = (dispatch) => {
  return {
    doConsents: (consentDetails) => dispatch(doConsent(consentDetails)),
    fetchJsonDatas: () => dispatch(fetchJsonData()),
    doSetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
  };
};
const mapStateToProps = (state) => ({
  termsState: state.TermsAndConditionReducer,
  jsonState: state.TermsJsonReducer,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  applicantData: state.ApplicationReducer.response,
  bankingType: state.MarketplaceReducer.bankingType,
  finInfo: state.MarketplaceReducer.finInfo,
});

const TermsAndConditionsWrapper = withApplicantHOC(TermsAndConditionsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditionsWrapper);
