import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import ProfileEscRequest from "../../Communication/ApiClasses/ProfileEscRequest";
import STRINGS from "Constants/Strings";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const waitMessages = [
  STRINGS.BUSINESS_LOOKUP.FORM.WAIT_MESSAGE,
  STRINGS.BUSINESS_LOOKUP.FORM.WAIT_MESSAGE_1,
  STRINGS.BUSINESS_LOOKUP.FORM.WAIT_MESSAGE_2,
  STRINGS.BUSINESS_LOOKUP.FORM.WAIT_MESSAGE_3,
];

// worker
function* handleAction(action) {
  yield put(doLoadingStart(actionTypes.ESC_PROFILE, waitMessages[0]));

  const profileEscRequest = new ProfileEscRequest(action.data);
  try {
    let success = false;
    const delayTime = 5000;
    yield delay(delayTime);
    let ctr = 0;
    while (!success) {
      const result = yield call(profileEscRequest.getData);
      if (result.data.status === "Complete") {
        yield put({
          type: `${actionTypes.ESC_PROFILE}_SUCCESS`,
          response: result.data,
        });
        yield call(action.callback, result);
        success = true;
      } else {
        ctr += 1;
        if (action.data.retry && action.data.retry <= ctr) {
          yield put({
            type: `${actionTypes.ESC_PROFILE}_SUCCESS`,
            response: result.data,
          });
          yield call(action.callback, result);
          success = true;
        } else {
          yield delay(delayTime);
          if (ctr < 5) {
            yield put(
              doLoadingStart(actionTypes.ESC_PROFILE, waitMessages[ctr])
            );
          }
        }
      }
    }
  } catch (error) {
    yield put({
      type: `${actionTypes.ESC_PROFILE}_ERROR`,
      error,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.ESC_PROFILE));
  }
}

// watcher
export default function* handleSaga() {
  yield takeLatest(actionTypes.ESC_PROFILE, handleAction);
}
