import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";
import { Typeahead } from "react-bootstrap-typeahead";

class BusinessInfoMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessMailingStreetName: "",
      businessMailingSuiteNo: "",
      businessMailingCity: "",
      businessMailingProvince: "",
      businessMailingPostalCode: "",
      selectedAddress: "",
      addressSearchResult: [],
      showSearchResult: false,
      addressInstanceKey: "",
      allowAddressInfoEdit: true,
      executeAdressSearch: true,
      blockAddressEdit: false,
      errors: {
        businessMailingStreetNameErrorList: [],
        businessMailingSuiteNoErrorList: [],
        businessMailingCityErrorList: [],
        businessMailingProvinceErrorList: [],
        businessMailingPostalCodeErrorList: [],
      },
    };
  }

  componentDidMount() {
    const { getDataFromServer, doAddressKey } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_INFO_MAIL,
      (response) => {
        console.log(response);
      }
    );

    doAddressKey((response) => {
      let addressInstanceKey = "";
      if (response.data.Output && response.data.Output.length > 0) {
        addressInstanceKey = response.data.Output[0].InstanceKey;
      }
      this.setState({ addressInstanceKey });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { applicantData } = this.props;

    if (applicantData && applicantData !== prevProps.applicantData) {
      this.setFormData(applicantData);
    }
  }

  setFormData = (data) => {
    console.log("setFormData", data);
    const selectedAddress = [];
    selectedAddress.push({
      label: data.address?.mailingAddressLine1,
      AddressLine1: data.address?.mailingAddressLine1,
      CurrentAddressStreetName: data.address?.mailingAddressLine1,
      StateProvince: data.address?.mailingProvince,
      PostalCode: data.address?.mailingPostalCode,
      City: data.address?.mailingCity,
      Unit: data.address?.mailingAddressLine2,
    });
    console.log("selectedAddress", selectedAddress);
    this.setState({
      selectedAddress,
      businessMailingStreetName: data.address.mailingAddressLine1,
      businessMailingSuiteNo: data.address.mailingAddressLine2,
      businessMailingCity: data.address.mailingCity,
      businessMailingProvince: data.address.mailingProvince,
      businessMailingPostalCode: data.address.mailingPostalCode,
    });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const {
      businessMailingStreetName,
      businessMailingSuiteNo,
      businessMailingCity,
      businessMailingProvince,
      businessMailingPostalCode,
    } = this.state;

    let valid = this.verifyData();

    if (valid) {
      handleContinue({
        contact: {},
        address: {
          mailingAddressLine1: businessMailingStreetName,
          mailingAddressLine2: businessMailingSuiteNo,
          mailingCity: businessMailingCity,
          mailingProvince: businessMailingProvince,
          mailingPostalCode: businessMailingPostalCode,
        },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_INFO_MAIL);
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    // Capitalize each word for the 'businessCity' field while preserving user-entered capitals
    if (eventName === "businessMailingCity") {
      eventValue = eventValue
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    }

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  doFindAddress = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      businessMailingStreetName,
      businessMailingProvince,
      businessMailingPostalCode,
      businessMailingCity,
      country,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress", value, eventValue);
    this.setState(
      { selectedAddress: [], businessMailingStreetName: eventValue },
      () => {
        if (value && value.length > 4) {
          const params = {
            InstanceKey: addressInstanceKey,
            AddressLine1: value,
            Country: "Canada",
          };
          const addressSearchResult = [];
          doGetAddress(params, (response) => {
            console.log("doGetAddress", response);
            const addressRes = response.data.Output;
            console.log("doGetAddress", addressRes);
            if (addressRes && addressRes.length > 0 && executeAdressSearch) {
              addressRes.forEach((raw, idx) => {
                console.log("Address", raw.Status, raw.AddressLine1);
                if (raw?.Status !== "F") {
                  if (raw.Ranges && raw.Ranges.length > 0) {
                    console.log("Ranges", raw.Ranges);
                    raw.Ranges.forEach((range, idx) => {
                      const streetNo =
                        range.Range?.indexOf("-") > -1
                          ? range.Range.split("-")[1]
                          : "";
                      const address = {
                        label: range.FormattedRangeAddress,
                        AddressLine1: range.FormattedRangeAddress.split(",")[0],
                        StreetName: streetNo
                          ? raw.AddressLine1.substring(streetNo.length)?.trim()
                          : raw.AddressLine1,
                        StateProvince: raw.StateProvince,
                        PostalCode: raw.PostalCode,
                        City: raw.City,
                        ProcessedBy: raw.ProcessedBy,
                        Country: finInfo.country[raw.Country],
                        Unit:
                          range.Range?.indexOf("-") > -1
                            ? range.Range.split("-")[0]
                            : "",
                      };
                      // console.log("Current Address", address);
                      if (range.UnitsInfo) {
                        range.UnitsInfo.forEach((unit) => {
                          addressSearchResult.push({
                            ...address,
                            label: unit.FormattedUnitAddress,
                            Unit: unit.UnitInfo,
                          });
                        });
                      } else {
                        addressSearchResult.push(address);
                      }
                    });
                  } else {
                    const address = {
                      label:
                        raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === 0
                          ? `${raw.AddressLine1}, ${raw.City}, ${raw.StateProvince}, ${raw.PostalCode}`
                          : `${raw.HouseNumber ? raw.HouseNumber : ""} ${
                              raw.AddressLine1
                            }, ${raw.City}, ${raw.StateProvince}, ${
                              raw.PostalCode
                            }`,
                      AddressLine1: raw.AddressLine1,
                      StreetName:
                        raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === 0
                          ? raw.AddressLine1.substring(
                              raw.HouseNumber.length
                            )?.trim()
                          : raw.AddressLine1,
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit: "",
                    };
                    addressSearchResult.push(address);
                  }
                }
              });
            }
            const address = {
              label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
              AddressLine1: eventValue,
              StateProvince: businessMailingProvince,
              PostalCode: businessMailingPostalCode,
              StreetName: businessMailingStreetName,
              City: businessMailingCity,
              Country: country,
            };
            addressSearchResult.push(address);
            console.log("addressSearchResult", addressSearchResult);
            this.setState({ addressSearchResult, showSearchResult: true });
          });
        }
      }
    );
  };

  handleChangeAddress = (value) => {
    const { errors } = this.state;
    console.log("handleChangeAddress", value);
    if (value && value.length > 0) {
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].AddressLine1,
          AddressLine1: value[0].AddressLine1,
          CurrentAddressStreetName: value[0].StreetName,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
          Unit: value[0].Unit,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress,
          businessMailingCity: value[0].City,
          businessMailingPostalCode: value[0].PostalCode,
          businessMailingProvince: value[0].StateProvince,
          businessMailingSuiteNo: value[0].Unit,
          businessMailingStreetName: value[0].AddressLine1,
          showSearchResult: false,
          blockAddressEdit: false,
          errors: {
            ...errors,
            businessMailingCityErrorList: [],
            businessMailingPostalCodeErrorList: [],
            businessMailingProvinceErrorList: [],
            businessMailingSuiteNoErrorList: [],
            businessMailingStreetNameErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit: false,
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  verifyData = () => {
    const {
      businessMailingStreetName,
      businessMailingSuiteNo,
      businessMailingCity,
      businessMailingProvince,
      businessMailingPostalCode,
      errors,
    } = this.state;
    let valid = true;
    console.log("verifyData", businessMailingStreetName);

    const businessStreetNameRes = checkErrors(
      businessMailingStreetName,
      VALIDATIONS.BUSINESS_INFO_MAIL.STREET_NAME
    );

    // const businessSuiteNoRes = checkErrors(
    //   businessMailingSuiteNo,
    //   VALIDATIONS.BUSINESS_INFO_MAIL.SUITE_NO
    // );

    const businessCityRes = checkErrors(
      businessMailingCity,
      VALIDATIONS.BUSINESS_INFO_MAIL.CITY
    );

    const businessProvinceRes = checkErrors(
      businessMailingProvince,
      VALIDATIONS.BUSINESS_INFO_MAIL.PROVINCE
    );

    const businessPostalCodeRes = checkErrors(
      businessMailingPostalCode,
      VALIDATIONS.BUSINESS_INFO_MAIL.POSTAL_CODE
    );

    this.setState({
      errors: {
        ...errors,
        businessMailingStreetNameErrorList: businessStreetNameRes,
        // businessMailingSuiteNoErrorList: businessSuiteNoRes,
        businessMailingCityErrorList: businessCityRes,
        businessMailingProvinceErrorList: businessProvinceRes,
        businessMailingPostalCodeErrorList: businessPostalCodeRes,
      },
    });

    if (
      businessStreetNameRes.length > 0 ||
      // businessSuiteNoRes.length > 0 ||
      businessCityRes.length > 0 ||
      businessProvinceRes.length > 0 ||
      businessPostalCodeRes.length > 0
    ) {
      valid = false;
    }
    return valid;
  };

  render() {
    const { steps, activeStepID, provinceList } = this.props;
    const {
      businessMailingStreetName,
      businessMailingSuiteNo,
      businessMailingCity,
      businessMailingProvince,
      businessMailingPostalCode,
      selectedAddress,
      addressSearchResult,
      showSearchResult,
      allowAddressInfoEdit,
      blockAddressEdit,
      errors,
    } = this.state;
    console.log(steps, activeStepID);

    const selectProvinceList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
      >
        {elem.description}
      </option>
    ));

    const actionComponent = (
      <div className="[ business-mail-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_INFO_MAIL.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_INFO_MAIL.BUSINESS_MAILING_ADDRESS}
          </div>

          <div className="[ row ] [ mb-3 ]">
            <div className="[ col-12 ]">
              <div className="[ alert alert-info ]">
                <div className="[ alert-heading ]">
                  {STRINGS.BUSINESS_INFO_MAIL.FOR_REVIEW_HEADER}
                </div>
                <p>{STRINGS.BUSINESS_INFO_MAIL.FOR_REVIEW_DESCRIPTION}</p>
                <p>{STRINGS.BUSINESS_INFO_MAIL.FOR_REVIEW_DESCRIPTION1}</p>
              </div>
            </div>
          </div>

          <div className="[ row ] [ mb-3 ]">
            <div className="[ col-md-4 order-md-2 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_INFO_MAIL.FORM.SUITE_NO_LABEL}
              </div>
              <input
                type="text"
                name="businessMailingSuiteNo"
                className="[ form-control ]"
                placeholder={
                  STRINGS.BUSINESS_INFO_MAIL.FORM.SUITE_NO_PLACEHOLDER
                }
                value={businessMailingSuiteNo}
                onChange={this.handleChange}
              />
              <div className="[ error-msg ]">
                {errors?.businessMailingSuiteNoErrorList[0]}
              </div>
            </div>
            <div className="[ col-md-8 order-md-1 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_INFO_MAIL.FORM.STREET_NAME_LABEL}
              </div>
              <Typeahead
                id="basic-typeahead-single"
                className="[ address-suggestions ]"
                clearButton={false}
                onChange={this.handleChangeAddress}
                onInputChange={this.doFindAddress}
                options={addressSearchResult}
                labelKey="label"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS_PLACEHOLDER
                }
                selected={selectedAddress}
                emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                open={showSearchResult}
                filterBy={() => true}
                inputProps={{
                  readOnly: !allowAddressInfoEdit,
                }}
                onBlur={() =>
                  this.setState({
                    showSearchResult: false,
                  })
                }
              />
              {/* <input
                type="text"
                name="businessMailingStreetName"
                className="[ form-control ]"
                placeholder={
                  STRINGS.BUSINESS_INFO_MAIL.FORM.STREET_NAME_LABEL
                }
                value={businessMailingStreetName}
                onChange={this.handleChange}
              /> */}
              <div>
                <small>
                  {STRINGS.BUSINESS_INFO_MAIL.FORM.SEARCH_MESSAGE}{" "}
                  {STRINGS.BUSINESS_INFO_MAIL.FORM.PHYSICAL_ADDRESS_REQ}
                </small>
              </div>
              <div className="[ error-msg ]">
                {errors?.businessMailingStreetNameErrorList[0]}
              </div>
            </div>
            <div className="[ col-md-4 order-md-3 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_INFO_MAIL.FORM.CITY_LABEL}
              </div>
              <input
                type="text"
                name="businessMailingCity"
                className="[ form-control ]"
                placeholder={STRINGS.BUSINESS_INFO_MAIL.FORM.CITY_LABEL}
                value={businessMailingCity}
                onChange={this.handleChange}
              />
              <div className="[ error-msg ]">
                {errors?.businessMailingCityErrorList[0]}
              </div>
            </div>
            <div className="[ col-md-4 order-md-4 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_INFO_MAIL.FORM.PROVINCE_LABEL}
              </div>
              <select
                value={businessMailingProvince}
                name={"businessMailingProvince"}
                className={
                  errors?.provinceErrorList &&
                  errors?.provinceErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.handleChange}
                id={"province"}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {selectProvinceList}
              </select>
              <div className="[ error-msg ]">
                {errors?.businessMailingProvinceErrorList[0]}
              </div>
            </div>
            <div className="[ col-md-4 order-md-5 ] [ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_INFO_MAIL.FORM.POSTAL_CODE_LABEL}
              </div>
              <MaskedInput
                mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                className={
                  errors?.businessMailingPostalCodeErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                    : "[ form-control ] [ postalCode ]"
                }
                placeholder={STRINGS.BUSINESS_INFO_MAIL.FORM.POSTAL_CODE_LABEL}
                guide={false}
                id="businessMailingPostalCode"
                name="businessMailingPostalCode"
                value={businessMailingPostalCode}
                onChange={this.handleChange}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">
                {errors?.businessMailingPostalCodeErrorList[0]}
              </div>
            </div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessInfoMail.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doGetProvinceData: PropTypes.func,
  doGetAddress: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessInfoMail.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  doGetProvinceData: () => {},
  doGetAddress: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessInfoMail;
