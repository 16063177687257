import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessTax from "./BusinessTax";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { HTTP_STATUS, S_PERSONAL } from "../../Communication/Constants";
import BusinessMemberTax from "./BusinessMemberTax";
import { getApplicant } from "../../Redux/Actions/ApplicationAction";

class BusinessTaxContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      personalData,
      continuehandler,
      actionBack,
      handleBack,
      doGetApplicant,
    } = this.props;
    if (personalData?.id) {
      doGetApplicant({ id: personalData?.id, isPersonalData: true });
    } else {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_TAX);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_TAX);
      }
    }
  }

  handleChange = () => {};

  handleContinue = (formDetails) => {
    console.log("handleContinue", formDetails);
    const {
      continuehandler,
      doUpdateApplicant,
      applicantData,
      finInfo,
      personalData,
      doGetApplicant,
    } = this.props;
    const {
      countryOption,
      countryName,
      areYouTaxResidentOfUsa,
      areYouTaxResidentOfOtherCountry,
      ssnUsa,
      ssn,
    } = formDetails;

    const selectedOption =
      countryOption.length > 1
        ? countryOption.find(
            (obj) => obj.taxResidentOtherName === formDetails.countryName
          )
        : countryOption[0];
    console.log("selectedOption", selectedOption);

    let taxResidency = "";
    if (selectedOption?.taxResidencyResult) {
      taxResidency = selectedOption?.taxResidencyResult?.taxResidency;
    }

    const fatcaInfo = {};

    if (countryName) {
      fatcaInfo.fatcaStatus =
        countryName === AppConstants.TAX_INFO.COUNTRY_USA ||
        countryName === AppConstants.TAX_INFO.COUNTRY_US ||
        countryName === AppConstants.TAX_INFO.COUNTRY_AMERICAN ||
        countryName === AppConstants.TAX_INFO.COUNTRY_UNITED_STATES ||
        areYouTaxResidentOfUsa
          ? finInfo.fatca
          : finInfo.noFatcaCrs;
      if (finInfo.useCRSTypeFieldFlag) {
        if (
          countryName === AppConstants.TAX_INFO.COUNTRY_USA ||
          countryName === AppConstants.TAX_INFO.COUNTRY_US ||
          countryName === AppConstants.TAX_INFO.COUNTRY_AMERICAN ||
          areYouTaxResidentOfUsa
        ) {
          fatcaInfo.fatcaType = finInfo.noFatcaCrs;
        } else {
          fatcaInfo.fatcaType = finInfo.oecdCrs;
        }
      } else {
        fatcaInfo.fatcaStatus = finInfo.oecdCrs;
      }
    } else {
      fatcaInfo.fatcaStatus = finInfo.noFatcaCrs;
      if (finInfo.useCRSTypeFieldFlag) {
        fatcaInfo.fatcaType = finInfo.noFatcaCrs;
      }
    }

    const updateRegulatoryFieldsInputModel = [];
    if (finInfo.useRegulatoryFieldsModel) {
      delete fatcaInfo.fatcaType;
      fatcaInfo.fatcaStatus = finInfo.noFatcaCrs;
      if (ssnUsa) {
        fatcaInfo.fatcaStatus = finInfo.fatca;
        taxResidency = finInfo.config.taxResidency.find(
          (item) => item.taxResidentUs
        )?.options[0].taxResidencyResult.taxResidency;
      }
      if (areYouTaxResidentOfOtherCountry) {
        if (!ssnUsa) {
          taxResidency = "";
        }
        updateRegulatoryFieldsInputModel.push({
          Code: 1,
          Value: finInfo.oecdCrs,
          Text: "OECD CRS Status",
          Type: "dropdown",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 2,
          Value: ssn,
          Text: "OECD CRS Tax ID",
          Type: "text",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 3,
          Value: selectedOption?.taxResidentOtherName,
          Text: "OECD Tax Residency",
          Type: "dropdown",
        });
      } else {
        updateRegulatoryFieldsInputModel.push({
          Code: 1,
          Value: finInfo.noFatcaCrs,
          Text: "OECD CRS Status",
          Type: "dropdown",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 2,
          Value: "",
          Text: "OECD CRS Tax ID",
          Type: "text",
        });
        updateRegulatoryFieldsInputModel.push({
          Code: 3,
          Value: "",
          Text: "OECD Tax Residency",
          Type: "dropdown",
        });
      }
    }

    const dispatchObject = {
      member: {
        sin: formDetails && formDetails.sin ? formDetails.sin : "",
      },
      regulatory: {
        ...fatcaInfo,
        taxResidency,
        ssn: formDetails && formDetails.ssn ? formDetails.ssn : "",
        taxResidencyOther: finInfo.useRegulatoryFieldsModel ? "" : countryName,
        updateRegulatoryFieldsInputModel,
      },
    };

    if (finInfo.useRegulatoryFieldsModel) {
      dispatchObject.regulatory.ssn =
        formDetails && formDetails.ssnUsa ? formDetails.ssnUsa : "";
    }
    doUpdateApplicant(dispatchObject, personalData.id, (res) => {
      console.log(res);
      if (res.status === HTTP_STATUS.OK) {
        doGetApplicant({ id: personalData?.id, isPersonalData: true }, () =>
          continuehandler(
            null,
            AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_TAX
          )
        );
      }
    });
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
      applicantData,
      personalData,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessMemberTax
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
          applicantData={applicantData}
          finInfo={finInfo}
          personalData={personalData}
        />
      </>
    );
  }
}

BusinessTaxContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessTaxContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  applicantData: state.ApplicationReducer.response,
  finInfo: state.MarketplaceReducer.finInfo,
  personalData: state.ApplicationReducer.personalData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicant: (payLoad, callback) =>
      dispatch(getApplicant(payLoad, callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessTaxContainer);

const BusinessTaxWrapper = withBusinessHOC(BusinessTaxContainer);

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTaxWrapper);
