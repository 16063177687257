import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import "./Liabilities.scss";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";

class Liabilities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: false,
      formIsValid: false,
      subTypeError: [],
      descriptionError: [],
      valueError: [],
      adjustmentError: [],
      secTypeError: [],
      locLimitError: [],
      loanForDebtError: [],
      monthlyError: [],
      liabilitiesList: [
        {
          subType: "",
          description: "",
          value: "",
          adjustment: "CON",
          // associatedLoan: "",
          secType: "Unsecured",
          primaryResidence: false,
          locLimit: "",
          monthly: "",
          isLoanForDebt: false,
        },
      ],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.LIABILITIES, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {
    console.log("component did update");
    console.log(prevProps);
    const { liabilitiesList, choice } = this.props;

    if (prevProps.liabilitiesList !== liabilitiesList) {
      this.updateFormData(liabilitiesList, choice);
      // this.getAssetList(prevProps.assets);
    }
  }

  updateFormData(liabilitiesList, choice) {
    console.log("updateFormData", liabilitiesList, choice);

    if (liabilitiesList.length > 0) {
      this.setState({
        choice: true,
      });
    }
    const newLiabilitiesList = [];
    liabilitiesList.forEach((liability) => {
      newLiabilitiesList.push({
        subType: liability.subType,
        description: liability.description,
        value: liability.value,
        primaryResidence: liability.isPrimaryResidence,
        financialId: liability.financialId,
        secType: liability.secType,
        adjustment: liability.adjustment,
        locLimit: liability.locLimit,
        monthly: liability.monthly,
        isLoanForDebt: liability.isLoanForDebt,
      });
    });
    this.setState({
      liabilitiesList: newLiabilitiesList,
    });
  }

  setFormData = () => {};

  handleLiabilityChange = (raw, prop) => (event) => {
    console.log(event);
    const { liabilitiesList } = this.state;
    const liability = raw;

    if (prop === "primaryResidence")
      liability[prop] = event.currentTarget.checked;
    else liability[prop] = event.currentTarget.value;

    console.log(liability);
    this.setState({ liabilitiesList, [`${prop}Error`]: [] });
  };

  handleButtonSecuredChange = (raw, val) => {
    const { liabilitiesList } = this.state;
    const liability = raw;
    liability["secType"] = val;

    this.setState({ liabilitiesList });
  };

  handleButtonIsPrimaryResidenceChange = (raw, val) => {
    console.log(raw, val);
    const { liabilitiesList } = this.state;
    const liability = raw;
    liability["primaryResidence"] = val;

    this.setState({ liabilitiesList });
  };

  handleButtonisLoanForDebtChange = (raw, val) => {
    console.log(raw, val);
    const { liabilitiesList } = this.state;
    const liability = raw;
    liability["isLoanForDebt"] = val;

    this.setState({ liabilitiesList });
  };

  validateForm = () => {
    let valid = true;
    const { liabilitiesList, choice } = this.state;
    this.setState({
      formIsValid: true,
      subTypeError: [],
      descriptionError: [],
      valueError: [],
      adjustmentError: [],
      secTypeError: [],
      locLimitError: [],
      monthlyError: [],
    });

    console.log(liabilitiesList.length, choice);
    if (liabilitiesList.length > 0 && choice) {
      console.log("checking errors");
      // Check error for Sub Type
      let error = checkErrors(
        liabilitiesList[liabilitiesList.length - 1].subType,
        VALIDATIONS.LIABILITIES.SUB_TYPE
      );
      if (error.length) {
        valid = false;
        this.setState({ subTypeError: error });
      }

      // Check error for Description
      error = checkErrors(
        liabilitiesList[liabilitiesList.length - 1].description,
        VALIDATIONS.LIABILITIES.DESCRIPTION
      );
      if (error.length) {
        valid = false;
        this.setState({ descriptionError: error });
      }

      // Check error for Value
      error = checkErrors(
        liabilitiesList[liabilitiesList.length - 1].value,
        VALIDATIONS.LIABILITIES.VALUE
      );
      if (error.length) {
        valid = false;
        this.setState({ valueError: error });
      }

      // Check error for secured type
      // error = checkErrors(
      //   liabilitiesList[liabilitiesList.length - 1].secType,
      //   VALIDATIONS.LIABILITIES.SECURED_TYPE
      // );
      // if (error.length) {
      //   valid = false;
      //   this.setState({ secTypeError: error });
      // }

      // Check error for adjustment
      // error = checkErrors(
      //   liabilitiesList[liabilitiesList.length - 1].adjustment,
      //   VALIDATIONS.LIABILITIES.ADJUSTMENT
      // );
      // if (error.length) {
      //   valid = false;
      //   this.setState({ adjustmentError: error });
      // }

      // Check error for locLimit
      error = checkErrors(
        liabilitiesList[liabilitiesList.length - 1].locLimit,
        VALIDATIONS.LIABILITIES.LIMIT
      );
      if (error.length) {
        valid = false;
        this.setState({ locLimitError: error });
      }

      error = checkErrors(
        liabilitiesList[liabilitiesList.length - 1].monthly,
        VALIDATIONS.LIABILITIES.AVG_PAYMENT
      );
      if (error.length) {
        valid = false;
        this.setState({ monthlyError: error });
      }

      // Check error for loan for debt
      console.log(
        "isLoanForDebt",
        liabilitiesList[liabilitiesList.length - 1].isLoanForDebt
      );

      this.setState({
        formIsValid: valid,
      });
    }

    return valid;
  };

  handleAddLiability = () => {
    if (this.validateForm()) {
      const { activeProduct, config } = this.props;
      let adjustment = null;
      let associatedLoanId = null;
      if (activeProduct.loanPurposeCode === config.consolidateDebtCode) {
        adjustment = "CON";
        associatedLoanId = activeProduct.doximProductId;
      }
      const liability = {
        subType: "",
        description: "",
        value: "",
        adjustment,
        associatedLoanId,
        primaryResidence: false,
        locLimit: "",
        secType: "Unsecured",
        monthly: "",
        isLoanForDebt: false,
      };
      const action = {
        type: "add",
        liability,
      };
      this.updateliabilitiesList(action);
    }
  };

  handleDeleteLiability = (idx) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateliabilitiesList(action);
  };

  // eslint-disable-next-line react/sort-comp
  updateliabilitiesList(action) {
    const { liabilitiesList, choice } = this.state;
    let newChoice = { ...choice };
    if (action.type === "add") {
      liabilitiesList.push(action.liability);
    } else if (action.type === "remove") {
      if (action.index === liabilitiesList.length - 1) {
        this.setState({
          subTypeError: [],
          descriptionError: [],
          valueError: [],
          adjustmentError: [],
          secTypeError: [],
          locLimitError: [],
          loanForDebtError: [],
          monthlyError: [],
        });
      }
      if (action.index > -1) {
        liabilitiesList.splice(action.index, 1);
        if (liabilitiesList.length === 0) {
          newChoice = false;
        }
      }
    }
    this.setState({ liabilitiesList, choice: newChoice });
  }

  handleContinue = () => {
    const { handleContinue } = this.props;

    if (this.validateForm()) {
      console.log(this.state.liabilitiesList);
      handleContinue({
        liabilitiesList: this.state.liabilitiesList,
      });
    }
  };

  handleLiabilitiesButtonChange = (value) => {
    console.log(value);
    const { handleLiabilitiesButtonChange } = this.props;
    const { liabilitiesList } = this.state;
    if (!value) {
      this.setState({ liabilitiesList: [] });
    } else {
      this.handleAddLiability();
    }
    this.setState({ choice: value });
    console.log(this.state.liabilitiesList);
    handleLiabilitiesButtonChange(value);
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LIABILITIES);
  };

  render() {
    const {
      choice,
      liabilitiesList,
      subTypeError,
      descriptionError,
      valueError,
      adjustmentError,
      locLimitError,
      secTypeError,
      monthlyError,
      loanForDebtError,
    } = this.state;
    const {
      steps,
      activeStepID,
      liabilityTypes,
      activeProduct,
      config,
    } = this.props;

    const adjustmentList = ["CON", "EXC", "NEW"];
    // const associatedLoanList = ["Associated Loan1", "Associated Loan2"];
    const primaryResidenceList = ["Yes", "No"];
    const secTypeList = ["Unsecured", "Secured"];

    const subTypeComponent = liabilityTypes.map((subType, index) => (
      <option value={subType.code} className="[ dropdown__option ]">
        {subType.description}
      </option>
    ));
    const adjusmentListComponent = adjustmentList.map(
      (adjustmentType, index) => (
        <option value={adjustmentType} className="[ dropdown__option ]">
          {adjustmentType}
        </option>
      )
    );
    const secTypeListComponent = secTypeList.map((secType, index) => (
      <option value={secType} className="[ dropdown__option ]">
        {secType}
      </option>
    ));
    // const associatedLoanListComponent = associatedLoanList.map(
    //   (associatedLoanType, index) => (
    //     <option value={associatedLoanType} className="[ dropdown__option ]">
    //       {associatedLoanType}
    //     </option>
    //   )
    // );
    const primaryResidenceListComponent = primaryResidenceList.map(
      (primaryResidenceType, index) => (
        <option value={primaryResidenceType} className="[ dropdown__option ]">
          {primaryResidenceType}
        </option>
      )
    );

    const loanPayOffComponent = (liability, index) => (
      <>
        {activeProduct.loanPurposeCode === config.consolidateDebtCode && (
          <>
            <div className="[ col-12 col-lg-12 ]">
              <div className="[ form-label ]">
                {
                  STRINGS.LIABILITIES.FORM
                    .NEW_LOAN_USED_TO_PAY_OFF_LIABILITY_LABEL
                }
              </div>
              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      liability.isLoanForDebt
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isLoanForDebt"
                    onClick={(event) =>
                      this.handleButtonisLoanForDebtChange(liability, true)
                    }
                  >
                    {STRINGS.LIABILITIES.FORM.YES}
                  </button>
                </div>
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      !liability.isLoanForDebt
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isLoanForDebt"
                    onClick={(event) =>
                      this.handleButtonisLoanForDebtChange(liability, false)
                    }
                  >
                    {STRINGS.LIABILITIES.FORM.NO}
                  </button>
                </div>
                <div className="[ error-msg ]"></div>
              </div>
            </div>
          </>
        )}
      </>
    );

    const addliabilitiesListComponent = liabilitiesList.map(
      (liability, index) => (
        <div className="[ drawer-content-item ]">
          <div className="[ info-title ]">
            {/* CHRIS NOTE: For some reason, index + 1 doesn't want to work here. {index + 1} Please include */}
            {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE_NO} {index + 1}
            <button
              type="button"
              className="[ fas fa-trash ]"
              onClick={() => this.handleDeleteLiability(index)}
              aria-label="remove"
            >
              <span className="[ sr-only ]">
                {STRINGS.LIABILITIES.FORM.REMOVE_LIABILITY}
              </span>
            </button>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              {/* LIABILITY TYPE */}
              <div className="[ col-12 col-lg-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE}
                </div>
                <select
                  value={liability.subType ? liability.subType : ""}
                  className="[ form-control ] [ select__dropdown ]"
                  onChange={this.handleLiabilityChange(liability, "subType")}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE_DEFAULT_SELECT}
                  </option>
                  {subTypeComponent}
                </select>
                <div className="[ error-msg ]">
                  {subTypeError
                    ? index + 1 === liabilitiesList.length
                      ? subTypeError[0]
                      : ""
                    : ""}
                </div>
              </div>
            </div>

            {/*  /!* ADJUSTMENT *!/*/}
            {/*  <div className="[ col-12 col-lg-6 ]">*/}
            {/*    <div className="[ form-label ]">*/}
            {/*      {STRINGS.LIABILITIES.FORM.ADJUSTMENT}*/}
            {/*    </div>*/}
            {/*    <select*/}
            {/*      value={liability.adjustment ? liability.adjustment : ""}*/}
            {/*      className=" [ form-control ] [ select__dropdown ] "*/}
            {/*      onChange={this.handleLiabilityChange(liability, "adjustment")}*/}
            {/*    >*/}
            {/*      <option value="" className="[ default ] [ dropdown__option ]">*/}
            {/*        {STRINGS.LIABILITIES.FORM.ADJUSTMENT_TYPE_DEFAULT_SELECT}*/}
            {/*      </option>*/}
            {/*      {adjusmentListComponent}*/}
            {/*    </select>*/}
            {/*    <div className="[ error-msg ]">*/}
            {/*      {adjustmentError*/}
            {/*        ? index + 1 === liabilitiesList.length*/}
            {/*          ? adjustmentError[0]*/}
            {/*          : ""*/}
            {/*        : ""}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/* SECURED TYPE */}
            {/* <div className="[ row ]">
            <div className="[ col-12 col-lg-6 ]">
              <div className="[ form-label ]">
                {STRINGS.LIABILITIES.FORM.SECURED_LABEL}
              </div>
              <select
                value={liability.secType ? liability.secType : ""}
                className=" [ form-control ] [ select__dropdown ] "
                onChange={this.handleLiabilityChange(liability, "secType")}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.LIABILITIES.FORM.SECURED_TYPE_DEFAULT_SELECT}
                </option>
                {secTypeListComponent}
              </select>
              <div className="[ error-msg ]">
                {secTypeError
                  ? index + 1 === liabilitiesList.length
                    ? secTypeError[0]
                    : ""
                  : ""}
              </div>
            </div>
          </div> */}

            {/* SECURED */}
            {/*<div className="[ row ] [ loan ]">*/}
            {/*  <div className="[ col-12 col-lg-12 ]">*/}
            {/*    <div className="[ form-label ]">*/}
            {/*      {STRINGS.LIABILITIES.FORM.SECURED_LABEL}*/}
            {/*    </div>*/}
            {/*    <div className="[ row ] [ custom-radios ]">*/}
            {/*      <div className="[ col-6 ]">*/}
            {/*        <button*/}
            {/*          type="button"*/}
            {/*          className={*/}
            {/*            liability.secType !== "" &&*/}
            {/*            liability.secType === "Secured"*/}
            {/*              ? "[ btn custom-radio-selected ]"*/}
            {/*              : "[ btn custom-radio-unselected ]"*/}
            {/*          }*/}
            {/*          name="secType"*/}
            {/*          onClick={(event) =>*/}
            {/*            this.handleButtonSecuredChange(liability, "Secured")*/}
            {/*          }*/}
            {/*        >*/}
            {/*          {STRINGS.LIABILITIES.FORM.SECURED}*/}
            {/*        </button>*/}
            {/*      </div>*/}
            {/*      <div className="[ col-6 ]">*/}
            {/*        <button*/}
            {/*          type="button"*/}
            {/*          className={*/}
            {/*            liability.secType !== "" &&*/}
            {/*            liability.secType === "Unsecured"*/}
            {/*              ? "[ btn custom-radio-selected ]"*/}
            {/*              : "[ btn custom-radio-unselected ]"*/}
            {/*          }*/}
            {/*          name="isSecured"*/}
            {/*          onClick={(event) =>*/}
            {/*            this.handleButtonSecuredChange(liability, "Unsecured")*/}
            {/*          }*/}
            {/*        >*/}
            {/*          {STRINGS.LIABILITIES.FORM.UNSECURED}*/}
            {/*        </button>*/}
            {/*      </div>*/}
            {/*      <div className="[ error-msg ]"></div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/* If Mortgage */}
            {liability.subType === "Mortgage" ||
            liability.subType === "Mortage" ? (
              <div className="[ row ] [ loan ]">
                {/* Primary Residence */}
                <div className="[ col-12 col-lg-12 ]">
                  <div className="[ form-label ]">
                    {STRINGS.LIABILITIES.FORM.PRIMARY_RESIDENCE}
                  </div>
                  <div className="[ row ] [ custom-radios ]">
                    <div className="[ col-6 ]">
                      <button
                        type="button"
                        className={
                          liability.primaryResidence
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        name="primaryResidence"
                        onClick={(event) =>
                          this.handleButtonIsPrimaryResidenceChange(
                            liability,
                            true
                          )
                        }
                      >
                        {STRINGS.LIABILITIES.FORM.YES}
                      </button>
                    </div>
                    <div className="[ col-6 ]">
                      <button
                        type="button"
                        className={
                          !liability.primaryResidence
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        name="primaryResidence"
                        onClick={(event) =>
                          this.handleButtonIsPrimaryResidenceChange(
                            liability,
                            false
                          )
                        }
                      >
                        {STRINGS.LIABILITIES.FORM.NO}
                      </button>
                    </div>
                    <div className="[ error-msg ]"></div>
                  </div>
                </div>

                {/* DESCRIPTION */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.DESCRIPTION}
                  </div>
                  <input
                    type="text"
                    name="description"
                    className="[ form-control ]"
                    placeholder={STRINGS.LIABILITIES.FORM.DESCRIPTION}
                    onChange={this.handleLiabilityChange(
                      liability,
                      "description"
                    )}
                    value={liability.description ? liability.description : ""}
                  />
                  <div className="[ error-msg ]">
                    {descriptionError
                      ? index + 1 === liabilitiesList.length
                        ? descriptionError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* VALUE */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.VALUE}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="value"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "value")}
                    value={liability.value ? liability.value : ""}
                  />
                  <div className="[ error-msg ]">
                    {valueError
                      ? index + 1 === liabilitiesList.length
                        ? valueError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Loc Limit */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LIMIT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="locLimit"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "locLimit")}
                    value={liability.locLimit ? liability.locLimit : ""}
                  />
                  <div className="[ error-msg ]">
                    {locLimitError
                      ? index + 1 === liabilitiesList.length
                        ? locLimitError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="monthly"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "monthly")}
                    value={liability.monthly ? liability.monthly : ""}
                  />
                  <div className="[ error-msg ]">
                    {monthlyError
                      ? index + 1 === liabilitiesList.length
                        ? monthlyError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {loanPayOffComponent(liability, index)}
              </div>
            ) : (
              ""
            )}

            {/* If Credit Card */}
            {liability.subType === "Credit Card" ||
            liability.subType === "CCard" ? (
              <div className="[ row ] [ loan ]">
                {/* DESCRIPTION */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.CREDIT_CARD.DESCRIPTION}
                  </div>
                  <input
                    type="text"
                    name="description"
                    className="[ form-control ]"
                    placeholder={STRINGS.LIABILITIES.FORM.DESCRIPTION}
                    onChange={this.handleLiabilityChange(
                      liability,
                      "description"
                    )}
                    value={liability.description ? liability.description : ""}
                  />
                  <div className="[ error-msg ]">
                    {descriptionError
                      ? index + 1 === liabilitiesList.length
                        ? descriptionError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* VALUE */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.CREDIT_CARD.VALUE}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="value"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "value")}
                    value={liability.value ? liability.value : ""}
                  />
                  <div className="[ error-msg ]">
                    {valueError
                      ? index + 1 === liabilitiesList.length
                        ? valueError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Loc Limit */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.CREDIT_CARD.LIMIT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="locLimit"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "locLimit")}
                    value={liability.locLimit ? liability.locLimit : ""}
                  />
                  <div className="[ error-msg ]">
                    {locLimitError
                      ? index + 1 === liabilitiesList.length
                        ? locLimitError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="monthly"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "monthly")}
                    value={liability.monthly ? liability.monthly : ""}
                  />
                  <div className="[ error-msg ]">
                    {monthlyError
                      ? index + 1 === liabilitiesList.length
                        ? monthlyError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {loanPayOffComponent(liability, index)}
              </div>
            ) : (
              ""
            )}

            {/* If Vehicle Loan */}
            {liability.subType === "Vehicle Loan" ||
            liability.subType === "Vehicle Loan" ? (
              <div className="[ row ] [ loan ]">
                {/* DESCRIPTION */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.DESCRIPTION}
                  </div>
                  <input
                    type="text"
                    name="description"
                    className="[ form-control ]"
                    placeholder={STRINGS.LIABILITIES.FORM.DESCRIPTION}
                    onChange={this.handleLiabilityChange(
                      liability,
                      "description"
                    )}
                    value={liability.description ? liability.description : ""}
                  />
                  <div className="[ error-msg ]">
                    {descriptionError
                      ? index + 1 === liabilitiesList.length
                        ? descriptionError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* VALUE */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.VALUE}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="value"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "value")}
                    value={liability.value ? liability.value : ""}
                  />
                  <div className="[ error-msg ]">
                    {valueError
                      ? index + 1 === liabilitiesList.length
                        ? valueError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Loc Limit */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.LIMIT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="locLimit"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "locLimit")}
                    value={liability.locLimit ? liability.locLimit : ""}
                  />
                  <div className="[ error-msg ]">
                    {locLimitError
                      ? index + 1 === liabilitiesList.length
                        ? locLimitError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* TERM OF LOAN */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.TERM_OF_LOAN}
                  </div>
                  <input
                    type="text"
                    name="description"
                    className="[ form-control ]"
                    placeholder={STRINGS.LIABILITIES.FORM.TERM_OF_LOAN}
                    onChange={this.handleLiabilityChange(
                      liability,
                      "description"
                    )}
                    value={liability.description ? liability.description : ""}
                  />
                  <div className="[ error-msg ]">
                    {descriptionError
                      ? index + 1 === liabilitiesList.length
                        ? descriptionError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="monthly"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "monthly")}
                    value={liability.monthly ? liability.monthly : ""}
                  />
                  <div className="[ error-msg ]">
                    {monthlyError
                      ? index + 1 === liabilitiesList.length
                        ? monthlyError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {loanPayOffComponent(liability, index)}
              </div>
            ) : (
              ""
            )}

            {/* If LOC or Line of Credit */}
            {liability.subType === "Line of Credit" ||
            liability.subType === "LOC" ? (
              <div className="[ row ] [ loan ]">
                {/* DESCRIPTION */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT.DESCRIPTION}
                  </div>
                  <input
                    type="text"
                    name="description"
                    className="[ form-control ]"
                    placeholder={STRINGS.LIABILITIES.FORM.DESCRIPTION}
                    onChange={this.handleLiabilityChange(
                      liability,
                      "description"
                    )}
                    value={liability.description ? liability.description : ""}
                  />
                  <div className="[ error-msg ]">
                    {descriptionError
                      ? index + 1 === liabilitiesList.length
                        ? descriptionError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* VALUE */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT.VALUE}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="value"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "value")}
                    value={liability.value ? liability.value : ""}
                  />
                  <div className="[ error-msg ]">
                    {valueError
                      ? index + 1 === liabilitiesList.length
                        ? valueError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Loc Limit */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT.LIMIT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="locLimit"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "locLimit")}
                    value={liability.locLimit ? liability.locLimit : ""}
                  />
                  <div className="[ error-msg ]">
                    {locLimitError
                      ? index + 1 === liabilitiesList.length
                        ? locLimitError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="monthly"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "monthly")}
                    value={liability.monthly ? liability.monthly : ""}
                  />
                  <div className="[ error-msg ]">
                    {monthlyError
                      ? index + 1 === liabilitiesList.length
                        ? monthlyError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {loanPayOffComponent(liability, index)}
              </div>
            ) : (
              ""
            )}

            {/* If LOAN or Personal Loan */}
            {liability.subType === "Loan" ||
            liability.subType === "Personal Loan" ? (
              <div className="[ row ] [ loan ]">
                {/* Primary Residence */}
                <div className="[ col-12 col-lg-12 ]">
                  <div className="[ form-label ]">
                    {STRINGS.LIABILITIES.FORM.PRIMARY_RESIDENCE}
                  </div>
                  <div className="[ row ] [ custom-radios ]">
                    <div className="[ col-6 ]">
                      <button
                        type="button"
                        className={
                          liability.primaryResidence !== "" &&
                          liability.primaryResidence === true
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        name="primaryResidence"
                        onClick={(event) =>
                          this.handleButtonIsPrimaryResidenceChange(
                            liability,
                            true
                          )
                        }
                      >
                        {STRINGS.LIABILITIES.FORM.YES}
                      </button>
                    </div>
                    <div className="[ col-6 ]">
                      <button
                        type="button"
                        className={
                          liability.primaryResidence !== "" &&
                          liability.primaryResidence === false
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        name="primaryResidence"
                        onClick={(event) =>
                          this.handleButtonIsPrimaryResidenceChange(
                            liability,
                            false
                          )
                        }
                      >
                        {STRINGS.LIABILITIES.FORM.NO}
                      </button>
                    </div>
                    <div className="[ error-msg ]"></div>
                  </div>
                </div>

                {/* DESCRIPTION */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LOAN.DESCRIPTION}
                  </div>
                  <input
                    type="text"
                    name="description"
                    className="[ form-control ]"
                    placeholder={STRINGS.LIABILITIES.FORM.DESCRIPTION}
                    onChange={this.handleLiabilityChange(
                      liability,
                      "description"
                    )}
                    value={liability.description ? liability.description : ""}
                  />
                  <div className="[ error-msg ]">
                    {descriptionError
                      ? index + 1 === liabilitiesList.length
                        ? descriptionError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* VALUE */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LOAN.VALUE}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="value"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "value")}
                    value={liability.value ? liability.value : ""}
                  />
                  <div className="[ error-msg ]">
                    {valueError
                      ? index + 1 === liabilitiesList.length
                        ? valueError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Loc Limit */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LOAN.LIMIT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="locLimit"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "locLimit")}
                    value={liability.locLimit ? liability.locLimit : ""}
                  />
                  <div className="[ error-msg ]">
                    {locLimitError
                      ? index + 1 === liabilitiesList.length
                        ? locLimitError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Secured or Unsecured */}
                <div className="[ col-12 col-lg-12 ]">
                  <div className="[ form-label ]">
                    {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE}
                  </div>
                  <select
                    value={liability.secType ? liability.secType : ""}
                    className="[ form-control ] [ select__dropdown ]"
                    onChange={this.handleLiabilityChange(liability, "secType")}
                  >
                    <option
                      value=""
                      className="[ default ] [ dropdown__option ]"
                    >
                      {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE_DEFAULT_SELECT}
                    </option>
                    {secTypeListComponent}
                  </select>
                  <div className="[ error-msg ]">
                    {secTypeError
                      ? index + 1 === liabilitiesList.length
                        ? secTypeError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Average Monthly Payment */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="monthly"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "monthly")}
                    value={liability.monthly ? liability.monthly : ""}
                  />
                  <div className="[ error-msg ]">
                    {monthlyError
                      ? index + 1 === liabilitiesList.length
                        ? monthlyError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {loanPayOffComponent(liability, index)}
              </div>
            ) : (
              ""
            )}
            {/* If Other */}
            {liability.subType === "Other" ||
            liability.subType === "Others" ||
            liability.subType === "Guarantor" ||
            liability.subType === "Guar" ? (
              <div className="[ row ] [ loan ]">
                {/* DESCRIPTION */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.DESCRIPTION}
                  </div>
                  <input
                    type="text"
                    name="description"
                    className="[ form-control ]"
                    placeholder={STRINGS.LIABILITIES.FORM.DESCRIPTION}
                    onChange={this.handleLiabilityChange(
                      liability,
                      "description"
                    )}
                    value={liability.description ? liability.description : ""}
                  />
                  <div className="[ error-msg ]">
                    {descriptionError
                      ? index + 1 === liabilitiesList.length
                        ? descriptionError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* VALUE */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.VALUE}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="value"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "value")}
                    value={liability.value ? liability.value : ""}
                  />
                  <div className="[ error-msg ]">
                    {valueError
                      ? index + 1 === liabilitiesList.length
                        ? valueError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                {/* Loc Limit */}
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.LIMIT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="locLimit"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "locLimit")}
                    value={liability.locLimit ? liability.locLimit : ""}
                  />
                  <div className="[ error-msg ]">
                    {locLimitError
                      ? index + 1 === liabilitiesList.length
                        ? locLimitError[0]
                        : ""
                      : ""}
                  </div>
                </div>

                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ form-label ] ">
                    {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                  </div>
                  <NumberFormat
                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    prefix={AppConstants.LOANS.SALARY_PREFIX}
                    name="monthly"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                    }
                    onChange={this.handleLiabilityChange(liability, "monthly")}
                    value={liability.monthly ? liability.monthly : ""}
                  />
                  <div className="[ error-msg ]">
                    {monthlyError
                      ? index + 1 === liabilitiesList.length
                        ? monthlyError[0]
                        : ""
                      : ""}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="[ row ]">
              {/* PRIMARY RESIDENCE - ORIGINAL */}
              {/* CHRIS NOTE: Throughout the application the user has encountered booleans like this with radios as opposed to select dropdowns, or checkboxes. */}
              {/* <div className="[ col-12 col-lg-3 ]">
              <div className="[ form-label ]">
                {STRINGS.LIABILITIES.FORM.PRIMARY_RESIDENCE}
              </div>
              <select
                value={
                  liability.primaryResidence ? liability.primaryResidence : ""
                }
                className=" [ form-control ] [ select__dropdown ] "
                onChange={this.handleLiabilityChange(
                  liability,
                  "primaryResidence"
                )}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.LIABILITIES.FORM.PRIMARY_RESIDENCE_TYPE_DEFAULT_SELECT}
                </option>
                {primaryResidenceListComponent}
              </select>
              <div className="[ error-msg ]" />
            </div> */}

              {/* locLimit */}
              {/* <div className="[ col-12 col-lg-4 ]">
                <div className="[ form-label ] ">
                  {STRINGS.LIABILITIES.FORM.LIMIT}
                </div>
                <NumberFormat
                  maxLength={AppConstants.LOANS.SALARY_LENGTH}
                  type="text"
                  thousandSeparator
                  allowNegative={false}
                  prefix={AppConstants.LOANS.SALARY_PREFIX}
                  name="locLimit"
                  className="[ form-control ]"
                  placeholder={
                    STRINGS.LIABILITIES.FORM.VALUE_AMOUNT_PLACEHOLDER
                  }
                  onChange={this.handleLiabilityChange(liability, "locLimit")}
                  value={liability.locLimit ? liability.locLimit : ""}
                />
                <div className="[ error-msg ]">
                  {locLimitError
                    ? index + 1 === liabilitiesList.length
                      ? locLimitError[0]
                      : ""
                    : ""}
                </div>
              </div> */}
            </div>
          </div>

          <div className="[ divider ] [ mb-3 ]" />
        </div>
      )
    );
    const actionComponent = (
      <div className="[ liabilities-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.LIABILITIES.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.LIABILITIES.PAGE_DESCRIPTION1}{" "}
          </div>
          <div className="[ form-description ]">
            {STRINGS.LIABILITIES.PAGE_DESCRIPTION2}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.LIABILITIES.FORM.LIABILITY_RADIO_LABEL}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) =>
                    this.handleLiabilitiesButtonChange(true, event)
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && !choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) =>
                    this.handleLiabilitiesButtonChange(false, event)
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
              <div className="[ error-msg ]"></div>
            </div>
          </div>

          {choice ? (
            <div className="[ drawer-content-container mb-3 ]">
              {addliabilitiesListComponent}

              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                    <button
                      type="button"
                      className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                      onClick={(event) => this.handleAddLiability(event)}
                    >
                      <span className="[ fas fa-plus ]" />
                      {STRINGS.LIABILITIES.FORM.ADD_BTN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

Liabilities.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleLiabilitiesButtonChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

Liabilities.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default Liabilities;
