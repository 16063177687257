import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import PopupModal from "../Common/PopupModal/PopupModal";
import "./Beneficiaries.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import { format } from "date-fns";
import DateOfBirth from "../Common/DateOfBirth";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

class BeneficiaryDetails extends React.Component {
  constructor(props) {
    super(props);
    const { beneficiary } = this.props;
    this.state = {
      addressSearchResult: [],
      selectedAddress: [
        {
          label: beneficiary.address1,
        },
      ],
      showSearchResult: false,
      blockAddressEdit: true,
      executeAdressSearch: true,
      isMobile: false,
      showHelp: false,
    };
  }

  onChangeHandler = (event) => {
    const { beneficiary, onInputChangeHandler, isEditBeneficiary } = this.props;
    const { showEditAndDeletBtn } = beneficiary;
    console.log("onChangeHandler", isEditBeneficiary, showEditAndDeletBtn);

    if (!isEditBeneficiary && showEditAndDeletBtn) {
      return;
    }
    const { errors } = beneficiary;
    const inputField = event?.target.name;
    let eventValue = event?.target.value;

    // Capitalize words and preserve user-entered capital letters
    if (
      inputField === "firstName" ||
      inputField === "middleName" ||
      inputField === "lastName" ||
      inputField === "city"
    ) {
      eventValue = eventValue
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize only the first letter
        .join(" ");
    }

    const errorList = {
      ...errors,
      [`${inputField}ErrorList`]: [],
    };

    let updatedBeneficiary = {
      ...beneficiary,
      [inputField]: eventValue,
      errors: errorList,
    };

    onInputChangeHandler(updatedBeneficiary);
  };

  onChangePercentage = (percent) => {
    const { beneficiary, onInputChangeHandler, isEditBeneficiary } = this.props;
    const { showEditAndDeletBtn } = beneficiary;
    console.log("onChangePercentage", percent);

    if (!isEditBeneficiary && showEditAndDeletBtn) {
      return;
    }
    const { errors } = beneficiary;
    const errorList = {
      ...errors,
      [`percentEntitlementErrorList`]: [],
    };
    let updatedBeneficiary = {
      ...beneficiary,
    };
    updatedBeneficiary = {
      ...updatedBeneficiary,
      percentEntitlement: percent.floatValue,
      errors: errorList,
    };
    onInputChangeHandler(updatedBeneficiary);
  };

  renderDropDownList = (dropdownList) => {
    const { beneficiary } = this.props;
    const { editMode } = beneficiary;
    return (
      <>
        {dropdownList?.map((item) => (
          <option
            value={item.description}
            key={item.code}
            className="[ dropdown__option ]"
            disabled={!editMode}
          >
            {item.description}
          </option>
        ))}
      </>
    );
  };

  renderDropDownListByCode = (dropdownList) => {
    const { beneficiary } = this.props;
    const { editMode } = beneficiary;
    return (
      <>
        {dropdownList?.map((item) => (
          <option
            value={item.code}
            key={item.code}
            className="[ dropdown__option ]"
            disabled={!editMode}
          >
            {item.description}
          </option>
        ))}
      </>
    );
  };

  doFindAddress = (value, event) => {
    const { addressInstanceKey, executeAdressSearch } = this.state;
    const { doGetAddress, beneficiary } = this.props;
    let eventValue = event?.target.value;
    console.log("doFindAddress", value, eventValue);
    this.setState({ selectedAddress: [] });
    if (value && value.length > 4) {
      const params = {
        InstanceKey: addressInstanceKey,
        AddressLine1: value,
        Country: "Canada",
      };
      const addressSearchResult = [];
      doGetAddress(params, (response) => {
        console.log("doGetAddress", response);
        let addressRes = response.data.Output;
        console.log("doGetAddress", addressRes);
        if (addressRes && addressRes.length > 0 && executeAdressSearch) {
          addressRes.forEach((raw, idx) => {
            if (raw.Status !== "F") {
              const address = {
                label:
                  raw.AddressLine1 +
                  ", " +
                  raw.City +
                  ", " +
                  raw.StateProvince +
                  ", " +
                  raw.PostalCode,
                AddressLine1: raw.AddressLine1,
                StateProvince: raw.StateProvince,
                PostalCode: raw.PostalCode,
                City: raw.City,
                ProcessedBy: raw.ProcessedBy,
                Country: raw.Country,
              };
              addressSearchResult.push(address);
            }
          });
        }
        const address = {
          label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
          AddressLine1: eventValue,
          StateProvince: beneficiary.province,
          PostalCode: beneficiary.postalCode,
          City: beneficiary.city,
        };
        addressSearchResult.push(address);
        console.log("addressSearchResult", addressSearchResult);
        this.setState({ addressSearchResult, showSearchResult: true });
      });
    }
  };

  handleChangeAddress = (value) => {
    console.log("handleChangeAddress", value);
    const { beneficiary, onInputChangeHandler, isEditBeneficiary } = this.props;
    const { showEditAndDeletBtn } = beneficiary;
    if (!isEditBeneficiary && showEditAndDeletBtn) {
      return;
    }
    if (value && value.length > 0) {
      if (value[0].AddressLine1) {
        const { errors } = beneficiary;
        const errorList = {
          ...errors,
          [`address1ErrorList`]: [],
          [`cityErrorList`]: [],
          [`provinceErrorList`]: [],
          [`postalCodeErrorList`]: [],
        };
        let updatedBeneficiary = {
          ...beneficiary,
        };
        updatedBeneficiary = {
          ...updatedBeneficiary,
          address1: value[0].AddressLine1,
          address2: value[0].AddressLine2 ? value[0].AddressLine2 : "",
          city: value[0].City,
          province: value[0].StateProvince,
          postalCode: value[0].PostalCode,
          errors: errorList,
        };
        console.log("updatedBeneficiary", updatedBeneficiary);
        const address = {
          label: value[0].AddressLine1,
          AddressLine1: value[0].AddressLine1,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress: selectedAddress,
          showSearchResult: false,
          blockAddressEdit: false,
        });
        onInputChangeHandler(updatedBeneficiary);
      } else {
        this.setState({
          blockAddressEdit: false,
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  setDOB = (dob) => {
    let dateOfBirth = null;
    if (dob != null) {
      dateOfBirth = format(dob, "yyyy-MM-dd");
    }
    const { beneficiary, onInputChangeHandler, isEditBeneficiary } = this.props;
    const { showEditAndDeletBtn } = beneficiary;
    console.log(
      "setDOB",
      dob,
      dateOfBirth,
      isEditBeneficiary,
      showEditAndDeletBtn
    );
    if (!isEditBeneficiary && showEditAndDeletBtn) {
      return;
    }
    const { errors } = beneficiary;
    const errorList = {
      ...errors,
      dateOfBirthErrorList: [],
    };
    let updatedBeneficiary = {
      ...beneficiary,
    };
    updatedBeneficiary = {
      ...updatedBeneficiary,
      dateOfBirth,
      errors: errorList,
    };
    onInputChangeHandler(updatedBeneficiary);
  };
  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const {
      beneficiary,
      provinceList,
      countryList,
      beneficiaryRelationList,
      beneficiaryTypeList,
      index,
      handleDeleteBeneficiary,
      handleEditClick,
      showSaveAndCancelBtn,
      renderDetails,
      isEditBeneficiary,
      showModal,
      type,
      modal,
      toggleModal,
      contKycFlag,
    } = this.props;
    const {
      addressSearchResult,
      selectedAddress,
      showSearchResult,
      blockAddressEdit,
      isMobile,
      showHelp,
      helpText,
    } = this.state;
    const {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      beneficiaryRelation,
      beneficiaryType,
      address1,
      address2,
      city,
      province,
      postalCode,
      mobileNo,
      sin,
      country,
      showEditAndDeletBtn,
      editMode,
      errors,
      percentEntitlement,
    } = beneficiary;

    const userInformation = (
      <>
        <div className="[ row ] [ mr-0 ] [ pr-1 ]">
          <div className="[ col-6 ]">
            <div className="[ info-title ]">
              {STRINGS.BENEFICIARIES.FORM.BENEFICIARY}
              {index + 1}
            </div>
          </div>
          {showEditAndDeletBtn && (
            <div className="[ col-6 ] [ text-right ] [ pr-0 ]">
              <button
                type="button"
                className={`[ btn edit-btn ] ${
                  !isEditBeneficiary && " [ common-edit-btn_color ]"
                }`}
                disabled={isEditBeneficiary}
                onClick={() => {
                  handleEditClick(beneficiary);
                }}
                aria-label="edit"
              >
                <span className="[ edit-icon ]" />
                <span className="[ edit-btn__label ]">
                  {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                </span>
              </button>
              <span
                className="beneficiary-remove-icon delete_icon pl-3"
                role="presentation"
                onClick={() => {
                  handleDeleteBeneficiary(beneficiary);
                }}
              />
            </div>
          )}
        </div>

        <div className="[ row ] [ pt-2 mb-3 ]">
          <div className="[ form-description col-12 ]">
            {STRINGS.BENEFICIARIES.FORM.NAME_DISCLAIMER}
          </div>
          <div className="[ col-md-3 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.FIRSTNAMELABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <input
                  type="text"
                  name="firstName"
                  className={
                    errors?.firstNameErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  placeholder={STRINGS.BENEFICIARIES.FORM.FIRSTNAMELABEL}
                  value={firstName}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                />
              </div>
              <div className="[ error-msg ] [ d-inline ]">
                {errors?.firstNameErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-md-3 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.MIDDLENAMELABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <input
                  type="text"
                  name="middleName"
                  className="[ form-control form-control-sm ]"
                  placeholder={STRINGS.BENEFICIARIES.FORM.MIDDLENAMELABEL}
                  value={middleName}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                />
                <div className="[ error-msg ]" />
              </div>
            </>
          </div>
          <div className="[ col-md-3 col-12 ] [ info-group ] [ pr-3 ] ">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.LASTNAMELABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <input
                  type="text"
                  name="lastName"
                  className={
                    errors?.lastNameErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  placeholder={STRINGS.BENEFICIARIES.FORM.LASTNAMELABEL}
                  value={lastName}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                />
              </div>
              <div className="[ error-msg ] [ d-inline ]">
                {errors?.lastNameErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-md-3 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.DOBLABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <DateOfBirth
                  dateOfBirth={dateOfBirth}
                  setDOB={this.setDOB}
                  readOnly={!editMode}
                />
              </div>
              <div className="[ error-msg ]">
                {errors?.dateOfBirthErrorList[0]}
              </div>
            </>
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-sm-6 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.RELATIONSHIP}
            </div>
            <>
              <div className="[ info-group__input ]">
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  name="beneficiaryRelation"
                  value={beneficiaryRelation}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.BENEFICIARIES.FORM.RELATIONSHIP_PLACEHOLDER}
                  </option>
                  {this.renderDropDownList(beneficiaryRelationList)}
                </select>
              </div>
              <div className="[ error-msg ]">
                {errors?.beneficiaryRelationErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-sm-4 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.BENEFICIARY_TYPE}
              {!contKycFlag ? (
                <QuestionCircle
                  className={"question-icon more-info-beneficiary"}
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(
                      STRINGS.BENEFICIARIES.FORM.BENEFICIARY_TYPE_INFO
                    )
                  }
                />
              ) : (
                <QuestionCircle
                  className={"question-icon more-info-beneficiary"}
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(
                      STRINGS.BENEFICIARIES.FORM.BENEFICIARY_TYPE_INFO_KYC
                    )
                  }
                />
              )}
            </div>
            <>
              <div className="[ info-group__input ]">
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  name="beneficiaryType"
                  value={beneficiaryType}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.BENEFICIARIES.FORM.BENEFICIARY_PLACEHOLDER}
                  </option>
                  {this.renderDropDownList(beneficiaryTypeList)}
                </select>
              </div>
              <div className="[ error-msg ]">
                {errors?.beneficiaryTypeErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-sm-2 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.PERCENTAGE}
            </div>
            <div className="[ info-group__input ]">
              <NumberFormat
                format="###"
                allowLeadingZeros={false}
                isAllowed={(values, sourceInfo) => {
                  const { value } = values;
                  return value < 101;
                }}
                className={
                  errors?.percentEntitlementErrorList?.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                type="text"
                name="percentEntitlement"
                value={percentEntitlement === 0 ? "" : percentEntitlement}
                placeholder={STRINGS.BENEFICIARIES.FORM.PERCENTAGE}
                onValueChange={(event) => this.onChangePercentage(event)}
                disabled={!editMode}
              />
              <div className="[ error-msg ]">
                {errors?.percentEntitlementErrorList && (
                  <>{errors?.percentEntitlementErrorList[0]}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );

    const countrySelectElem = (
      <select
        name="country"
        value={country}
        className={
          errors?.countryErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={(event) => this.onChangeHandler(event)}
        disabled={!editMode}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.BENEFICIARIES.FORM.SELECT_COUNTRY}
        </option>
        {this.renderDropDownListByCode(countryList)}
      </select>
    );

    const taxResidency = (
      <div className="[ card-body ] [ py-4 ]">
        <div className="[ form-label ] [ py-2 ] [ m-0 ]">
          {STRINGS.BENEFICIARIES.FORM.TAX_RESIDENCY}
        </div>
        <div className="[ row ]">
          <div className="[ col-12 ]">{countrySelectElem}</div>
        </div>

        <div className="[ error-msg ] [ row ]">
          {errors?.countryErrorList && (
            <div className="[ col-12 ]">{errors?.countryErrorList}</div>
          )}
        </div>
        <>
          <div className="[ row ] [ py-2 ]">
            <div className="[ form-label ] [ col-12 ]">
              {STRINGS.BENEFICIARIES.FORM.SIN}
            </div>
          </div>
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <NumberFormat
                format={AppConstants.EMPLOYMENTANDINCOME.TIN_FORMATE}
                className={
                  errors?.sinErrorList.length > 0
                    ? "[ form-control form-control-sm ] [ invalid-input ]"
                    : "[ form-control form-control-sm ]"
                }
                type="text"
                name="sin"
                value={sin}
                placeholder={`${STRINGS.BENEFICIARIES.FORM.SIN_PLACEHOLDER}`}
                onChange={this.onChangeHandler}
                disabled={!editMode}
              />
            </div>
          </div>
          <div className="[ error-msg ] [ row ]">
            {errors?.sinErrorList && (
              <div className="[ col-12 ]">{errors?.sinErrorList[0]}</div>
            )}
          </div>
        </>
      </div>
    );

    const selectList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
        disabled={!editMode}
      >
        {elem.description}
      </option>
    ));

    const selectElement = (
      <select
        name="province"
        value={province}
        className={
          errors?.provinceErrorList.length > 0
            ? "[ form-control form-control-sm ] [ invalid-input ]"
            : "[ form-control form-control-sm ]"
        }
        onChange={(event) => this.onChangeHandler(event)}
        disabled={!editMode || (editMode && blockAddressEdit)}
      >
        <option value="" className="[ dropdown__option ]">
          {" "}
          Select {STRINGS.BENEFICIARIES.FORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const addressInputGroup = (
      <div className="[ address-container__group ]">
        <div className="[ address-container__group__header ]">
          {STRINGS.BENEFICIARIES.FORM.RESIDENCE_ADDRESS}
        </div>

        <div className="[ row ] [ pt-2 ]">
          <div className="[ col-md-8 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
            </div>
            <Typeahead
              id="basic-typeahead-single"
              className="[ address-suggestions ]"
              clearButton={false}
              onChange={this.handleChangeAddress}
              onInputChange={this.doFindAddress}
              options={addressSearchResult}
              labelKey="label"
              placeholder={
                STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS_PLACEHOLDER
              }
              selected={selectedAddress}
              disabled={!editMode}
              emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
              open={showSearchResult}
              filterBy={() => true}
              onBlur={() =>
                this.setState({
                  showSearchResult: false,
                })
              }
            />
            <div>
              <small>
                {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
              </small>
            </div>
            <div className="[ error-msg ]">{errors?.address1ErrorList[0]}</div>
          </div>
          <div className="[ col-md-4 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
            </div>
            <input
              type="text"
              name="address2"
              className="[ form-control ]"
              placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.IF_APPLICABLE}
              value={address2}
              onChange={(event) => this.onChangeHandler(event)}
              disabled={!editMode}
            />
            <div className="[ error-msg ]" />
          </div>
        </div>

        <div className="[ row ]">
          <div className="[ col-12 col-md-6 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
              </div>
              <>
                <input
                  type="text"
                  name="city"
                  className={
                    errors?.cityErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={STRINGS.BENEFICIARIES.FORM.CITY}
                  value={city}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode || (editMode && blockAddressEdit)}
                />
                <div className="[ error-msg ]">{errors?.cityErrorList[0]}</div>
              </>
            </div>
          </div>

          <div className="[ col-12 col-md-3 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
              </div>
              {selectElement}
              <div className="[ error-msg ]">
                {errors?.provinceErrorList[0]}
              </div>
            </div>
          </div>

          <div className="[ col-12 col-md-3 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
              </div>
              <>
                <MaskedInput
                  mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                  className={
                    errors?.postalCodeErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={STRINGS.BENEFICIARIES.FORM.POSTALCODE}
                  guide={false}
                  id="postalCode"
                  name="postalCode"
                  value={postalCode}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode || (editMode && blockAddressEdit)}
                />
                <div className="[ error-msg ]">
                  {errors?.postalCodeErrorList[0]}
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="[ row ] [ mb-3 ]">
          <div className="[ col-12 ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.MOBILENOLABEL}
            </div>

            <>
              <NumberFormat
                format={AppConstants.REGISTRATION.NUMBERFORMAT}
                className={
                  errors?.mobileNoErrorList &&
                  errors?.mobileNoErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                type="text"
                name="mobileNo"
                value={mobileNo}
                placeholder={`${STRINGS.BENEFICIARIES.FORM.MOBILENOPLACEHOLDER}`}
                onChange={(values) => this.onChangeHandler(values)}
                disabled={!editMode}
              />
              <div className="[ error-msg ]">
                {errors?.mobileNoErrorList[0]}
              </div>
            </>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {renderDetails && (
          <div>
            {index !== 0 && (
              <div className="[ beneficiary-container__line-separator ] [ my-2 ]" />
            )}
            <div className="[ pb-2 ] [ justify-content-xl-start justify-content-center ] [ beneficiary-Info ]">
              {userInformation}
            </div>
            <div className="[ adress-container ]">{addressInputGroup}</div>
            <div className="[ tax-residency-container ]">{taxResidency}</div>
            {!editMode && showEditAndDeletBtn
              ? ""
              : showSaveAndCancelBtn && (
                  <div className="[ row ] [ btn-choice ] [ mt-2 ] ">
                    <div className="[ col-6 ]">
                      <button
                        type="button"
                        className="[ btn ] [ w-100 ] [ submit-btn ] [ secondary-submit-btn ] [ text-responsive ]"
                        onClick={() => {
                          const { handleSaveClick } = this.props;
                          handleSaveClick(beneficiary);
                        }}
                      >
                        {STRINGS.BENEFICIARIES.FORM.SAVE}
                      </button>
                    </div>
                    {beneficiary !== "" && beneficiary && (
                      <div className="[ col-6 ]">
                        <button
                          type="button"
                          className="[ btn ] [ w-100 ] [ submit-btn ] [ secondary-submit-btn ] [ text-responsive ]"
                          onClick={() => {
                            const { handleCancelClick } = this.props;
                            handleCancelClick(beneficiary);
                          }}
                        >
                          {STRINGS.BENEFICIARIES.FORM.CANCEL}
                        </button>
                      </div>
                    )}
                  </div>
                )}
          </div>
        )}
        {showHelp && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

BeneficiaryDetails.propTypes = {
  beneficiary: PropTypes.oneOfType(PropTypes.object),
  onInputChangeHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  provinceList: PropTypes.arrayOf(PropTypes.object),
  countryList: PropTypes.arrayOf(PropTypes.object),
  beneficiaryRelationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  beneficiaryTypeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleDeleteBeneficiary: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  showSaveAndCancelBtn: PropTypes.bool,
  renderDetails: PropTypes.bool,
  isEditBeneficiary: PropTypes.bool,
};
BeneficiaryDetails.defaultProps = {
  beneficiary: {},
  countryList: [],
  provinceList: [],
  showSaveAndCancelBtn: false,
  renderDetails: true,
  isEditBeneficiary: false,
};

export default BeneficiaryDetails;
