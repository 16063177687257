import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import { HTTP_STATUS } from "Communication/Constants";
import qr from "query-string";
import Signin from "./Signin";
import {
  doSignin,
  doReset,
  doForgotPassword,
  addUsername,
} from "./Actions/SigninAction";
import STRINGS from "Constants/Strings";
import { getLoanProduct } from "../LoanDetails/Actions/LoanDetailsAction";
import {
  getFIInfo,
  getProductList,
  storeFilterBankingType,
  updateVaultProductList,
} from "../MarketPlace/Action/MarketPlaceAction";
import { doGetProvinceList } from "../AboutYou/Actions/AboutYouActions";
import { getApplicantType } from "../JointApplicant/Actions/JointApplicantAction";
import {
  getOccupationCodes,
  getReasonCodes,
  setKycFlag,
  toggleScanFlag,
} from "../../Redux/Actions/ApplicationAction";
import { getCentral } from "../../Redux/Actions/LoginAction";
import { addApplicant } from "../Inverite/Actions/InveriteAction";
import doCheckExistingUser from "../Register/Actions/ExistingUserActions";
import AppConstants from "../../Constants/AppConstants";
import {
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_BANKING_TYPE,
  S_MEMBER_VAULT_UPDATE,
  S_QR_KYC,
  TOKEN_KEY,
} from "../../Communication/Constants";

class SigninContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      isChecked: false,
    };
  }

  componentDidMount() {
    // const { doResetStore } = this.props;
    // doResetStore();
    // sessionStorage.removeItem(
    //   STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
    // );
    // sessionStorage.removeItem(STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID);
    console.log("componentDidMount", this.props.location);
    const {
      doGetCentralInfo,
      doAddApplicant,
      prepareVaultProductList,
      doGetLoanProduct,
      doGetFinInfo,
      doGetProvinceList,
      doGetApplicantType,
      doGetOccupationCodes,
      doGetReasonCodes,
      doGetProductList,
      doStoreFilterBankingType,
      doToggleScanFlag,
      doSetKycFlag,
    } = this.props;
    const authCode = this.getAuthCode();
    const token = this.getToken();
    console.log("auth_code", authCode);
    if (authCode) {
      doGetCentralInfo(authCode, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          sessionStorage.setItem(S_MEMBER_VAULT_UPDATE, true);
          const productId = this.getProductId();
          doStoreFilterBankingType(AppConstants.BANKING_TYPE.PERSONAL);
          if (res.data.applicantId && res.data.newAccount) {
            doAddApplicant(
              {
                applicantId: res.data.applicantId,
                relationship: "self",
              },
              (response) => {
                console.log(response);
                if (response.status === HTTP_STATUS.OK) {
                  doGetProductList();
                  doGetLoanProduct();
                  doGetFinInfo();
                  doGetProvinceList();
                  doGetApplicantType();
                  doGetOccupationCodes();
                  doGetReasonCodes();
                  prepareVaultProductList(true, authCode, productId);
                }
              }
            );
          } else {
            doGetProductList();
            doGetLoanProduct();
            doGetFinInfo();
            doGetProvinceList();
            doGetApplicantType();
            doGetOccupationCodes();
            doGetReasonCodes();
            prepareVaultProductList(true, authCode, productId);
          }
        }
      });
    } else if (token) {
      const { vaultProductList, doUpdateVaultProductList } = this.props;
      doToggleScanFlag(true);
      if (vaultProductList && vaultProductList.length > 0) {
        vaultProductList[0].kycStatus = "mobileStarted";
        doUpdateVaultProductList(vaultProductList, () => {
          doSetKycFlag(true);
        });
      } else {
        doSetKycFlag(true);
      }
      sessionStorage.setItem(S_MEMBER_VAULT_UPDATE, true);
      sessionStorage.setItem(TOKEN_KEY, token);
      sessionStorage.setItem(EXPIRES_AT, this.getExpiresAt());
      sessionStorage.setItem(REFRESH_EXPIRES_AT, this.getRefreshExpiresAt());
      sessionStorage.setItem(
        S_BANKING_TYPE,
        AppConstants.BANKING_TYPE.PERSONAL
      );
      doGetLoanProduct();
      doGetFinInfo();
      doGetProvinceList();
      doGetApplicantType();
      doGetOccupationCodes();
      doGetReasonCodes();
      prepareVaultProductList(true, null, null, true);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      signInState,
      doToggleScanFlag,
      scanDocument,
      doSetKycFlag,
      isQrKyc,
    } = this.props;
    const { isChecked, username } = this.state;
    console.log("componentDidUpdate", isQrKyc, prevProps !== this.props);
    if (prevProps !== this.props) {
      const sessionQrKycFlag = sessionStorage.getItem(S_QR_KYC);
      if (!isQrKyc && sessionQrKycFlag && sessionQrKycFlag === "true") {
        doSetKycFlag(true);
      }
      if ((isQrKyc || sessionQrKycFlag === "true") && !scanDocument) {
        doToggleScanFlag(true);
      }
      if (
        signInState.success &&
        signInState.success.status === HTTP_STATUS.OK
      ) {
        if (isChecked) {
          localStorage.removeItem("username");
          localStorage.setItem("checkbox", isChecked);
          localStorage.setItem("username", username);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("checkbox");
        }
      }
    }
  }

  getAuthCode = () => {
    const params = qr.parse(this.props.location.search);
    return params.auth_code;
  };

  getToken = () => {
    const params = qr.parse(this.props.location.search);
    return params.token;
  };

  getExpiresAt = () => {
    const params = qr.parse(this.props.location.search);
    return params.expiresAt;
  };

  getRefreshExpiresAt = () => {
    const params = qr.parse(this.props.location.search);
    return params.refreshExpiresAt;
  };

  getProductId = () => {
    const params = qr.parse(this.props.location.search);
    return params.product_id;
  };

  handleSignin = (userDetails) => {
    const {
      doSignIn,
      prepareVaultProductList,
      doGetLoanProduct,
      doGetFinInfo,
      doGetProvinceList,
      doGetApplicantType,
      doGetOccupationCodes,
      doGetReasonCodes,
      doStoreFilterBankingType,
    } = this.props;
    this.setState({
      username: userDetails.credential,
      isChecked: userDetails.isChecked,
    });
    doSignIn(userDetails, (response) => {
      if (response.status === HTTP_STATUS.OK) {
        sessionStorage.setItem(S_MEMBER_VAULT_UPDATE, true);
        doStoreFilterBankingType(
          userDetails.bankingType || AppConstants.BANKING_TYPE.PERSONAL
        );
        sessionStorage.setItem(S_BANKING_TYPE, userDetails.bankingType);
        doGetLoanProduct();
        doGetFinInfo();
        doGetProvinceList();
        doGetApplicantType();
        doGetOccupationCodes();
        doGetReasonCodes();
        prepareVaultProductList(true);
      }
    });
  };

  render() {
    const {
      history,
      doCheckExistingUser,
      finInfo,
      doAddUserName,
      doForgotPassword,
    } = this.props;
    return (
      <>
        <Signin
          handleSignin={this.handleSignin}
          history={history}
          doCheckExistingUser={doCheckExistingUser}
          finInfo={finInfo}
          doAddUserName={doAddUserName}
          doForgotPassword={doForgotPassword}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doSignIn: doSignin,
      doResetStore: doReset,
      doGetLoanProduct: getLoanProduct,
      doGetFinInfo: getFIInfo,
      doGetProvinceList,
      doGetApplicantType: getApplicantType,
      doGetOccupationCodes: getOccupationCodes,
      doGetReasonCodes: getReasonCodes,
      doGetCentralInfo: getCentral,
      doAddApplicant: addApplicant,
      doCheckExistingUser,
      doForgotPassword,
      doAddUserName: addUsername,
      doGetProductList: getProductList,
      doStoreFilterBankingType: storeFilterBankingType,
      doToggleScanFlag: toggleScanFlag,
      doSetKycFlag: setKycFlag,
      doUpdateVaultProductList: updateVaultProductList,
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  signInState: state.SigninReducer,
  finInfo: state.MarketplaceReducer.finInfo,
  scanDocument: state.ApplicationReducer.scanDocument,
  isQrKyc: state.ApplicationReducer.isQrKyc,
  vaultProductList: state.VaultReducer.vaultProductList,
});

SigninContainer.propTypes = {
  doSignIn: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]),
  signInState: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  prepareVaultProductList: PropTypes.func.isRequired,
  doGetCentralInfo: PropTypes.func.isRequired,
  doAddApplicant: PropTypes.func.isRequired,
  doGetLoanProduct: PropTypes.func.isRequired,
  doGetFinInfo: PropTypes.func.isRequired,
  doGetProvinceList: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  doGetOccupationCodes: PropTypes.func.isRequired,
  doGetReasonCodes: PropTypes.func.isRequired,
  doCheckExistingUser: PropTypes.func.isRequired,
  doForgotPassword: PropTypes.func.isRequired,
  doAddUserName: PropTypes.func.isRequired,
  finInfo: PropTypes.oneOfType([PropTypes.object]),
};
SigninContainer.defaultProps = {
  history: {},
  finInfo: {},
};
const SigninWrapper = withVaultHOC(SigninContainer);

export default connect(mapStateToProps, mapDispatchToProps)(SigninWrapper);
