import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import doLogout from "Redux/Actions/LogoutAction";
import {
  updateVaultProductList,
  getFIInfo,
  storeFilterBankingType,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import { storeProductTemplate } from "Redux/Actions/ProductTemplateActions";
import {
  storeFundProduct,
  addFundAmount,
  addFundMethod,
} from "Components/FundingAmount/Action/FundAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PublicRoute from "Components/Common/Routes/PublicRoute";
import { removeLinkTag } from "Utils/CommonUtilities";
import SigninContainer from "Components/Signin/SigninContainer";
import TermsAndConditionsContainer from "Components/TermsAndConditions/TermsAndConditionsContainer";
import RegisterContainer from "Components/Register/RegisterContainer";
import VerificationCodeContainer from "Components/VerificationCode/VerificationCodeContainer";
import withCommunication from "Hoc/Communication/Axios";
import Header from "Components/Common/Header/Header";
import Footer from "Components/Common/Footer/Footer";
import { Title, SEOConfig } from "Constants/SeoConfig";
import SetPasswordContainer from "Components/SetPassword/SetPasswordContainer";
import ForgotPassword from "Components/ForgotPassword/ForgotPasswordContainer";
import ExistingMember from "Components/ExistingMember/ExistingMemberContainer";
import EmploymentAndIncome from "Components/EmploymentAndIncome/EmploymentAndIncomeContainer";
import * as ROUTES from "Constants/Routes";
import RecoverPassword from "Components/SetPassword/RecoverPasswordContainer";
import ConfirmAccountCreateContainer from "Components/ConfirmAccountCreate/ConfirmAccountCreateContainer";
import GettingStarted from "Components/GettingStarted/GettingStartedContainer";
import Compliance from "Components/Compliance/ComplianceContainer";
import AccountInformationContainer from "Components/AccountInformation/AccountInformationContainer";
import AboutYouContainer from "Components/AboutYou/AboutYouContainer";
import PrivateRoute from "Components/Common/Routes/PrivateRoute";
import NetworkHandler from "Components/Common/NetworkHandler/NetworkHandler";
import {
  TOKEN_KEY,
  HTTP_STATUS,
  IS_LOGOUT,
  IS_SET_PASSWORD_VISIT,
  S_BANKING_TYPE,
} from "Communication/Constants";
import AppConstants from "Constants/AppConstants";
import FundMethodContainer from "Components/FundMethod/FundMethodContainer";
import FundingAmountContainer from "Components/FundingAmount/FundingAmountContainer";
import MarketPlaceContainer from "Components/MarketPlace/MarketPlaceContainer";
import VaultDashboardContainer from "Components/VaultDashboard/VaultDashboardContainer";
import ProgressIndicator from "Components/Common/ProgressIndicator/ProgressIndicator";

import JointApplicantContainer from "Components/JointApplicant/JointApplicantContainer";
import JointApplicantStatusContainer from "Components/JointApplicant/JointApplicantStatusContainer";
import OverdraftProtectionContainer from "Components/OverdraftProtection/OverdraftProtectionContainer";
import BeneficiariesContainer from "Components/Beneficiaries/BeneficiariesContainer";

import SpousalInfoContainer from "Components/SpousalInfo/SpousalInfoContainer";
import MarketPlaceLayout from "Components/MarketPlaceLayout/MarketPlaceLayout";
import ApplyProductContainer from "Components/MarketPlaceLayout/ApplyProductContainer";
import ThirdPartyContainer from "Components/ThirdParty/ThirdPartyContainer";

// Styles
import "./App.scss";

import { isLogin } from "Utils";
import OnlineBankingContainer from "./Components/OnlineBanking/OnlineBankingContainer";
import DebitCardContainer from "./Components/DebitCard/DebitCardContainer";
import SignatureContainer from "./Components/Signature/SignatureContainer";

// LOS IMPORTS
import LoanDetailsContainer from "./Components/LoanDetails/LoanDetailsContainer";
import SecurityPropertyContainer from "./Components/SecurityProperty/SecurityPropertyContainer";
import SecurityVehicleContainer from "./Components/SecurityVehicle/SecurityVehicleContainer";
import IncomeContainer from "./Components/Income/IncomeContainer";
import ExpenseContainer from "./Components/Expense/ExpenseContainer";
import AssetContainer from "./Components/Asset/AssetContainer";
import LiabilitiesContainer from "./Components/Liabilities/LiabilitiesContainer";
import DocumentsContainer from "./Components/Documents/DocumentsContainer";
import FeesContainer from "./Components/Fees/FeesContainer";
import CostOfBorrowingContainer from "./Components/CostOfBorrowing/CostOfBorrowingContainer";
import LoanSummaryContainer from "./Components/LoanSummary/LoanSummaryContainer";
import LoanResultContainer from "./Components/LoanResult/LoanResultContainer";
import BeforeWeStartContainer from "./Components/BeforeWeStart/BeforeWeStartContainer";
import DisbursementContainer from "./Components/Disbursement/DisbursementContainer";
import RepaymentContainer from "./Components/Repayment/RepaymentContainer";
import LoanPurposeContainer from "./Components/LoanDetails/LoanPurposeContainer";
import { getLoanProduct } from "./Components/LoanDetails/Actions/LoanDetailsAction";
import { doGetProvinceList } from "./Components/AboutYou/Actions/AboutYouActions";
import { getApplicantType } from "./Components/JointApplicant/Actions/JointApplicantAction";
import {
  getOccupationCodes,
  getReasonCodes,
} from "./Redux/Actions/ApplicationAction";
import BusinessStartContainer from "./Components/BusinessStart/BusinessStartContainer";
import BusinessTypeContainer from "./Components/BusinessType/BusinessTypeContainer";
import BusinessEligibilityContainer from "./Components/BusinessEligibility/BusinessEligibilityContainer";
import BusinessInfoContainer from "./Components/BusinessInfo/BusinessInfoContainer";
import BusinessConfirmContainer from "./Components/BusinessConfirm/BusinessConfirmContainer";
import TaxReportingContainer from "./Components/TaxReporting/TaxReportingContainer";
import GICInfoContainer from "./Components/GICInfo/GICInfoContainer";
import QuestionnaireContainer from "./Components/Questionnaire/QuestionnaireContainer";
import BusinessLookupContainer from "./Components/BusinessLookup/BusinessLookupContainer";
import BusinessContactContainer from "./Components/BusinessContact/BusinessContactContainer";
import BusinessDetailsContainer from "./Components/BusinessDetails/BusinessDetailsContainer";
import BusinessOperationsContainer from "./Components/BusinessOperations/BusinessOperationsContainer";
import BusinessTaxContainer from "./Components/BusinessTax/BusinessTaxContainer";
import BusinessComplianceContainer from "./Components/BusinessCompliance/BusinessComplianceContainer";
import BusinessOverdraftContainer from "./Components/BusinessOverdraft/BusinessOverdraftContainer";
import BusinessChequeContainer from "./Components/BusinessCheque/BusinessChequeContainer";
import BusinessDebitContainer from "./Components/BusinessDebit/BusinessDebitContainer";
import BusinessOnlineContainer from "./Components/BusinessOnline/BusinessOnlineContainer";
import BusinessEmploymentContainer from "./Components/BusinessEmployment/BusinessEmploymentContainer";
import BusinessReviewContainer from "./Components/BusinessReview/BusinessReviewContainer";
import BusinessIndividualContainer from "./Components/BusinessIndividual/BusinessIndividualContainer";
import BusinessMemberContainer from "./Components/BusinessMember/BusinessMemberContainer";
import BusinessMemberReviewContainer from "./Components/BusinessMemberReview/BusinessMemberReviewContainer";
import BusinessAboutYouContainer from "./Components/BusinessAboutYou/BusinessAboutYouContainer";
import BusinessInfoAddressContainer from "./Components/BusinessInfoAddress/BusinessInfoAddressContainer";
import BusinessInfoMailContainer from "./Components/BusinessInfoMail/BusinessInfoMailContainer";
import BusinessExistContainer from "./Components/BusinessExist/BusinessExistContainer";
import BusinessClientContainer from "./Components/BusinessClient/BusinessClientContainer";
import BusinessUploadContainer from "./Components/BusinessUpload/BusinessUploadContainer";
import BusinessDocumentReviewContainer from "./Components/BusinessDocumentReview/BusinessDocumentReviewContainer";
import BusinessKycContainer from "./Components/BusinessKyc/BusinessKycContainer";
import MembershipContainer from "./Components/Membership/MembershipContainer";
import BusinessMemberComplianceContainer from "./Components/BusinessMemberCompliance/BusinessMemberComplianceContainer";
import BusinessMemberTaxContainer from "./Components/BusinessTax/BusinessMemberTaxContainer";

const defaultTitle = AppConstants.APPLICATIONDEFAULTNAME.APP_DEFAULT_NAME;

class App extends Component {
  constructor(props) {
    super(props);
    const {
      doUpdateVaultProductList,
      doStoreProductTemplateFlow,
      doStoreFundProduct,
      doAddFundAmount,
      doAddFundMethod,
    } = this.props;
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (
      window.performance &&
      window.performance.getEntriesByType("navigation") &&
      window.performance.getEntriesByType("navigation")[0] &&
      window.performance.getEntriesByType("navigation")[0].type === "reload" &&
      token
    ) {
      const data = JSON.parse(sessionStorage.getItem("persist:root"));
      const { vaultProductList } = JSON.parse(data.VaultReducer);
      console.log("update vault 8");
      doUpdateVaultProductList(vaultProductList);
      const { fundProduct } = JSON.parse(data.VaultReducer);
      doStoreFundProduct(fundProduct);
      const { method, amount } = JSON.parse(data.FundReducer);
      doAddFundAmount(amount);
      doAddFundMethod(method);
      const { templates } = JSON.parse(data.ApplicationReducer);
      const { processTemplate } = JSON.parse(data.ApplicationReducer);
      doStoreProductTemplateFlow(templates, processTemplate);
    }
    this.state = {
      showVault: false,
      showFundAccount: false,
      showComponent: false,
    };
  }

  /**
   * Initialize all lookup data needed
   */
  initializeLookupData = () => {
    const {
      doGetLoanProduct,
      doGetFinInfo,
      doGetProvinceList,
      doGetApplicantType,
      doGetOccupationCodes,
      doGetReasonCodes,
    } = this.props;
    doGetLoanProduct();
    doGetFinInfo();
    doGetProvinceList();
    doGetApplicantType();
    doGetOccupationCodes();
    doGetReasonCodes();
  };

  componentDidMount() {
    console.log("componentDidMount");
    const { history, doLogoutAction } = this.props;
    this.initializeLookupData();
    history.listen(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
    if (
      isLogin() &&
      history.location.pathname.startsWith(ROUTES.RECOVER_PASSWORD)
    ) {
      sessionStorage.setItem(IS_LOGOUT, true);
      const promise = new Promise((resolve) => {
        doLogoutAction(() => {
          resolve(true);
        });
      });
      promise.then(
        (result) => {
          this.setState({ showComponent: result });
        },
        (error) => {
          this.setState({ showComponent: error });
        }
      );
    } else {
      this.setState({ showComponent: true });
    }
  }

  toggleVaultHandler = () => {
    const { showVault, showFundAccount } = this.state;
    this.setState({
      showVault: !showVault,
      showFundAccount: showFundAccount && showVault,
    });
  };

  toggleFundVaultHandler = () => {
    const { showVault, showFundAccount } = this.state;
    this.setState({
      showFundAccount: !showFundAccount,
      showVault: showFundAccount && showVault,
    });
  };

  handleURLBlocking = () => {
    const {
      history,
      doLogoutAction,
      updatePasswordStatus,
      registerPasswordState,
    } = this.props;
    const isSetPasswordVisit = sessionStorage.getItem(IS_SET_PASSWORD_VISIT);
    const token = sessionStorage.getItem(TOKEN_KEY);
    console.log(
      "handleURLBlocking",
      isSetPasswordVisit,
      updatePasswordStatus,
      history.location.pathname,
      token
    );
    if (token) {
      if (
        ROUTES.PRIVATE_ROUTES.includes(history.location.pathname) &&
        isSetPasswordVisit === "true" &&
        (!updatePasswordStatus.success || !registerPasswordState.success) &&
        history.location.pathname !== ROUTES.CHANGE_PASSWORD
      ) {
        console.log("going to home");
        sessionStorage.removeItem(TOKEN_KEY); // remove login token from storage
        sessionStorage.removeItem(IS_SET_PASSWORD_VISIT);
        doLogoutAction(() => {
          history.push(ROUTES.HOME);
        });
        return false;
      }
      return true;
    }
    return false;
  };

  isRecoverpasswordByEmail() {
    const { location, match } = this.props;
    const toCheckRecoverpassword = location.pathname.startsWith(
      ROUTES.RECOVER_PASSWORD
    );

    if (
      !ROUTES.BREADCRUMBS[location?.pathname] &&
      !toCheckRecoverpassword &&
      !location.pathname.startsWith(
        AppConstants.JOINT_APPLICANT.INVITE_APPLY_URL
      )
      // If any other URL is hit we have to navigate to sign in or dashboar as per user logged in
    ) {
      if (isLogin()) {
        return <Redirect to={ROUTES.VAULT_DASHBOARD} />;
      }
      return <Redirect to={ROUTES.SIGN_IN} />;
    }
    return "";
  }

  handleSignIn = () => {
    const { history } = this.props;
    history.push(ROUTES.SIGN_IN);
  };

  handleRegister = () => {
    const { history } = this.props;
    history.push(ROUTES.REGISTER);
  };

  switchBanking = () => {
    const { doStoreFilterBankingType, bankingType } = this.props;
    const newBankingType =
      bankingType === AppConstants.BANKING_TYPE.PERSONAL
        ? AppConstants.BANKING_TYPE.BUSINESS
        : AppConstants.BANKING_TYPE.PERSONAL;
    sessionStorage.setItem(S_BANKING_TYPE, newBankingType);
    doStoreFilterBankingType(newBankingType);
  };
  render() {
    const { showVault, showFundAccount, showComponent } = this.state;
    const { history, location } = this.props;
    // removeLinkTag();
    return (
      <>
        <NetworkHandler />
        <Helmet>
          {SEOConfig &&
            SEOConfig.length > 0 &&
            SEOConfig.map((tag) => (
              <meta name={tag.name} key={tag.name} content={tag.content} />
            ))}
          <title>{Title || defaultTitle}</title>
        </Helmet>
        <div className="App">
          <ProgressIndicator />
          <Header
            showVaultClicked={this.toggleVaultHandler}
            showVault={showVault}
            showFundAccount={showFundAccount}
            activeRoute={location?.pathname}
            history={history}
            showFundVaultClicked={this.toggleFundVaultHandler}
            handleSignIn={this.handleSignIn}
            handleRegister={this.handleRegister}
            switchBanking={this.switchBanking}
          />
          <Switch>
            {/* Public Routes */}
            <PublicRoute
              exact
              restricted={false}
              component={MarketPlaceContainer}
              path={ROUTES.MARKETPLACE}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              restricted={false}
              component={RegisterContainer}
              path={ROUTES.INVITE_APPLY}
              exact
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={SigninContainer}
              path={ROUTES.SIGN_IN}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={ForgotPassword}
              path={ROUTES.FORGOT_PASSWORD}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              restricted={false}
              component={RegisterContainer}
              path={ROUTES.REGISTER}
              exact
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={VerificationCodeContainer}
              path={ROUTES.VERIFICATION_CODE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={QuestionnaireContainer}
              path={ROUTES.QUESTIONNAIRE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={SetPasswordContainer}
              path={ROUTES.VAULT_PASSWORD}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={SetPasswordContainer}
              path={ROUTES.SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={VerificationCodeContainer}
              path={ROUTES.SIGNIN_FORGOT_VERIFICATION}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={RecoverPassword}
              path={ROUTES.RECOVER_PASSWORD_BY_EMAIL}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PublicRoute
              exact
              restricted={false}
              component={ApplyProductContainer}
              path={ROUTES.APPLY_PRODUCT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            {/* Private Routes */}
            <PrivateRoute
              exact
              restricted
              component={SetPasswordContainer}
              path={ROUTES.CHANGE_PASSWORD}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
              history={history}
              showComponent={showComponent}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.EXISTING_MEMBER}
              component={ExistingMember}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.CONFIRM_ACCOUNT_CREATE}
              component={ConfirmAccountCreateContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.GETTING_STARTED}
              component={GettingStarted}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.FUND_METHOD}
              component={FundMethodContainer}
              showVault={showVault}
              showFundAccount={showFundAccount}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.MEMBER_CREATION}
              component={TermsAndConditionsContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.ABOUT_YOU}
              component={AboutYouContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.EMPLOYMENT_AND_INCOME}
              component={EmploymentAndIncome}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.TAX_INFO}
              component={TaxReportingContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.COMPLIANCE}
              component={Compliance}
              showVault={showVault}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.ACCOUNT_INFORMATION}
              component={AccountInformationContainer}
              showVault={showVault}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.FUNDING_AMOUNT}
              component={FundingAmountContainer}
              showVault={showVault}
              showFundAccount={showFundAccount}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.VAULT_DASHBOARD}
              component={VaultDashboardContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.OVERDRAFT_PROTECTION}
              component={OverdraftProtectionContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.BENEFICIARIES}
              component={BeneficiariesContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.SPOUSAL_INFO}
              component={SpousalInfoContainer}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={JointApplicantContainer}
              path={ROUTES.JOINT_APPLICANT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={JointApplicantStatusContainer}
              path={ROUTES.JOINT_APPLICANT_STATUS}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={SignatureContainer}
              path={ROUTES.SIGNATURE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted={false}
              component={OnlineBankingContainer}
              path={ROUTES.ONLINE_BANKING}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted={false}
              component={DebitCardContainer}
              path={ROUTES.DEBIT_CARD}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            {/* LOS SCREENS */}
            <PrivateRoute
              exact
              restricted
              component={LoanDetailsContainer}
              path={ROUTES.LOAN_DETAILS}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={LoanPurposeContainer}
              path={ROUTES.LOAN_PURPOSE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={SecurityPropertyContainer}
              path={ROUTES.PROPERTY_DETAILS}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={SecurityVehicleContainer}
              path={ROUTES.SECURITY_VEHICLE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={IncomeContainer}
              path={ROUTES.INCOME}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={ExpenseContainer}
              path={ROUTES.EXPENSE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={AssetContainer}
              path={ROUTES.ASSET}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={LiabilitiesContainer}
              path={ROUTES.LIABILITIES}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={DocumentsContainer}
              path={ROUTES.DOCUMENT_UPLOAD}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={FeesContainer}
              showComponent={showComponent}
              path={ROUTES.FEES}
            />
            <PrivateRoute
              exact
              restricted
              component={CostOfBorrowingContainer}
              path={ROUTES.COST_OF_BORROWING}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={LoanSummaryContainer}
              path={ROUTES.LOAN_SUMMARY}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={LoanResultContainer}
              path={ROUTES.LOAN_ADJUDICATION_RESULTS}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BeforeWeStartContainer}
              path={ROUTES.BEFORE_WE_START}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={DisbursementContainer}
              path={ROUTES.DISBURSEMENT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={RepaymentContainer}
              path={ROUTES.REPAYMENT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={MembershipContainer}
              path={ROUTES.MEMBERSHIP}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessExistContainer}
              path={ROUTES.BUSINESS_EXIST}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessClientContainer}
              path={ROUTES.BUSINESS_CLIENT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessKycContainer}
              path={ROUTES.BUSINESS_KYC}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessStartContainer}
              path={ROUTES.BUSINESS_START}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessLookupContainer}
              path={ROUTES.BUSINESS_LOOKUP}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessTypeContainer}
              path={ROUTES.BUSINESS_TYPE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessEligibilityContainer}
              path={ROUTES.BUSINESS_ELIGIBILITY}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessInfoContainer}
              path={ROUTES.BUSINESS_INFO}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessInfoAddressContainer}
              path={ROUTES.BUSINESS_INFO_ADDRESS}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessInfoMailContainer}
              path={ROUTES.BUSINESS_INFO_MAIL}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessContactContainer}
              path={ROUTES.BUSINESS_CONTACT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessDetailsContainer}
              path={ROUTES.BUSINESS_DETAILS}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessOperationsContainer}
              path={ROUTES.BUSINESS_OPERATIONS}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessTaxContainer}
              path={ROUTES.BUSINESS_TAX}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessMemberTaxContainer}
              path={ROUTES.BUSINESS_MEMBER_TAX}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessComplianceContainer}
              path={ROUTES.BUSINESS_COMPLIANCE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessMemberComplianceContainer}
              path={ROUTES.BUSINESS_MEMBER_COMPLIANCE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessOverdraftContainer}
              path={ROUTES.BUSINESS_OVERDRAFT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessChequeContainer}
              path={ROUTES.BUSINESS_CHEQUE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessDebitContainer}
              path={ROUTES.BUSINESS_DEBIT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessOnlineContainer}
              path={ROUTES.BUSINESS_ONLINE}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessEmploymentContainer}
              path={ROUTES.BUSINESS_EMPLOYMENT}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessReviewContainer}
              path={ROUTES.BUSINESS_REVIEW}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessIndividualContainer}
              path={ROUTES.BUSINESS_INDIVIDUAL}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessMemberContainer}
              path={ROUTES.BUSINESS_MEMBER}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessMemberReviewContainer}
              path={ROUTES.BUSINESS_MEMBER_REVIEW}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessAboutYouContainer}
              path={ROUTES.BUSINESS_ABOUT_YOU}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessConfirmContainer}
              path={ROUTES.BUSINESS_CONFIRM}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessUploadContainer}
              path={ROUTES.BUSINESS_UPLOAD}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={BusinessDocumentReviewContainer}
              path={ROUTES.BUSINESS_DOC_REVIEW}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              component={GICInfoContainer}
              path={ROUTES.GIC_INFO}
              showVault={showVault}
              showVaultClicked={this.showVaultClicked}
            />
            <PrivateRoute
              exact
              restricted
              path={ROUTES.THIRD_PARTY}
              component={ThirdPartyContainer}
              showVault={showVault}
            />
          </Switch>
          {/* toCheckRecoverpassword used for recoverypassword */}
          {this.isRecoverpasswordByEmail()}

          {this.handleURLBlocking() ? (
            <Route exact path={ROUTES.HOME}>
              <Redirect to={ROUTES.VAULT_DASHBOARD} />
            </Route>
          ) : (
            <Route exact path={ROUTES.HOME}>
              <Redirect to={ROUTES.MARKETPLACE} />
            </Route>
          )}
          <Footer />
        </div>
      </>
    );
  }
}

App.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  location: PropTypes.oneOfType([PropTypes.object]),
  doLogoutAction: PropTypes.func,
  updatePasswordStatus: PropTypes.objectOf(PropTypes.any),
  registerPasswordState: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.oneOfType([PropTypes.object]),
  doUpdateVaultProductList: PropTypes.func.isRequired,
  doStoreProductTemplateFlow: PropTypes.func.isRequired,
  doStoreFundProduct: PropTypes.func.isRequired,
  doAddFundMethod: PropTypes.func.isRequired,
  doAddFundAmount: PropTypes.func.isRequired,
};
App.defaultProps = {
  history: {},
  location: {},
  doLogoutAction: () => {},
  updatePasswordStatus: {},
  registerPasswordState: {},
  match: {},
};

const mapStateToProps = (state) => ({
  updatePasswordStatus: state.UpdatePasswordReducer,
  registerPasswordState: state.RegisterReducer,
  bankingType: state.MarketplaceReducer.bankingType,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doLogoutAction: doLogout,
      doUpdateVaultProductList: updateVaultProductList,
      doStoreProductTemplateFlow: storeProductTemplate,
      doStoreFundProduct: storeFundProduct,
      doAddFundMethod: addFundMethod,
      doAddFundAmount: addFundAmount,
      doGetLoanProduct: getLoanProduct,
      doGetFinInfo: getFIInfo,
      doGetProvinceList: doGetProvinceList,
      doGetApplicantType: getApplicantType,
      doGetOccupationCodes: getOccupationCodes,
      doGetReasonCodes: getReasonCodes,
      doStoreFilterBankingType: storeFilterBankingType,
    },
    dispatch
  );
};

const AppWithCommunication = withCommunication(App);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppWithCommunication);
