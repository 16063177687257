/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import AppConstants from "Constants/AppConstants";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AuthBody from "../Common/AuthBody/AuthBody";
import PopupModal from "../Common/PopupModal/PopupModal";
import "./Compliance.scss";
import Stepper from "../Common/Stepper/Stepper";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";
import { isScanningAllowed } from "../../Utils/CommonUtilities";
import Button from "react-bootstrap/Button";
import NumberFormat from "react-number-format";
import formatStringByPattern from "format-string-by-pattern";
import {
  allowKycEdit,
  allowKycEditForJointInSession,
  isNewMember,
  stepAlreadyProcessed,
} from "../../Utils/LogicUtilities";

class BusinessMemberCompliance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      businessRelation: "",
      isThirdPartyAccount: false,
      thirdPartyName: "",
      thirdPartyRelationship: "",
      areYouPEPorHIO: "",
      memberPEPDomestic: false,
      memberPEPForeign: false,
      memberHIO: false,
      memberOfficeHeld: "",
      memberJurisdiction: "",
      memberFundsSource: "",
      isAnyoneConsideredPEPHIO: "",
      indicateName: "",
      anyRelationship: "",
      PEPDomestic: false,
      PEPForeign: false,
      HIO: false,
      jurisdictionType: "",
      anyOfficeHeld: "",
      anyJurisdiction: "",
      anySourceOfFunds: "",
      isThirdPartyAccountError: "",
      thirdPartyNameError: "",
      indicateNameError: "",
      businessRelationError: "",
      thirdPartyRelationshipError: [],
      showHelp: false,
    };
  }

  componentDidMount() {
    const {
      getDataFromServer,
      indicateWhoArray,
      accountPurposeArray,
      dogetAccountPurposeList,
      dogetIndicateWhoList,
    } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_COMPLIANCE,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { personalData } = this.props;
    if (personalData !== prevProps.personalData) {
      this.setFormData();
    }
  }

  setFormData = (response) => {
    const { activeProduct, personalData } = this.props;
    let { regulatory, member, peps, thirdParties, employment } = personalData;
    console.log("setFormData", personalData);
    const jurisdictionType = peps ? peps[0]?.jurisdictionType : "";
    this.setState({
      businessRelation: member?.intendedUseOfMembership,
      isThirdPartyAccount: regulatory?.memberHasThirdParty || false,
      thirdPartyName:
        thirdParties && thirdParties[0]?.name ? thirdParties[0]?.name : "",
      thirdPartyRelationship:
        thirdParties && thirdParties[0]?.relationship
          ? thirdParties[0]?.relationship
          : "",
      areYouPEPorHIO:
        !!regulatory?.memberIsHio ||
        !!regulatory?.memberIsPedp ||
        !!regulatory?.memberIsPefp,
      memberPEPDomestic: regulatory?.memberIsPedp || false,
      memberPEPForeign: regulatory?.memberIsPefp || false,
      memberHIO: regulatory?.memberIsHio || false,
      memberOfficeHeld: regulatory?.memberOfficeHeld || "",
      memberJurisdiction: regulatory?.memberJurisdiction || "",
      memberFundsSource: regulatory?.memberFundsSource || "",
      isAnyoneConsideredPEPHIO: !!(peps && peps?.length > 0),
      indicateName: peps && peps[0]?.name ? peps[0]?.name : "",
      anyRelationship:
        peps && peps[0]?.relationship ? peps[0]?.relationship : "",
      PEPDomestic:
        jurisdictionType ===
          AppConstants.COMPLIANCE.JURISDICTION_TYPE.DOMESTIC || "",
      PEPForeign:
        jurisdictionType ===
          AppConstants.COMPLIANCE.JURISDICTION_TYPE.FOREIGN || "",
      HIO: peps && peps[0]?.hio ? peps[0]?.hio : "",
      jurisdictionType:
        peps && peps[0]?.jurisdictionType ? peps[0]?.jurisdictionType : "",
      anyOfficeHeld: peps && peps[0]?.officeHeld ? peps[0]?.officeHeld : "",
      anyJurisdiction:
        peps && peps[0]?.jurisdiction ? peps[0]?.jurisdiction : "",
      anySourceOfFunds:
        peps && peps[0]?.fundsSource ? peps[0]?.fundsSource : "",
      employerContactNumber:
        employment?.employerPhoneArea + employment?.employerPhone,
      occupationCode: employment ? employment.occupationCode : "",
      sourceOfIncome: employment ? employment.incomeType : "",
    });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_COMPLIANCE);
  };

  changeHandler = (event) => {
    const complianceData = this.state;
    const inputField = event?.target.name;
    let eventValue = event?.target.value;

    // Restrict non-letter characters for specific fields
    if (
      eventValue &&
      (inputField === "thirdPartyName" || inputField === "indicateName")
    ) {
      eventValue = eventValue.replace(
        /[^A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\. \-']/gi,
        ""
      );
    }

    // Capitalize words for the specified fields while respecting spaces and any capital letters entered by the user
    if (
      [
        "thirdPartyName",
        "memberOfficeHeld",
        "memberJurisdiction",
        "memberFundsSource",
        "indicateName",
        "anyOfficeHeld",
        "anyJurisdiction",
        "anySourceOfFunds",
      ].includes(inputField)
    ) {
      eventValue = eventValue.replace(/\b\w/g, (char) => char.toUpperCase());
    }

    complianceData[event.target.name] = eventValue;
    complianceData[`${event.target.name}Error`] = "";
    this.setState({ complianceData });
  };

  handleChoice = (name, choice) => {
    console.log("handleChoice", name, choice);
    const complianceData = this.state;
    complianceData[name] = choice;
    if (choice === false) {
      if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT) {
        this.setState({
          thirdPartyNameError: [],
          thirdPartyRelationshipError: [],
        });
        complianceData.thirdPartyName = "";
        complianceData.thirdPartyRelationship = "";
      }
    }
    this.setState({ complianceData });

    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT) {
      this.setState({ isThirdPartyAccountError: [] });
    }
  };

  handleMemberPEPHIOChoice = (name) => {
    console.log("handleMemberPEPHIOChoice", name);
    const { memberPEPDomestic, memberPEPForeign, memberHIO } = this.state;
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPDOMESTIC) {
      this.setState({
        memberPEPDomestic: !memberPEPDomestic,
        memberTypeError: [],
      });
    }
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPFOREIGN) {
      this.setState({
        memberPEPForeign: !memberPEPForeign,
        memberTypeError: [],
      });
    }
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERHIO) {
      this.setState({
        memberHIO: !memberHIO,
        memberTypeError: [],
      });
    }
  };

  handlePEPHIOChoice = (name) => {
    console.log("handlePEPHIOChoice", name);
    const { PEPDomestic, PEPForeign, HIO } = this.state;
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPDOMESTIC) {
      this.setState({
        PEPDomestic: !PEPDomestic,
        PEPForeign: false,
        anyTypeError: [],
      });
    } else if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPFOREIGN) {
      this.setState({
        PEPDomestic: false,
        PEPForeign: !PEPForeign,
        anyTypeError: [],
      });
    }
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.HIO) {
      this.setState({
        HIO: !HIO,
        anyTypeError: [],
      });
    }
  };

  handlePEPChoice = (name, choice) => {
    console.log("handlePEPChoice", name, choice);
    const complianceData = this.state;
    complianceData[name] = choice;
    if (choice === false) {
      if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.AREYOUPEPORHIO) {
        this.setState({
          memberPEPDomestic: false,
          memberPEPForeign: false,
          memberHIO: false,
          memberTypeError: [],
          memberFundsSourceError: [],
          memberJurisdictionError: [],
          memberOfficeHeldError: [],
        });
        complianceData.memberOfficeHeld = "";
        complianceData.memberJurisdiction = "";
        complianceData.memberFundsSource = "";
      }
      if (
        name === STRINGS.COMPLIANCE.FORM.FIELDNAME.ISANYONECONSIDEREDPEPORHIO
      ) {
        this.setState({
          PEPDomestic: false,
          PEPForeign: false,
          HIO: false,
          indicateNameError: [],
          anyRelationshipError: [],
          anyTypeError: [],
          anyOfficeHeldError: [],
          anyJurisdictionError: [],
          anySourceOfFundsError: [],
        });
        complianceData.anyOfficeHeld = "";
        complianceData.anyJurisdiction = "";
        complianceData.anySourceOfFunds = "";
        complianceData.indicateName = "";
        complianceData.anyRelationship = "";
      }
    }
    this.setState({ complianceData });
  };

  handleSubmit = (e) => {
    const { handleContinue } = this.props;

    e.preventDefault();

    const formIsValid = this.verifyData();
    if (formIsValid) {
      const formdata = this.state;
      handleContinue(formdata);
    }
  };

  verifyData = () => {
    let formIsValid = true;
    const {
      isThirdPartyAccount,
      thirdPartyName,
      businessRelation,
      thirdPartyRelationship,
      areYouPEPorHIO,
      memberPEPDomestic,
      memberPEPForeign,
      memberHIO,
      memberOfficeHeld,
      memberJurisdiction,
      memberFundsSource,
      isAnyoneConsideredPEPHIO,
      indicateName,
      anyRelationship,
      PEPDomestic,
      PEPForeign,
      HIO,
      anyOfficeHeld,
      anyJurisdiction,
      anySourceOfFunds,
    } = this.state;
    const { finInfo, applicantData } = this.props;
    let thirdPartyNameRes = "";
    let indicateNameRes = "";
    let thirdPartyRelationshipError = [];
    let memberTypeError = [];
    let memberOfficeHeldError = [];
    let memberJurisdictionError = [];
    let memberFundsSourceError = [];
    let anyRelationshipError = [];
    let anyTypeError = [];
    let anyOfficeHeldError = [];
    let anyJurisdictionError = [];
    let anySourceOfFundsError = [];

    console.log("isThirdPartyAccount", isThirdPartyAccount);
    const isThirdPartyAccountRes = checkErrors(
      isThirdPartyAccount,
      VALIDATIONS.COMPLIANCE.isThirdPartyAccount
    );

    this.setState({
      isThirdPartyAccountError: isThirdPartyAccountRes,
    });

    if (isThirdPartyAccount) {
      thirdPartyNameRes = checkErrors(
        thirdPartyName,
        VALIDATIONS.COMPLIANCE.thirdPartyName
      );
      this.setState({
        thirdPartyNameError: thirdPartyNameRes,
      });

      thirdPartyRelationshipError = checkErrors(
        thirdPartyRelationship,
        VALIDATIONS.COMPLIANCE.RELATIONSHIP
      );
      this.setState({ thirdPartyRelationshipError });
    }

    if (areYouPEPorHIO) {
      memberTypeError =
        !memberPEPDomestic && !memberPEPForeign && !memberHIO
          ? ["Please select type"]
          : [];

      memberOfficeHeldError = checkErrors(
        memberOfficeHeld,
        VALIDATIONS.COMPLIANCE.OFFICE_HELD
      );

      memberJurisdictionError = checkErrors(
        memberJurisdiction,
        VALIDATIONS.COMPLIANCE.JURISDICTION
      );

      memberFundsSourceError = checkErrors(
        memberFundsSource,
        VALIDATIONS.COMPLIANCE.SOURCE_OF_FUNDS
      );

      this.setState({
        memberTypeError,
        memberOfficeHeldError,
        memberJurisdictionError,
        memberFundsSourceError,
      });
    }

    if (isAnyoneConsideredPEPHIO) {
      indicateNameRes = checkErrors(
        indicateName,
        VALIDATIONS.COMPLIANCE.indicateName
      );

      anyRelationshipError = checkErrors(
        anyRelationship,
        VALIDATIONS.COMPLIANCE.RELATIONSHIP
      );

      anyTypeError =
        !PEPDomestic && !PEPForeign && !HIO ? ["Please select type"] : [];

      anyOfficeHeldError = checkErrors(
        anyOfficeHeld,
        VALIDATIONS.COMPLIANCE.OFFICE_HELD
      );

      anyJurisdictionError = checkErrors(
        anyJurisdiction,
        VALIDATIONS.COMPLIANCE.JURISDICTION
      );

      anySourceOfFundsError = checkErrors(
        anySourceOfFunds,
        VALIDATIONS.COMPLIANCE.SOURCE_OF_FUNDS
      );

      this.setState({
        indicateNameError: indicateNameRes,
        anyRelationshipError,
        anyTypeError,
        anyOfficeHeldError,
        anyJurisdictionError,
        anySourceOfFundsError,
      });
    }

    if (
      isThirdPartyAccountRes.length > 0 ||
      thirdPartyNameRes.length > 0 ||
      indicateNameRes.length > 0 ||
      thirdPartyRelationshipError.length > 0 ||
      memberTypeError.length > 0 ||
      memberOfficeHeldError.length > 0 ||
      memberJurisdictionError.length > 0 ||
      memberFundsSourceError.length > 0 ||
      anyTypeError.length > 0 ||
      anyOfficeHeldError.length > 0 ||
      anyJurisdictionError.length > 0 ||
      anySourceOfFundsError.length > 0
    ) {
      formIsValid = false;
    }

    const businessRelationError = checkErrors(
      businessRelation,
      VALIDATIONS.COMPLIANCE.selectBusinessRelation
    );
    if (businessRelationError.length > 0) {
      formIsValid = false;
    }
    this.setState({ businessRelationError });

    console.log("validation result", formIsValid, this.state);
    return formIsValid;
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const {
      businessRelation,
      isThirdPartyAccount,
      thirdPartyName,
      thirdPartyRelationship,
      areYouPEPorHIO,
      memberPEPDomestic,
      memberPEPForeign,
      memberHIO,
      memberOfficeHeld,
      memberJurisdiction,
      memberFundsSource,
      isAnyoneConsideredPEPHIO,
      indicateName,
      anyRelationship,
      PEPDomestic,
      PEPForeign,
      HIO,
      anyOfficeHeld,
      anyJurisdiction,
      anySourceOfFunds,
      isThirdPartyAccountError,
      thirdPartyNameError,
      indicateNameError,
      businessRelationError,
      thirdPartyRelationshipError,
      memberTypeError,
      memberOfficeHeldError,
      memberJurisdictionError,
      memberFundsSourceError,
      anyRelationshipError,
      anyTypeError,
      anyOfficeHeldError,
      anyJurisdictionError,
      anySourceOfFundsError,
      showHelp,
      helpText,
      employerPhoneSettings,
      employerContactNumber,
      employerContactNumberError,
      occupationCodeSettings,
      occupationCode,
      occupationCodeError,
      sourceOfIncomeSettings,
      sourceOfIncome,
      sourceOfIncomeError,
    } = this.state;

    const {
      businessRelationArray,
      indicateWhoArray,
      showModal,
      type,
      modal,
      toggleModal,
      steps,
      activeStepID,
      occupationCodes,
      incomeSourcesList,
      finInfo,
      activeProduct,
      applicantData,
      contKycFlag,
    } = this.props;

    const actionComponent = (
      <div className="[ compliance-container ]">
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? this.handlePopupModalBtnClick
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}

        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.COMPLIANCE.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.BUSINESS_INTENDED_RELATIONSHIP}
            </div>
            <select
              className={
                businessRelationError && businessRelationError.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              id="exampleFormControlSelect1"
              name="businessRelation"
              placeholder={STRINGS.COMPLIANCE.FORM.ACCOUNT_PURPOSE_PLACEHOLDER}
              value={businessRelation}
              onChange={this.changeHandler}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.COMPLIANCE.FORM.ACCOUNT_PURPOSE_PLACEHOLDER}
              </option>
              {finInfo?.config?.businessMemberRelation?.map((obj) => {
                return (
                  <option key={obj.code} value={obj.code}>
                    {obj.description}
                  </option>
                );
              })}
            </select>
            <div className="[ error-msg ] ">{businessRelationError[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.THIRDPARTY_ACCOUNT_BUSINESS}
              {!contKycFlag ? (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(STRINGS.COMPLIANCE.HELP.THIRD_PARTY)
                  }
                />
              ) : (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(STRINGS.COMPLIANCE.HELP.THIRD_PARTY_KYC)
                  }
                />
              )}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isThirdPartyAccount
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handleChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT,
                      true
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    !isThirdPartyAccount
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handleChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT,
                      false
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">{isThirdPartyAccountError}</div>

            {isThirdPartyAccount ? (
              <div className="[ drawer-content-container ]">
                <div className="[ info-title ]">
                  {STRINGS.COMPLIANCE.FORM.THIRDPARTY_TITLE}
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME}
                  </div>
                  <input
                    type="text"
                    id="thirdPartyName"
                    name="thirdPartyName"
                    className={
                      thirdPartyNameError.length > 0
                        ? "[ form-control ] [ invalid-input ]"
                        : "[ form-control ]"
                    }
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME_PLACEHOLDER
                    }
                    value={thirdPartyName}
                    onChange={this.changeHandler}
                  />
                  <div className="[ error-msg ] ">{thirdPartyNameError}</div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_RELATIONSHIP}
                  </div>
                  <select
                    className="[ form-control ] [ select__dropdown ]"
                    id="thirdPartyRelationship"
                    name="thirdPartyRelationship"
                    value={thirdPartyRelationship}
                    onChange={this.changeHandler}
                  >
                    <option
                      value=""
                      className="[ default ] [ dropdown__option ]"
                    >
                      {
                        STRINGS.COMPLIANCE.FORM
                          .THIRDPARTY_RELATIONSHIP_PLACEHOLDER
                      }
                    </option>
                    {indicateWhoArray?.map((obj) => {
                      return (
                        <option
                          key={obj.code}
                          value={obj.description}
                          className="[ dropdown__option ]"
                        >
                          {obj.description}
                        </option>
                      );
                    })}
                  </select>
                  <div className="[ error-msg ] ">
                    {thirdPartyRelationshipError}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {/* Start PEP block */}
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.MEMBER_PEP_HIO_CHOICE}
              {!contKycFlag ? (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(STRINGS.COMPLIANCE.HELP.MEMBER_PEP_HIO)
                  }
                />
              ) : (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(
                      STRINGS.COMPLIANCE.HELP.MEMBER_PEP_HIO_KYC
                    )
                  }
                />
              )}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    areYouPEPorHIO
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.AREYOUPEPORHIO,
                      true
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    !areYouPEPorHIO
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.AREYOUPEPORHIO,
                      false
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]" />
            {/* Start PEP Inner block */}
            {areYouPEPorHIO ? (
              <div className="[ drawer-content-container ]">
                <div className="[ info-title ]">
                  {STRINGS.COMPLIANCE.FORM.PERSON_TITLE}
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.HOI_CHOICE}
                  </div>

                  <div className="[ row ] [ custom-radios ]">
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          memberPEPDomestic
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handleMemberPEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPDOMESTIC
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_DOMESTIC}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          memberPEPForeign
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handleMemberPEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPFOREIGN
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_FOREIGN}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          memberHIO
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handleMemberPEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERHIO
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.HOI}
                      </button>
                    </div>
                    <div className="[ col-12 ]">
                      <div className="[ error-msg ] ">{memberTypeError}</div>
                    </div>
                  </div>
                </div>

                {finInfo.config.officeHeld && (
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_LABEL}
                    </div>
                    <select
                      className="[ form-control ] [ select__dropdown ]"
                      id="memberOfficeHeld"
                      name="memberOfficeHeld"
                      value={memberOfficeHeld}
                      onChange={this.changeHandler}
                    >
                      <option
                        value=""
                        className="[ default ] [ dropdown__option ]"
                      >
                        {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_PLACEHOLDER}
                      </option>
                      {finInfo.config.officeHeld?.map((obj) => {
                        return (
                          <option
                            key={obj.code}
                            value={obj.code}
                            className="[ dropdown__option ]"
                          >
                            {obj.description}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ] ">
                      {memberOfficeHeldError}
                    </div>
                  </div>
                )}

                {!finInfo.config.officeHeld && (
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_LABEL}
                    </div>
                    <input
                      type="text"
                      id="memberOfficeHeld"
                      name="memberOfficeHeld"
                      className="form-control"
                      placeholder={
                        STRINGS.COMPLIANCE.FORM.OFFICE_HELD_PLACEHOLDER
                      }
                      value={memberOfficeHeld}
                      onChange={(event) => this.changeHandler(event)}
                    />
                    <div className="[ error-msg ] ">
                      {memberOfficeHeldError}
                    </div>
                  </div>
                )}

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.JURISDICTION_LABEL}
                  </div>
                  <input
                    type="text"
                    id="memberJurisdiction"
                    name="memberJurisdiction"
                    className="form-control"
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.JURISDICTION_PLACEHOLDER
                    }
                    value={memberJurisdiction}
                    onChange={(event) => this.changeHandler(event)}
                  />
                  <div className="[ error-msg ] ">
                    {memberJurisdictionError}
                  </div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_LABEL}
                  </div>
                  <input
                    type="text"
                    id="memberFundsSource"
                    name="memberFundsSource"
                    className="form-control"
                    maxLength={40}
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_PLACEHOLDER
                    }
                    value={memberFundsSource}
                    onChange={(event) => this.changeHandler(event)}
                  />
                  <div className="[ error-msg ] ">{memberFundsSourceError}</div>
                </div>
              </div>
            ) : null}

            {/* End PEP Inner block */}
            {/* End PEP block */}
          </div>

          {/* Start HIO block */}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.PEP_HIO_CHOICE}
              {!contKycFlag ? (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(STRINGS.COMPLIANCE.HELP.ANY_PEP_HIO)
                  }
                />
              ) : (
                <QuestionCircle
                  className="question-icon"
                  size={20}
                  onClick={() =>
                    this.handleOpenHelp(STRINGS.COMPLIANCE.HELP.ANY_PEP_HIO_KYC)
                  }
                />
              )}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isAnyoneConsideredPEPHIO
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME
                        .ISANYONECONSIDEREDPEPORHIO,
                      true
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    !isAnyoneConsideredPEPHIO
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME
                        .ISANYONECONSIDEREDPEPORHIO,
                      false
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
              <div className="[ error-msg ]" />
            </div>
            {/* Start HIO inner block */}

            {isAnyoneConsideredPEPHIO ? (
              <div className="[ drawer-content-container ]">
                <div className="[ info-title ]">
                  {STRINGS.COMPLIANCE.FORM.PERSON_TITLE}
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.PERSON_NAME}
                  </div>
                  <input
                    type="text"
                    id="indicateName"
                    name="indicateName"
                    className={
                      indicateNameError.length > 0
                        ? "[ form-control ] [ invalid-input ]"
                        : "[ form-control ]"
                    }
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.PERSON_NAME_PLACEHOLDER
                    }
                    value={indicateName}
                    onChange={this.changeHandler}
                  />
                  <div className="[ error-msg ]">{indicateNameError}</div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_RELATIONSHIP}
                  </div>
                  <select
                    className="[ form-control ] [ select__dropdown ]"
                    id="anyRelationship"
                    name="anyRelationship"
                    value={anyRelationship}
                    onChange={this.changeHandler}
                  >
                    <option
                      value=""
                      className="[ default ] [ dropdown__option ]"
                    >
                      {
                        STRINGS.COMPLIANCE.FORM
                          .THIRDPARTY_RELATIONSHIP_PLACEHOLDER
                      }
                    </option>
                    {indicateWhoArray?.map((obj) => {
                      return (
                        <option
                          key={obj.code}
                          value={obj.description}
                          className="[ dropdown__option ]"
                        >
                          {obj.description}
                        </option>
                      );
                    })}
                  </select>
                  <div className="[ error-msg ] ">{anyRelationshipError}</div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.HOI_CHOICE}
                  </div>
                  <div className="[ row ] [ custom-radios ]">
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          PEPDomestic
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handlePEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPDOMESTIC
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_DOMESTIC}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          PEPForeign
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handlePEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPFOREIGN
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_FOREIGN}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          HIO
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handlePEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.HIO
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.HOI}
                      </button>
                    </div>
                  </div>
                  <div className="[col-12]">
                    <div className="[ error-msg ] ">{anyTypeError}</div>
                  </div>
                </div>

                {finInfo.config.officeHeld && (
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_LABEL}
                    </div>
                    <select
                      className="[ form-control ] [ select__dropdown ]"
                      id="anyOfficeHeld"
                      name="anyOfficeHeld"
                      value={anyOfficeHeld}
                      onChange={this.changeHandler}
                    >
                      <option
                        value=""
                        className="[ default ] [ dropdown__option ]"
                      >
                        {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_PLACEHOLDER}
                      </option>
                      {finInfo.config.officeHeld?.map((obj) => {
                        return (
                          <option
                            key={obj.code}
                            value={obj.code}
                            className="[ dropdown__option ]"
                          >
                            {obj.description}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ] ">{anyOfficeHeldError}</div>
                  </div>
                )}

                {!finInfo.config.officeHeld && (
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_LABEL}
                    </div>

                    <input
                      type="text"
                      id="anyOfficeHeld"
                      name="anyOfficeHeld"
                      className="form-control"
                      placeholder={
                        STRINGS.COMPLIANCE.FORM.OFFICE_HELD_PLACEHOLDER
                      }
                      value={anyOfficeHeld}
                      onChange={(event) => this.changeHandler(event)}
                    />
                    <div className="[ error-msg ] ">{anyOfficeHeldError}</div>
                  </div>
                )}
                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.JURISDICTION_LABEL}
                  </div>
                  <input
                    type="text"
                    id="anyJurisdiction"
                    name="anyJurisdiction"
                    className="form-control"
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.JURISDICTION_PLACEHOLDER
                    }
                    value={anyJurisdiction}
                    onChange={(event) => this.changeHandler(event)}
                  />
                  <div className="[ error-msg ] ">{anyJurisdictionError}</div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_LABEL}
                  </div>
                  <input
                    type="text"
                    id="anySourceOfFunds"
                    name="anySourceOfFunds"
                    className="form-control"
                    maxLength={40}
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_PLACEHOLDER
                    }
                    value={anySourceOfFunds}
                    onChange={(event) => this.changeHandler(event)}
                  />
                  <div className="[ error-msg ] ">{anySourceOfFundsError}</div>
                </div>
              </div>
            ) : null}

            {/* End HIO inner block */}
            {/* End HIO block */}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
        {showHelp && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

BusinessMemberCompliance.propTypes = {
  indicateWhoArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  finInfo: PropTypes.objectOf(PropTypes.any),
  occupationCodes: PropTypes.arrayOf(PropTypes.object),
  applicantData: PropTypes.oneOfType([PropTypes.object]),
};

BusinessMemberCompliance.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  finInfo: {},
  statusList: [],
  applicantData: null,
};

export default BusinessMemberCompliance;
