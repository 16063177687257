import React, { Component } from "react";
import PropTypes from "prop-types";

class LinkTermComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //  handle file click
  clickHandler = (event) => {
    const { linkClickHandler } = this.props;
    const filename = event.target.getAttribute("name");
    const name = event.target.getAttribute("value");

    linkClickHandler(filename, name);
  };

  //  render link items
  renderLinkTerms = (data) => {
    if (data.description) {
      return (
        <li
          key={data.filename}
          htmlFor="age"
          role="presentation"
          className="[ link ]"
          value={data.description}
          name={data.filename}
          onClick={this.clickHandler}
        >
          {data.description}
        </li>
      );
    }
    return false;
  };

  render() {
    const { linkTermsArray } = this.props;
    return <ul>{linkTermsArray.map((data) => this.renderLinkTerms(data))}</ul>;
  }
}
LinkTermComponent.propTypes = {
  linkTermsArray: PropTypes.arrayOf(PropTypes.object),
  linkClickHandler: PropTypes.func,
};
LinkTermComponent.defaultProps = {
  linkTermsArray: [],
  linkClickHandler: () => {},
};

export default LinkTermComponent;
