import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import AppConstants from "../../Constants/AppConstants";
import PopupModal from "../Common/PopupModal/PopupModal";

class BusinessExist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: {
        title: "",
        description: `It appears you are already an existing member.<br/>At this time, the Marketplace is unavailable to existing members. Please visit one of our branches.<br/>You may sign out.`,
      },
      isExistingBusinessClient: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_EXIST,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  handleChange = (isExistingBusinessClient) => {
    this.setState({ isExistingBusinessClient });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { isExistingBusinessClient } = this.state;
    this.clearChoice();
    if (isExistingBusinessClient) {
      this.setState({ showModal: true });
    } else {
      handleContinue({ isExistingBusinessClient });
    }
  };

  clearChoice = () => {
    this.setState({ isExistingBusinessClient: "" });
  };

  handleCloseHelp = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { isExistingBusinessClient, showModal, modal } = this.state;
    const { steps, activeStepID } = this.props;

    console.log(steps, activeStepID);
    const actionComponent = (
      <div className="[ existing-client-container ]">
        <div className="[ form-title-existing ]">
          {STRINGS.BUSINESS_EXIST.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_EXIST.FORM.CHOICE_MESSAGE}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isExistingBusinessClient !== "" && isExistingBusinessClient
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isExistingBusinessClient"
                  onClick={(event) => this.handleChange(true, event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    isExistingBusinessClient !== "" && !isExistingBusinessClient
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isExistingBusinessClient"
                  onClick={(event) => this.handleChange(false, event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                disabled={isExistingBusinessClient === ""}
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    const infoComponent = (
      <div className="[ existing-client-secondary-container ]">
        <div className="[ info-title ]">
          <span className="lock-icon">
            {STRINGS.EXISTING_MEMBER.INFOBLOCK.TITLE}
          </span>
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.EXISTING_MEMBER.INFOBLOCK.DESCRIPTION}
        </div>
      </div>
    );
    return (
      <>
        <AuthBody
          actionComponent={actionComponent}
          infoComponent={infoComponent}
          handleBack={this.handleBack}
          renderInfoComponent={true}
        />
        {showModal && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={"Hold on..."}
            description={modal.description}
            toggleModal={this.handleCloseHelp}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

BusinessExist.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessExist.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessExist;
