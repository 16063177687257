import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as ROUTES from "Constants/Routes";
import STRINGS from "Constants/Strings";

import {
  TOKEN_KEY,
  HTTP_STATUS,
  SORT_ON_PRIORITY,
  LENDING_PRODUCT,
} from "Communication/Constants";
import AppConstants from "Constants/AppConstants";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import PopupModal from "Components/Common/PopupModal/PopupModal";

import { sortList } from "Utils/CommonUtilities";
import QuestionFormContainer from "./QuestionFormContainer";

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: true,
      showModal: false,
      isChecked: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      showReset: false,
      currentQuestion: null,
      prevQuestion: null,
      currentAnswer: {},
      isEdit: false,
      showRecommendation: false,
      recommendedProducts: [],
      recommendedRelatedProducts: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  setModalState = (modal) => {
    this.setState(modal);
  };

  toggleModal = () => {};

  toggleReset = () => {};

  toggleEdit = () => {
    const { isEdit } = this.state;

    this.setState({
      isEdit: !isEdit,
    });
  };

  toggleRecommendation = (flag) => {
    this.setState({
      showRecommendation: flag,
    });
  };

  handlePrevQuestionChange = (prevQuestion) => {
    this.setState({
      prevQuestion,
    });
  };

  handleCurrentQuestionChange = (currentQuestion) => {
    this.setState({
      currentQuestion: currentQuestion,
    });
  };

  handleCurrentAnswerChange = (answer) => {
    console.log("handleCurrentAnswerChange", answer);
    this.setState({
      currentAnswer: answer,
    });
  };

  handleBack = () => {
    const { history, questionnaire } = this.props;
    const {
      currentAnswer,
      currentQuestion,
      prevQuestion,
      showRecommendation,
    } = this.state;
    const questionList = questionnaire?.questionList;
    if (prevQuestion !== null) {
      if (Object.keys(currentAnswer)?.length > 0) {
        const answerList = Object.keys(currentAnswer);
        const currentQuestionIndex =
          currentQuestion !== undefined
            ? answerList?.findIndex(
                (item, index) => parseInt(currentQuestion.id) === parseInt(item)
              )
            : answerList?.length;
        const prevQuestionIndex =
          currentQuestionIndex > 0 ? currentQuestionIndex - 1 : -1;
        if (prevQuestionIndex === -1) {
          history.push(ROUTES.HOME);
        }
        const newPrevQuestion = questionList?.find(
          (q) => q.id === parseInt(answerList[prevQuestionIndex])
        );

        // need to set the new currentAnswer as we go back, it's because every question and answers have different scenario.
        // So we have to remove the succeeding answers.
        this.handleUpdateCurrentAnswers(newPrevQuestion.id);

        // this.handlePrevQuestionChange(newPrevQuestion);
        this.handleCurrentQuestionChange(newPrevQuestion);

        if (currentQuestion === undefined) this.toggleRecommendation(false);

        return;
      }
      this.handleCurrentQuestionChange(prevQuestion);
      // prevQuestion();
      return;
    }

    history.push(ROUTES.HOME);
  };

  handleUpdateCurrentAnswers = (questionId) => {
    const { currentAnswer } = this.state;
    const filteredAnswers = Object.keys(currentAnswer)
      .filter((key) => parseInt(key) <= questionId)
      .reduce((result, key) => {
        result[key] = currentAnswer[key];
        return result;
      }, {});

    this.handleCurrentAnswerChange(filteredAnswers);
  };

  // Call this function to update the next answers if the next question was changed
  handleUpdateNextQuestionAnswers = (currentQuestion, nextQuestion) => {
    const { currentAnswer, isEdit } = this.state;
    const { questionnaire } = this.props;
    const questionList = questionnaire?.questionList;
    const answerList = Object.keys(currentAnswer);

    const currentQuestionIndex = answerList?.findIndex(
      (item, index) => parseInt(currentQuestion.id) === parseInt(item)
    );
    const currentNextQuestionIndex =
      answerList.length <= currentQuestionIndex + 1
        ? -1
        : currentQuestionIndex + 1;

    this.toggleRecommendation(
      !(nextQuestion !== null && nextQuestion !== undefined)
    );
    if (!(nextQuestion !== null && nextQuestion !== undefined)) {
      this.handleComputeRecommendationScores();
    }
    // do nothing if there's no next question
    if (currentNextQuestionIndex === -1) return;

    const currentNextQuestion = questionList?.find(
      (q) => q.id === parseInt(answerList[currentNextQuestionIndex])
    );

    // do nothing if same question
    if (currentNextQuestion?.id === nextQuestion?.id) {
      // check if it's edit question, set undefined
      if (isEdit) {
        this.handleCurrentQuestionChange(undefined);
        this.toggleRecommendation(true);
        this.handleComputeRecommendationScores();
      }

      return;
    }

    const filteredAnswers = Object.keys(currentAnswer)
      .filter((key) => parseInt(key) < parseInt(currentNextQuestion.id))
      .reduce((result, key) => {
        result[key] = currentAnswer[key];
        return result;
      }, {});
    this.handleCurrentAnswerChange(filteredAnswers);
  };

  handleComputeRecommendationScores = () => {
    const { questionnaire, productList } = this.props;
    const { currentAnswer } = this.state;
    const questionList = questionnaire?.questionList;
    let recommendedProductIds = [];
    // Create a Set to store unique exclusion values
    const uniqueExclusions = new Set();

    let totalScore = 0;
    Object.keys(currentAnswer).forEach(function (key) {
      const question = questionList?.find((q) => q?.id === parseInt(key));
      if (question?.type === "checkbox") {
        if (currentAnswer[key] && currentAnswer[key]?.length > 0) {
          currentAnswer[key].forEach((ans) => {
            if (ans?.exclusion && ans?.checked) {
              ans.exclusion.forEach((prodId) => {
                uniqueExclusions.add(prodId);
              });
            }
          });
        }
      } else {
        if (currentAnswer[key] && currentAnswer[key]?.exclusion) {
          currentAnswer[key].exclusion.forEach((prodId) => {
            uniqueExclusions.add(prodId);
          });
        }
      }
    });
    // Convert the Set back to an array if needed
    const uniqueExclusionsArray = [...uniqueExclusions];
    console.log("uniqueExclusionsArray", uniqueExclusionsArray);

    Object.keys(currentAnswer).forEach(function (key) {
      if (currentAnswer[key]?.score) {
        totalScore += currentAnswer[key].score;
      } else if (Array.isArray(currentAnswer[key])) {
        Object.keys(currentAnswer[key]).forEach(function (idx) {
          if (currentAnswer[key][idx]?.score) {
            totalScore += currentAnswer[key][idx].score;
          }
        });
      }
    });
    console.log("totalScore", totalScore);
    for (let i = 0; i < questionnaire?.result.length; i++) {
      const r = questionnaire?.result[i];
      console.log(r);
      if (r.min <= totalScore && r.max >= totalScore) {
        if (r?.productId) {
          r.productId.forEach((prodId) => {
            recommendedProductIds.push(prodId);
          });
        }
      }
    }
    recommendedProductIds = Array.from(new Set(recommendedProductIds));
    console.log("recommendedProductIds", recommendedProductIds);
    const recommendedRelatedProducts = questionnaire?.products?.filter(
      (product) =>
        recommendedProductIds?.some((recommendedProd) =>
          recommendedProd?.includes(product?.productId)
        )
    );
    console.log("recommendedRelatedProducts", recommendedRelatedProducts);
    const recommendedProductsTemp = productList.flatMap((item) =>
      item.products.filter((product) => {
        const isRecommended = recommendedProductIds?.some((recommendedProd) =>
          recommendedProd?.includes(product?.productId)
        );
        const isExcluded = uniqueExclusionsArray?.some((exclusion) =>
          product?.productId?.includes(exclusion)
        );

        return product.show && item.show && isRecommended && !isExcluded;
      })
    );
    console.log("recommendedProductsTemp", recommendedProductsTemp);

    const recommendedProducts = [];
    // recommended products shoult be sorted as indicated in the result array
    recommendedProductIds.forEach((productId) => {
      const recom = recommendedProductsTemp.find(
        (item) => item.productId === productId
      );
      if (recom) recommendedProducts.push(recom);
    });
    console.log("recommendedProducts", recommendedProducts);

    let isRecommendationAllowed = false;
    if (questionnaire?.result) {
      if (questionnaire.result.length > 0) {
        isRecommendationAllowed = true;
      }
    }

    this.setState({
      recommendedProducts: isRecommendationAllowed ? recommendedProducts : [],
      recommendedRelatedProducts: recommendedRelatedProducts,
    });
  };

  handleAddToVault = (cardItem) => {
    const {
      vaultProductList,
      doGetProductFromVault,
      loanProducts,
      doGetLoanProduct,
    } = this.props;
    console.log("handleAddToVault", cardItem);
    const accessToken = sessionStorage.getItem(TOKEN_KEY);

    if (accessToken) {
      doGetProductFromVault((response) => {
        if (loanProducts && loanProducts.length > 0) {
          if (!response || response.data === "") {
            this.addToVaultWithProductExistCheck([], cardItem);
          } else {
            this.addToVaultWithProductExistCheck([...response?.data], cardItem);
          }
        } else {
          doGetLoanProduct(() => {
            if (!response || response.data === "") {
              this.addToVaultWithProductExistCheck([], cardItem);
            } else {
              this.addToVaultWithProductExistCheck(
                [...response?.data],
                cardItem
              );
            }
          });
        }
      });
    } else {
      this.addToVaultWithProductExistCheck([...vaultProductList], cardItem);
    }
  };

  addToVaultWithProductExistCheck = (vaultCards, cardItem) => {
    const { selectCard, showModal } = this.state;
    const {
      loanProducts,
      finInfo,
      doUpdateVaultProductList,
      doUpdateProductToVault,
      bankingType,
    } = this.props;
    if (vaultCards && vaultCards.length >= finInfo.vaultLimit) {
      this.setState({
        showVaultLimitError: true,
      });
    }

    const selectedProduct = cardItem || selectCard;
    console.log("selectedProduct", selectedProduct, this.props);
    const product = {
      ...selectedProduct,
      // don't store product details as it makes the data too big
      productDetails: null,
      active: false,
      applicantId: null,
      applicationId: null,
      applicationStep: {
        index: null,
        step: "",
      },
      bankingType,
    };
    const rand = Math.floor(Math.random() * 1000);
    product.productIndex = rand;

    const valueIndex = vaultCards.findIndex(
      (elem) => elem.productId === selectedProduct.productId
    );

    if (product.type === LENDING_PRODUCT) {
      const loanProduct = loanProducts.find(
        (item) => product.productId === item.id
      );
      if (loanProduct) {
        product.category = loanProduct.category;
        product.subCategory = loanProduct.subCategory;
        product.productType = loanProduct.productType;
        product.productCode = loanProduct.productCode;
        product.cutoff = loanProduct.cutoff;
        product.applicationType = loanProduct.applicationType;
      }
    } else {
      product.category = "";
      product.subCategory = "";
    }
    if (valueIndex !== -1) {
      const modal = {};
      modal.title = STRINGS.MARKETPLACE.BODY.PRODUCT_ADDED;
      modal.description = STRINGS.MARKETPLACE.BODY.MULTIPLE_PRODUCT_ADD;

      this.setState({
        showModal: !showModal,
        modal,
        modalType: AppConstants.MODALTYPE.CUSTOM,
      });
    }
    vaultCards.push(product);
    let sortedVaultCards = sortList(vaultCards, SORT_ON_PRIORITY);

    // move in progress applications to first
    const cardNotStarted = sortedVaultCards.filter(
      (x) => x.applicationStep.index == null
    );
    const cardStarted = sortedVaultCards.filter(
      (x) => x.applicationStep.index != null
    );

    sortedVaultCards = [...cardStarted, ...cardNotStarted];

    const accessToken = sessionStorage.getItem(TOKEN_KEY);
    if (accessToken) {
      doUpdateProductToVault(sortedVaultCards, () => {});
    } else {
      doUpdateVaultProductList(sortedVaultCards);
    }
  };

  handlePrintPdf = () => {
    const { currentAnswer } = this.state;
    const { questionnaire } = this.props;
    const questionList = questionnaire?.questionList;

    const result = [];
    for (const key in currentAnswer) {
      if (currentAnswer.hasOwnProperty(key)) {
        const answerData = currentAnswer[key];
        const question = questionList.find((q) => q.id === parseInt(key));
        result.push({
          ...question,
          answer: answerData,
        });
      }
    }

    // send result to API
    console.log("result data for print", result);
    // return result;
  };

  render() {
    const {
      showModal,
      modalType,
      modal,
      showReset,
      prevQuestion,
      currentQuestion,
      currentAnswer,
      isEdit,
      showRecommendation,
      recommendedProducts,
      recommendedRelatedProducts,
    } = this.state;
    const {
      showVaultClicked,
      finInfo,
      productList,
      vaultProductList,
      questionnaire,
      questionnaireProduct,
      applicantData,
      showCaptcha,
      hideCaptcha,
    } = this.props;
    console.log("product List", productList);
    const questionnaireComponent = (
      <form className="[ questionnaire-container ]">
        <div className="[ form-title-questionnaire ]">
          {STRINGS.QUESTIONNAIRE.TITLE}
        </div>
        <QuestionFormContainer
          productList={productList}
          vaultProductList={vaultProductList}
          handleAddToVault={this.handleAddToVault}
          questionnaire={questionnaire}
          questionnaireProduct={questionnaireProduct}
          prevQuestion={prevQuestion}
          currentQuestion={currentQuestion}
          currentAnswer={currentAnswer}
          isEdit={isEdit}
          showRecommendation={showRecommendation}
          recommendedProducts={recommendedProducts}
          recommendedRelatedProducts={recommendedRelatedProducts}
          onPrevQuestionChange={this.handlePrevQuestionChange}
          onCurrentQuestionChange={this.handleCurrentQuestionChange}
          onCurrentAnswerChange={this.handleCurrentAnswerChange}
          onUpdateNextQuestionAnswers={this.handleUpdateNextQuestionAnswers}
          toggleEdit={this.toggleEdit}
          toggleRecommendation={this.toggleRecommendation}
          computeRecommendationScores={this.handleComputeRecommendationScores}
          onPrintPdf={this.handlePrintPdf}
          applicantData={applicantData}
          showCaptcha={showCaptcha}
          hideCaptcha={hideCaptcha}
        />
      </form>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showReset && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleReset}
            showModal={showReset}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}

        <AuthBody
          actionComponent={questionnaireComponent}
          showVaultClicked={() => showVaultClicked}
          handleBack={() => this.handleBack()}
          memberCreation
          showProceedBtn
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  signinState: state.SigninReducer,
});
Questionnaire.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  showVaultClicked: PropTypes.func,
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  loanProducts: PropTypes.oneOfType([PropTypes.object]),
  doGetProductFromVault: PropTypes.func,
  doUpdateVaultProductList: PropTypes.func,
  questionnaire: PropTypes.oneOfType([PropTypes.object]),
  doGetLoanProduct: PropTypes.func,
};
Questionnaire.defaultProps = {
  history: {},
  showVaultClicked: () => {},
  finInfo: {},
  productList: [],
  vaultProductList: [],
  loanProducts: [],
  doGetProductFromVault: () => {},
  doUpdateVaultProductList: () => {},
  doGetLoanProduct: () => {},
  questionnaire: {},
};
export default connect(mapStateToProps)(Questionnaire);
