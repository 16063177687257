import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import NumberFormat from "react-number-format";
import Alert from "react-bootstrap/Alert";
// eslint-disable-next-line spaced-comment
/*import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "../../Constants/ValidationList";*/
import { QuestionCircle } from "react-bootstrap-icons";
import STRINGS from "../../Constants/Strings";
import AppConstants from "../../Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import PopupModal from "../Common/PopupModal/PopupModal";
import Stepper from "../Common/Stepper/Stepper";

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      membership: null,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.MEMBERSHIP, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {}

  setFormData = () => {};

  validateForm = () => {
    let valid = true;
    const { applicants } = this.state;
    console.log("validateForm", applicants);
    const { membership } = this.props;

    const error = checkErrors(membership, VALIDATIONS.MEMBERSHIP.MEMBERSHIP);

    if (error.length) {
      valid = false;
      this.setState({ membershipError: error });
    } else {
      this.setState({ membershipError: [] });
    }

    return valid;
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    if (this.validateForm()) {
      handleContinue(this.state);
    }
  };

  selectMembership = (event) => {
    const { selectMembership } = this.props;
    this.setState({ membershipError: [] });
    selectMembership(event);
  };

  handleBack = () => {
    const { handleBack, page, togglePage } = this.props;
    console.log("handleBack", page);
    if (page === 2) {
      this.setState({ applicants: [] }, () => {
        togglePage(1);
      });
    } else {
      handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
    }
  };

  render() {
    const { membershipError, showPopup, membership } = this.state;
    const {
      steps,
      activeStepID,
      applicantTypes,
      ownershipList,
      ownership,
      survivorList,
      survivor,
      finInfo,
      page,
      singularMembership,
      dataIssue,
    } = this.props;
    console.log("page", page);
    const membershipList = singularMembership?.map((mem) => (
      <option
        key={mem.Member}
        value={mem.Member}
        className="[ dropdown__option ]"
      >
        {mem.FirstName}--{mem.Branch}--{mem.Member}
      </option>
    ));

    const membershipComponent = (
      <div className="[ joint-application-container ]">
        <Stepper
          steps={steps}
          active={activeStepID}
          title={STRINGS.MEMBERSHIP.BREADCRUMB}
        />

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-row ]">{STRINGS.MEMBERSHIP.INFO1}</div>
            <div className="[ form-row ]">{STRINGS.MEMBERSHIP.INFO2}</div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.MEMBERSHIP.FORM.SELECT_MEMBERSHIP}
            </div>
            <select
              className="[ form-control select__dropdown]"
              id="membership"
              name="membership"
              value={membership}
              onChange={(event) => {
                this.selectMembership(event);
              }}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.MEMBERSHIP.FORM.SELECT_DEFAULT}
              </option>
              {membershipList}
              <option value="NEW" className="[ dropdown__option ]">
                {STRINGS.MEMBERSHIP.FORM.CREATE_NEW}
              </option>
            </select>
            <div className="[ error-msg ]">
              {membershipError && <>{membershipError}</>}
              {dataIssue && <>{dataIssue}</>}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <AuthBody
          actionComponent={membershipComponent}
          memberCreation
          handleBack={this.handleBack}
        />
        {showPopup && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={"error message"}
            toggleModal={this.handleCloseHelp}
            showModal={showPopup}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </div>
    );
  }
}

Membership.propTypes = {
  getDataFromServer: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.object),
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  getActiveProduct: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  jointAccounts: PropTypes.arrayOf(PropTypes.object),
  jointInSession: PropTypes.bool,
  ownershipList: PropTypes.arrayOf(PropTypes.object),
  ownership: PropTypes.string,
  survivorList: PropTypes.arrayOf(PropTypes.object),
  survivor: PropTypes.string,
  handleChange: PropTypes.func,
  account: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
};

Membership.defaultProps = {
  getDataFromServer: () => {},
  handleChange: () => {},
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  products: [],
  jointAccounts: [],
  jointInSession: false,
  ownershipList: [],
  ownership: "",
  survivorList: [],
  survivor: "",
  account: {},
  finInfo: {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Membership);
