import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import * as ROUTES from "Constants/Routes";
import PopupModal from "../Common/PopupModal/PopupModal";
import { setOptions } from "../../Utils/EmploymentAndIncomeUtilities";
import { isNewMember } from "../../Utils/LogicUtilities";
import { findCode, getDescriptionFromArray } from "../../Utils/CommonUtilities";
import { HTTP_STATUS } from "../../Communication/Constants";

class BusinessIndividual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      employmentStatus: "",
      employerPosition: "",
      currentEmployer: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      address: "",
      unit: "",
      city: "",
      province: "",
      postalCode: "",
      mailingSameAsCivic: false,
      mailingAddress: "",
      mailingUnit: "",
      mailingCity: "",
      mailingProvince: "",
      mailingPostalCode: "",
      employmentData: {},
      complianceData: {},
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_INDIVIDUAL,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct, processTemplate } = this.props;
    if (prevProps !== this.props) {
      this.setFormData();
      const processFlow = processTemplate.flow;
      const show = processFlow.map((item) => item.componentName);
      this.setState({
        processFlow,
        show,
      });
    }
  }

  setFormData = () => {
    const { activeProduct, personalData, finInfo } = this.props;
    if (personalData?.id) {
      this.setState({
        employmentStatus: finInfo?.config.businessEmployment?.find(
          (x) => x.code === personalData.employment?.employmentStatus
        )?.description,
        employerPosition: activeProduct?.position,
        currentEmployer: activeProduct?.employerName,
        firstName: personalData.member?.name,
        middleName: personalData.member?.middleName,
        lastName: personalData.member?.surname,
        dob: personalData.member?.dob,
        address: personalData?.address?.currentAddressLine1,
        unit: personalData?.address?.currentAddressUnitNumber,
        city: personalData?.address?.currentAddressCity,
        province: personalData?.address?.currentAddressProvince,
        postalCode: personalData?.address?.currentAddressPostalCode,
        mailingSameAsCivic: personalData?.address?.mailingSameAsCivic,
        mailingAddress: personalData?.address?.mailingAddressLine1,
        mailingUnit: personalData?.address?.mailingUnit,
        mailingCity: personalData?.address?.mailingCity,
        mailingProvince: personalData?.address?.mailingProvince,
        mailingPostalCode: personalData?.address?.mailingPostalCode,
      });
      this.saveemploymentData(personalData);
      this.saveComplianceData(personalData);
    } else {
      this.setState({
        employmentStatus: finInfo?.config.businessEmployment?.find(
          (x) => x.code === activeProduct?.employmentStatus
        )?.description,
        employerPosition: activeProduct?.position,
        currentEmployer: activeProduct?.employerName,
        firstName: activeProduct?.personal?.firstName,
        middleName: activeProduct?.personal?.middleName,
        lastName: activeProduct?.personal?.lastName,
        dob: activeProduct?.personal?.dateOfBirth,
        address: activeProduct?.personal?.address,
        unit: activeProduct?.personal?.currentAddressUnitNumber,
        city: activeProduct?.personal?.city,
        province: activeProduct?.personal?.state,
        postalCode: activeProduct?.personal?.postalCode,
        mailingSameAsCivic: activeProduct?.personal?.mailingSameAsCivic,
        mailingAddress: activeProduct?.personal?.mailingAddressLine1,
        mailingUnit: activeProduct?.personal?.mailingUnit,
        mailingCity: activeProduct?.personal?.mailingCity,
        mailingProvince: activeProduct?.personal?.mailingProvince,
        mailingPostalCode: activeProduct?.personal?.mailingPostalCode,
      });
    }
  };

  toggleModal = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    let valid = true;

    if (valid) {
      handleContinue(property);
    }
  };
  handleContinue = () => {
    const {
      doUpdateApplicant,
      personalData,
      applicantData,
      activeProduct,
    } = this.props;
    let valid = true;

    if (valid) {
      if (personalData.id) {
        let request = { ownerApplicantId: personalData.id };
        let selectedMember = activeProduct?.foundMemberList?.find(
          (x) => x.selectedMember
        );
        if (!selectedMember) {
          selectedMember = activeProduct?.additionalMemberList?.find(
            (x) => x.selectedMember
          );
        }
        if (selectedMember?.authorizedSignatory) {
          const unformatedMobile = selectedMember.mobileNo.replace(
            /\(|\)|-| /g,
            ""
          );
          const homeArea = unformatedMobile.substring(0, 3);
          const homePhone = unformatedMobile.substring(
            homeArea.length,
            unformatedMobile.length
          );
          request = {
            member: {
              name: selectedMember.firstName,
              surname: selectedMember.lastName,
              email: selectedMember.email,
            },
            address: {
              currentAddressCellPhoneArea: homeArea,
              currentAddressCellPhone: homePhone,
            },
            ownerApplicantId: personalData.id,
          };
        }
        doUpdateApplicant(request, applicantData.id, (res) => {
          console.log(res);
          if (res.status === HTTP_STATUS.OK) {
            this.setState({ showModal: true });
          }
        });
      } else {
        this.setState({ showModal: true });
      }
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_INDIVIDUAL);
  };

  handleChoice = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    return formIsValid;
  };

  saveemploymentData = (response) => {
    const { finInfo, vaultMember } = this.props;
    let { employment, regulatory, member } = response;

    regulatory = { ...regulatory, ...vaultMember };
    member = {
      ...member,
      ...vaultMember,
    };

    const resOptions = setOptions(regulatory?.taxResidency, finInfo);
    console.log("resOptions", resOptions);
    const selectedOption = resOptions?.options?.find(
      (obj) => obj.taxResidencyResult?.taxResidency === regulatory?.taxResidency
    );
    console.log("selectedOption", selectedOption);
    let areYouTaxResidentOfOtherCountry =
      resOptions?.taxResidentOther !== ""
        ? resOptions?.taxResidentOther
        : false;
    let countryName = selectedOption?.taxResidentOtherName;
    let tin = "";

    if (finInfo.useRegulatoryFieldsModel) {
      const tinOtherCountry = regulatory.regulatoryFields?.find(
        (item) => item.Code === 2
      );
      if (tinOtherCountry?.Value) {
        areYouTaxResidentOfOtherCountry = true;
        countryName = regulatory?.regulatoryFields?.find(
          (item) => item.Code === 3
        )?.Value;
        tin = tinOtherCountry.Value;
      }
    }

    this.setState({
      employmentData: {
        ...employment,
        employerName: employment.currentEmployer,
        employerContactNumber:
          employment.employerPhoneArea + employment.employerPhone,
        annualGrossIncome: employment.employerSalary,
        sourceOfIncome: employment.incomeType,
        sin: member.sin,
        ssn: regulatory.ssn,
        areYouTaxResidentOfCanada: !!member.sin,
        areYouTaxResidentOfOtherCountry,
        countryOption: resOptions?.options,
        countryList: regulatory.taxResidencyOther,
        countryName,
        regulatoryFields: regulatory.regulatoryFields,
        tin,
      },
    });
  };

  saveComplianceData = (response) => {
    let { peps, member, regulatory } = response;
    this.setState({
      complianceData: {
        ...response,
        isAnyoneConsideredPEPHIO: !!(peps && peps.length > 0),
        member: {
          ...member,
          pep:
            regulatory?.memberIsPedp ||
            regulatory?.memberIsPefp ||
            regulatory?.memberIsHio,
        },
        regulatory: {
          ...response.regulatory,
        },
      },
    });
  };

  render() {
    const {
      steps,
      activeStepID,
      handleEditPage,
      finInfo,
      applicantData,
      activeProduct,
      personalData,
    } = this.props;
    const {
      showModal,
      employmentStatus,
      employerPosition,
      currentEmployer,
      firstName,
      middleName,
      lastName,
      dob,
      address,
      unit,
      city,
      province,
      postalCode,
      mailingSameAsCivic,
      mailingAddress,
      mailingUnit,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      show,
      employmentData,
      complianceData,
    } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ business-applicant-review ] [ confirm-account-container ] [ business-review-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.BUSINESS_INDIVIDUAL.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_INDIVIDUAL.REVIEW_DETAILS_MSG1}
          </div>
          <div className="[ form-description ]">
            {STRINGS.BUSINESS_INDIVIDUAL.REVIEW_DETAILS_MSG2}
          </div>

          {show?.includes(AppConstants.APPLICATIONSTEP.BUSINESS_KYC) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.BUSINESS_ABOUT_YOU}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.BUSINESS_KYC)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_INDIVIDUAL.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {firstName}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {middleName}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {lastName}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {dob}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {address}
                    </div>
                  </div>
                </div>

                {finInfo?.coreBankingType !==
                  AppConstants.CORE_BANKING_TYPE.DNA && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {unit}
                      </div>
                    </div>
                  </div>
                )}

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {city}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {province}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {postalCode}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSCONFIRMQUESTION}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {mailingSameAsCivic ? "Yes" : "No"}
                    </div>
                  </div>
                </div>

                {!mailingSameAsCivic && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingAddress}
                        </div>
                      </div>
                    </div>

                    {finInfo?.coreBankingType !==
                      AppConstants.CORE_BANKING_TYPE.DNA && (
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {mailingUnit}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingCity}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingProvince}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {mailingPostalCode}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {show?.includes(AppConstants.APPLICATIONSTEP.BUSINESS_EMPLOYMENT) && (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.BUSINESS_EMPLOYMENT}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_EMPLOYMENT)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_INDIVIDUAL.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_EMPLOYMENT.FORM.EMPLOYMENT_STATUS_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentStatus}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_EMPLOYMENT.FORM.POSITION_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employerPosition}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_EMPLOYMENT.FORM.EMPLOYER_NAME_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {currentEmployer}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {show?.includes(AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_TAX) &&
            personalData?.id && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.TAX_INFO.TITLE}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_MEMBER_TAX)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OF_CANADA}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData &&
                      employmentData.areYouTaxResidentOfCanada
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  </div>
                </div>

                {employmentData && employmentData.sin && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.SIN_NUMBER}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        ****&nbsp;
                        {employmentData && employmentData.sin
                          ? employmentData.sin.substr(
                              employmentData.sin.length - 3
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                )}

                {finInfo.useRegulatoryFieldsModel && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OF_USA}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData && employmentData.ssn
                            ? STRINGS.COMMON.CHOICE.YES
                            : STRINGS.COMMON.CHOICE.NO}
                        </div>
                      </div>
                    </div>
                    {employmentData.ssn && (
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.SSN_NUMBER}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            ****&nbsp;
                            {employmentData && employmentData.ssn
                              ? employmentData.ssn.substr(
                                  employmentData.ssn.length - 3
                                )
                              : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OTHER_COUNTRY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData &&
                      employmentData.areYouTaxResidentOfOtherCountry
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  </div>
                </div>

                {employmentData &&
                  employmentData.areYouTaxResidentOfOtherCountry && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.TAX_SELECT_COUNTRY}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData && employmentData.countryName
                            ? employmentData.countryName
                            : null}
                        </div>
                      </div>
                    </div>
                  )}

                {employmentData &&
                  employmentData.ssn &&
                  !finInfo.useRegulatoryFieldsModel && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.SSN_NUMBER}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          ****&nbsp;
                          {employmentData && employmentData.ssn
                            ? employmentData.ssn.substr(
                                employmentData.ssn.length - 3
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  )}

                {employmentData &&
                  employmentData.tin &&
                  finInfo.useRegulatoryFieldsModel && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.TAX_IDENTITY_NO}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          ****&nbsp;
                          {employmentData && employmentData.tin
                            ? employmentData.tin.substr(
                                employmentData.tin.length - 3
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}

          {show?.includes(
            AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_COMPLIANCE
          ) &&
            personalData?.id && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.COMPLIANCE.TITLE}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_MEMBER_COMPLIANCE)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.COMPLIANCE.FORM
                          .BUSINESS_INTENDED_RELATIONSHIP_LABEL
                      }
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {finInfo?.config?.businessMemberRelation
                        ? finInfo?.config?.businessMemberRelation.find(
                            (x) => x.code === activeProduct?.businessRelation
                          )?.description
                        : ""}
                    </div>
                  </div>
                </div>

                {complianceData &&
                complianceData.regulatory?.memberHasThirdParty ? (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    {complianceData.thirdParties?.map((record) => (
                      <div key={record}>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYNAME}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {record.name}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYREL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {record.relationship}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}

                {/* is applicant pep or hio start  */}
                {complianceData && complianceData.member?.pep ? (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.MEMBER_PEP_HIO_CHOICE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.APPLICANTTYPE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberIsHio && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.HIO_STATUS}
                            </div>
                          )}

                        {complianceData &&
                          complianceData.regulatory?.memberIsPedp && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.PEP_DOMESTIC}
                            </div>
                          )}
                        {complianceData &&
                          complianceData.regulatory?.memberIsPefp && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.PEP_FOREGIN}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.OFFICE_HELD_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberOfficeHeld && (
                            <div className="[ details-section__item-description ]">
                              {finInfo?.config?.officeHeld
                                ? finInfo.config?.officeHeld?.find(
                                    (item) =>
                                      item.code ===
                                      complianceData.regulatory.memberOfficeHeld
                                  )?.description
                                : complianceData.regulatory?.memberOfficeHeld}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.JURISDICTION_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberFundsSource && (
                            <div className="[ details-section__item-description ]">
                              {complianceData.regulatory.memberJurisdiction}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.SOURCE_OF_FUNDS_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberFundsSource && (
                            <div className="[ details-section__item-description ]">
                              {complianceData.regulatory.memberFundsSource}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MEMBER_PEP_HIO_CHOICE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}
                {/* is applicant pep or hio end */}
                {/* PEP details start */}
                {complianceData && complianceData.peps?.length > 0 ? (
                  <>
                    {/* applicant details start */}

                    {/* applicant details end */}

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.PEP_HIO_CHOICE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    {complianceData.peps.map((data) => (
                      <div key={data}>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.CLOSEPERSONDETAILS}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.name}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYREL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.relationship}
                            </div>
                          </div>
                        </div>

                        {data.jurisdictionType && (
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {
                                  STRINGS.CONFIRM_CREATION
                                    .CLOSEPERSONJURISDICTIONTYPE
                                }
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {data.jurisdictionType}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.ISPEPORHIO}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.hio ? "true" : "false"}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.OFFICE_HELD_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {finInfo?.config?.officeHeld
                                ? finInfo.config?.officeHeld?.find(
                                    (item) => item.code === data.officeHeld
                                  )?.description
                                : data.officeHeld}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.JURISDICTION_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.jurisdiction}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.SOURCE_OF_FUNDS_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.fundsSource}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.PEP_HIO_CHOICE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}
                {/* PEP details end */}
              </div>
            )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            title={STRINGS.BUSINESS_INDIVIDUAL.SUCCESS_POP_UP_TITLE}
            description={STRINGS.BUSINESS_INDIVIDUAL.SUCCESS_POP_UP_DESCRIPTION}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessIndividual.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func.isRequired,
  handleSample: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessIndividual.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  handleSample: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessIndividual;
