import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import "./PopupModal.scss";
import MODALMESSAGES from "Constants/Messages";
import STRINGS from "Constants/Strings";
import { EXPIRES_AT, REFRESH_EXPIRES_AT } from "../../Communication/Constants";
import { QRCodeSVG } from "qrcode.react";
import ROUTES from "../../Constants/AppConstants";

class PopupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.showModal,
      qrUrl: "",
      qrCode: "",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleClose = () => {
    this.setState({ show: false });
  };

  processNextStep = (step) => {
    const {
      doUpdateProductToVault,
      vaultProductList,
      getActiveProduct,
      history,
      refreshAboutYou,
    } = this.props;
    const activeProduct = getActiveProduct(vaultProductList);
    if (activeProduct.kycStatus === "started") {
      const qrCode = Math.floor(100000 + Math.random() * 900000);
      vaultProductList[0].kycStatus = "generate";
      vaultProductList[0].kycQrCode = qrCode;
      this.setState({
        qrCode,
        qrUrl:
          process.env.REACT_APP_BUCKET_URL +
          "signin?token=" +
          sessionStorage.getItem("token") +
          "&" +
          EXPIRES_AT +
          "=" +
          sessionStorage.getItem(EXPIRES_AT) +
          "&" +
          REFRESH_EXPIRES_AT +
          "=" +
          sessionStorage.getItem(REFRESH_EXPIRES_AT) +
          "&x=x",
      });
      doUpdateProductToVault(vaultProductList);
    } else if (
      activeProduct?.kycStatus === "mobileInProgress" ||
      activeProduct?.kycStatus === "mobileEnd"
    ) {
      refreshAboutYou();
    }
  };

  render() {
    const {
      showPopupModalButtons,
      toggleKyc,
      vaultProductList,
      getActiveProduct,
      skipKyc,
    } = this.props;
    const { show, qrUrl, qrCode } = this.state;
    console.log("qrUrl", qrUrl);
    const activeProduct = getActiveProduct(vaultProductList);
    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.handleClose();
            toggleKyc(false);
          }}
          className={"[ popup-modal__container kyc-qr ]"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
          keyboard={false}
        >
          <Modal.Header
            className="[ popup-modal__header ]"
            closeButton={showPopupModalButtons}
          >
            {activeProduct?.kycStatus === "started" && (
              <Modal.Title className={""}>
                {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_INTRO_TITLE}
              </Modal.Title>
            )}
            {activeProduct?.kycStatus === "generate" && (
              <Modal.Title className={""}>
                {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_DESKTOP_TITLE}
              </Modal.Title>
            )}
            {activeProduct?.kycStatus === "mobileStarted" && (
              <Modal.Title className={""}>
                {
                  STRINGS.ABOUTYOU.KYC_QR_CODE
                    .QR_CODE_DESKTOP_MOBILE_STARTED_TITLE
                }
              </Modal.Title>
            )}
            {(activeProduct?.kycStatus === "mobileInProgress" ||
              activeProduct?.kycStatus === "mobileEnd") && (
              <Modal.Title className={""}>
                {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_DESKTOP_PAIRING_TITLE}
              </Modal.Title>
            )}
          </Modal.Header>

          <Modal.Body className="[ text-left ] [ overflow-auto ] [ iframe-popup-modal__body ]">
            {activeProduct?.kycStatus === "started" && (
              <div className="[ mb-3 ]">
                <div className="[ mb-3 ] [ alert alert-warning ]">
                  <p>
                    {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INTRO_DESCRITPION}
                  </p>
                  <p>
                    {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INTRO_DESCRITPION1}
                  </p>
                </div>
                <div className="[ mb-3 ]">
                  <ul>
                    <li>
                      {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INTRO_DESCRITPION2}
                    </li>
                    <li>
                      {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INTRO_DESCRITPION3}
                    </li>
                    <li>
                      {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INTRO_DESCRITPION4}
                    </li>
                    <li>
                      {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INTRO_DESCRITPION5}
                    </li>
                    <li>
                      {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INTRO_DESCRITPION6}
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {activeProduct?.kycStatus === "generate" && (
              <>
                <div className="[ mb-3 text-center ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INSTRUCTION}
                    </div>
                  </div>
                </div>
                <div className="[ mb-3 text-center ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <QRCodeSVG value={qrUrl} />
                    </div>
                  </div>
                </div>
                <div className="[ mb-3 text-center ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_INSTRUCTION1}
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeProduct?.kycStatus === "mobileStarted" && (
              <>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      {
                        STRINGS.ABOUTYOU.KYC_QR_CODE
                          .QR_CODE_DESKTOP_MOBILE_STARTED_DESCRIPTION
                      }
                    </div>
                  </div>
                </div>
                <div className="[ mb-3 text-center ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <b>{qrCode}</b>
                    </div>
                  </div>
                </div>
                <div className="[ mb-3 text-center ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      {
                        STRINGS.ABOUTYOU.KYC_QR_CODE
                          .QR_CODE_DESKTOP_MOBILE_STARTED_DESCRIPTION1
                      }
                    </div>
                  </div>
                </div>
              </>
            )}
            {(activeProduct?.kycStatus === "mobileInProgress" ||
              activeProduct?.kycStatus === "mobileEnd") && (
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <p>
                      {
                        STRINGS.ABOUTYOU.KYC_QR_CODE
                          .QR_CODE_DESKTOP_PAIRING_DESCRIPTION
                      }
                    </p>
                    <p>
                      {
                        STRINGS.ABOUTYOU.KYC_QR_CODE
                          .QR_CODE_DESKTOP_PAIRING_DESCRIPTION1
                      }
                    </p>
                    <p>
                      {
                        STRINGS.ABOUTYOU.KYC_QR_CODE
                          .QR_CODE_DESKTOP_PAIRING_DESCRIPTION2
                      }
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
            {activeProduct?.kycStatus === "started" && (
              <>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <div>
                        <button
                          type="button"
                          className="[ btn submit-btn ] [ w-100 ]"
                          onClick={() => this.processNextStep()}
                        >
                          <span>
                            {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_BUTTON}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 text-center ]">
                      <div>
                        <p>
                          <span
                            className="[ link ]"
                            role="presentation"
                            onClick={skipKyc}
                          >
                            {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_BUTTON_SKIP}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {(activeProduct?.kycStatus === "mobileInProgress" ||
              activeProduct?.kycStatus === "mobileEnd") && (
              <>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <div>
                        <button
                          type="button"
                          className="[ btn submit-btn ] [ w-100 ]"
                          onClick={() => this.processNextStep()}
                          disabled={
                            activeProduct?.kycStatus === "mobileInProgress"
                          }
                        >
                          <span>
                            {
                              STRINGS.ABOUTYOU.KYC_QR_CODE
                                .QR_CODE_DESKTOP_BUTTON_CONTINUE
                            }
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

PopupModal.propTypes = {
  showModal: PropTypes.bool,
  type: PropTypes.string,
  modalType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  btnText: PropTypes.string,
  toggleModal: PropTypes.func,
  popupBtnClick: PropTypes.func,
  closeBtnClick: PropTypes.func,
  showPopupModalButtons: PropTypes.bool,
  iframeModal: PropTypes.bool,
  iframeUrl: PropTypes.string,
  footer: PropTypes.string,
  subtitle: PropTypes.string,
  addToVault: PropTypes.bool,
  tag: PropTypes.bool,
  seeDetailsClick: PropTypes.bool,
  shortDescription: PropTypes.string,
  customBtnClick: PropTypes.func,
  customBtnText: PropTypes.string,
  product: PropTypes.oneOfType([PropTypes.object]),
};

PopupModal.defaultProps = {
  showModal: false,
  type: "success",
  modalType: "",
  title: "",
  description: "Test description",
  btnText: "OK",
  toggleModal: () => {},
  popupBtnClick: () => {},
  closeBtnClick: () => {},
  showPopupModalButtons: false,
  iframeModal: false,
  iframeUrl: "",
  footer: "",
  subtitle: "",
  addToVault: true,
  tag: false,
  seeDetailsClick: false,
  shortDescription: "",
  product: {},
  customBtnClick: () => {},
  customBtnText: "",
};

export default withRouter(PopupModal);
