import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import { isScanningAllowed } from "Utils/CommonUtilities";
import Webcam from "react-webcam";
import { confirmAlert } from "react-confirm-alert";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import "../Documents/Documents.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as ROUTES from "Constants/Routes";
import MODALMESSAGES from "../../Constants/Messages";
import PopupModal from "../Common/PopupModal/PopupModal";
import { businessDocumentList } from "../../Utils/LogicUtilities";

class BusinessDocumentReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      type: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      removeIds: [],
      webcamEnabled: false,
      documentList: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_DOC_REVIEW,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        documentList: activeProduct?.documentList ?? [],
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue();
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_DOC_REVIEW);
  };

  render() {
    const isMobile = isScanningAllowed();
    const videoConstraints = {
      width: { min: 480 },
      height: { min: 720 },
      aspectRatio: 0.6666666667,
      facingMode: "user",
    };
    const { choice, showModal, modal, documentList } = this.state;
    const {
      steps,
      activeStepID,
      handleEditPage,
      finInfo,
      activeProduct,
      applicantData,
    } = this.props;
    console.log(steps, activeStepID, documentList);
    const fileListComponent = documentList.map((file, index) => (
      <div key={index} className="[ row ] [ details-section__item ]">
        <div className="[ col-10 ]">
          <div className="[ details-section__item-label ]">
            <span className="[ check-icon ]" /> - {file.documentName}
          </div>
        </div>
        <div className="[ col-2 ]">
          <div className="[ details-section__item-description ]"></div>
        </div>
      </div>
    ));
    const actionComponent = (
      <div className="[ documents-review-container ] [ confirm-account-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.BUSINESS_DOC_REVIEW.TITLE}
        </div>

        <div className="[ form-container ]">
          {/* <div className="[ form-subtitle ]">
                {STRINGS.BUSINESS_DOC_REVIEW.INFO}
              </div> */}
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_DOC_REVIEW.REVIEW_INFO}
          </div>

          {businessDocumentList(finInfo, activeProduct, applicantData).length >
            0 && (
            <div className="[ row mb-3 ]">
              <div className="[ col-12 ]">
                <div className="[ alert alert-warning ]" role="alert">
                  <div className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.BUSINESS_DOC_REVIEW.DOCUMENTS_REQUIRED_INFO}
                  </div>
                  <ul>
                    {businessDocumentList(
                      finInfo,
                      activeProduct,
                      applicantData
                    ).map((list, idx) => (
                      <li key={idx}>{list}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          <div className="[ details-section ] [ mb-3 ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.BUSINESS_DOC_REVIEW.DOCUMENTS}
                  <button
                    type="button"
                    className="[ btn edit-btn ]"
                    onClick={() => handleEditPage(ROUTES.BUSINESS_UPLOAD)}
                    aria-label="edit"
                  >
                    <span className="[ edit-icon ]" />
                    <span className="[ edit-btn__label ]">
                      {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                    </span>
                  </button>
                </h3>
              </div>
            </div>
            <div className="[ row mb-3 ]">
              <div className="[ col ]">
                <div className="[ details-section ] [ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <h3 className="[ details-section__header ]">
                        {documentList.length > 0
                          ? STRINGS.BUSINESS_DOC_REVIEW.ATTACHED_DOCUMENTS
                          : STRINGS.BUSINESS_DOC_REVIEW.NO_ATTACHED_DOCUMENTS}
                      </h3>
                    </div>
                  </div>
                  {fileListComponent}
                </div>
                <div className="[ form-description ]">
                  {STRINGS.BUSINESS_DOC_REVIEW.DOCUMENT_NOTE}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <div>
          {this.webcamEnabled ? (
            <Webcam
              audio={false}
              ref={this.webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          ) : (
            this.uploadByCamera
          )}
        </div>
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

BusinessDocumentReview.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  // handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessDocumentReview.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessDocumentReview;
