import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessLookup from "./BusinessLookup";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import {
  profileEsc,
  requestEsc,
  searchEsc,
} from "../../Redux/Actions/ApplicantAction";
import {
  doCreateApplication,
  updateVaultMember,
} from "../AboutYou/Actions/AboutYouActions";
import {
  getActiveProduct,
  getBusinessAddress,
} from "../../Utils/CommonUtilities";
import {
  HTTP_STATUS,
  S_APPLICANT_ID,
  S_PERSONAL,
} from "../../Communication/Constants";
import getBundleProductRelation from "../../Redux/Actions/GetBundleProductRelationAction";
import * as ROUTES from "Constants/Routes";
import { getJurisdictionType } from "../../Utils/LogicUtilities";

class BusinessLookupContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
    const { applicantData, history } = this.props;
    if (applicantData?.id) {
      history.push(ROUTES.VAULT_DASHBOARD);
    }
  }

  handleChange = () => {};

  noResultContinue = () => {
    const { history } = this.props;
    console.log("noResultContinue");
    history.push(ROUTES.VAULT_DASHBOARD);
  };
  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      doCreateNewApplicant,
      products,
      finInfo,
      doUpdateProductToVault,
      doGetBundleProductRelation,
      doUpdateApplicant,
      doGetApplicant,
      vaultMember,
      doUpdateVaultMember,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (activeProduct.applicantId) {
      // lookup shouldn't happen if applicant already created
    } else {
      doCreateNewApplicant(
        {
          bundleProductId: activeProduct.productId,
          company: data.entity.name,
          isCommercial: true,
          isSoleProprietor:
            activeProduct.selectedBusinessType === finInfo.soleBusinessType,
          dateOfBirth: "1900-01-01",
          branch: activeProduct.businessBranch,
          reasonOpened: activeProduct.reasonOpenAccount,
          ownerApplicantId:
            activeProduct.selectedBusinessType === finInfo.soleBusinessType
              ? sessionStorage.getItem(S_PERSONAL)
              : null,
        },
        (response) => {
          console.log("create ", response);
          if (response.status === HTTP_STATUS.OK) {
            sessionStorage.setItem(S_APPLICANT_ID, response.data.applicantId);
            const updateRequest = {
              member: {
                businessNumber: data.entity?.businessNumber,
                jurisdictionProv1:
                  getJurisdictionType(data.entity?.jurisdiction, finInfo) ===
                  "Federal"
                    ? ""
                    : data.entity?.jurisdiction,
                jurisdictionType1: getJurisdictionType(
                  data.entity?.jurisdiction,
                  finInfo
                ),
                intendedUseOfMembership: activeProduct.relationshipBusiness,
              },
              business: {
                founded: data.entity?.formationDate,
                businessType: activeProduct.selectedBusinessType,
                incorporationNumber: data.entity?.number,
              },
              contact: {},
              regulatory: {
                updateRegulatoryFieldsInputModel: [
                  {
                    Code: 13,
                    Value: activeProduct.isCannabisOrMoneyIndustry,
                    Text: "Part of Cannabis Industry",
                    Type: "dropdown",
                  },
                  {
                    Code: 14,
                    Value: activeProduct.isFinancialInstitution,
                    Text: "Is a Financial Entity?",
                    Type: "dropdown",
                  },
                ],
              },
            };
            updateRequest.address = getBusinessAddress(data.entity?.addresses);

            doUpdateApplicant(
              updateRequest,
              response.data.applicantId,
              (res) => {
                console.log("update result", res);
                if (data?.orderId) {
                  doUpdateVaultMember({
                    ...vaultMember,
                    orderId: data?.orderId,
                  });
                }
                const updatedVaultProductList = products;

                if (
                  updatedVaultProductList &&
                  updatedVaultProductList.length !== 0
                ) {
                  const idx = updatedVaultProductList.findIndex(
                    (x) => x === activeProduct
                  );
                  updatedVaultProductList[idx].parties = data.parties;
                  updatedVaultProductList[idx].applicationId =
                    response.data.applicationId;
                  updatedVaultProductList[idx].applicantId =
                    response.data.applicantId;
                  if (
                    response.data.doximProducts &&
                    response.data.doximProducts.length !== 0
                  ) {
                    if (response.data.doximProducts.length === 1) {
                      updatedVaultProductList[idx].doximProductId =
                        response.data.doximProducts[idx].id;
                    } else {
                      // search registered contract
                      const registeredContract = response.data.doximProducts.find(
                        (item) =>
                          item.type ===
                          AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                      );
                      if (registeredContract) {
                        updatedVaultProductList[0].contractProductId = registeredContract
                          ? registeredContract.id
                          : null;
                      }
                      const nonRegisteredContract = response.data.doximProducts.find(
                        (item) =>
                          item.type !==
                          AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                      );
                      if (nonRegisteredContract) {
                        updatedVaultProductList[0].doximProductId =
                          nonRegisteredContract.id;
                      } else {
                        updatedVaultProductList[idx].doximProductId =
                          response.data.doximProducts[idx].id;
                        console.error(
                          "UNHANDLED MULTI PRODUCTS",
                          response.data
                        );
                      }
                    }
                  }

                  doGetBundleProductRelation(
                    {
                      bundleProductId: activeProduct.productId,
                      demandProductId: updatedVaultProductList[idx].productId,
                      applicationId: updatedVaultProductList[idx].applicationId,
                    },
                    (getBundleProductRelationRes) => {
                      if (
                        getBundleProductRelationRes.status === HTTP_STATUS.OK
                      ) {
                        console.log(
                          "getBundleProductRelationRes",
                          getBundleProductRelationRes
                        );
                        updatedVaultProductList[idx].bundleProducts =
                          getBundleProductRelationRes.data;
                        doUpdateProductToVault(updatedVaultProductList, () => {
                          doGetApplicant(
                            { id: activeProduct.applicantId },
                            () => {
                              continuehandler(
                                null,
                                AppConstants.APPLICATIONSTEP.BUSINESS_LOOKUP
                              );
                            }
                          );
                        });
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );
    }
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      doSearchEsc,
      doRequestEsc,
      doProfileEsc,
      finInfo,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    return (
      <>
        <BusinessLookup
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
          doSearchEsc={doSearchEsc}
          doRequestEsc={doRequestEsc}
          doProfileEsc={doProfileEsc}
          finInfo={finInfo}
          noResultContinue={this.noResultContinue}
        />
      </>
    );
  }
}

BusinessLookupContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessLookupContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  finInfo: state.MarketplaceReducer.finInfo,
  applicantData: state.ApplicationReducer.response,
  vaultMember: state.ApplicationReducer.vaultMember,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doSearchEsc: (payLoad, callback) => dispatch(searchEsc(payLoad, callback)),
    doRequestEsc: (payLoad, callback) =>
      dispatch(requestEsc(payLoad, callback)),
    doProfileEsc: (payLoad, callback) =>
      dispatch(profileEsc(payLoad, callback)),
    doCreateNewApplicant: (request, callback) =>
      dispatch(doCreateApplication(request, callback)),
    doGetBundleProductRelation: (activeProduct, callback) =>
      dispatch(getBundleProductRelation(activeProduct, callback)),
    doUpdateVaultMember: (member, callback) =>
      dispatch(updateVaultMember(member, callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessLookupContainer);

const BusinessLookupWrapper = withBusinessHOC(BusinessLookupContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessLookupWrapper);
