import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import doRecoverPassword from "Redux/Actions/RecoverPasswordAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import STRINGS from "Constants/Strings";
import { HTTP_STATUS } from "Communication/Constants";
import "./VerificationCode.scss";
import { updateProductToVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import doRegistration from "../Register/Actions/RegisterAction";
import VerificationCode from "./VerificationCode";
import { doForgotPassword } from "../Signin/Actions/SigninAction";
import doVerificationCodeAction from "./Actions/VerificationCodeAction";
import { confirmApplicantCreate } from "Components/JointApplicant/Actions/JointApplicantAction";
import {
  setInSessionJointApplicant,
  fillApplicationSlot,
  inviteApplicationSlot,
  otpVerifyInvite,
  applicantCreateRequest,
} from "../JointApplicant/Actions/JointApplicantAction";
import { disableBack, setKycFlag } from "../../Redux/Actions/ApplicationAction";
import {
  EXPIRES_AT,
  IS_SET_PASSWORD_VISIT,
  REFRESH_EXPIRES_AT,
  S_EMAIL,
  S_MSISDN,
  TOKEN_KEY,
} from "../../Communication/Constants";
import PopupModal from "../Common/PopupModal/PopupModal";
import { updateVaultProductList } from "../MarketPlace/Action/MarketPlaceAction";

class VerificationCodeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      steps: [],
      activeStepID: 4, // 4th for Joint applicants
      showKycEnd: false,
    };
  }

  componentDidMount() {
    const {
      signinState,
      history,
      registerState,
      otpVerify,
      inSessionJointApplicant,
      doDisableBack,
    } = this.props;
    sessionStorage.removeItem(IS_SET_PASSWORD_VISIT);
    if (
      !signinState.username &&
      !Object.keys(registerState.success).length &&
      !otpVerify.success
    ) {
      if (!inSessionJointApplicant) {
        history.push(ROUTES.SIGN_IN);
      }
    }
    if (inSessionJointApplicant) {
      doDisableBack(true);
      let steps = [];
      let index = 1;
      AppConstants.JOINT_APPLICANT.FLOW.forEach((flow) => {
        steps.push({
          Id: index,
          Name: STRINGS.STEPS[flow.componentName],
        });
        index += 1;
      });
      this.setState({
        steps,
      });
    }
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    const {
      verificationCodeState,
      recoverPasswordState,
      history,
      forgotPasswordState,
      doUpdateProductToVault,
      vaultProductList,
      registerState,
    } = this.props;

    if (
      verificationCodeState !== prevProps.verificationCodeState ||
      recoverPasswordState !== prevProps.recoverPasswordState
    ) {
      console.log("1");
      if (vaultProductList.length !== 0 && !registerState?.success) {
        console.log("update vault 7");
        doUpdateProductToVault(vaultProductList);
      }
      if (verificationCodeState.success.status === HTTP_STATUS.OK) {
        if (verificationCodeState.success?.data?.data) {
          const {
            slotId,
            applicationId,
          } = verificationCodeState.success.data.data;
          if (slotId && slotId !== "") {
            sessionStorage.setItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID,
              slotId + "-register"
            );
            sessionStorage.setItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID,
              applicationId
            );
            history.push(ROUTES.VAULT_PASSWORD);
          } else {
            history.push(ROUTES.VAULT_PASSWORD);
          }
        } else {
          history.push(ROUTES.VAULT_PASSWORD);
        }
      } else if (recoverPasswordState.success.status === HTTP_STATUS.OK) {
        history.push(ROUTES.SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD);
      }
    }
    if (prevProps.forgotPasswordState !== forgotPasswordState) {
      console.log("2");
      if (
        forgotPasswordState.success &&
        forgotPasswordState.success.status === HTTP_STATUS.OK
      ) {
        if (
          forgotPasswordState.success.data.otpTo ===
          STRINGS.FORGOTPASSWORD.OTP_TO.EMAIL
        ) {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title: STRINGS.FORGOTPASSWORD.MODAL.SUCCESS_MAIL,
              description: "",
            },
          });
        } else {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title: STRINGS.FORGOTPASSWORD.MODAL.SUCCESS_MESSAGE,
              description: "",
            },
          });
        }
      }
    }
  }

  setModalState = (modal) => {
    this.setState(modal);
  };

  handleBack = () => {
    const { history, forgotPasswordState, registerState } = this.props;
    if (
      forgotPasswordState.success &&
      forgotPasswordState.success.status === HTTP_STATUS.OK
    ) {
      history.push(ROUTES.FORGOT_PASSWORD);
    } else if (registerState) {
      history.push(ROUTES.REGISTER);
    }
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handlVerificationCodeSubmit = (userDetail) => {
    const {
      registerState,
      doVerificationCode,
      doGetRecoverPassword,
      otpVerify,
      inSessionJointApplicant,
      doConfirmApplicantCreate,
      doSetInSessionJointApplicant,
      history,
      applicationId,
      processTemplate,
      isQrKyc,
      vaultProductList,
      doUpdateProductToVault,
    } = this.props;
    console.log("handlVerificationCodeSubmit", userDetail);
    if (inSessionJointApplicant) {
      doConfirmApplicantCreate(
        { otpId: userDetail.otpId, otp: userDetail.otp },
        (res) => {
          const jointInfo = {
            ...inSessionJointApplicant,
            applicantId: res.data.applicantId,
          };
          doSetInSessionJointApplicant(jointInfo);
          const empIndex = processTemplate.flow.findIndex(
            (s) => s.componentName === AppConstants.APPLICATIONSTEP.EMPLOYMENT
          );
          const taxIndex = processTemplate.flow.findIndex(
            (s) => s.componentName === AppConstants.APPLICATIONSTEP.TAX_INFO
          );
          const complianceIndex = processTemplate.flow.findIndex(
            (s) => s.componentName === AppConstants.APPLICATIONSTEP.COMPLIANCE
          );
          console.log("indexs", empIndex, taxIndex, complianceIndex);
          let inSessionNextStep = ROUTES.COMPLIANCE;
          if (empIndex > -1) {
            if (taxIndex > -1 && taxIndex < empIndex) {
              inSessionNextStep = ROUTES.TAX_INFO;
            } else if (complianceIndex > -1 && complianceIndex < empIndex) {
              inSessionNextStep = ROUTES.COMPLIANCE;
            } else {
              inSessionNextStep = ROUTES.EMPLOYMENT_AND_INCOME;
            }
          } else if (taxIndex > -1) {
            if (complianceIndex > -1 && complianceIndex < taxIndex) {
              inSessionNextStep = ROUTES.COMPLIANCE;
            } else {
              inSessionNextStep = ROUTES.TAX_INFO;
            }
          }
          if (isQrKyc) {
            vaultProductList[0].inSessionJointApplicant.applicantId =
              res.data.applicantId;
            vaultProductList[0].inSessionJointApplicant.inSessionNextStep = inSessionNextStep;
            vaultProductList[0].kycStatus = "mobileEnd";
            doUpdateProductToVault(vaultProductList, () => {
              this.setState({ showKycEnd: true });
            });
          } else {
            history.push(inSessionNextStep);
          }
        }
      );
    } else {
      if (registerState.success || otpVerify.success) {
        doVerificationCode(userDetail);
      } else {
        doGetRecoverPassword(userDetail);
      }
    }
  };

  handleSendVerificationCode = () => {
    const {
      registerState,
      doRegistratioProcess,
      signinState,
      doForgotPasswordRequest,
      otpVerify,
      doOtpVerifyInvite,
      otp,
      inSessionJointApplicant,
      doApplicantCreateRequest,
      doSetInSessionJointApplicant,
      bankingType,
    } = this.props;
    console.log("handleSendVerificationCode", otpVerify);
    if (otpVerify.success) {
      doOtpVerifyInvite(
        {
          purpose: "register",
          otpId: otp.otpId,
          otp: otp.otp,
          bankingType,
        },
        (otpVerifyRes) => {
          if (otpVerifyRes.status === HTTP_STATUS.OK) {
            this.setState({
              showModal: true,
              modalType: AppConstants.MODALTYPE.SUCCESS,
              modal: {
                title: MODALMESSAGES.VERIFICATIONCODE.SUCCESSMSG,
                description: "",
              },
            });
          }
        }
      );
    } else {
      console.log("registerState", registerState);
      if (registerState.success !== "") {
        const userDetail = JSON.parse(registerState.success.config.data);

        const userDetails = {
          email: userDetail.email,
          msisdn: userDetail.msisdn,
          otpTo: userDetail.otpTo,
          confirm: userDetail.confirm,
          bankingType,
        };
        doRegistratioProcess(userDetails, (response) => {
          console.log("doRegistratioProcess", response);
          if (response.status === HTTP_STATUS.OK) {
            const Successmsg = `${MODALMESSAGES.VERIFICATIONCODE.SUCCESSMSG}. `;
            this.setState({
              showModal: true,
              modalType: AppConstants.MODALTYPE.SUCCESS,
              modal: {
                title: Successmsg,
                description: "",
              },
            });
          } else {
            this.setState({
              showModal: true,
              modalType: AppConstants.MODALTYPE.FAILURE,
              modal: {
                title: MODALMESSAGES.VERIFICATIONCODE.ERRORMSG,
                description: "",
              },
            });
          }
        });
      } else if (signinState.username !== "") {
        console.log("signinState", signinState);
        const userDetails = {
          credential: signinState.username,
          bankingType,
        };
        doForgotPasswordRequest(userDetails);
      } else if (inSessionJointApplicant) {
        doApplicantCreateRequest(
          inSessionJointApplicant.request,
          (response) => {
            if (response.status === HTTP_STATUS.OK) {
              const inSessionData = {
                ...inSessionJointApplicant,
                otpId: response.data.otpId,
                otpTo: response.data.otpTo,
              };
              doSetInSessionJointApplicant(inSessionData);
              const msg = `${MODALMESSAGES.VERIFICATIONCODE.SUCCESSMSG}. `;
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: msg,
                  description: "",
                },
              });
            } else {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.FAILURE,
                modal: {
                  title: MODALMESSAGES.VERIFICATIONCODE.ERRORMSG,
                  description: "",
                },
              });
            }
          }
        );
      } else {
        console.log("failure");
        this.setState({
          showModal: true,
          modalType: AppConstants.MODALTYPE.FAILURE,
          modal: {
            title: MODALMESSAGES.VERIFICATIONCODE.RESENDCODEERROR,
            description: "",
          },
        });
      }
    }
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  continueAfterKyc = () => {
    console.log("continueAfterKyc");
    const { history, doSetKycFlag, doUpdateVaultProductList } = this.props;
    this.setState({ showKycEnd: false }, () => {
      sessionStorage.removeItem(TOKEN_KEY);
      sessionStorage.removeItem(S_EMAIL);
      sessionStorage.removeItem(S_MSISDN);
      sessionStorage.removeItem(EXPIRES_AT);
      sessionStorage.removeItem(REFRESH_EXPIRES_AT);
      doSetKycFlag(false);
      doUpdateVaultProductList([]);
      history.push(ROUTES.SIGN_IN);
    });
  };

  render() {
    const {
      showModal,
      modalType,
      modal,
      steps,
      activeStepID,
      showKycEnd,
    } = this.state;
    const {
      registerState,
      forgotPasswordState,
      otpVerify,
      inSessionJointApplicant,
    } = this.props;
    console.log("showModal", showModal);
    return (
      <>
        <VerificationCode
          handlVerificationCodeSubmit={this.handlVerificationCodeSubmit}
          handleSendVerificationCode={this.handleSendVerificationCode}
          showModal={showModal}
          modalType={modalType}
          modal={modal}
          registerState={registerState}
          otpVerify={otpVerify}
          forgotPasswordState={forgotPasswordState}
          handleBack={this.handleBack}
          inSessionJointApplicant={inSessionJointApplicant}
          steps={steps}
          activeStepID={activeStepID}
          hideModal={this.hideModal}
        />
        {showKycEnd && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            description={
              STRINGS.ABOUTYOU.KYC_QR_CODE
                .QR_CODE_MOBILE_VERIFICATION_SUCCESS_TITLE
            }
            description2={
              STRINGS.ABOUTYOU.KYC_QR_CODE
                .QR_CODE_MOBILE_VERIFICATION_SUCCESS_DESCRIPTION
            }
            toggleModal={this.continueAfterKyc}
            showModal={showKycEnd}
            popupBtnClick={this.continueAfterKyc}
            closeBtnClick={this.continueAfterKyc}
            btnText={
              STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_DESKTOP_BUTTON_CONTINUE
            }
          />
        )}
      </>
    );
  }
}

VerificationCodeContainer.propTypes = {
  verificationCodeState: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  recoverPasswordState: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  registerState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  forgotPasswordState: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  history: PropTypes.oneOfType([PropTypes.object]),
  push: PropTypes.func,
  doVerificationCode: PropTypes.func,
  doGetRecoverPassword: PropTypes.func,
  doRegistratioProcess: PropTypes.func,
  doForgotPasswordRequest: PropTypes.func,
  signinState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  doUpdateProductToVault: PropTypes.func,
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]).isRequired,
  otpVerify: PropTypes.oneOfType([PropTypes.object]),
  otp: PropTypes.object,
  doConfirmApplicantCreate: PropTypes.func.isRequired,
  inSessionJointApplicant: PropTypes.object,
  applicationId: PropTypes.string,
  doinviteApplicationSlot: PropTypes.func.isRequired,
};
VerificationCodeContainer.defaultProps = {
  verificationCodeState: "",
  recoverPasswordState: "",
  registerState: "",
  forgotPasswordState: "",
  signinState: "",
  otpVerify: "",
  history: () => {},
  push: () => {},
  doVerificationCode: () => {},
  doGetRecoverPassword: () => {},
  doRegistratioProcess: () => {},
  doForgotPasswordRequest: () => {},
  doUpdateProductToVault: () => {},
  otp: null,
};

const mapDispatchToProps = (dispatch) => {
  return {
    doRegistratioProcess: (userDetails, callback) =>
      dispatch(doRegistration(userDetails, callback)),
    doVerificationCode: (userDetails) =>
      dispatch(doVerificationCodeAction(userDetails)),
    doGetRecoverPassword: (userDetails) =>
      dispatch(doRecoverPassword(userDetails)),
    doForgotPasswordRequest: (userDetails) =>
      dispatch(doForgotPassword(userDetails)),
    doUpdateProductToVault: (productList, callback) =>
      dispatch(updateProductToVault(productList, callback)),
    doConfirmApplicantCreate: (productList, callback) =>
      dispatch(confirmApplicantCreate(productList, callback)),
    doSetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
    doFillApplicationSlot: (request, callback) =>
      dispatch(fillApplicationSlot(request, callback)),
    doinviteApplicationSlot: (request, callback) =>
      dispatch(inviteApplicationSlot(request, callback)),
    doOtpVerifyInvite: (request, callback) =>
      dispatch(otpVerifyInvite(request, callback)),
    doDisableBack: (flag) => dispatch(disableBack(flag)),
    doApplicantCreateRequest: (request, callback) =>
      dispatch(applicantCreateRequest(request, callback)),
    doSetKycFlag: () => dispatch(setKycFlag()),
    doUpdateVaultProductList: (vaultList, callback) =>
      dispatch(updateVaultProductList(vaultList, callback)),
  };
};
const mapStateToProps = (state) => ({
  registerState: state.RegisterReducer,
  recoverPasswordState: state.RecoverPasswordReducer,
  verificationCodeState: state.VerificationCodeReducer,
  forgotPasswordState: state.ForgotPasswordReducer,
  signinState: state.SigninReducer,
  vaultProductList: state.VaultReducer.vaultProductList,
  otpVerify: state.JoinApplicantReducer.otpVerify,
  otp: state.JoinApplicantReducer.otp,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  applicationId: state.JoinApplicantReducer.applicationId,
  processTemplate: state.ApplicationReducer.processTemplate,
  bankingType: state.MarketplaceReducer.bankingType,
  isQrKyc: state.ApplicationReducer.isQrKyc,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationCodeContainer);
