/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import PopupModal from "Components/Common/PopupModal/PopupModal";

import {
  updateProductInformation,
  getProductInformation,
} from "Redux/Actions/ProductInformationAction";
import getAccount from "Redux/Actions/GetAccountAction";

import updateApplicantData from "Redux/Actions/UpdateApplicantAction";
import { getProductTemplate } from "Redux/Actions/ProductTemplateActions";

import { updateGettingStarted } from "Components/GettingStarted/Actions/GettingStartedActions";
import { doCreateApplication } from "Components/AboutYou/Actions/AboutYouActions";
import {
  updateProductToVault,
  getProductFromVault,
  getProductList,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import { storeFundProduct } from "Components/FundingAmount/Action/FundAction";
import {
  addBeneficiaries,
  getBeneficiaries,
} from "Components/Beneficiaries/Action/BeneficiariesAction";

import AppConstants from "Constants/AppConstants";
import * as ROUTES from "Constants/Routes";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";
import {
  setSpousalInfo,
  getSpousalInfo,
} from "Components/SpousalInfo/Actions/SpousalInfoAction";

import {
  addApplicationSlot,
  inviteApplicationSlot,
  getGlobalVault,
  getApplicationProperty,
  applicantCreateSelf,
  fillApplicationSlot,
  updateGlobalVault,
  applicantCreateRequest,
  setInSessionJointApplicant,
} from "Components/JointApplicant/Actions/JointApplicantAction";
import getBundleProductRelation from "Redux/Actions/GetBundleProductRelationAction";
import {
  addBundleProduct,
  deleteDebitCard,
  storeDebitCard,
  debitCardShown,
} from "Components/DebitCard/Actions/DebitCardAction";
import {
  allowKycEdit,
  getJointInSessionFlow,
  getKycData,
  getProductIdByAge,
  getProductIdInActiveProduct,
  isNewMember,
  showDebitCard,
  showOnlineBanking,
  showTaxInfo,
  verifyAge,
} from "../../Utils/LogicUtilities";
import {
  toggleEditFlag,
  getApplicant,
  getApplication,
  getGettingStartedData,
  toggleScanFlag,
  updateProductTemplate,
  getApplicationList,
  setNotFirstFlag,
  storeCompletedProductInASession,
  toggleBackFlag,
  disableBack,
  getCompleteApplicant,
  getProspect,
  setKycFlag,
} from "../../Redux/Actions/ApplicationAction";
import {
  getProductInfo,
  searchCategoryInCompleteList,
  getProduct,
  getGettingStarted,
  printTermsAndConditions,
  downloadTermsAndConditions,
} from "../../Utils/CommonUtilities";
import {
  HTTP_STATUS,
  S_APPLICANT_ID,
  REGISTERCONTRACTPRODUCT,
  LENDING_PRODUCT,
  S_MSISDN,
  S_EMAIL,
  TOKEN_KEY,
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_INSTNT_TXN_ID,
} from "../../Communication/Constants";
import { getApplicantBankingProductList } from "../../Components/AccountInformation/Actions/AccountInformationAction";
import { checkEsignDoc } from "../../Components/Signature/Actions/SignatureAction";
import { setOnlineBanking } from "../../Components/OnlineBanking/Actions/OnlineBankingAction";
import { addApplicant } from "../../Components/Inverite/Actions/InveriteAction";
import {
  submitQuestionnaire,
  uploadQuestionnaire,
} from "../../Components/Questionnaire/Actions/QuestionnaireAction";
import { updateVaultMember } from "../../Components/AboutYou/Actions/AboutYouActions";
import Button from "react-bootstrap/Button";
import { FileEarmarkArrowDown, Printer } from "react-bootstrap-icons";
import { SIGN_IN } from "../../Constants/Routes";
import { updateVaultProductList } from "../../Components/MarketPlace/Action/MarketPlaceAction";

export default function withApplicantHOC(WrappedComponent) {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        steps: [],
        activeStepID: -1,
        showModal: false,
        showModalAge: false,
        showRecommend: false,
        showRequired: false,
        showSuccess: false,
        showSuccessDescription: "",
        showInReview: false,
        showKycEnd: false,
        recommendProduct: null,
        modal: {
          title: "",
          description: "",
          type: "",
        },
        modalAge: {
          title: "",
          description: "",
          type: "",
        },
      };
    }

    /**
     *
     * @param {*} step default value is blank, need only for first step
     * @return callback
     * There are following steps followed before calling the get APIs respectively.
     * 1. Get the product details from the product vault.
     * 2. Get the templateId and template details of the active product
     * 3. Prepare the stepper details for each steps
     * 4. Check if step has been completed previously or not then call the Get API i.e. step 5
     *
     * So the following method is Step 1
     */
    getDataFromServer = (step = "", callback) => {
      const { doGetProductFromVault, products, productList } = this.props;
      console.log("getDataFromServer", step);
      if (step === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
        this.prepareOnlineBankingStep();
      } else {
        if (products.length <= 0) {
          doGetProductFromVault((productsRes) => {
            if (productsRes && productsRes.length > 0) {
              if (productsRes?.status === HTTP_STATUS.OK) {
                this.getProcessFlowAndCallApplicantDetails(
                  productsRes?.data ? productsRes.data : [],
                  step,
                  callback
                );
              }
            } else {
              this.redirectToDashboard();
            }
          });
        } else {
          this.getProcessFlowAndCallApplicantDetails(products, step, callback);
        }

        if (productList && productList.length <= 0) {
          const { doGetProductList } = this.props;
          doGetProductList();
        }
      }
    };

    /**
     * Step 2
     * @param {*} products
     * @param {*} step
     * @param {*} callback
     */
    getProcessFlowAndCallApplicantDetails = (products, step = "", callback) => {
      const {
        processTemplate,
        doGetProductTemplateFlow,
        doGetBundleProductRelation,
        doGetAcoountDetails,
        doGetGlobalVault,
        applicantData,
      } = this.props;
      console.log("getProcessFlowAndCallApplicantDetails", products, step);
      const activeProduct = this.getActiveProduct(products);
      if (!processTemplate || processTemplate?.flow?.length <= 0) {
        if (
          activeProduct?.jointExternalApplicationId ||
          activeProduct?.isJointProduct
        ) {
          doGetAcoountDetails((getAccountActionRes) => {
            if (getAccountActionRes.status === HTTP_STATUS.OK) {
              const mobileNo = getAccountActionRes.data.msisdn;
              const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
              doGetGlobalVault(
                {
                  vaultKey: vKey,
                  product: activeProduct,
                  productTemplates: STRINGS.JOIN_APPLICANT.PRODUCT_TEMPLATE,
                },
                (gobalVaultRes) => {
                  if (
                    gobalVaultRes &&
                    gobalVaultRes.jointApplicants.status ===
                      STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING
                  ) {
                    doGetProductTemplateFlow((response) => {
                      this.getActiveTemplateAndCallNextMethod(
                        response,
                        activeProduct,
                        step,
                        callback
                      );
                    }, activeProduct);
                  }
                }
              );
            }
          });
        } else {
          doGetProductTemplateFlow((response) => {
            this.getActiveTemplateAndCallNextMethod(
              response,
              activeProduct,
              step,
              callback
            );
          }, activeProduct);
        }
      } else if (processTemplate.templateId !== activeProduct?.templateId) {
        const { doUpdateProductTemplate, templates } = this.props;

        // if the product is deleted in between steps then need to change the
        // template ID
        doUpdateProductTemplate(activeProduct);

        this.getActiveTemplateAndCallNextMethod(
          templates,
          activeProduct,
          step,
          callback
        );
      } else {
        doGetBundleProductRelation(
          {
            bundleProductId: getProductIdInActiveProduct(
              activeProduct,
              applicantData
            ),
            demandProductId: activeProduct.productId,
            applicationId: activeProduct.applicationId,
          },
          (getBundleProductRelationRes) => {
            if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
              this.getCompletedApplicationsAndGetApplicantDetails(
                processTemplate,
                step,
                getBundleProductRelationRes.data,
                callback
              );
            }
          }
        );
      }
    };

    /**
     * Helper method
     */
    getActiveTemplateAndCallNextMethod = (
      templates,
      activeProduct,
      step,
      callback
    ) => {
      console.log(
        "getActiveTemplateAndCallNextMethod",
        templates,
        activeProduct,
        step
      );
      const { doGetBundleProductRelation, applicantData } = this.props;
      const processTemp = this.getTemplateByTemplateID(
        templates,
        activeProduct?.templateId
      );

      doGetBundleProductRelation(
        {
          bundleProductId: getProductIdInActiveProduct(
            activeProduct,
            applicantData
          ),
          demandProductId: activeProduct.productId,
          applicationId: activeProduct.applicationId,
        },
        (getBundleProductRelationRes) => {
          if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
            this.getCompletedApplicationsAndGetApplicantDetails(
              processTemp,
              step,
              getBundleProductRelationRes.data,
              callback
            );
          }
        }
      );
    };

    /**
     * Step 3
     * @param {*} applicationTemplate
     * @param {*} activeProduct
     * @param {*} step
     * @param {*} bundleProductRelation
     */
    prepareStepsAndActiveStep = (
      applicationTemplate,
      activeProduct,
      step,
      bundleProductRelation
    ) => {
      const {
        isDebitCardEnabled,
        completedApplications,
        doGetApplicationList,
        applicantData,
        inSessionJointApplicant,
        processTemplate,
      } = this.props;
      console.log(
        "prepareStepsAndActiveStep",
        applicationTemplate,
        activeProduct,
        step,
        bundleProductRelation
      );
      let debitCardAlreadyEnabled = false;
      if (!completedApplications) {
        doGetApplicationList(() => {
          completedApplications.forEach((item) => {
            const debitCardProduct = item.doximProducts?.find(
              (product) =>
                product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
            );
            if (debitCardProduct && item.id !== activeProduct.applicationId) {
              debitCardAlreadyEnabled = true;
            }
          });
        });
      } else {
        completedApplications.forEach((item) => {
          const debitCardProduct = item.doximProducts?.find(
            (product) => product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
          );
          if (debitCardProduct && item.id !== activeProduct.applicationId) {
            debitCardAlreadyEnabled = true;
          }
        });
      }
      console.log(
        "prepareStepsAndActiveStep",
        isDebitCardEnabled,
        debitCardAlreadyEnabled,
        activeProduct,
        completedApplications,
        applicantData,
        bundleProductRelation,
        inSessionJointApplicant
      );

      const steps = [];
      let activeStepID = -1;

      let index = 1;
      if (
        inSessionJointApplicant &&
        step !== AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
      ) {
        const inSessionJointFlow = getJointInSessionFlow(
          processTemplate.flow,
          applicantData
        );
        inSessionJointFlow.forEach((flow) => {
          steps.push({
            Id: index,
            Name: STRINGS.STEPS[flow.componentName],
          });
          if (flow.componentName === step) {
            activeStepID = index;
          }
          index += 1;
        });
      } else {
        applicationTemplate.flow.forEach((flow) => {
          if (
            flow.componentName !==
              AppConstants.APPLICATIONSTEP.DOC_VERIFICATION &&
            flow.componentName !== AppConstants.APPLICATIONSTEP.FUND_METHOD
          ) {
            if (
              (flow.componentName === AppConstants.APPLICATIONSTEP.DEBIT_CARD &&
                debitCardAlreadyEnabled === false &&
                applicantData?.acquireType === "create" &&
                bundleProductRelation.length > 0) ||
              flow.componentName !== AppConstants.APPLICATIONSTEP.DEBIT_CARD
            ) {
              if (
                (flow.componentName ===
                  AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
                  debitCardAlreadyEnabled === false &&
                  applicantData?.acquireType === "create") ||
                flow.componentName !==
                  AppConstants.APPLICATIONSTEP.ONLINE_BANKING
              ) {
                if (activeProduct.isJointProduct) {
                  if (
                    flow.componentType ===
                      AppConstants.COMPONENTSTEPTYPE.APPLICANT ||
                    (flow.componentType ===
                      AppConstants.COMPONENTSTEPTYPE.APPLICATION &&
                      flow.componentName ===
                        AppConstants.APPLICATIONSTEP.DEBIT_CARD) ||
                    flow.componentName === AppConstants.APPLICATIONSTEP.CONFIRM
                  ) {
                    steps.push({
                      Id: index,
                      Name: STRINGS.STEPS[flow.componentName],
                    });
                    if (flow.componentName === step) {
                      activeStepID = index;
                    }
                    index += 1;
                  }
                } else {
                  // if (
                  //   flow.componentName ===
                  //   AppConstants.APPLICATIONSTEP.DEBIT_CARD
                  // ) {
                  //   console.log("setting debit card as shown");
                  //   doDebitCardShown(true);
                  // }

                  steps.push({
                    Id: index,
                    Name: STRINGS.STEPS[flow.componentName],
                  });
                  if (flow.componentName === step) {
                    activeStepID = index;
                  }
                  index += 1;
                }
              }
            }
          }
        });
      }
      console.log("result -> ", steps, activeStepID);
      this.setState({ steps, activeStepID });
    };

    /**
     * step 4
     * @param {*} applicationTemplate
     * @param {*} step
     * @param {*} callback
     */
    getCompletedApplicationsAndGetApplicantDetails = (
      applicationTemplate,
      step,
      bundleProductRelation,
      callback
    ) => {
      console.log(
        "getCompletedApplicationsAndGetApplicantDetails",
        applicationTemplate,
        step,
        bundleProductRelation
      );
      const { products, inSessionJointApplicant } = this.props;
      const activeProduct = this.getActiveProduct(products);
      // prepares stepper
      this.prepareStepsAndActiveStep(
        applicationTemplate,
        activeProduct,
        step,
        bundleProductRelation
      );

      if (inSessionJointApplicant) {
        this.getApplicantDetails(callback, step);
      } else if (step === AppConstants.APPLICATIONSTEP.GETTINGSTARTED) {
        // change condition for getting started specifically.
        // Retrieving Getting Started Data from Applicant API if applicantID exist
        const applicantID = sessionStorage.getItem(S_APPLICANT_ID);
        if (applicantID) {
          this.getGettingStartedDataFromApplicantData(applicantID, callback);
        } else {
          this.getGettingStartedDataAndReturn(callback);
        }
      } else if (
        step === AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION ||
        step === AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION ||
        step === AppConstants.APPLICATIONSTEP.THIRD_PARTY
      ) {
        this.getAccountInFormationData(activeProduct, callback);
      } else if (step === AppConstants.APPLICATIONSTEP.BENEFICIARIES) {
        this.getBeneficiariesData(activeProduct, callback);
      } else if (step === AppConstants.APPLICATIONSTEP.SPOUSAL_INFO) {
        this.getSpousalInFormationData(activeProduct, callback);
      } else if (step !== AppConstants.APPLICATIONSTEP.TERMS_AND_CONDITIONS) {
        this.getApplicantDetails(callback, step);
      }
    };

    /**
     * Helper Method
     * calls the callback with getting started data
     */
    getGettingStartedDataAndReturn = (callback) => {
      const { doGetGettingStartedData } = this.props;
      doGetGettingStartedData((gettingStartedRes) => {
        callback(gettingStartedRes);
      });
    };

    /**
     * Helper Method
     * calls the callback with product information data
     * @param {*} callback
     */
    getAccountInFormationData = (activeProduct, callback) => {
      const { doGetProductInformation } = this.props;
      doGetProductInformation(
        activeProduct.applicationId,
        activeProduct.type,
        activeProduct.doximProductId,
        (getProductInformationRes) => {
          if (getProductInformationRes.status === HTTP_STATUS.OK) {
            callback(getProductInformationRes.data);
          }
        }
      );
    };

    getApplicationData = (activeProduct, callback) => {
      const { doGetApplication } = this.props;
      doGetApplication(activeProduct.applicationId, (getApplicationRes) => {
        if (getApplicationRes.status === HTTP_STATUS.OK) {
          callback(getApplicationRes.data);
        }
      });
    };

    /**
     * @param {*} activeProduct
     * calls the callback with beneficiary information data
     * @param {*} callback
     */
    getBeneficiariesData = (activeProduct, callback) => {
      const { doGetBeneficiaries } = this.props;
      doGetBeneficiaries(
        activeProduct.applicationId,
        activeProduct.contractProductId,
        (getBeneficiariesDataRes) => {
          if (getBeneficiariesDataRes.status === HTTP_STATUS.OK) {
            callback(getBeneficiariesDataRes.data);
          }
        }
      );
    };

    getSpousalInFormationData = (activeProduct, callback) => {
      const { doGetSpousalInformation } = this.props;
      doGetSpousalInformation(
        activeProduct.applicationId,
        REGISTERCONTRACTPRODUCT,
        activeProduct.contractProductId,
        (getSpousalInformationRes) => {
          if (getSpousalInformationRes.status === HTTP_STATUS.OK) {
            callback(getSpousalInformationRes.data);
          }
        }
      );
    };

    /**
     * step 5
     * @param {*} step
     * @param {*} completedApplications
     * @param {*} callback
     */
    getApplicantDetails = (callback, step) => {
      console.log("getApplicantDetails", step);
      const {
        doGetApplicant,
        products,
        inSessionJointApplicant,
        doGetCompleteApplicant,
        doUpdateVaultMember,
      } = this.props;
      const activeProduct = this.getActiveProduct(products);
      let applicantId = activeProduct.applicantId
        ? activeProduct.applicantId
        : sessionStorage.getItem(S_APPLICANT_ID);
      if (inSessionJointApplicant) {
        applicantId = inSessionJointApplicant.applicantId;
      }
      if (applicantId) {
        if (
          step === AppConstants.APPLICATIONSTEP.COMPLIANCE ||
          step === AppConstants.APPLICATIONSTEP.THIRD_PARTY ||
          step === AppConstants.APPLICATIONSTEP.CONFIRM
        ) {
          doGetCompleteApplicant(applicantId, (applicantDetails) => {
            callback(applicantDetails);
          });
        } else {
          if (inSessionJointApplicant) {
            doGetCompleteApplicant(applicantId, (applicantDetails) => {
              if (
                !inSessionJointApplicant.vault &&
                !isNewMember(applicantDetails.data)
              ) {
                console.log("edwin", applicantDetails);
                doUpdateVaultMember(
                  {
                    inSessionJoint: applicantDetails.data.id,
                    name: applicantDetails.data.member.name,
                    middleName: applicantDetails.data.member.middleName,
                    surname: applicantDetails.data.member.surname,
                    dob: applicantDetails.data.member.dob,
                    sin: applicantDetails.data.member.sin,
                    regulatoryFields:
                      applicantDetails.data.regulatory.regulatoryFields,
                    fatcaStatus: applicantDetails.data.regulatory.fatcaStatus,
                    fatcaType: applicantDetails.data.regulatory.fatcaType,
                    ssn: applicantDetails.data.regulatory.ssn,
                    memberFundsSource:
                      applicantDetails.data.regulatory.memberFundsSource,
                    memberHasThirdParty:
                      applicantDetails.data.regulatory.memberHasThirdParty,
                    memberIsHio: applicantDetails.data.regulatory.memberIsHio,
                    memberIsPedp: applicantDetails.data.regulatory.memberIsPedp,
                    memberIsPefp: applicantDetails.data.regulatory.memberIsPefp,
                    memberJurisdiction:
                      applicantDetails.data.regulatory.memberJurisdiction,
                    memberOfficeHeld:
                      applicantDetails.data.regulatory.memberOfficeHeld,
                    thirdParties: applicantDetails.data.thirdParties,
                    peps: applicantDetails.data.peps,
                    pep: applicantDetails.data.member.pep,
                  },
                  () => {
                    callback(applicantDetails);
                  }
                );
              } else {
                callback(applicantDetails);
              }
            });
          } else {
            doGetApplicant({ id: applicantId }, (applicantDetails) => {
              callback(applicantDetails);
            });
          }
        }
      }
    };

    /**
     * Returns active product if not found then returns first object from the array
     * @param {*} products
     */
    getActiveProduct = (products) => {
      const { bankingType } = this.props;
      let activeProduct = products?.find((product) => product?.active);
      if (!activeProduct) {
        activeProduct = products?.find(
          (product) => product.bankingType === bankingType
        );
        if (!activeProduct?.applicantId) {
          const jointSlotId = sessionStorage.getItem(
            STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
          );
          if (jointSlotId) {
            const applicantId = sessionStorage.getItem(S_APPLICANT_ID);
            if (applicantId) {
              activeProduct.applicantId = applicantId;
            }
          }
        }
      }
      return activeProduct;
    };

    /**
     * Returns template from the list of templates which matches the template ID
     * @param {*} templates
     * @param {*} templateId
     */
    getTemplateByTemplateID = (templates, templateId) => {
      return templates.find((template) => template.templateId === templateId);
    };

    /**
     * returns the step object from the template flow which matches the param step
     * @param {*} template
     * @param {*} step
     */
    getCurrentStepFromTemplateFlowByStep = (template, step) => {
      console.log("getCurrentStepFromTemplateFlowByStep", template, step);
      return template.flow.find(
        (applicantStep) => applicantStep.componentName === step
      );
    };

    /**
     * returns the step object's index from the template flow which matches the param step
     * @param {*} template
     * @param {*} step
     */
    getCurrentStepIndexFromTemplateFlowByStep = (template, step) => {
      return template?.flow?.findIndex(
        (applicantStep) => applicantStep.componentName === step
      );
    };

    /**
     * Check if the previous step from the current template flow is completed or not..
     * Also check for in between step visit is valid or not
     * @param {*} step
     * @param {*} applicationTemplate
     */
    checkStepVisited = (step, applicationTemplate) => {
      console.log("checkStepVisited", step, applicationTemplate);
      const { products, applicantData } = this.props;
      const activeProduct = this.getActiveProduct(products);
      if (products && products.length > 0) {
        if (
          (step === AppConstants.APPLICATIONSTEP.GETTINGSTARTED ||
            step === AppConstants.APPLICATIONSTEP.TERMS_AND_CONDITIONS) &&
          applicantData?.acquireType !== "search"
        ) {
          this.checkForConsentAndRedirect(step);
          // } else if (step === AppConstants.APPLICATIONSTEP.ABOUTYOU) {
          //   // If user tries to access the about you before completing the getting started step
          //   if (
          //     !activeProduct ||
          //     (activeProduct.applicationStep?.index === null &&
          //       applicantData?.acquireType !== "search" &&
          //       (!completedApplications ||
          //         sessionStorage.getItem("TEMP_HIDE") === "true"))
          //   ) {
          //     this.redirectToDashboard();
          //   }
        } else {
          const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
            applicationTemplate,
            step
          );
          //
          if (activeProduct?.isJointProduct) {
            if (
              !activeProduct ||
              !currentStepObj ||
              activeProduct?.applicationStep?.index === null
            ) {
              this.redirectToDashboard();
            }
          } else if (
            !activeProduct ||
            !currentStepObj ||
            activeProduct?.applicationStep?.index === null
          ) {
            this.redirectToDashboard();
          }
        }
      } else {
        const { history } = this.props;
        history.push(ROUTES.VAULT_DASHBOARD);
      }
    };

    /**
     * Helper method
     * Checking if the user has checked the consent and redirect based on the step.
     * @param {*} step
     */
    checkForConsentAndRedirect = (step) => {
      console.log("checkForConsentAndRedirect", step);
      // if not consent then it should go to terms and condition
      const { doGetAcoountDetails, applicantData } = this.props;
      doGetAcoountDetails((response) => {
        console.log(response);
        if (
          response.data.consent === null &&
          applicantData?.acquireType !== "search" &&
          step === AppConstants.APPLICATIONSTEP.GETTINGSTARTED
        ) {
          this.redirectToDashboard();
        }
        if (
          response.data.consent &&
          step === AppConstants.APPLICATIONSTEP.TERMS_AND_CONDITIONS
        ) {
          this.redirectToDashboard();
        }
      });
    };

    /**
     * Helper method
     * Redirect to dashboard
     */
    redirectToDashboard = () => {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    };

    /**
     *
     * @param {*} request
     * @param {*} applicationDetails this will made here
     * @param {*} nextStep route remove this. and this also.
     * @param {*} step default value is blank, need only for first two steps
     */
    onContinueHandler = (request, step) => {
      const {
        products,
        doGetAcoountDetails,
        doGetGlobalVault,
        inSessionJointApplicant,
        doUpdateApplicant,
        doSetInSessionJointApplicant,
        history,
        doToggleBackFlag,
        applicantData,
        doDisableBack,
        doFillApplicationSlot,
      } = this.props;
      console.log("onContinueHandler", request, step);
      const activeProduct = this.getActiveProduct(products);
      doToggleBackFlag(false);
      doDisableBack(false);
      switch (step) {
        case AppConstants.APPLICATIONSTEP.GETTINGSTARTED: {
          const { doUpdateGettingStarted } = this.props;
          if (inSessionJointApplicant) {
            const inSessionData = {
              ...inSessionJointApplicant,
              ...request,
            };
            if (!inSessionData.branch) {
              inSessionData.branch = applicantData.member.branch;
            }
            doSetInSessionJointApplicant(inSessionData);
            history.push(ROUTES.ABOUT_YOU);
          } else if (request) {
            doUpdateGettingStarted(request, (response) => {
              if (response.status === HTTP_STATUS.OK) {
                const applicantID = sessionStorage.getItem(S_APPLICANT_ID);
                if (applicantID) {
                  this.createGettingStartedData(
                    request,
                    (updateApplicantDataRequest) => {
                      doUpdateApplicant(
                        updateApplicantDataRequest,
                        applicantID,
                        (doUpdateApplicantResponse) => {
                          if (
                            doUpdateApplicantResponse.status === HTTP_STATUS.OK
                          ) {
                            this.addApplicationProgressOrRedirect(step);
                          }
                        }
                      );
                    }
                  );
                } else {
                  this.addApplicationProgressOrRedirect(step);
                }
              }
            });
          } else {
            doGetAcoountDetails((getAccountActionRes) => {
              this.addApplicationProgressOrRedirect(step);
            });
          }
          break;
        }
        case AppConstants.APPLICATIONSTEP.ABOUTYOU: {
          if (
            !activeProduct.applicationId ||
            (activeProduct.isJointProduct && !activeProduct.applicantId)
          ) {
            const jointApplicant = sessionStorage.getItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
            );
            if (jointApplicant || activeProduct.isJointProduct) {
              doGetAcoountDetails((getAccountActionRes) => {
                if (getAccountActionRes.status === HTTP_STATUS.OK) {
                  const mobileNo = getAccountActionRes.data.msisdn;
                  const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
                  doGetGlobalVault({ vaultKey: vKey }, (gobalVaultRes) => {
                    if (
                      gobalVaultRes?.jointApplicants?.status &&
                      gobalVaultRes.jointApplicants.status ===
                        STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING
                    ) {
                      this.createNewJointApplicantApplication(
                        request,
                        products,
                        step,
                        vKey
                      );
                    }
                  });
                }
              });
            } else {
              this.createNewApplication(request, products, step);
            }
          } else if (inSessionJointApplicant) {
            if (inSessionJointApplicant.applicantId) {
              this.updateApplicantData(request, step);
            } else {
              this.createInSessionJointApplicant(request);
            }
          } else {
            this.updateApplicantData(request, step);
          }
          break;
        }
        case AppConstants.APPLICATIONSTEP.CONFIRM:
          this.addApplicationProgressOrRedirect(step);
          break;

        case AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION:
          this.updateProductInformation(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.THIRD_PARTY:
          this.updateProductInformation(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.SPOUSAL_INFO:
          this.setSpousalInfo(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION:
          this.updateProductInformation(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.BENEFICIARIES:
          this.addApplicationProgressOrRedirect(step);
          // this.addBeneficiaries(request, step);
          break;

        case AppConstants.APPLICATIONSTEP.DEBIT_CARD:
          this.setDebitCard(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.ONLINE_BANKING:
          this.setOnlineBanking(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.SIGNATURE:
          this.setSignature(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.JOINT_APPLICANT:
          this.setJointApplicant(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS:
          this.setJointApplicantStatus(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.INCOME:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.EXPENSE:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.ASSET:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.LIABILITIES:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.DISBURSEMENT:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.REPAYMENT:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.LOAN_SUMMARY:
          this.addApplicationProgressOrRedirect(step);
          break;
        default: {
          this.updateApplicantData(request, step);
        }
      }
    };

    setOnlineBanking = (request, step) => {
      // const { products } = this.props;
      // const activeProduct = this.getActiveProduct(products);
      // TODO what needs to be done for online banking
      this.addApplicationProgressOrRedirect(step);
    };

    setSignature = (request, step) => {
      // const { products } = this.props;
      // const activeProduct = this.getActiveProduct(products);
      // TODO what needs to be done for e-signature
      if (request?.showInReview) {
        this.setState({ showInReview: true });
      } else if (request && request.justAPassThrough) {
        this.addApplicationProgressOrRedirect(step, null, null, true);
      } else {
        this.addApplicationProgressOrRedirect(step);
      }
    };

    setDebitCard = (request, step) => {
      // const { products } = this.props;
      // const activeProduct = this.getActiveProduct(products);
      // TODO what needs to be done for debit
      this.addApplicationProgressOrRedirect(step);
    };

    setJointApplicant = (request, step) => {
      this.addApplicationProgressOrRedirect(step);
    };

    setJointApplicantStatus = (request, step) => {
      this.addApplicationProgressOrRedirect(step);
    };

    setSpousalInfo = (request, step) => {
      const { doSetSpousalInformation, products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      doSetSpousalInformation(
        request,
        activeProduct.applicationId,
        REGISTERCONTRACTPRODUCT,
        activeProduct.contractProductId,
        (response) => {
          if (response.status === HTTP_STATUS.OK) {
            this.addApplicationProgressOrRedirect(step);
          }
        }
      );
    };

    /**
     * Create new application-> creates new applicant for the first time
     * then update application progress in product vault
     * @param {*} request
     * @param {*} products
     * @param {*} step
     */
    createNewApplication = (
      request,
      products,
      step,
      isNotFirstFlag = false,
      processTemplate
    ) => {
      console.log(
        "createNewApplication",
        request,
        products,
        step,
        isNotFirstFlag,
        processTemplate
      );
      const {
        finInfo,
        doGetProspect,
        doUpdateProductToVault,
        applicantData,
        doAddApplicant,
        doGetApplicant,
        doUpdateApplicant,
        doUpdateVaultMember,
      } = this.props;

      if (finInfo.prospectMerge && !applicantData) {
        let telephone = sessionStorage.getItem(S_MSISDN);
        let email = sessionStorage.getItem(S_EMAIL);
        if (telephone && telephone !== "null" && telephone.length > 1) {
          telephone = telephone.substring(1);
          doGetProspect(
            {
              telephone,
              dob: request.dateOfBirth,
            },
            (prospectRes) => {
              if (prospectRes.status === HTTP_STATUS.OK) {
                if (prospectRes.data?.duplicate) {
                  this.setState({
                    showModalStop: true,
                    modalAge: {
                      title: STRINGS.ABOUTYOU.PROSPECT.TITLE,
                      description: STRINGS.ABOUTYOU.PROSPECT.MESSAGE,
                    },
                  });
                } else if (prospectRes.data?.id) {
                  products[0].prospect = prospectRes.data;
                  products[0].applicantId = prospectRes.data.id;
                  doUpdateProductToVault(products, () => {
                    doAddApplicant(
                      {
                        applicantId: prospectRes.data.id,
                        relationship: "self",
                        acquireType: "create",
                      },
                      (addResponse) => {
                        if (addResponse.status === HTTP_STATUS.OK) {
                          doGetApplicant(
                            {
                              id: prospectRes.data.id,
                            },
                            (applicantRes) => {
                              if (applicantRes.status === HTTP_STATUS.OK) {
                                request.branch =
                                  prospectRes.data.member.branch ||
                                  request.branch;
                                request.reasonOpened =
                                  prospectRes.data.member.reasonOpened ||
                                  request.reasonOpened;
                                if (
                                  prospectRes.data.contact
                                    .mailingPreferenceStatement
                                ) {
                                  request.mailingPreferenceStatement =
                                    prospectRes.data.contact.mailingPreferenceStatement;
                                  if (
                                    finInfo.mailingPreference.paper ===
                                    prospectRes.data.contact
                                      .mailingPreferenceStatement
                                  ) {
                                    request.mailStmt = true;
                                    request.eStmt = false;
                                  } else {
                                    request.mailStmt = false;
                                    request.eStmt = true;
                                  }
                                }
                                doUpdateApplicant(
                                  {
                                    doVerification: true,
                                    instntTxnId: request.instntTxnId,
                                    fingerprint: request.fingerprint,
                                    allowChange: true,
                                    member: {
                                      email,
                                      name: request.firstName,
                                      surname: request.surname,
                                      middleName: request.middleName,
                                      branch: request.branch,
                                      reasonOpened: request.reasonOpened,
                                      dob: request.dateOfBirth,
                                    },
                                    regulatory: {
                                      mailStmt: request.mailStmt,
                                      eStmt: request.eStmt,
                                    },
                                    contact: {
                                      mailingPreferenceStatement:
                                        request.mailingPreferenceStatement,
                                    },
                                    address: {
                                      currentAddressLine1: request.address,
                                      currentAddressLine2: request.addressLine2,
                                      currentAddressLine3: request.addressLine3,
                                      currentAddressCity: request.city,
                                      currentAddressProvince: request.state,
                                      currentAddressPostalCode:
                                        request.postalCode,
                                      currentAddressUnitNumber:
                                        request.currentAddressUnitNumber,
                                      currentAddressUnitType:
                                        request.currentAddressUnitType,
                                      currentAddressStreetType:
                                        request.currentAddressStreetType,
                                      currentAddressStreetDir:
                                        request.currentAddressStreetDir,
                                      currentAddressStreetNumber:
                                        request.currentAddressStreetNumber,
                                      currentAddressStreetName:
                                        request.currentAddressStreetName,
                                      currentAddressCellPhoneArea:
                                        prospectRes.data.address
                                          .currentAddressPhoneArea,
                                      currentAddressCellPhone:
                                        prospectRes.data.address
                                          .currentAddressPhone,
                                      mailingSameAsCivic:
                                        request.mailingSameAsCivic,
                                      mailingAddressLine1:
                                        request.mailingAddressLine1,
                                      mailingAddressLine2:
                                        request.mailingAddressLine2,
                                      mailingAddressLine3:
                                        request.mailingAddressLine3,
                                      mailingCity: request.mailingCity,
                                      mailingProvince: request.mailingProvince,
                                      mailingPostalCode:
                                        request.mailingPostalCode,
                                      mailingCountry: request.mailingCountry,
                                      mailingUnitNumber:
                                        request.mailingUnitNumber,
                                      mailingUnitType: request.mailingUnitType,
                                      mailingStreetType:
                                        request.mailingStreetType,
                                      mailingStreetDir:
                                        request.mailingStreetDir,
                                      mailingStreetNumber:
                                        request.mailingStreetNumber,
                                      mailingStreetName:
                                        request.mailingStreetName,
                                    },
                                  },
                                  prospectRes.data.id,
                                  (res) => {
                                    request.doVerification = true;
                                    request.email = sessionStorage.getItem(
                                      S_EMAIL
                                    );
                                    request.msisdn = sessionStorage.getItem(
                                      S_MSISDN
                                    );
                                    this.continueCreateNewApplication(
                                      request,
                                      products,
                                      step,
                                      isNotFirstFlag,
                                      processTemplate
                                    );
                                  }
                                );
                                doUpdateVaultMember(
                                  {
                                    name: request.firstName,
                                    middleName: request.middleName,
                                    surname: request.surname,
                                    dob: request.dateOfBirth,
                                    sin: applicantRes.data.member.sin,
                                    ssn: applicantRes.data.regulatory.ssn,
                                    fatcaStatus:
                                      applicantRes.data.regulatory.fatcaStatus,
                                    fatcaType:
                                      applicantRes.data.regulatory.fatcaType,
                                    taxResidency:
                                      applicantRes.data.regulatory.taxResidency,
                                    taxResidencyOther:
                                      applicantRes.data.regulatory
                                        .taxResidencyOther,
                                    regulatoryFields:
                                      applicantRes.data.regulatory
                                        .regulatoryFields,
                                  },
                                  (res) => console.log(res)
                                );
                              }
                            }
                          );
                        }
                      }
                    );
                  });
                } else {
                  this.continueCreateNewApplication(
                    request,
                    products,
                    step,
                    isNotFirstFlag,
                    processTemplate
                  );
                }
              }
            }
          );
        }
      } else {
        this.continueCreateNewApplication(
          request,
          products,
          step,
          isNotFirstFlag,
          processTemplate
        );
      }
    };

    continueCreateNewApplication = (
      request,
      products,
      step,
      isNotFirstFlag = false,
      processTemplate
    ) => {
      const {
        doCreateNewApplicant,
        doUpdateApplicant,
        doDebitCardShown,
        doGetBundleProductRelation,
        finInfo,
        applicantData,
        doUploadQuestionnaire,
      } = this.props;
      console.log("continueCreateNewApplication", request);

      const applicationStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      if (
        finInfo.jointMembership &&
        applicantData &&
        !(finInfo.disableJointForExistingMember && !isNewMember(applicantData))
      ) {
        doUpdateApplicant(
          {
            member: {
              name: request.firstName,
              middleName: request.middleName,
              surname: request.surname,
              dob: request.dateOfBirth,
            },
            address: {
              currentAddressLine1: request.address,
              currentAddressLine2: request.addressLine2,
              currentAddressLine3: request.addressLine3,
              currentAddressCity: request.city,
              currentAddressProvince: request.state,
              currentAddressPostalCode: request.postalCode,
              currentAddressUnitNumber: request.currentAddressUnitNumber,
              currentAddressUnitType: request.currentAddressUnitType,
              currentAddressStreetType: request.currentAddressStreetType,
              currentAddressStreetDir: request.currentAddressStreetDir,
              currentAddressStreetNumber: request.currentAddressStreetNumber,
              currentAddressStreetName: request.currentAddressStreetName,
              mailingSameAsCivic: request.mailingSameAsCivic,
              mailingAddressLine1: request.mailingAddressLine1,
              mailingAddressLine2: request.mailingAddressLine2,
              mailingAddressLine3: request.mailingAddressLine3,
              mailingCity: request.mailingCity,
              mailingProvince: request.mailingProvince,
              mailingPostalCode: request.mailingPostalCode,
              mailingCountry: request.mailingCountry,
              mailingUnitNumber: request.mailingUnitNumber,
              mailingUnitType: request.mailingUnitType,
              mailingStreetType: request.mailingStreetType,
              mailingStreetDir: request.mailingStreetDir,
              mailingStreetNumber: request.mailingStreetNumber,
              mailingStreetName: request.mailingStreetName,
            },
          },
          applicantData.id,
          (res) => {
            doDebitCardShown(false);
            sessionStorage.removeItem(
              AppConstants.SESSION.DEBIT_CARD_ALREADY_LOADED
            );
            const updatedVaultProductList = products;

            if (
              updatedVaultProductList &&
              updatedVaultProductList.length !== 0
            ) {
              const activeProduct = this.getActiveProduct(
                updatedVaultProductList
              );
              const idx = updatedVaultProductList.findIndex(
                (x) => x === activeProduct
              );

              // updatedVaultProductList[idx].applicantId =
              //     response.data.applicantId;
              if (
                isNotFirstFlag &&
                !(applicationStepIndex <= -1 || applicationStepIndex - 1 <= -1)
              ) {
                updatedVaultProductList[idx].active = true;
                updatedVaultProductList[idx].applicationStep = {
                  index: processTemplate.flow[applicationStepIndex - 1].index,
                  componentName:
                    processTemplate.flow[applicationStepIndex - 1]
                      .componentName,
                };
              }

              updatedVaultProductList[idx].instntTxnId = request.instntTxnId;

              this.updateVaultProductList(
                updatedVaultProductList,
                isNotFirstFlag,
                step,
                {
                  applicantCreated: false,
                  applicantId: applicantData.id,
                  applicationId: null,
                  doximProducts: null,
                }
              );
            }
          }
        );
      } else {
        const { promoCode } = request;
        const { referralCode } = request;
        delete request.promoCode;
        delete request.request;

        request.newCustomer = applicantData ? isNewMember(applicantData) : true;

        doCreateNewApplicant(request, (response) => {
          if (response.status === HTTP_STATUS.OK) {
            let regulatory = null;
            const updateRegulatoryFieldsInputModel = [];
            if (finInfo.showPromoCode) {
              updateRegulatoryFieldsInputModel.push({
                Code: 506,
                Value: promoCode,
                Text: "Promo Code",
                Type: "text",
              });
            }
            if (finInfo.showReferralCode) {
              updateRegulatoryFieldsInputModel.push({
                Code: 507,
                Value: referralCode,
                Text: "Referral Code",
                Type: "text",
              });
            }
            if (response.data.applicantCreated) {
              if (updateRegulatoryFieldsInputModel.length > 0) {
                regulatory = { updateRegulatoryFieldsInputModel };
                doUpdateApplicant(
                  {
                    regulatory,
                  },
                  response.data.applicantId
                );
              }
            } else {
              if (updateRegulatoryFieldsInputModel.length > 0) {
                regulatory = { updateRegulatoryFieldsInputModel };
              }
              doUpdateApplicant(
                {
                  member: {
                    name: request.firstName,
                    middleName: request.middleName,
                    surname: request.surname,
                    dob: request.dateOfBirth,
                  },
                  address: {
                    currentAddressLine1: request.address,
                    currentAddressLine2: request.addressLine2,
                    currentAddressLine3: request.addressLine3,
                    currentAddressCity: request.city,
                    currentAddressProvince: request.state,
                    currentAddressPostalCode: request.postalCode,
                    currentAddressUnitNumber: request.currentAddressUnitNumber,
                    currentAddressUnitType: request.currentAddressUnitType,
                    currentAddressStreetType: request.currentAddressStreetType,
                    currentAddressStreetDir: request.currentAddressStreetDir,
                    currentAddressStreetNumber:
                      request.currentAddressStreetNumber,
                    currentAddressStreetName: request.currentAddressStreetName,
                    mailingSameAsCivic: request.mailingSameAsCivic,
                    mailingAddressLine1: request.mailingAddressLine1,
                    mailingAddressLine2: request.mailingAddressLine2,
                    mailingAddressLine3: request.mailingAddressLine3,
                    mailingCity: request.mailingCity,
                    mailingProvince: request.mailingProvince,
                    mailingPostalCode: request.mailingPostalCode,
                    mailingCountry: request.mailingCountry,
                    mailingUnitNumber: request.mailingUnitNumber,
                    mailingUnitType: request.mailingUnitType,
                    mailingStreetType: request.mailingStreetType,
                    mailingStreetDir: request.mailingStreetDir,
                    mailingStreetNumber: request.mailingStreetNumber,
                    mailingStreetName: request.mailingStreetName,
                  },
                  regulatory: regulatory || null,
                },
                response.data.applicantId
              );
            }
            doDebitCardShown(false);
            sessionStorage.removeItem(
              AppConstants.SESSION.DEBIT_CARD_ALREADY_LOADED
            );
            const updatedVaultProductList = products;

            if (
              updatedVaultProductList &&
              updatedVaultProductList.length !== 0
            ) {
              const activeProduct = this.getActiveProduct(
                updatedVaultProductList
              );
              const idx = updatedVaultProductList.findIndex(
                (x) => x === activeProduct
              );

              updatedVaultProductList[idx].applicationId =
                response.data.applicationId;
              updatedVaultProductList[idx].applicantId =
                response.data.applicantId;
              if (updatedVaultProductList[0].questionnaire) {
                doUploadQuestionnaire({
                  ...updatedVaultProductList[0].questionnaire,
                  applicationId: response.data.applicationId,
                });
              }
              if (
                response.data.doximProducts &&
                response.data.doximProducts.length !== 0
              ) {
                if (response.data.doximProducts.length === 1) {
                  updatedVaultProductList[idx].doximProductId =
                    response.data.doximProducts[idx].id;
                } else {
                  // search registered contract
                  const registeredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type ===
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (registeredContract) {
                    updatedVaultProductList[0].contractProductId = registeredContract
                      ? registeredContract.id
                      : null;
                  }
                  const nonRegisteredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type !==
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (nonRegisteredContract) {
                    updatedVaultProductList[0].doximProductId =
                      nonRegisteredContract.id;
                  } else {
                    updatedVaultProductList[idx].doximProductId =
                      response.data.doximProducts[idx].id;
                    console.error("UNHANDLED MULTI PRODUCTS", response.data);
                  }
                }
              }

              if (
                isNotFirstFlag &&
                !(applicationStepIndex <= -1 || applicationStepIndex - 1 <= -1)
              ) {
                updatedVaultProductList[idx].active = true;
                updatedVaultProductList[idx].applicationStep = {
                  index: processTemplate.flow[applicationStepIndex - 1].index,
                  componentName:
                    processTemplate.flow[applicationStepIndex - 1]
                      .componentName,
                };
              }

              doGetBundleProductRelation(
                {
                  bundleProductId: getProductIdInActiveProduct(
                    updatedVaultProductList[idx],
                    applicantData
                  ),
                  demandProductId: updatedVaultProductList[idx].productId,
                  applicationId: updatedVaultProductList[idx].applicationId,
                },
                (getBundleProductRelationRes) => {
                  if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
                    console.log(
                      "getBundleProductRelationRes",
                      getBundleProductRelationRes
                    );
                    updatedVaultProductList[idx].bundleProducts =
                      getBundleProductRelationRes.data;
                    this.updateVaultProductList(
                      updatedVaultProductList,
                      isNotFirstFlag,
                      step,
                      response.data
                    );
                  }
                }
              );
            }
          }
        });
      }
    };

    updateVaultProductList = (
      updatedVaultProductList,
      isNotFirstFlag,
      step,
      applicantInfo
    ) => {
      console.log(
        "updateVaultProductList",
        updatedVaultProductList,
        isNotFirstFlag,
        step,
        applicantInfo
      );
      const { doUpdateProductToVault, isQrKyc } = this.props;
      if (isQrKyc) {
        updatedVaultProductList[0].kycStatus = "mobileEnd";
      }
      doUpdateProductToVault(updatedVaultProductList, () => {
        if (isQrKyc) {
          this.setState({ showKycEnd: true });
        } else if (isNotFirstFlag) {
          const { history } = this.props;
          history.push(ROUTES[step]);
        } else {
          this.addApplicationProgressOrRedirect(
            step,
            applicantInfo,
            !isNotFirstFlag
          );
        }
      });
    };

    removeCurrentActiveApplication = () => {
      const { products, doUpdateProductToVault, history } = this.props;
      console.log("removeCurrentActiveApplication", products.splice(0, 1));
      const updatedProducts = products.splice(0, 1);
      console.log("updatedProducts", updatedProducts);
      doUpdateProductToVault(updatedProducts, () => {
        history.push(ROUTES.VAULT_DASHBOARD);
      });
    };

    createInSessionJointApplicant = (request) => {
      console.log("createInSessionJointApplicant");
      const {
        doSetInSessionJointApplicant,
        inSessionJointApplicant,
        history,
        doGetProspect,
        finInfo,
        doAddApplicant,
        doUpdateApplicant,
        processTemplate,
      } = this.props;

      if (finInfo.prospectMerge) {
        doGetProspect(
          {
            telephone: inSessionJointApplicant.extra.msisdn.substring(1),
            dob: request.dateOfBirth,
          },
          (prospectRes) => {
            if (prospectRes.status === HTTP_STATUS.OK) {
              if (prospectRes.data?.duplicate) {
                this.setState({
                  showModalStop: true,
                  modalAge: {
                    title: STRINGS.ABOUTYOU.PROSPECT.TITLE,
                    description: STRINGS.ABOUTYOU.PROSPECT.MESSAGE,
                  },
                });
              } else if (prospectRes.data?.id) {
                inSessionJointApplicant.prospect = prospectRes.data;
                inSessionJointApplicant.applicantId = prospectRes.data.id;
                const updateRequest = {
                  member: {
                    email: inSessionJointApplicant.extra.email,
                    name: request.firstName,
                    surname: request.surname,
                    middleName: request.middleName,
                    reasonOpened:
                      prospectRes.data.member.reasonOpened ||
                      request.reasonOpened,
                  },
                  address: {
                    currentAddressLine1: request.address,
                    currentAddressLine2: request.addressLine2,
                    currentAddressLine3: request.addressLine3,
                    currentAddressCity: request.city,
                    currentAddressProvince: request.state,
                    currentAddressPostalCode: request.postalCode,
                    currentAddressUnitNumber: request.currentAddressUnitNumber,
                    currentAddressUnitType: request.currentAddressUnitType,
                    currentAddressStreetType: request.currentAddressStreetType,
                    currentAddressStreetDir: request.currentAddressStreetDir,
                    currentAddressStreetNumber:
                      request.currentAddressStreetNumber,
                    currentAddressStreetName: request.currentAddressStreetName,
                    currentAddressCellPhoneArea:
                      prospectRes.data.address.currentAddressPhoneArea,
                    currentAddressCellPhone:
                      prospectRes.data.address.currentAddressPhone,
                    mailingSameAsCivic: request.mailingSameAsCivic,
                    mailingAddressLine1: request.mailingAddressLine1,
                    mailingAddressLine2: request.mailingAddressLine2,
                    mailingAddressLine3: request.mailingAddressLine3,
                    mailingCity: request.mailingCity,
                    mailingProvince: request.mailingProvince,
                    mailingPostalCode: request.mailingPostalCode,
                    mailingCountry: request.mailingCountry,
                    mailingUnitNumber: request.mailingUnitNumber,
                    mailingUnitType: request.mailingUnitType,
                    mailingStreetType: request.mailingStreetType,
                    mailingStreetDir: request.mailingStreetDir,
                    mailingStreetNumber: request.mailingStreetNumber,
                    mailingStreetName: request.mailingStreetName,
                  },
                };
                doSetInSessionJointApplicant({ ...inSessionJointApplicant });
                doAddApplicant(
                  {
                    applicantId: prospectRes.data.id,
                    relationship: "joint",
                    acquireType: "create",
                  },
                  (addResponse) => {
                    if (addResponse.status === HTTP_STATUS.OK) {
                      doUpdateApplicant(
                        {
                          allowChange: true,
                          ...updateRequest,
                        },
                        prospectRes.data.id,
                        (updateResponse) => {
                          if (updateResponse.status === HTTP_STATUS.OK) {
                            const empIndex = processTemplate.flow.findIndex(
                              (s) =>
                                s.componentName ===
                                AppConstants.APPLICATIONSTEP.EMPLOYMENT
                            );
                            const taxIndex = processTemplate.flow.findIndex(
                              (s) =>
                                s.componentName ===
                                AppConstants.APPLICATIONSTEP.TAX_INFO
                            );
                            const complianceIndex = processTemplate.flow.findIndex(
                              (s) =>
                                s.componentName ===
                                AppConstants.APPLICATIONSTEP.COMPLIANCE
                            );
                            console.log(
                              "indexs",
                              empIndex,
                              taxIndex,
                              complianceIndex
                            );
                            if (empIndex > -1) {
                              if (taxIndex > -1 && taxIndex < empIndex) {
                                history.push(ROUTES.TAX_INFO);
                              } else if (
                                complianceIndex > -1 &&
                                complianceIndex < empIndex
                              ) {
                                history.push(ROUTES.COMPLIANCE);
                              } else {
                                history.push(ROUTES.EMPLOYMENT_AND_INCOME);
                              }
                            } else if (taxIndex > -1) {
                              if (
                                complianceIndex > -1 &&
                                complianceIndex < taxIndex
                              ) {
                                history.push(ROUTES.COMPLIANCE);
                              } else {
                                history.push(ROUTES.TAX_INFO);
                              }
                            } else {
                              // default first step for joint in session
                              history.push(ROUTES.COMPLIANCE);
                            }
                          }
                        }
                      );
                    }
                  }
                );
              } else {
                this.continueCreateInSessionJointApplicant(request);
              }
            }
          }
        );
      } else {
        this.continueCreateInSessionJointApplicant(request);
      }
    };

    continueCreateInSessionJointApplicant = (request) => {
      console.log("continueCreateInSessionJointApplicant");
      const {
        doApplicantCreateRequest,
        doSetInSessionJointApplicant,
        inSessionJointApplicant,
        history,
        jointApplicationId,
      } = this.props;

      request.relationship = "joint";
      request.email = inSessionJointApplicant.extra.email;
      request.msisdn = inSessionJointApplicant.extra.msisdn;
      request.consent = inSessionJointApplicant.consent;
      request.branch = inSessionJointApplicant.branch;
      request.accountStatementType =
        inSessionJointApplicant.accountStatementType;
      request.applicationId = jointApplicationId;

      doApplicantCreateRequest(request, (response) => {
        if (response.status === HTTP_STATUS.OK) {
          const inSessionData = {
            ...inSessionJointApplicant,
            request: {
              ...request,
            },
            otpId: response.data.otpId,
            otpTo: response.data.otpTo,
          };
          doSetInSessionJointApplicant(inSessionData);
          history.push(ROUTES.VERIFICATION_CODE);
        }
      });
    };

    /**
     * Create new application-> creates new joint applicant
     * @param {*} request
     * @param {*} products
     * @param {*} step
     */
    createNewJointApplicantApplication = (
      request,
      products,
      step,
      vKey,
      isNotFirstFlag = false,
      processTemplate
    ) => {
      const {
        doUpdateProductToVault,
        doGetProspect,
        doAddApplicant,
        doGetApplicant,
        finInfo,
        applicantData,
        doUpdateApplicant,
      } = this.props;

      if (finInfo.prospectMerge && !applicantData) {
        let telephone = sessionStorage.getItem(S_MSISDN);
        let email = sessionStorage.getItem(S_EMAIL);
        if (telephone && telephone !== "null" && telephone.length > 1) {
          telephone = telephone.substring(1);
          doGetProspect(
            {
              telephone,
              dob: request.dateOfBirth,
            },
            (prospectRes) => {
              if (prospectRes.status === HTTP_STATUS.OK) {
                if (prospectRes.data?.duplicate) {
                  this.setState({
                    showModalStop: true,
                    modalAge: {
                      title: STRINGS.ABOUTYOU.PROSPECT.TITLE,
                      description: STRINGS.ABOUTYOU.PROSPECT.MESSAGE,
                    },
                  });
                } else if (prospectRes.data?.id) {
                  products[0].prospect = prospectRes.data;
                  products[0].applicantId = prospectRes.data.id;
                  doUpdateProductToVault(products, () => {
                    doAddApplicant(
                      {
                        applicantId: prospectRes.data.id,
                        relationship: "self",
                        acquireType: "create",
                      },
                      (addResponse) => {
                        if (addResponse.status === HTTP_STATUS.OK) {
                          doGetApplicant(
                            { id: prospectRes.data.id },
                            (applicantRes) => {
                              if (applicantRes.status === HTTP_STATUS.OK) {
                                request.branch =
                                  prospectRes.data.member.branch ||
                                  request.branch;
                                request.reasonOpened =
                                  prospectRes.data.member.reasonOpened ||
                                  request.reasonOpened;
                                if (
                                  prospectRes.data.regulatory.mailStmt ||
                                  prospectRes.data.regulatory.eStmt
                                ) {
                                  request.mailStmt =
                                    prospectRes.data.regulatory.mailStmt;
                                  request.eStmt =
                                    prospectRes.data.regulatory.eStmt;
                                }
                                doUpdateApplicant(
                                  {
                                    doVerification: true,
                                    instntTxnId: request.instntTxnId,
                                    fingerprint: request.fingerprint,
                                    allowChange: true,
                                    member: {
                                      email,
                                      name: request.firstName,
                                      surname: request.surname,
                                      middleName: request.middleName,
                                      branch: request.branch,
                                      reasonOpened: request.reasonOpened,
                                      dob: request.dateOfBirth,
                                    },
                                    regulatory: {
                                      mailStmt: request.mailStmt,
                                      eStmt: request.eStmt,
                                    },
                                    address: {
                                      currentAddressLine1: request.address,
                                      currentAddressLine2: request.addressLine2,
                                      currentAddressLine3: request.addressLine3,
                                      currentAddressCity: request.city,
                                      currentAddressProvince: request.state,
                                      currentAddressPostalCode:
                                        request.postalCode,
                                      currentAddressUnitNumber:
                                        request.currentAddressUnitNumber,
                                      currentAddressUnitType:
                                        request.currentAddressUnitType,
                                      currentAddressStreetType:
                                        request.currentAddressStreetType,
                                      currentAddressStreetDir:
                                        request.currentAddressStreetDir,
                                      currentAddressStreetNumber:
                                        request.currentAddressStreetNumber,
                                      currentAddressStreetName:
                                        request.currentAddressStreetName,
                                      currentAddressCellPhoneArea:
                                        prospectRes.data.address
                                          .currentAddressPhoneArea,
                                      currentAddressCellPhone:
                                        prospectRes.data.address
                                          .currentAddressPhone,
                                      mailingSameAsCivic:
                                        request.mailingSameAsCivic,
                                      mailingAddressLine1:
                                        request.mailingAddressLine1,
                                      mailingAddressLine2:
                                        request.mailingAddressLine2,
                                      mailingAddressLine3:
                                        request.mailingAddressLine3,
                                      mailingCity: request.mailingCity,
                                      mailingProvince: request.mailingProvince,
                                      mailingPostalCode:
                                        request.mailingPostalCode,
                                      mailingCountry: request.mailingCountry,
                                      mailingUnitNumber:
                                        request.mailingUnitNumber,
                                      mailingUnitType: request.mailingUnitType,
                                      mailingStreetType:
                                        request.mailingStreetType,
                                      mailingStreetDir:
                                        request.mailingStreetDir,
                                      mailingStreetNumber:
                                        request.mailingStreetNumber,
                                      mailingStreetName:
                                        request.mailingStreetName,
                                    },
                                  },
                                  prospectRes.data.id
                                );
                                this.continueCreateNewJointSetup(
                                  request,
                                  products,
                                  step,
                                  vKey,
                                  (isNotFirstFlag = false),
                                  processTemplate,
                                  prospectRes.data.id,
                                  isNewMember(applicantRes.data)
                                );
                              }
                            }
                          );
                        }
                      }
                    );
                  });
                } else {
                  this.continueCreateNewJointApplicantApplication(
                    request,
                    products,
                    step,
                    vKey,
                    (isNotFirstFlag = false),
                    processTemplate
                  );
                }
              }
            }
          );
        }
      } else {
        this.continueCreateNewJointApplicantApplication(
          request,
          products,
          step,
          vKey,
          (isNotFirstFlag = false),
          processTemplate
        );
      }
    };

    continueCreateNewJointApplicantApplication = (
      request,
      products,
      step,
      vKey,
      isNotFirstFlag = false,
      processTemplate
    ) => {
      const {
        doApplicantCreateSelf,
        doUpdateProductToVault,
        isQrKyc,
      } = this.props;

      const applicationStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      const activeProduct = this.getActiveProduct(products);

      request.relationship = "self";
      request.applicationId = activeProduct.applicationId;

      doApplicantCreateSelf(request, (response) => {
        if (response.status === HTTP_STATUS.OK) {
          const updatedVaultProductList = products;
          sessionStorage.setItem(S_APPLICANT_ID, response.data.applicantId);
          this.fillApplicationSlot(response.data.applicantId, vKey, true);

          if (updatedVaultProductList && updatedVaultProductList.length !== 0) {
            const activeProduct = this.getActiveProduct(
              updatedVaultProductList
            );
            const idx = updatedVaultProductList.findIndex(
              (x) => x === activeProduct
            );

            updatedVaultProductList[idx].applicationId =
              updatedVaultProductList[idx].jointExternalApplicationId;
            updatedVaultProductList[idx].applicantId =
              response.data.applicantId;
            if (updatedVaultProductList[idx].isJointProduct) {
              updatedVaultProductList[idx].slotFilled = true;
            }
            if (
              response.data.doximProducts &&
              response.data.doximProducts.length !== 0
            ) {
              if (response.data.doximProducts.length === 1) {
                updatedVaultProductList[idx].doximProductId =
                  response.data.doximProducts[idx].id;
              } else {
                const termDepositProduct = response.data.doximProducts.find(
                  (item) => item.type === AppConstants.PRODUCT_TYPE.TERM_DEPOSIT
                );
                if (termDepositProduct) {
                  updatedVaultProductList[idx].doximProductId =
                    termDepositProduct.id;
                  const registeredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type ===
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  updatedVaultProductList[
                    idx
                  ].contractProductId = registeredContract
                    ? registeredContract.id
                    : null;
                } else {
                  updatedVaultProductList[idx].doximProductId =
                    response.data.doximProducts[idx].id;
                  console.error("UNHANDLED MULTI PRODUCTS", response.data);
                }
              }
            }

            if (
              isNotFirstFlag &&
              !(applicationStepIndex <= -1 || applicationStepIndex - 1 <= -1)
            ) {
              updatedVaultProductList[idx].active = true;
              updatedVaultProductList[idx].applicationStep = {
                index: processTemplate.flow[applicationStepIndex - 1].index,
                componentName:
                  processTemplate.flow[applicationStepIndex - 1].componentName,
              };
            }
            if (isQrKyc) {
              updatedVaultProductList[0].kycStatus = "mobileEnd";
            }
            doUpdateProductToVault(updatedVaultProductList, () => {
              if (isQrKyc) {
                this.setState({ showKycEnd: true });
              } else if (isNotFirstFlag) {
                const { history } = this.props;
                history.push(ROUTES[step]);
              } else {
                this.addApplicationProgressOrRedirect(step);
              }
            });
          }
        }
      });
    };

    continueCreateNewJointSetup = (
      request,
      products,
      step,
      vKey,
      isNotFirstFlag = false,
      processTemplate,
      applicantId,
      newCustomer = true
    ) => {
      const { doUpdateProductToVault } = this.props;

      const applicationStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      const activeProduct = this.getActiveProduct(products);

      const updatedVaultProductList = products;
      sessionStorage.setItem(S_APPLICANT_ID, applicantId);
      this.fillApplicationSlot(applicantId, vKey, newCustomer);

      if (updatedVaultProductList && updatedVaultProductList.length !== 0) {
        updatedVaultProductList[0].applicationId =
          updatedVaultProductList[0].jointExternalApplicationId;
        updatedVaultProductList[0].applicantId = applicantId;
        if (updatedVaultProductList[0].isJointProduct) {
          updatedVaultProductList[0].slotFilled = true;
        }

        if (
          isNotFirstFlag &&
          !(applicationStepIndex <= -1 || applicationStepIndex - 1 <= -1)
        ) {
          updatedVaultProductList[0].active = true;
          updatedVaultProductList[0].applicationStep = {
            index: processTemplate.flow[applicationStepIndex - 1].index,
            componentName:
              processTemplate.flow[applicationStepIndex - 1].componentName,
          };
        }

        doUpdateProductToVault(updatedVaultProductList, () => {
          if (isNotFirstFlag) {
            const { history } = this.props;
            history.push(ROUTES[step]);
          } else {
            this.addApplicationProgressOrRedirect(step);
          }
        });
      }
    };

    /**
     * Call applicant-fill api for joint applicant registration
     * @param {*} applicantId
     * @param {*} vKey
     * @param {*} newCustomer
     */
    fillApplicationSlot = (applicantId, vKey, newCustomer) => {
      console.log("fillApplicationSlot", applicantId, vKey);
      const {
        doGetAcoountDetails,
        doFillApplicationSlot,
        doUpdateGlobalVault,
        products,
        applicantData,
        finInfo,
        sessionCompletedProducts,
        inSessionJointApplicant,
      } = this.props;
      const activeProduct = this.getActiveProduct(products);
      let kycData = {};
      if (
        allowKycEdit(
          applicantData,
          sessionCompletedProducts,
          finInfo,
          inSessionJointApplicant
        )
      ) {
        kycData = getKycData(applicantData);
      }
      if (newCustomer) {
        kycData.instntTxnId = sessionStorage.getItem(S_INSTNT_TXN_ID);
      }
      if (vKey) {
        doFillApplicationSlot(
          {
            applicantId,
            slotId: activeProduct.slotId,
            applicationId: activeProduct.jointExternalApplicationId,
            newCustomer,
            ...kycData,
          },
          (fillAppRes) => {
            if (fillAppRes.status === HTTP_STATUS.OK) {
              doUpdateGlobalVault({
                vaultKey: vKey,
                body: {},
              });
            }
          }
        );
      } else {
        doGetAcoountDetails((getAccountActionRes) => {
          if (getAccountActionRes.status === HTTP_STATUS.OK) {
            const mobileNo = getAccountActionRes.data.msisdn;
            vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
            doFillApplicationSlot(
              {
                applicantId,
                slotId: activeProduct.slotId,
                applicationId: activeProduct.jointExternalApplicationId,
                newCustomer,
                ...kycData,
              },
              (fillAppRes) => {
                if (fillAppRes.status === HTTP_STATUS.OK) {
                  doUpdateGlobalVault({
                    vaultKey: vKey,
                    body: {},
                  });
                }
              }
            );
          }
        });
      }
    };

    /**
     * Updating product information API
     * then update application progress in product vault
     * @param {*} request
     * @param {*} step
     */
    updateProductInformation = (request, step) => {
      const {
        doUpdateProductInformation,
        products,
        doUpdateProductToVault,
      } = this.props;
      const activeProduct = this.getActiveProduct(products);
      doUpdateProductInformation(
        request,
        activeProduct.applicationId,
        activeProduct.type,
        activeProduct.doximProductId,
        (response) => {
          if (response.status === HTTP_STATUS.OK) {
            const updatedProducts = [...products];
            if (request.hasOverdraftProtection !== undefined) {
              updatedProducts[0].hasOverdraftProtection =
                request.hasOverdraftProtection;
            }
            doUpdateProductToVault(updatedProducts, () => {
              this.addApplicationProgressOrRedirect(step);
            });
          }
        }
      );
    };

    addBeneficiaries = (request, step) => {
      const { doAddBeneficiaries, products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      doAddBeneficiaries(
        request,
        activeProduct.applicationId,
        activeProduct.contractProductId,
        (response) => {
          if (response.status === HTTP_STATUS.OK) {
            this.addApplicationProgressOrRedirect(step);
          }
        }
      );
    };

    /**
     * updating application details
     * then update application progress in product vault
     * @param {*} request
     * @param {*} step
     */
    updateApplicantData = (request, step) => {
      console.log("updateApplicantData", request, step);
      const {
        doUpdateApplicant,
        inSessionJointApplicant,
        doUpdateProductToVault,
        doFillApplicationSlot,
        products,
        applicantData,
        sessionCompletedProducts,
        finInfo,
      } = this.props;
      const activeProduct = this.getActiveProduct(products);
      console.log("activeProduct", activeProduct);
      if (inSessionJointApplicant) {
        if (request) {
          request.branch = inSessionJointApplicant.branch;
          request.accountStatementType =
            inSessionJointApplicant.accountStatementType;
          doUpdateApplicant(
            request,
            inSessionJointApplicant.applicantId,
            (response) => {
              if (response.status === HTTP_STATUS.OK) {
                this.redirectToNextStep(step);
              }
            }
          );
        } else {
          this.redirectToNextStep(step);
        }
      } else if (request === null) {
        this.addApplicationProgressOrRedirect(step);
      } else {
        doUpdateApplicant(request, activeProduct.applicantId, (response) => {
          if (response.status === HTTP_STATUS.OK) {
            if (
              activeProduct.jointExternalApplicationId &&
              !activeProduct.slotFilled
            ) {
              let kycData = {};
              if (
                allowKycEdit(
                  applicantData,
                  sessionCompletedProducts,
                  finInfo,
                  inSessionJointApplicant
                )
              ) {
                kycData = getKycData({ ...applicantData, ...request });
              }
              doFillApplicationSlot(
                {
                  applicantId: activeProduct.applicantId,
                  slotId: activeProduct.slotId,
                  applicationId: activeProduct.jointExternalApplicationId,
                  newCustomer: isNewMember(applicantData),
                  ...kycData,
                },
                (fillAppRes) => {
                  console.log(fillAppRes);
                  const updatedVaultProductList = products;
                  updatedVaultProductList[
                    updatedVaultProductList.findIndex(
                      (x) => x === activeProduct
                    )
                  ].slotFilled = true;
                  doUpdateProductToVault(updatedVaultProductList, () => {
                    this.addApplicationProgressOrRedirect(step);
                  });
                }
              );
            } else {
              this.addApplicationProgressOrRedirect(step);
            }

            // TODO review this code in relation to above process
            // const jointApplicant = sessionStorage.getItem(
            //   STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
            // );
            // if (jointApplicant) {
            //   const applicantId = sessionStorage.getItem(S_APPLICANT_ID);
            //   if (activeProduct.applicantId) {
            //     activeProduct.applicantId = applicantId;
            //     if (activeProduct.applicantId) {
            //       this.fillApplicationSlot(activeProduct.applicantId, null);
            //     }
            //   }
            // }
          }
        });
      }
    };

    /**
     *
     * @param {*} step
     * @param {*} responseApplicantDetails
     * @param {*} shouldNotUpdate used from create application method as create is already updating the app progress
     */
    addApplicationProgressOrRedirect = (
      step,
      responseApplicantDetails,
      shouldNotUpdate = true,
      justAPassThrough = false
    ) => {
      // Previous application progress.
      const {
        products,
        doUpdateProductToVault,
        doStoreFundProduct,
        doGetApplicationProperty,
        isNotFirst,
        processTemplate,
        inSessionJointApplicant,
        onlineBankingInfo,
        doSetOnlineBanking,
        doGetApplicationList,
        doStoreCompletedProductInASession,
        history,
      } = this.props;

      const activeProduct = this.getActiveProduct(products);
      console.log(
        "addApplicationProgressOrRedirect",
        step,
        responseApplicantDetails,
        shouldNotUpdate,
        justAPassThrough,
        processTemplate,
        products
      );

      if (
        !activeProduct &&
        step !== AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
        step !== AppConstants.APPLICATIONSTEP.FUND_METHOD
      ) {
        history.push(ROUTES.VAULT_DASHBOARD);
      } else if (activeProduct?.prospect?.duplicate) {
        this.setState({
          showModalStop: true,
          modalAge: {
            title: STRINGS.ABOUTYOU.PROSPECT.TITLE,
            description: STRINGS.ABOUTYOU.PROSPECT.MESSAGE,
          },
        });
      } else if (
        shouldNotUpdate &&
        (!this.isCurrentStepCompleted(step) ||
          (activeProduct && activeProduct?.applicationStep?.index === null))
      ) {
        let updatedProductList = [...products];

        if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.SIGNATURE
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
              wait: justAPassThrough ? "" : STRINGS.SIGNATURE.WAIT_MESSAGE,
            },
            (res) => {
              doGetApplicationList(() => {
                doStoreFundProduct({
                  ...activeProduct,
                  ...res,
                });
                doStoreCompletedProductInASession(activeProduct);

                if (res.inReview || res.uploadedAt || res.esignCompletedAt) {
                  updatedProductList = products.slice(1, products.length);
                }

                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    S_APPLICANT_ID
                  );
                }

                if (!onlineBankingInfo.applicationId) {
                  doSetOnlineBanking(
                    activeProduct.applicationId,
                    activeProduct.debitProductId,
                    null
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.redirectToNextStep(step);
                });
              });
            }
          );
        } else if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.REPAYMENT
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              if (res.inReview || res.uploadedAt) {
                doStoreCompletedProductInASession(activeProduct);
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    S_APPLICANT_ID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.redirectToDashboard();
                });
              } else {
                this.redirectToNextStep(step);
              }
            }
          );
        } else if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.LOAN_SUMMARY &&
          activeProduct.productType === "Mortgage"
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              console.log(res);
              if (res.inReview || res.uploadedAt || res.appliedAt) {
                doStoreCompletedProductInASession(activeProduct);
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    S_APPLICANT_ID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.showNextProductPopUporRedirect(
                    activeProduct,
                    res.inReview
                  );
                });
              } else {
                this.redirectToNextStep(step);
              }
            }
          );
        } else if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.LOAN_SUMMARY &&
          activeProduct.productType === "Loan"
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              console.log(res);
              if (res.inReview) {
                doStoreCompletedProductInASession(activeProduct);
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    S_APPLICANT_ID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.showNextProductPopUporRedirect(
                    activeProduct,
                    res.inReview
                  );
                });
              } else {
                this.redirectToNextStep(step);
              }
            }
          );
        } else if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              console.log(res);
              if (res.inReview) {
                doStoreCompletedProductInASession(activeProduct);
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    S_APPLICANT_ID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.showNextProductPopUporRedirect(
                    activeProduct,
                    res.inReview
                  );
                });
              } else {
                this.redirectToNextStep(step);
              }
            }
          );
        } else if (step === AppConstants.APPLICATIONSTEP.CONFIRM) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              console.log(res);
              if (res.inReview) {
                doStoreCompletedProductInASession(activeProduct);
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    S_APPLICANT_ID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.showNextProductPopUporRedirect(
                    activeProduct,
                    res.inReview
                  );
                });
              } else {
                const { flow } = processTemplate;
                const currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
                  processTemplate,
                  step
                );

                if (!onlineBankingInfo.applicationId) {
                  doSetOnlineBanking(
                    activeProduct.applicationId,
                    activeProduct.debitProductId,
                    null
                  );
                }

                if (
                  currentStepIndex + 1 === flow.length ||
                  flow[currentStepIndex + 1].componentName ===
                    AppConstants.APPLICATIONSTEP.FUND_METHOD ||
                  (flow[currentStepIndex + 1].componentName ===
                    AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
                    flow[currentStepIndex + 1].componentName ===
                      AppConstants.APPLICATIONSTEP.FUND_METHOD)
                ) {
                  doStoreFundProduct({
                    ...activeProduct,
                    ...res,
                  });
                  doStoreCompletedProductInASession(activeProduct);
                  updatedProductList = products.slice(1, products.length);
                  if (updatedProductList && updatedProductList.length !== 0) {
                    updatedProductList[0].active = true;
                    updatedProductList[0].applicantId = sessionStorage.getItem(
                      S_APPLICANT_ID
                    );
                  }
                  doUpdateProductToVault(updatedProductList, () => {
                    this.redirectToNextStep(step);
                  });
                } else {
                  const request = this.prepareApplicationProgress(
                    step,
                    responseApplicantDetails
                  );
                  updatedProductList[0] = request;
                  doUpdateProductToVault(updatedProductList, () => {
                    this.redirectToNextStep(step);
                  });
                }
              }
            }
          );
        } else if (step === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
          this.redirectToNextStep(step);
        } else if (
          !isNotFirst ||
          (isNotFirst &&
            this.getNextStep(step) ===
              this.getNextApplicationStep(step, processTemplate))
        ) {
          // when its not the second product configuration
          // or if the seond product configuration then if the normal next step
          // and skipping next step is similar then continue as it is
          if (inSessionJointApplicant) {
            this.redirectToNextStep(step);
          } else {
            const request = this.prepareApplicationProgress(
              step,
              responseApplicantDetails
            );
            updatedProductList[0] = request;
            doUpdateProductToVault(updatedProductList, () => {
              this.redirectToNextStep(step);
            });
          }
        } else if (inSessionJointApplicant) {
          // when configuring second product and next step is not similar as next skipping step
          this.redirectToNextStep(step);
        } else {
          const nextStep = this.getNextApplicationStep(step, processTemplate);
          const nextStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
            processTemplate,
            nextStep
          );
          const request = this.prepareApplicationProgress(
            processTemplate.flow[nextStepIndex - 1].componentName,
            responseApplicantDetails
          );
          updatedProductList[0] = request;
          doUpdateProductToVault(updatedProductList, () => {
            this.redirectToNextStep(step);
          });
        }
      } else {
        console.log("skip and redirectToNextStep");
        if (
          activeProduct?.applicationId &&
          step === AppConstants.APPLICATIONSTEP.SIGNATURE
        ) {
          // this happens if after signature the user exits where the product stayed in the vault
          let updatedProductList = [...products];
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              doStoreFundProduct({
                ...this.getActiveProduct(products),
                ...res,
              });

              doStoreCompletedProductInASession(activeProduct);

              if (res.inReview || res.uploadedAt || res.esignCompletedAt) {
                updatedProductList = products.slice(1, products.length);
              }

              if (updatedProductList && updatedProductList.length !== 0) {
                updatedProductList[0].active = true;
                updatedProductList[0].applicantId = sessionStorage.getItem(
                  S_APPLICANT_ID
                );
              }

              doUpdateProductToVault(updatedProductList, () => {
                this.redirectToNextStep(step);
              });
            }
          );
        } else {
          this.redirectToNextStep(step, isNotFirst, processTemplate);
        }
      }
    };

    /**
     * Specific redirect for Online banking since product vault is already removed/updated
     * Prepare stepper
     */
    prepareOnlineBankingStep = () => {
      const { processTemplate } = this.props;
      console.log("prepareOnlineBankingStep", processTemplate);
      const steps = [];
      let activeStepID = -1;
      let index = 1;
      processTemplate.flow.forEach((flow) => {
        if (
          flow.componentName !==
            AppConstants.APPLICATIONSTEP.DOC_VERIFICATION &&
          flow.componentName !== AppConstants.APPLICATIONSTEP.FUND_METHOD
        ) {
          steps.push({
            Id: index,
            Name: STRINGS.STEPS[flow.componentName],
          });
          if (
            flow.componentName === AppConstants.APPLICATIONSTEP.ONLINE_BANKING
          ) {
            activeStepID = index;
          }
          index += 1;
        }
      });
      this.setState({ steps, activeStepID });
    };

    /**
     * Prepare the application progress object
     * @param {*} step
     * @param {*} responseApplicantDetails
     */
    prepareApplicationProgress = (step, responseApplicantDetails = null) => {
      console.log("prepareApplicationProgress", step, responseApplicantDetails);
      const { products, processTemplate } = this.props;
      console.log("products", products);
      const activeProduct = this.getActiveProduct(products);

      const applicationStep = this.getCurrentStepFromTemplateFlowByStep(
        processTemplate,
        step
      );

      if (responseApplicantDetails) {
        sessionStorage.setItem(
          S_APPLICANT_ID,
          responseApplicantDetails.applicantId
        );
        activeProduct.applicantId = responseApplicantDetails.applicantId;
        activeProduct.applicationId = responseApplicantDetails.applicationId;
      }

      if (activeProduct) {
        activeProduct.applicationStep = {
          index: applicationStep.index,
          componentName: applicationStep.componentName,
        };
      }

      return activeProduct;
    };

    /**
     * Redirect to the next step from current template flow
     * redirects to confirm if its in edit mode
     * @param {*} step
     */
    redirectToNextStep = (step, isNotFirstFlag = false, processTemp) => {
      const {
        history,
        isEdit,
        isNotFirst,
        inSessionJointApplicant,
        products,
        doCheckEsignDoc,
        doGetApplicationProperty,
        applicantData,
        confirmApplicationReducer,
        doGetApplicant,
        fundProduct,
      } = this.props;
      console.log(
        "redirectToNextStep",
        step,
        isNotFirstFlag,
        processTemp,
        isEdit,
        isNotFirst
      );
      console.log("redirectToNextStep", step, isNotFirstFlag, processTemp);
      const notFirstFlag = isNotFirstFlag || isNotFirst;
      let activeProduct = this.getActiveProduct(products);
      if (fundProduct?.applicationId) {
        activeProduct = fundProduct;
      }
      /**
       * Edit flow from confirm
       * Use reducers isEdit flag.
       * if isEdit true then make it false.
       */
      if (isEdit) {
        const { doToggleEdit } = this.props;
        doToggleEdit(false);
        history.push(ROUTES.CONFIRM_ACCOUNT_CREATE);
      } else if (
        notFirstFlag &&
        !inSessionJointApplicant &&
        !activeProduct.isJointProduct
      ) {
        const { processTemplate } = this.props;
        let finalProcessTemplate = { ...processTemplate };
        if (processTemp && processTemp?.flow.length > 0) {
          finalProcessTemplate = { ...processTemp };
        }
        this.navigateToNextApplicationStep(step, finalProcessTemplate, history);
      } else {
        const nextStep = this.getNextStep(step);
        console.log("nextStep", nextStep);
        if (nextStep === step) {
          // if next step is the same as current step
          this.showNextProductPopUporRedirect();
        } else if (nextStep === AppConstants.APPLICATIONSTEP.SIGNATURE) {
          // check if there is esign
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (appProperty) => {
              if (appProperty.uploadedAt) {
                if (confirmApplicationReducer?.successConfirmed) {
                  this.continueAfterSignature();
                } else {
                  this.setState({
                    showSuccess: true,
                    showSuccessDescription:
                      activeProduct.productType === "Loan"
                        ? MODALMESSAGES.DISBURSEMENT.CONGRATS_MESSAGE.split(
                            AppConstants.TEMPLATE.NAME
                          ).join(applicantData.member.name)
                        : MODALMESSAGES.SIGNATURE.SUCCESS_MSG,
                  });
                }
              } else if (appProperty.inReview) {
                this.setState({
                  showInReview: true,
                });
              } else {
                doCheckEsignDoc(activeProduct.applicationId, (res) => {
                  console.log("check esign doc", res);
                  if (res.data && res.data.length) {
                    console.log("continue with e-signature");
                    history.push(ROUTES[nextStep]);
                  } else {
                    this.continueAfterSignature();
                  }
                });
              }
            }
          );
        } else if (
          nextStep === AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
        ) {
          doGetApplicationProperty(
            { applicationId: activeProduct.applicationId },
            (res) => {
              console.log("response get app property", res);
              if (res.slots.length === 1) {
                this.onContinueHandler(
                  "",
                  AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
                );
              } else if (res.inReview || res.uploadedAt) {
                this.onContinueHandler("", nextStep);
              } else {
                history.push(ROUTES[nextStep]);
              }
            }
          );
        } else if (nextStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
          doGetApplicant({ id: applicantData.id }, () => {
            history.push(ROUTES[nextStep]);
          });
        } else {
          history.push(ROUTES[nextStep]);
        }
      }
    };

    continueAfterSignature = () => {
      console.log("continueAfterSignature");
      this.onContinueHandler(
        { justAPassThrough: true },
        AppConstants.APPLICATIONSTEP.SIGNATURE
      );
    };

    continueAfterKyc = () => {
      console.log("continueAfterKyc");
      const { history, doSetKycFlag, doUpdateVaultProductList } = this.props;
      this.setState({ showKycEnd: false }, () => {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(S_EMAIL);
        sessionStorage.removeItem(S_MSISDN);
        sessionStorage.removeItem(EXPIRES_AT);
        sessionStorage.removeItem(REFRESH_EXPIRES_AT);
        doSetKycFlag(false);
        doUpdateVaultProductList([]);
        history.push(ROUTES.SIGN_IN);
      });
    };

    /**
     * When configuring second product navigating to next application step
     * or non visited applicant step
     * @param {*} step
     * @param {*} processTemplate
     * @param {*} history
     */
    navigateToNextApplicationStep = (step, processTemplate, history) => {
      console.log("navigateToNextApplicationStep", step);
      const nextStep = this.getNextApplicationStep(step, processTemplate);
      console.log("nextStep", nextStep);
      if (nextStep === step) {
        this.showNextProductPopUporRedirect();
      } else if (nextStep === AppConstants.APPLICATIONSTEP.SIGNATURE) {
        const {
          products,
          applicantData,
          doCheckEsignDoc,
          doGetApplicationProperty,
          confirmApplicationReducer,
        } = this.props;
        const activeProduct = this.getActiveProduct(products);
        // check if there is esign
        doCheckEsignDoc(activeProduct.applicationId, (res) => {
          console.log("check esign doc", res);
          if (res.data && res.data.length) {
            doGetApplicationProperty(
              {
                applicationId: activeProduct.applicationId,
                delayTime: 0,
              },
              (appProperty) => {
                if (appProperty.uploadedAt) {
                  if (confirmApplicationReducer?.successConfirmed) {
                    this.continueAfterSignature();
                  } else {
                    this.setState({
                      showSuccess: true,
                      showSuccessDescription:
                        activeProduct.productType === "Loan"
                          ? MODALMESSAGES.DISBURSEMENT.CONGRATS_MESSAGE.split(
                              AppConstants.TEMPLATE.NAME
                            ).join(applicantData.member.name)
                          : MODALMESSAGES.SIGNATURE.SUCCESS_MSG,
                    });
                  }
                } else if (appProperty.inReview) {
                  this.setState({
                    showInReview: true,
                  });
                } else {
                  console.log("continue with e-signature");
                  history.push(ROUTES[nextStep]);
                }
              }
            );
          } else {
            doGetApplicationProperty(
              {
                applicationId: activeProduct.applicationId,
                delayTime: 0,
              },
              (appProperty) => {
                if (appProperty.uploadedAt) {
                  if (confirmApplicationReducer?.successConfirmed) {
                    this.continueAfterSignature();
                  } else {
                    this.setState({
                      showSuccess: true,
                      showSuccessDescription:
                        activeProduct.productType === "Loan"
                          ? MODALMESSAGES.DISBURSEMENT.CONGRATS_MESSAGE.split(
                              AppConstants.TEMPLATE.NAME
                            ).join(applicantData.member.name)
                          : MODALMESSAGES.SIGNATURE.SUCCESS_MSG,
                    });
                  }
                } else if (appProperty.inReview) {
                  this.setState({
                    showInReview: true,
                  });
                } else {
                  this.continueAfterSignature();
                }
              }
            );
          }
        });
      } else if (
        nextStep === AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
      ) {
        const { doGetApplicationProperty, products } = this.props;
        const activeProduct = this.getActiveProduct(products);
        doGetApplicationProperty(
          { applicationId: activeProduct.applicationId },
          (res) => {
            console.log("response get app property", res);
            if (res.slots.length === 1) {
              this.onContinueHandler(
                "",
                AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
              );
            } else {
              history.push(ROUTES[nextStep]);
            }
          }
        );
      } else {
        history.push(ROUTES[nextStep]);
      }
    };

    /**
     * To get the next application step
     * or non visited applicant step
     * @param {*} step
     * @param {*} processTemplate
     */
    getNextApplicationStep = (step, processTemplate) => {
      console.log("getNextApplicationStep", step);
      const {
        products,
        applicationCompleteList,
        applicantData,
        finInfo,
        onlineBankingInfo,
        fundProduct,
      } = this.props;
      let nextStep = step;
      let currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );
      currentStepIndex += 1;
      const activeProduct = this.getActiveProduct(products);
      while (currentStepIndex < processTemplate?.flow?.length) {
        if (
          processTemplate?.flow[currentStepIndex]?.componentType ===
            AppConstants.COMPONENTSTEPTYPE.APPLICATION ||
          !this.stepVisisted(
            processTemplate?.flow[currentStepIndex]?.componentName
          )
        ) {
          const targetNextStep =
            processTemplate?.flow[currentStepIndex]?.componentName;
          if (targetNextStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
            if (
              showOnlineBanking(
                activeProduct,
                applicationCompleteList,
                applicantData,
                finInfo,
                onlineBankingInfo,
                fundProduct
              )
            ) {
              nextStep = targetNextStep;
              break;
            }
          } else if (
            targetNextStep === AppConstants.APPLICATIONSTEP.BENEFICIARIES ||
            targetNextStep === AppConstants.APPLICATIONSTEP.SPOUSAL_INFO
          ) {
            if (!activeProduct.hasExistingContract) {
              nextStep = targetNextStep;
              break;
            }
          } else {
            nextStep = processTemplate?.flow[currentStepIndex]?.componentName;
            break;
          }
        }
        currentStepIndex += 1;
      }

      return nextStep;
    };

    /**
     * When configuring second product then check if the step visited in previously
     * completed products in the session
     * @param {*} step
     */
    stepVisisted = (step) => {
      const { templates, products, sessionCompletedProducts } = this.props;
      let stepCompleted = false;
      // this part added to check if the step is completed in current template or not
      const activeProduct = this.getActiveProduct(products);
      let completedTemplate = null;
      for (let i = 0; i < sessionCompletedProducts.length; i += 1) {
        const completedProduct = sessionCompletedProducts[i];

        if (completedProduct) {
          completedTemplate = this.getTemplateByTemplateID(
            templates,
            completedProduct.templateId
          );
        }

        if (completedTemplate) {
          const stepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
            completedTemplate,
            step
          );

          if (stepIndex >= 0) {
            stepCompleted = true;
            break;
          }
          stepCompleted = false;
        } else {
          // this part added to check if the step is completed in current template or not
          completedTemplate = this.getTemplateByTemplateID(
            templates,
            activeProduct.templateId
          );

          if (completedTemplate) {
            const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
              completedTemplate,
              step
            );

            if (
              currentStepObj &&
              activeProduct.applicationStep?.index <= currentStepObj.index
            ) {
              stepCompleted = true;
              break;
            }
          }
        }
      }
      return stepCompleted;
    };

    /**
     * Returns next step to the step(from param)
     * @param {*} step
     */
    getNextStep = (step) => {
      console.log("getNextStep", step);
      let nextStep = step;
      const {
        processTemplate,
        inSessionJointApplicant,
        products,
        applicationCompleteList,
        applicantData,
        doGetApplicationProperty,
        fundProduct,
        finInfo,
        onlineBankingInfo,
        sessionCompletedProducts,
      } = this.props;

      let activeProduct = this.getActiveProduct(products);
      if (fundProduct?.applicationId) {
        activeProduct = fundProduct;
      }

      if (activeProduct.isJointProduct) {
        if (
          !activeProduct.applicationStep.index &&
          step !== AppConstants.APPLICATIONSTEP.GETTINGSTARTED
        ) {
          nextStep = step;
        } else {
          const currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
            processTemplate,
            step
          );
          console.log("currentStepIndex", currentStepIndex);
          if (
            currentStepIndex !== -1 &&
            currentStepIndex !== processTemplate.flow.length - 1
          ) {
            // check if we have application steps already
            if (
              processTemplate.flow[currentStepIndex + 1].componentType ===
              AppConstants.COMPONENTSTEPTYPE.APPLICATION
            ) {
              // search if we have debit card step
              const debitCardStepIndex = processTemplate.flow.findLastIndex(
                (item) =>
                  item.componentName === AppConstants.APPLICATIONSTEP.DEBIT_CARD
              );
              if (
                debitCardStepIndex &&
                debitCardStepIndex >= currentStepIndex + 1
              ) {
                if (
                  showDebitCard(
                    activeProduct,
                    applicationCompleteList,
                    applicantData
                  )
                ) {
                  nextStep = AppConstants.APPLICATIONSTEP.DEBIT_CARD;
                } else {
                  nextStep = AppConstants.APPLICATIONSTEP.CONFIRM;
                }
              } else {
                nextStep = AppConstants.APPLICATIONSTEP.CONFIRM;
              }
            } else {
              nextStep =
                processTemplate.flow[currentStepIndex + 1].componentName;
              if (nextStep === AppConstants.APPLICATIONSTEP.DOC_VERIFICATION) {
                const { doToggleScanFlag } = this.props;
                doToggleScanFlag(true);
                nextStep =
                  processTemplate.flow[currentStepIndex + 2].componentName;
              }
              if (!nextStep) {
                nextStep =
                  processTemplate.flow[currentStepIndex + 2].componentName;
              }
            }
          }
        }
      } else if (inSessionJointApplicant) {
        const inSessionJointFlow = getJointInSessionFlow(
          processTemplate.flow,
          applicantData
        );
        const currentStepIndex = inSessionJointFlow.findIndex(
          (applicantStep) => applicantStep.componentName === step
        );

        if (
          currentStepIndex !== -1 &&
          currentStepIndex !== inSessionJointFlow.length - 1
        ) {
          nextStep = inSessionJointFlow[currentStepIndex + 1].componentName;
          if (
            nextStep === "VERIFICATION_CODE" &&
            inSessionJointApplicant.applicantId
          ) {
            // no need to do verification if we already have an applicant thru inverite
            nextStep = inSessionJointFlow[currentStepIndex + 2].componentName;
          }
        }
      } else {
        const currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
          processTemplate,
          step
        );

        if (
          currentStepIndex !== -1 &&
          currentStepIndex !== processTemplate.flow.length - 1
        ) {
          // if current step is not the last step

          nextStep = processTemplate.flow[currentStepIndex + 1].componentName;

          console.log("succeeding step", nextStep);

          if (nextStep === AppConstants.APPLICATIONSTEP.TAX_INFO) {
            const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            if (
              !showTaxInfo(
                applicationCompleteList,
                applicantData,
                null,
                activeProduct,
                finInfo,
                sessionCompletedProducts
              )
            ) {
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
              } else {
                nextStep = step;
              }
            }
          } else if (nextStep === AppConstants.APPLICATIONSTEP.BENEFICIARIES) {
            const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            if (activeProduct.hasExistingContract) {
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
              } else {
                nextStep = step;
              }
            }
          } else if (nextStep === AppConstants.APPLICATIONSTEP.SPOUSAL_INFO) {
            const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            if (activeProduct.hasExistingContract) {
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
              } else {
                nextStep = step;
              }
            }
          } else if (
            nextStep === AppConstants.APPLICATIONSTEP.DOC_VERIFICATION
          ) {
            const { doToggleScanFlag } = this.props;
            doToggleScanFlag(true);
            if (processTemplate.flow[currentStepIndex + 2]) {
              nextStep =
                processTemplate.flow[currentStepIndex + 2].componentName;
            } else {
              nextStep = step;
            }
          } else if (nextStep === AppConstants.APPLICATIONSTEP.DEBIT_CARD) {
            const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            if (
              !showDebitCard(
                activeProduct,
                applicationCompleteList,
                applicantData
              )
            ) {
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
              } else {
                nextStep = step;
              }
            }
          } else if (nextStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
            const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            if (
              !showOnlineBanking(
                activeProduct,
                applicationCompleteList,
                applicantData,
                finInfo,
                onlineBankingInfo,
                fundProduct
              )
            ) {
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
                if (nextStep === AppConstants.APPLICATIONSTEP.FUND_METHOD) {
                  if (!fundProduct.uploadedAt) {
                    nextStep = step;
                  }
                }
              } else {
                nextStep = step;
              }
            }
          } else if (nextStep === AppConstants.APPLICATIONSTEP.FUND_METHOD) {
            // product should already have been removed in the vault product list
            // TODO it won't wait for this
            if (fundProduct.applicationId) {
              doGetApplicationProperty(
                { applicationId: fundProduct.applicationId },
                (res) => {
                  if (!res.uploadedAt) {
                    nextStep = step;
                  }
                }
              );
            }
          } else if (
            nextStep === AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE
          ) {
            if (activeProduct.category !== AppConstants.LOAN_CATEGORY.AUTO) {
              const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
                processTemplate,
                nextStep
              );
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
              } else {
                nextStep = step;
              }
            }
          }
        }
      }

      return nextStep;
    };

    /**
     * Check if current step is already visited or not
     * so the application progress should be updated or not
     * @param {*} step
     */
    isCurrentStepCompleted = (step) => {
      console.log("isCurrentStepCompleted", step);
      const { processTemplate } = this.props;

      const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
        processTemplate,
        step
      );

      console.log("currentStepObj", currentStepObj);

      // Previous application progress.
      const { products } = this.props;
      const activeProduct = this.getActiveProduct(products);

      console.log(
        "currentStepObj result",
        !currentStepObj ||
          !activeProduct ||
          activeProduct?.applicationStep?.index === null ||
          currentStepObj.index < activeProduct?.applicationStep?.index
      );

      return (
        !currentStepObj ||
        !activeProduct ||
        activeProduct?.applicationStep?.index === null ||
        currentStepObj.index < activeProduct?.applicationStep?.index
      );
    };

    /**
     * From the current template flow goes to the previous step
     * @param {*} currentStep
     */
    onBackHandleClick = (currentStep) => {
      const previousStep = this.getPreviousStep(currentStep);
      const { isNotFirst, doToggleBackFlag } = this.props;
      if (isNotFirst) {
        const { doSetNotFirstFlag } = this.props;
        doSetNotFirstFlag(false);
      }
      if (previousStep) {
        const { history, doToggleEdit } = this.props;
        doToggleEdit(false);
        doToggleBackFlag(true);
        history.push(ROUTES[previousStep]);
      }
    };

    /**
     * Returns the previous step from current template flow
     * @param {*} step
     */
    getPreviousStep = (step) => {
      const {
        processTemplate,
        appProperties,
        isDebitCardEnabled,
        enableDebitCard,
        inSessionJointApplicant,
        applicationCompleteList,
        applicantData,
        products,
        finInfo,
        sessionCompletedProducts,
      } = this.props;
      console.log("getPreviousStep", step);
      let previousStep = "";

      const activeProduct = this.getActiveProduct(products);

      if (inSessionJointApplicant) {
        const inSessionJointFlow = getJointInSessionFlow(
          processTemplate.flow,
          applicantData
        );
        const applicantStepIndex = inSessionJointFlow.findIndex(
          (applicantStep) => applicantStep.componentName === step
        );
        if (applicantStepIndex) {
          previousStep =
            inSessionJointFlow[applicantStepIndex - 1].componentName;
        }
      } else if (
        activeProduct.isJointProduct &&
        step === AppConstants.APPLICATIONSTEP.CONFIRM
      ) {
        const lastIndex = processTemplate.flow.findLastIndex(
          (item) =>
            item.componentType === AppConstants.COMPONENTSTEPTYPE.APPLICANT ||
            (item.componentType ===
              AppConstants.COMPONENTSTEPTYPE.APPLICATION &&
              item.componentName === AppConstants.APPLICATIONSTEP.DEBIT_CARD)
        );
        if (
          processTemplate.flow[lastIndex].componentName ===
          AppConstants.APPLICATIONSTEP.DEBIT_CARD
        ) {
          if (
            showDebitCard(activeProduct, applicationCompleteList, applicantData)
          ) {
            previousStep = AppConstants.APPLICATIONSTEP.DEBIT_CARD;
          } else {
            const newLastIndex = processTemplate.flow.findLastIndex(
              (item) =>
                item.componentType === AppConstants.COMPONENTSTEPTYPE.APPLICANT
            );
            previousStep = processTemplate.flow[newLastIndex].componentName;
          }
        } else {
          previousStep = processTemplate.flow[lastIndex].componentName;
        }
      } else if (
        activeProduct.isJointProduct &&
        step === AppConstants.APPLICATIONSTEP.DEBIT_CARD
      ) {
        const lastIndex = processTemplate.flow.findLastIndex(
          (item) =>
            item.componentType === AppConstants.COMPONENTSTEPTYPE.APPLICANT
        );
        previousStep = processTemplate.flow[lastIndex].componentName;
      } else {
        const applicantStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
          processTemplate,
          step
        );

        if (applicantStepIndex) {
          previousStep = processTemplate.flow[applicantStepIndex - 1]
            .componentName
            ? processTemplate.flow[applicantStepIndex - 1].componentName
            : processTemplate.flow[applicantStepIndex - 2].componentName;
        }

        if (
          previousStep === AppConstants.APPLICATIONSTEP.DOC_VERIFICATION ||
          previousStep === "" ||
          (previousStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
            isDebitCardEnabled) ||
          (previousStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
            enableDebitCard === false) ||
          (previousStep ===
            AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS &&
            appProperties &&
            appProperties.length < 2) ||
          (previousStep === AppConstants.APPLICATIONSTEP.TAX_INFO &&
            !showTaxInfo(
              applicationCompleteList,
              applicantData,
              null,
              activeProduct,
              finInfo,
              sessionCompletedProducts
            )) ||
          (previousStep === AppConstants.APPLICATIONSTEP.BENEFICIARIES &&
            activeProduct.hasExistingContract) ||
          (previousStep === AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE &&
            activeProduct.category !== AppConstants.LOAN_CATEGORY.AUTO)
        ) {
          previousStep =
            processTemplate.flow[applicantStepIndex - 2].componentName;
        }
      }

      return previousStep;
    };

    /**
     * Display Pop up if there is any more product left to configure
     * or redirect to dashboard
     */
    showNextProductPopUporRedirect = (activeProduct, inReview) => {
      console.log("showNextProductPopUporRedirect", inReview);
      const {
        products,
        finInfo,
        sessionCompletedProducts,
        applicationCompleteList,
      } = this.props;
      let currentActiveProduct = activeProduct;
      if (!currentActiveProduct) {
        currentActiveProduct =
          sessionCompletedProducts[sessionCompletedProducts.length - 1];
      }
      if (finInfo.recommend && currentActiveProduct?.recommend) {
        let recommendedProductInVault = products.find(
          (product) => product.productId === currentActiveProduct.recommend
        );
        if (!recommendedProductInVault) {
          applicationCompleteList.forEach((application) => {
            const doximProduct = application.doximProducts.find((product) =>
              currentActiveProduct.recommend.includes(product.bundleProductId)
            );
            if (doximProduct) {
              recommendedProductInVault = doximProduct;
            }
          });
        }
        console.log(
          "recommendedProductInVault",
          recommendedProductInVault,
          inReview
        );
        if (recommendedProductInVault) {
          this.showNextProductPopUporRedirectContinued();
        } else {
          const modal = {};
          modal.title = MODALMESSAGES.RECOMMEND.POPUP_TITLE;
          modal.description = MODALMESSAGES.RECOMMEND.POPUP_MESSAGE;
          modal.type = AppConstants.MODALTYPE.INFORMATION;
          this.setState({
            showRecommend: true,
            recommendProduct: currentActiveProduct.recommend,
            modal,
          });
        }
      } else {
        this.showNextProductPopUporRedirectContinued();
      }
    };

    getApplicantBankingProductList = (applicantId, callback) => {
      const { doGetApplicantBankingProductList } = this.props;
      doGetApplicantBankingProductList(applicantId, (res) => {
        callback(res);
      });
    };

    showNextProductPopUporRedirectContinued = () => {
      console.log("showNextProductPopUporRedirectContinued");
      const { products } = this.props;
      if (products && products.length !== 0) {
        this.showContinueWithNextProductPopup();
      } else {
        this.redirectToDashboard();
      }
    };

    /**
     * Show Pop up for next product
     */
    showContinueWithNextProductPopup = () => {
      const { productList, products } = this.props;

      const product = getProduct(productList, products);
      const modal = {};
      const description =
        MODALMESSAGES.FUNDING_AMOUNT.FUND_PRODUCT_POPUP_MESSAGE;
      modal.title = MODALMESSAGES.FUNDING_AMOUNT.FUND_PRODUCT;
      modal.description = description.replace("$", product.productName);
      modal.type = AppConstants.MODALTYPE.ANOTHER_PRODUCT;
      this.setState({
        modal,
        showModal: true,
      });
    };

    /**
     * To start configuring the second product
     * Assuming the about you step will be skipping in the next product configutaion
     */
    navigateToNextProduct = () => {
      const {
        productList,
        products,
        applicantData,
        applicationCompleteList,
        doGetApplicationList,
        doGetApplicant,
        doStoreFundProduct,
      } = this.props;
      console.log("navigateToNextProduct", applicationCompleteList);
      doStoreFundProduct({});
      const activeProduct = products[0];
      doGetApplicant({ id: applicantData.id }, (getAccountActionRes) => {
        if (isNewMember(getAccountActionRes.data)) {
          if (activeProduct.required && activeProduct.required.length > 0) {
            const requiredList = [];
            let oneRequiredProductFound = false;
            doGetApplicationList((res) => {
              const latestCompleteList = res.data.filter(
                (application) =>
                  application.inReview ||
                  application.uploadedAt ||
                  application.submittedToDecisionAt
              );
              activeProduct.required.forEach((req) => {
                const product = searchCategoryInCompleteList(
                  req.category,
                  productList,
                  latestCompleteList
                );
                if (product) {
                  oneRequiredProductFound = true;
                } else {
                  requiredList.push(req);
                }
              });
              if (!oneRequiredProductFound && requiredList.length > 0) {
                this.setState({
                  showRequired: true,
                  requiredList,
                  modal: {
                    type: AppConstants.MODALTYPE.INFORMATION,
                    title: MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_PRODUCT,
                    description: MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_MSG,
                  },
                });
              } else {
                this.continueOnNextProduct(
                  activeProduct,
                  getAccountActionRes.data
                );
              }
            });
          } else {
            this.continueOnNextProduct(activeProduct, getAccountActionRes.data);
          }
        } else {
          this.continueOnNextProduct(activeProduct, getAccountActionRes.data);
        }
      });
    };

    continueOnNextProduct = (activeProduct, applicantData) => {
      console.log("continueOnNextProduct");
      // Set the not first flag to true
      // this flag gets invalidated when user clicks on back when configuraing second product
      const { doSetNotFirstFlag } = this.props;
      doSetNotFirstFlag(true);

      // checking if the second products process template is different or same
      const { processTemplate } = this.props;
      let processTemp = { ...processTemplate };
      if (activeProduct.templateId !== processTemplate?.templateId) {
        // if process template different then updating the reducer
        const { doUpdateProductTemplate, templates } = this.props;
        doUpdateProductTemplate(activeProduct);

        processTemp = this.getTemplateByTemplateID(
          templates,
          activeProduct.templateId
        );
      }

      let nextComponent = processTemp.flow.find(
        (flow) =>
          ((activeProduct.type === LENDING_PRODUCT &&
            flow.componentName === AppConstants.APPLICATIONSTEP.EMPLOYMENT) ||
            (flow.componentType === AppConstants.COMPONENTSTEPTYPE.APPLICANT &&
              !this.stepVisisted(flow.componentName)) ||
            flow.componentType ===
              AppConstants.COMPONENTSTEPTYPE.APPLICATION) &&
          flow.componentName !== AppConstants.APPLICATIONSTEP.DOC_VERIFICATION
      );
      console.log("nextComponent", nextComponent, activeProduct);
      if (activeProduct.applicationStep.componentName) {
        const nextStep = this.getNextStep(
          activeProduct.applicationStep.componentName
        );
        const productNextComponent = processTemp.flow.find(
          (flow) => flow.componentName === nextStep
        );
        if (
          productNextComponent.componentType !==
          AppConstants.COMPONENTSTEPTYPE.APPLICANT
        ) {
          nextComponent = productNextComponent;
        }
      }
      console.log("nextComponent", nextComponent);

      let nextStep = nextComponent?.componentName;

      // check if the second product configuration is dropeed in between then
      // do not call create application for same product twice
      if (verifyAge(applicantData.member.dob, activeProduct)) {
        if (!activeProduct.applicationId) {
          if (nextStep === AppConstants.APPLICATIONSTEP.GETTINGSTARTED) {
            nextStep = AppConstants.APPLICATIONSTEP.ABOUTYOU;
          }
          this.callCreateNewApplication(nextStep, processTemp);
        } else {
          nextStep = AppConstants.APPLICATIONSTEP.ABOUTYOU;
          this.startProcessingApplication(activeProduct, nextStep);
        }
      } else {
        this.setState({
          showModalStop: true,
          modalAge: {
            title: STRINGS.ABOUTYOU.AGE_RESTRICTION.TITLE,
            description: STRINGS.ABOUTYOU.AGE_RESTRICTION.MESSAGE,
          },
        });
      }
    };

    startProcessingApplication = (activeProduct, nextStep) => {
      const { history } = this.props;
      console.log("startProcessingApplication", activeProduct, nextStep);
      history.push(ROUTES[nextStep]);
    };

    /**
     * Call create application method
     * @param {*} addProgressForStep this step passed will be the landing step
     * @param {*} processTemp updated process template
     */
    callCreateNewApplication = (addProgressForStep, processTemp) => {
      const { applicantData, products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      const { member, address } = applicantData;
      let bundleProductId = activeProduct.productId;
      if (activeProduct.productList && activeProduct.productList.length > 0) {
        bundleProductId = getProductIdByAge(
          member.dob,
          activeProduct.productList
        );
      }
      console.log("bundleProductId", bundleProductId);
      const request = {
        firstName: member.name,
        middleName: member.middleName,
        lastName: member.surname,
        dateOfBirth: member.dob,
        address: address.currentAddressLine1,
        addressLine2: address.currentAddressLine2,
        city: address.currentAddressCity,
        province: address.currentAddressProvince,
        postalCode: address.currentAddressPostalCode,
        mailingAddressLine1: address.mailingAddressLine1,
        mailingAddressLine2: address.mailingAddressLine2,
        mailingCity: address.mailingCity,
        mailingProvince: address.mailingProvince,
        mailingPostalCode: address.mailingPostalCode,
        mailingCountry: address.mailingCountry,
        mailingSameAsCivic: address.mailingSameAsCivic,
        bundleProductId,
      };

      this.createNewApplication(
        request,
        products,
        addProgressForStep,
        true,
        processTemp
      );
    };

    handlePopupModalCloseBtnClick = () => {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    };

    toggleModal = (route) => {
      const modal = { title: "", description: "", type: "" };
      this.setState(
        {
          showModal: false,
          showRecommend: false,
          showRequired: false,
          showInReview: false,
          modal,
          recommendProduct: null,
        },
        () => {
          if (route) {
            const { history } = this.props;
            history.push(route);
          }
        }
      );
    };

    getGettingStartedDataFromApplicantData = (applicantID, callback) => {
      const { doGetApplicant } = this.props;
      doGetApplicant({ id: applicantID }, (applicantDetails) => {
        getGettingStarted(applicantDetails.data, callback);
      });
    };

    createGettingStartedData = (gettingStartedData, callback) => {
      const { finInfo } = this.props;
      const mailingPreference =
        finInfo.mailingPreference[gettingStartedData.accountStatementType];
      let regulatory = {
        mailStmt:
          gettingStartedData.accountStatementType ===
          AppConstants.GETTINGSTARTED.PAPER,
        eStmt:
          gettingStartedData.accountStatementType ===
          AppConstants.GETTINGSTARTED.EMAIL,
      };
      const updateRegulatoryFieldsInputModel = [];
      if (finInfo.showPromoCode) {
        updateRegulatoryFieldsInputModel.push({
          Code: 506,
          Value: gettingStartedData.promoCode,
          Text: "Promo Code",
          Type: "text",
        });
      }
      if (finInfo.showReferralCode) {
        updateRegulatoryFieldsInputModel.push({
          Code: 507,
          Value: gettingStartedData.referralCode,
          Text: "Referral Code",
          Type: "text",
        });
      }
      if (updateRegulatoryFieldsInputModel.length > 0) {
        regulatory = { ...regulatory, updateRegulatoryFieldsInputModel };
      }
      const gettingStartedDataRequest = {
        member: {
          branch: gettingStartedData.branch,
          reasonOpened: gettingStartedData.reasonCode,
        },
        regulatory,
        contact: {
          mailingPreferenceStatement: mailingPreference,
        },
      };

      callback(gettingStartedDataRequest);
    };

    addProduct = (productId, callback) => {
      console.log("addProduct", productId);
      const {
        loanProducts,
        products,
        productList,
        doUpdateProductToVault,
        bankingType,
      } = this.props;

      const selectedProduct = getProductInfo(productId, productList);
      console.log("selectedProduct", selectedProduct, this.props);
      const product = {
        ...selectedProduct,
        // don't store product details as it makes the data too big
        productDetails: null,
        active: true,
        applicantId: null,
        applicationId: null,
        applicationStep: {
          index: null,
          step: "",
        },
        bankingType,
      };
      const rand = Math.floor(Math.random() * 1000);
      product.productIndex = rand;
      console.log("product", product);
      if (product.type === LENDING_PRODUCT) {
        const loanProduct = loanProducts.find(
          (item) => product.productId === item.id
        );
        console.log("loanProduct", loanProduct);
        product.category = loanProduct.category;
        product.subCategory = loanProduct.subCategory;
        product.productType = loanProduct.productType;
        product.productCode = loanProduct.productCode;
        product.cutoff = loanProduct.cutoff;
        product.applicationType = loanProduct.applicationType;
      } else {
        product.category = "";
        product.subCategory = "";
      }

      const newProductList = [product].concat(products);
      if (newProductList.length > 1) {
        newProductList[1].active = false;
      }

      doUpdateProductToVault(newProductList, () => {
        callback();
      });
    };

    addProductAndProcess = (productId) => {
      this.setState(
        {
          showRequired: false,
          showRecommend: false,
        },
        () => {
          this.addProduct(productId, () => {
            this.navigateToNextProduct();
          });
        }
      );
    };

    handlePopupModalStopBtnClick = () => {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    };

    render() {
      const {
        steps,
        activeStepID,
        showModal,
        modal,
        showRecommend,
        recommendProduct,
        showRequired,
        requiredList,
        showModalStop,
        showSuccess,
        showSuccessDescription,
        modalAge,
        showInReview,
        showKycEnd,
      } = this.state;
      const { productList, applicantData } = this.props;
      return (
        <>
          {showRequired && (
            <PopupModal
              type={modal.type}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showRequired}
              btnText={MODALMESSAGES.PRODUCTREQUIRED.GO_TO_MARKETPLACE}
              popupBtnClick={() =>
                this.toggleModal(`${ROUTES.VAULT_DASHBOARD}?marketplace`)
              }
              requiredList={requiredList}
              productList={productList}
              addProduct={this.addProductAndProcess}
            />
          )}
          {showModal && (
            <PopupModal
              type={modal.type}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showModal}
              popupBtnClick={this.navigateToNextProduct}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
              closeBtnClick={this.handlePopupModalCloseBtnClick}
            />
          )}
          {showModalStop && (
            <PopupModal
              modalType={AppConstants.MODALTYPE.CUSTOM}
              title={modalAge.title}
              description={modalAge.description}
              showModal={showModalStop}
              popupBtnClick={() => this.handlePopupModalStopBtnClick()}
              closeBtnClick={() => this.handlePopupModalStopBtnClick()}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
            />
          )}
          {showRecommend && (
            <PopupModal
              type={modal.type}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showRecommend}
              popupBtnClick={this.showNextProductPopUporRedirectContinued}
              btnText={STRINGS.POPUPMODAL.CONTINUE}
              closeBtnClick={this.handlePopupModalCloseBtnClick}
              recommendProduct={recommendProduct}
              addProduct={this.addProductAndProcess}
              productList={productList}
            />
          )}
          {showSuccess && (
            <PopupModal
              type={AppConstants.MODALTYPE.SUCCESS}
              title={MODALMESSAGES.DISBURSEMENT.CONGRATS_TITLE.split(
                AppConstants.TEMPLATE.NAME
              ).join(applicantData.member.name)}
              description={showSuccessDescription}
              toggleModal={this.toggleModal}
              showModal={showSuccess}
              popupBtnClick={this.continueAfterSignature}
              closeBtnClick={this.continueAfterSignature}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
            />
          )}
          {showInReview && (
            <PopupModal
              type={AppConstants.MODALTYPE.SUCCESS}
              title={MODALMESSAGES.ON_REVIEW.TITLE}
              description={MODALMESSAGES.ON_REVIEW.MSG}
              toggleModal={this.toggleModal}
              showModal={showInReview}
              popupBtnClick={this.continueAfterSignature}
              closeBtnClick={this.continueAfterSignature}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
            />
          )}
          {showKycEnd && (
            <PopupModal
              type={AppConstants.MODALTYPE.SUCCESS}
              description={"User identity verified!"}
              description2={
                "Thank you! You can complete your banking application by going back to your desktop screen"
              }
              toggleModal={this.continueAfterKyc}
              showModal={showKycEnd}
              popupBtnClick={this.continueAfterKyc}
              closeBtnClick={this.continueAfterKyc}
              btnText={STRINGS.POPUPMODAL.CONTINUE}
            />
          )}
          <WrappedComponent
            getDataFromServer={(step, callback) =>
              this.getDataFromServer(step, callback)
            }
            continuehandler={(request, applicationDetails, nextStep, step) =>
              this.onContinueHandler(
                request,
                applicationDetails,
                nextStep,
                step
              )
            }
            handleBack={(step) => this.onBackHandleClick(step)}
            getNextStep={(step) => this.getNextStep(step)}
            steps={steps}
            activeStepID={activeStepID}
            showNextProductPopUporRedirect={this.showNextProductPopUporRedirect}
            getActiveProduct={this.getActiveProduct}
            navigateToNextProduct={this.navigateToNextProduct}
            removeCurrentActiveApplication={this.removeCurrentActiveApplication}
            {...this.props}
            updateVaultProductList={this.updateVaultProductList}
          />
        </>
      );
    }
  }

  HOC.propTypes = {
    doGetApplicantBankingProductList: PropTypes.func.isRequired,
    doGetApplicant: PropTypes.func.isRequired,
    doGetCompleteApplicant: PropTypes.func.isRequired,
    doGetGettingStartedData: PropTypes.func.isRequired,
    doUpdateGettingStarted: PropTypes.func.isRequired,
    doCreateNewApplicant: PropTypes.func.isRequired,
    doUpdateApplicant: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isEdit: PropTypes.bool.isRequired,
    doToggleEdit: PropTypes.func.isRequired,
    doGetAcoountDetails: PropTypes.func.isRequired,
    productList: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.string,
    ]).isRequired,
    doUpdateProductToVault: PropTypes.func.isRequired,
    processTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
    doGetProductTemplateFlow: PropTypes.func.isRequired,
    doGetProductFromVault: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    doToggleScanFlag: PropTypes.func.isRequired,
    doUpdateProductTemplate: PropTypes.func.isRequired,
    templates: PropTypes.arrayOf(PropTypes.object).isRequired,
    doGetApplicationList: PropTypes.func.isRequired,
    completedApplications: PropTypes.arrayOf(PropTypes.object),
    applicantList: PropTypes.arrayOf(PropTypes.object),
    doGetProductList: PropTypes.func.isRequired,
    doStoreFundProduct: PropTypes.func.isRequired,
    doUpdateProductInformation: PropTypes.func.isRequired,
    doSetSpousalInformation: PropTypes.func.isRequired,
    doGetProductInformation: PropTypes.func.isRequired,
    doAddBeneficiaries: PropTypes.func.isRequired,
    doGetBeneficiaries: PropTypes.func.isRequired,
    doGetSpousalInformation: PropTypes.func.isRequired,
    doAddApplicationSlot: PropTypes.func.isRequired,
    doinviteApplicationSlot: PropTypes.func.isRequired,
    doGetBundleProductRelation: PropTypes.func.isRequired,
    doAddBundleProduct: PropTypes.func.isRequired,
    doDeleteDebitCard: PropTypes.func.isRequired,
    doGetGlobalVault: PropTypes.func.isRequired,
    doGetApplicationProperty: PropTypes.func.isRequired,
    doGetApplication: PropTypes.func.isRequired,
    appProperties: PropTypes.arrayOf(PropTypes.object),
    doSetNotFirstFlag: PropTypes.func.isRequired,
    isNotFirst: PropTypes.bool.isRequired,
    doStoreCompletedProductInASession: PropTypes.func.isRequired,
    sessionCompletedProducts: PropTypes.oneOfType([PropTypes.array]).isRequired,
    applicantData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    doApplicantCreateSelf: PropTypes.func.isRequired,
    doFillApplicationSlot: PropTypes.func.isRequired,
    isDebitCardEnabled: PropTypes.bool,
    enableDebitCard: PropTypes.bool,
    applicationAppliedList: PropTypes.arrayOf(PropTypes.any).isRequired,
    doStoreDebitCard: PropTypes.func.isRequired,
    doUpdateGlobalVault: PropTypes.func.isRequired,
    doDebitCardShown: PropTypes.func.isRequired,
    inSessionJointApplicant: PropTypes.objectOf(PropTypes.any),
    doApplicantCreateRequest: PropTypes.func.isRequired,
    doSetInSessionJointApplicant: PropTypes.func.isRequired,
    onlineBankingInfo: PropTypes.objectOf(PropTypes.any),
    gettingStarted: PropTypes.objectOf(PropTypes.any),
    finInfo: PropTypes.objectOf(PropTypes.any),
    applicationCompleteList: PropTypes.arrayOf(PropTypes.object),
    loanProducts: PropTypes.oneOfType([PropTypes.array]),
    bankingProductList: PropTypes.arrayOf(PropTypes.object),
    showRequired: PropTypes.bool,
    requiredList: PropTypes.arrayOf(PropTypes.object),
    bankingType: PropTypes.string.isRequired,
    doCheckEsignDoc: PropTypes.func.isRequired,
    jointApplicationId: PropTypes.string,
    doSetOnlineBanking: PropTypes.func.isRequired,
  };

  HOC.defaultProps = {
    completedApplications: [],
    applicantList: [],
    appProperties: [],
    applicantData: "",
    isDebitCardEnabled: false,
    enableDebitCard: false,
    inSessionJointApplicant: null,
    gettingStarted: null,
    finInfo: {},
    onlineBankingInfo: {},
    applicationCompleteList: [],
    loanProducts: [],
    bankingProductList: [],
    showRequired: false,
    requiredList: [],
    jointApplicationId: "",
  };

  const mapStateToProps = (state) => ({
    inReview: state.ApplicationReducer.inReview,
    uploadedAt: state.ApplicationReducer.uploadedAt,
    isEdit: state.ApplicationReducer.isEdit,
    processTemplate: state.ApplicationReducer.processTemplate,
    products: state.VaultReducer.vaultProductList,
    templates: state.ApplicationReducer.templates,
    productList: state.MarketplaceReducer.productList,
    completedApplications: state.ApplicationReducer.applicationCompleteList,
    applicantList: state.ApplicationReducer.applicantList,
    appProperties: state.JoinApplicantReducer.appProperties,
    isNotFirst: state.ApplicationReducer.isNotFirst,
    sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
    applicantData: state.ApplicationReducer.response,
    isDebitCardEnabled: state.DebitCardReducer.isDebitCardEnabled,
    enableDebitCard: state.DebitCardReducer.enableDebitCard,
    applicationAppliedList: state.ApplicationReducer.applicationAppliedList,
    inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
    fillApplicationSlot: state.JoinApplicantReducer.fillApplicationSlot,
    onlineBankingInfo: state.OnlineBankingReducer,
    gettingStarted: state.ApplicationReducer.gettingStarted,
    applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
    finInfo: state.MarketplaceReducer.finInfo,
    fundProduct: state.VaultReducer.fundProduct,
    loanProducts: state.LoanDetailsReducer.loanProducts,
    bankingProductList: state.AccountInformationReducer.bankingProductList,
    bankingType: state.MarketplaceReducer.bankingType,
    jointApplicationId: state.JoinApplicantReducer.applicationId,
    confirmApplicationReducer: state.ConfirmApplicationReducer,
    vaultMember: state.ApplicationReducer.vaultMember,
    isQrKyc: state.ApplicationReducer.isQrKyc,
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      doGetApplicant: (payLoad, callback) =>
        dispatch(getApplicant(payLoad, callback)),
      doGetCompleteApplicant: (id, callback) =>
        dispatch(getCompleteApplicant(id, callback)),
      doGetGettingStartedData: (callback) =>
        dispatch(getGettingStartedData(callback)),
      doUpdateGettingStarted: (request, callback) =>
        dispatch(updateGettingStarted(request, callback)),
      doCreateNewApplicant: (request, callback) =>
        dispatch(doCreateApplication(request, callback)),
      doUpdateApplicant: (request, applicantId, callback) =>
        dispatch(updateApplicantData(request, applicantId, callback)),
      doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
      doDisableBack: (flag) => dispatch(disableBack(flag)),
      doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
      doUpdateProductToVault: (vaultProductList, callback) =>
        dispatch(updateProductToVault(vaultProductList, callback)),
      doUpdateVaultProductList: (vaultList, callback) =>
        dispatch(updateVaultProductList(vaultList, callback)),
      doGetProductTemplateFlow: (callback, product) =>
        dispatch(getProductTemplate(callback, product)),
      doGetProductFromVault: (callback) =>
        dispatch(getProductFromVault(callback)),
      doToggleScanFlag: (flag) => dispatch(toggleScanFlag(flag)),
      doUpdateProductTemplate: (activeProduct) =>
        dispatch(updateProductTemplate(activeProduct)),
      doGetApplicationList: (callback) =>
        dispatch(getApplicationList(callback)),
      doGetProductList: () => dispatch(getProductList()),
      doStoreFundProduct: (product, callback) =>
        dispatch(storeFundProduct(product, callback)),
      doUpdateProductInformation: (
        requestBody,
        applicationId,
        productType,
        doximProductId,
        callback
      ) =>
        dispatch(
          updateProductInformation(
            requestBody,
            applicationId,
            productType,
            doximProductId,
            callback
          )
        ),
      doSetSpousalInformation: (
        requestBody,
        applicationId,
        regidteredcontractproduct,
        reporductNo,
        callback
      ) =>
        dispatch(
          setSpousalInfo(
            requestBody,
            applicationId,
            regidteredcontractproduct,
            reporductNo,
            callback
          )
        ),
      doGetSpousalInformation: (
        applicationId,
        regidteredcontractproduct,
        reporductNo,
        callback
      ) =>
        dispatch(
          getSpousalInfo(
            applicationId,
            regidteredcontractproduct,
            reporductNo,
            callback
          )
        ),
      doGetProductInformation: (
        applicationId,
        productType,
        doximProductId,
        callback
      ) =>
        dispatch(
          getProductInformation(
            applicationId,
            productType,
            doximProductId,
            callback
          )
        ),
      doAddBeneficiaries: (
        requestBody,
        applicationId,
        contractProductId,
        callback
      ) =>
        dispatch(
          addBeneficiaries(
            requestBody,
            applicationId,
            contractProductId,
            callback
          )
        ),
      doGetBeneficiaries: (applicationId, contractProductId, callback) =>
        dispatch(getBeneficiaries(applicationId, contractProductId, callback)),
      doAddApplicationSlot: (request, callback) =>
        dispatch(addApplicationSlot(request, callback)),
      doinviteApplicationSlot: (request, callback) =>
        dispatch(inviteApplicationSlot(request, callback)),
      doGetBundleProductRelation: (activeProduct, callback) =>
        dispatch(getBundleProductRelation(activeProduct, callback)),
      doAddBundleProduct: (
        applicationId,
        bundleProductId,
        demandProductId,
        applicantId,
        callback
      ) =>
        dispatch(
          addBundleProduct(
            applicationId,
            bundleProductId,
            demandProductId,
            applicantId,
            callback
          )
        ),
      doDeleteDebitCard: (applicationId, debitProductId, callback) =>
        dispatch(deleteDebitCard(applicationId, debitProductId, callback)),
      doGetGlobalVault: (request, callback) =>
        dispatch(getGlobalVault(request, callback)),
      doGetApplicationProperty: (request, callback) =>
        dispatch(getApplicationProperty(request, callback)),
      doGetApplication: (request, callback) =>
        dispatch(getApplication(request, callback)),
      doSetNotFirstFlag: (flag) => dispatch(setNotFirstFlag(flag)),
      doStoreCompletedProductInASession: (product) =>
        dispatch(storeCompletedProductInASession(product)),
      doApplicantCreateSelf: (request, callback) =>
        dispatch(applicantCreateSelf(request, callback)),
      doFillApplicationSlot: (request, callback) =>
        dispatch(fillApplicationSlot(request, callback)),
      doStoreDebitCard: (enableDebitCard, applicationId, callback) =>
        dispatch(storeDebitCard(enableDebitCard, applicationId, callback)),
      doUpdateGlobalVault: (request, callback) =>
        dispatch(updateGlobalVault(request, callback)),
      doDebitCardShown: (flagDebitCardShown, callback) =>
        dispatch(debitCardShown(flagDebitCardShown, callback)),
      doApplicantCreateRequest: (request, callback) =>
        dispatch(applicantCreateRequest(request, callback)),
      doSetInSessionJointApplicant: (request, callback) =>
        dispatch(setInSessionJointApplicant(request, callback)),
      doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
      doGetApplicantBankingProductList: (applicantId, callback) =>
        dispatch(getApplicantBankingProductList(applicantId, callback)),
      doCheckEsignDoc: (applicantId, callback) =>
        dispatch(checkEsignDoc(applicantId, callback)),
      doSetOnlineBanking: (
        applicationId,
        debitCardProductId,
        templateId,
        callback
      ) =>
        dispatch(
          setOnlineBanking(
            applicationId,
            debitCardProductId,
            templateId,
            callback
          )
        ),
      doGetProspect: (request, callback) =>
        dispatch(getProspect(request, callback)),
      doAddApplicant: (payLoad, callback) =>
        dispatch(addApplicant(payLoad, callback)),
      doSubmitQuestionnaire: (payLoad, callback) =>
        dispatch(submitQuestionnaire(payLoad, callback)),
      doUploadQuestionnaire: (payLoad, callback) =>
        dispatch(uploadQuestionnaire(payLoad, callback)),
      doUpdateVaultMember: (member, callback) =>
        dispatch(updateVaultMember(member, callback)),
      doSetKycFlag: () => dispatch(setKycFlag()),
    };
  };

  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HOC);
}
