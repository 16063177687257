import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  TOKEN_KEY,
  HTTP_STATUS,
  SORT_ON_PRIORITY,
  LENDING_PRODUCT,
} from "Communication/Constants";

import FilterBar from "Components/Common/FilterBar/FilterBar";
import Vault from "Components/Vault/Vault";
import ProductCardContainer from "Components/Common/ProductCardContainer/ProductCardContainer";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { sortList } from "Utils/CommonUtilities";

import {
  storeFilterProduct,
  updateVaultProductList,
  updateProductToVault,
  getProductFromVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";

import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import { Modal } from "react-bootstrap";
import { getLoanProduct } from "../LoanDetails/Actions/LoanDetailsAction";
import { storeFilterBankingType } from "../MarketPlace/Action/MarketPlaceAction";
import { S_BANKING_TYPE } from "../../Communication/Constants";

class MarketPlaceLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      showModal: false,
      modal: {},
      modalType: "",
      allCheck: true,
      showProductError: false,
      showVaultLimitError: false,
      selectedBankingType: AppConstants.BANKING_TYPE.PERSONAL,
      bankingProductList: [],
      showWelcomeModal: false,
      appliedProducts: [],
    };
  }

  componentDidMount() {
    console.log("location", window.location);
    const { doStoreFilterBankingType, bankingType } = this.props;
    if (window.location.search.indexOf("productError") > -1) {
      console.log("show error");
      this.setState({ showProductError: true });
    }
    const appliedProducts = sessionStorage.getItem(
      AppConstants.SESSION.APPLIED_PRODUCTS
    );
    if (appliedProducts) {
      this.setState({
        appliedProducts,
      });
    }
    this.checkRedirectProp();
    if (bankingType) {
      this.setState({
        selectedBankingType: bankingType,
      });
    } else {
      const sessionBankingType = sessionStorage.getItem(S_BANKING_TYPE);
      console.log("session banking type", sessionBankingType);
      if (sessionBankingType) {
        doStoreFilterBankingType(sessionBankingType);
        this.setState({
          selectedBankingType: sessionBankingType,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      productList,
      redirectFrom,
      bankingType,
      finInfo,
      doStoreFilterBankingType,
    } = this.props;
    const { selectedBankingType } = this.state;

    if (bankingType && bankingType !== selectedBankingType) {
      this.setState({
        selectedBankingType: bankingType,
      });
    }

    if (prevProps.productList !== productList) {
      this.updateBankProductList();
    }
    console.log("redirectFrom", redirectFrom);
    if (prevProps.redirectFrom !== redirectFrom) {
      this.checkRedirectProp();
    }
  }

  welcomeModalWindow = (param) => {
    this.setState({ showWelcomeModal: param });
  };

  setAllCheck = (allCheck) => {
    this.setState({ allCheck });
  };

  handleAddToVault = (cardItem) => {
    const {
      vaultProductList,
      doGetProductFromVault,
      loanProducts,
      doGetLoanProduct,
    } = this.props;
    console.log("handleAddToVault", cardItem);
    const accessToken = sessionStorage.getItem(TOKEN_KEY);

    if (accessToken) {
      doGetProductFromVault((response) => {
        if (loanProducts && loanProducts.length > 0) {
          if (!response || response.data === "") {
            this.addToVaultWithProductExistCheck([], cardItem);
          } else {
            this.addToVaultWithProductExistCheck([...response?.data], cardItem);
          }
        } else {
          doGetLoanProduct(() => {
            if (!response || response.data === "") {
              this.addToVaultWithProductExistCheck([], cardItem);
            } else {
              this.addToVaultWithProductExistCheck(
                [...response?.data],
                cardItem
              );
            }
          });
        }
      });
    } else {
      this.addToVaultWithProductExistCheck([...vaultProductList], cardItem);
    }
  };

  addToVaultWithProductExistCheck = (vaultCards, cardItem) => {
    const { selectCard, showModal } = this.state;
    const {
      loanProducts,
      finInfo,
      doUpdateVaultProductList,
      doUpdateProductToVault,
      bankingType,
      applicantData,
    } = this.props;
    if (vaultCards && vaultCards.length >= finInfo.vaultLimit) {
      this.setState({
        showVaultLimitError: true,
      });
    }

    const selectedProduct = cardItem || selectCard;
    console.log("selectedProduct", selectedProduct, this.props);
    const product = {
      ...selectedProduct,
      // don't store product details as it makes the data too big
      productDetails: null,
      active: false,
      applicantId: null,
      applicationId: null,
      applicationStep: {
        index: null,
        step: "",
      },
      bankingType,
    };
    const rand = Math.floor(Math.random() * 1000);
    product.productIndex = rand;

    const valueIndex = vaultCards.findIndex(
      (elem) => elem.productId === selectedProduct.productId
    );

    if (product.type === LENDING_PRODUCT) {
      const loanProduct = loanProducts.find(
        (item) => product.productId === item.id
      );
      if (loanProduct) {
        product.category = loanProduct.category;
        product.subCategory = loanProduct.subCategory;
        product.productType = loanProduct.productType;
        product.productCode = loanProduct.productCode;
        product.cutoff = loanProduct.cutoff;
        product.applicationType = loanProduct.applicationType;
      }
    } else {
      product.category = "";
      product.subCategory = "";
    }
    if (applicantData?.isSoleProprietor) {
      const soleTemplateId = finInfo.config.businessTypes?.find(
        (x) => x.code === finInfo.soleBusinessType
      )?.templateId;
      if (soleTemplateId) {
        product.templateId = soleTemplateId;
      }
    }
    if (valueIndex !== -1) {
      const modal = {};
      modal.title = STRINGS.MARKETPLACE.BODY.PRODUCT_ADDED;
      modal.description = STRINGS.MARKETPLACE.BODY.MULTIPLE_PRODUCT_ADD;

      this.setState({
        showModal: !showModal,
        modal,
        modalType: AppConstants.MODALTYPE.CUSTOM,
      });
    }
    vaultCards.push(product);
    let sortedVaultCards = sortList(vaultCards, SORT_ON_PRIORITY);

    // move in progress applications to first
    const cardNotStarted = sortedVaultCards.filter(
      (x) => x.applicationStep.index == null
    );
    const cardStarted = sortedVaultCards.filter(
      (x) => x.applicationStep.index != null
    );

    sortedVaultCards = [...cardStarted, ...cardNotStarted];

    const accessToken = sessionStorage.getItem(TOKEN_KEY);
    if (accessToken) {
      doUpdateProductToVault(sortedVaultCards, () => {});
    } else {
      doUpdateVaultProductList(sortedVaultCards);
    }
  };

  onSearchChangeHandler = (event) => {
    const searchInput = event.target?.value;
    const { categoryList, productList } = this.props;
    const { doStoreFilterProduct } = this.props;
    this.setState({ searchInput });
    const checkValue = !searchInput;
    const resultcategoryList = categoryList.map((elem) => {
      const checkbox = { ...elem };
      checkbox.checked = checkValue;
      return checkbox;
    });
    this.setAllCheck(checkValue);
    const resultProductList = productList.map((item) => {
      const productListItem = { ...item };
      if (searchInput) productListItem.show = false;
      productListItem.products = productListItem.products.map((object) => {
        const product = { ...object };
        if (searchInput) {
          if (
            product.productName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            productListItem.title
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          ) {
            productListItem.show = true;
          }
        } else {
          productListItem.show = true;
        }
        return product;
      });
      return productListItem;
    });
    doStoreFilterProduct(resultProductList, resultcategoryList);
  };

  closeWelcomeModal = () => {
    this.setState({ showWelcomeModal: false });
  };

  toggleModal = () => {
    const { showModal } = this.state;
    const modal = {};
    this.setState({ showModal: !showModal, modal });
  };

  toggleProductErrorModal = () => {
    const { showProductError } = this.state;
    this.setState({ showProductError: !showProductError });
  };

  toggleVaultLimitErrorModal = () => {
    const { showVaultLimitError } = this.state;
    this.setState({ showVaultLimitError: !showVaultLimitError });
  };

  setBankingType = (bankingType) => {
    console.log("setBankingType", bankingType);
    this.setState({ selectedBankingType: bankingType }, () => {
      this.updateBankProductList();
    });
  };

  checkRedirectProp() {
    const { redirectFrom } = this.props;
    console.log("checkRedirectProp", redirectFrom);
    const appliedProducts = sessionStorage.getItem(
      AppConstants.SESSION.APPLIED_PRODUCTS
    );
    if (redirectFrom && appliedProducts) {
      console.log("showing welcome modal", appliedProducts);
      this.setState(
        { selectedBankingType: redirectFrom, appliedProducts },
        () => {
          this.welcomeModalWindow(true);
        }
      );
    }
    sessionStorage.removeItem(AppConstants.SESSION.APPLIED_PRODUCTS);
  }

  buildRequiredProduct(requiredProducts) {
    const { productList, loanProducts, bankingType } = this.props;
    const vaultCards = [];
    const rProducts = [];
    console.log("buildRequiredProduct", requiredProducts, productList);

    productList.forEach((raw) => {
      const products = raw.products ? raw.products : [];
      rProducts.push(...products);
    });

    console.log("buildRequiredProduct", requiredProducts, rProducts);

    requiredProducts.forEach((rawId) => {
      const selectedProduct = rProducts.find(
        (item) => rawId === item.productId
      );
      console.log("buildRequiredProduct", selectedProduct);

      const product = {
        ...selectedProduct,
        // don't store product details as it makes the data too big
        productDetails: null,
        active: false,
        applicantId: null,
        applicationId: null,
        applicationStep: {
          index: null,
          step: "",
        },
        bankingType,
      };
      const rand = Math.floor(Math.random() * 1000);
      product.productIndex = rand;

      if (product.type === LENDING_PRODUCT) {
        const loanProduct = loanProducts.find(
          (item) => product.productId === item.id
        );
        product.category = loanProduct.category;
        product.subCategory = loanProduct.subCategory;
        product.productType = loanProduct.productType;
        product.productCode = loanProduct.productCode;
        product.cutoff = loanProduct.cutoff;
        product.applicationType = loanProduct.applicationType;
      } else {
        product.category = "";
        product.subCategory = "";
      }
      vaultCards.push(product);
    });

    console.log("buildRequiredProduct", vaultCards);
    return vaultCards;
  }

  updateBankProductList() {
    const { productList } = this.props;
    console.log("updateBankProductList", productList);
    const { selectedBankingType } = this.state;
    const bankingProductList = productList.filter(
      (item) =>
        item.show &&
        (item.type === selectedBankingType ||
          (selectedBankingType === AppConstants.BANKING_TYPE.PERSONAL &&
            !item.type))
    );
    console.log(bankingProductList);
    this.setState({
      bankingProductList,
    });
  }

  render() {
    const { openRegistration, openSignIn } = this.props;
    const {
      searchInput,
      showModal,
      modal,
      modalType,
      allCheck,
      showProductError,
      showVaultLimitError,
      selectedBankingType,
      bankingProductList,
      showWelcomeModal,
      appliedProducts,
    } = this.state;
    return (
      <>
        {showModal && (
          <PopupModal
            modalType={modalType}
            title={modal.title}
            description={modal.description}
            showModal={showModal}
            toggleModal={this.toggleModal}
          />
        )}
        {showProductError && (
          <PopupModal
            type={AppConstants.MODALTYPE.FAILURE}
            title={STRINGS.APPLY_PRODUCT.INVALID_PRODUCT}
            description=""
            toggleModal={this.toggleProductErrorModal}
            showModal={showProductError}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showVaultLimitError && (
          <PopupModal
            type={AppConstants.MODALTYPE.FAILURE}
            title={STRINGS.MARKETPLACE.ERROR.LIMIT.TITLE}
            description={STRINGS.MARKETPLACE.ERROR.LIMIT.MESSAGE}
            toggleModal={this.toggleVaultLimitErrorModal}
            showModal={showVaultLimitError}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <Modal
          show={showWelcomeModal}
          onHide={() => {
            this.closeWelcomeModal();
          }}
          className="[ popup-modal__container ]"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            className="[ popup-modal__header ]"
            closeButton={this.closeWelcomeModal}
          />
          <Modal.Title>
            <center>
              {STRINGS.FISITE_REDIRECT.TITLE.split(
                AppConstants.TEMPLATE.PRODUCT
              ).join(appliedProducts)}
            </center>
          </Modal.Title>
          <Modal.Body className="[ text-left ] [ overflow-auto ] [ iframe-popup-modal__body ]">
            <div className="[ row ]">
              <div className="[ col-12 ]  [ popup-modal-message__description-container ]">
                <p className="[ welcome-text welcome-text-1 ]">
                  {STRINGS.FISITE_REDIRECT.DESCRIPTION1.split(
                    AppConstants.TEMPLATE.PRODUCT
                  ).join(appliedProducts)}
                </p>
                <p className="[ welcome-text welcome-text-2 ]">
                  {STRINGS.FISITE_REDIRECT.DESCRIPTION2.split(
                    AppConstants.TEMPLATE.PRODUCT
                  ).join(appliedProducts)}
                </p>
                <p className="[ welcome-text welcome-text-3 ]">
                  {STRINGS.FISITE_REDIRECT.DESCRIPTION3.split(
                    AppConstants.TEMPLATE.PRODUCT
                  ).join(appliedProducts)}
                </p>
                <p className="[ welcome-text welcome-text-4 ]">
                  {STRINGS.FISITE_REDIRECT.DESCRIPTION4.split(
                    AppConstants.TEMPLATE.PRODUCT
                  ).join(appliedProducts)}
                </p>
              </div>
            </div>
            <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <button
                      className="[ btn submit-btn ] [ w-100 ]"
                      type="button"
                      onClick={openRegistration}
                    >
                      {STRINGS.FISITE_REDIRECT.REGISTERBTN}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
            <div className="[ row ]">
              <div className="[ col-12 ]  [ popup-modal-message__description-container ]">
                <p className="[ welcome-text welcome-text-5 ]">
                  {STRINGS.FISITE_REDIRECT.DESCRIPTION5.split(
                    AppConstants.TEMPLATE.PRODUCT
                  ).join(appliedProducts)}
                </p>
              </div>
            </div>
            <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <button
                      className="[ btn submit-btn ] [ w-100 ]"
                      type="button"
                      onClick={openSignIn}
                    >
                      {STRINGS.FISITE_REDIRECT.SIGNINBTN}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal.Body>
          <Modal.Footer className="[ iframe-popup-modal-footer ]" />
        </Modal>

        <div className="[ row ]">
          <FilterBar
            setAllCheck={this.setAllCheck}
            allCheck={allCheck}
            selectedBankingType={selectedBankingType}
            setBankingType={this.setBankingType}
          />

          <div className="[ col-xl-7 col-12 ]">
            <div className="[ product-gallery ]">
              <div className="[ vault-header__container ]">
                <h4 className="[ form-subtitle ]">
                  {AppConstants.BANKING_TYPE.DESCRIPTION[selectedBankingType]}
                </h4>
              </div>
              <div className="[ form-group has-search ]">
                <span className="[ fa fa-search form-control-feedback ]" />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={this.onSearchChangeHandler}
                  value={searchInput}
                  aria-label="Search for..."
                  autoComplete="off"
                  spellCheck="false"
                  aria-autocomplete="list"
                />
              </div>

              <ProductCardContainer
                bankingProductList={bankingProductList}
                handleAddToVault={this.handleAddToVault}
              />
            </div>
          </div>
          <div className="[ col-xl-3 ] [ d-none d-xl-block ]">
            <div className="[ summary ]">
              <Vault showProceedBtn bankingType={selectedBankingType} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

MarketPlaceLayout.propTypes = {
  doUpdateVaultProductList: PropTypes.func,
  doStoreFilterProduct: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doGetProductFromVault: PropTypes.func,
  doGetLoanProduct: PropTypes.func,
  redirectFrom: PropTypes.string,
  openSignIn: PropTypes.func,
  openRegistration: PropTypes.func,
  loanProducts: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  bankingType: PropTypes.string.isRequired,
};

MarketPlaceLayout.defaultProps = {
  doUpdateVaultProductList: () => {},
  doStoreFilterProduct: () => {},
  doUpdateProductToVault: () => {},
  doGetProductFromVault: () => {},
  doGetLoanProduct: () => {},
  categoryList: [],
  productList: [],
  vaultProductList: [],
  redirectFrom: AppConstants.BANKING_TYPE.PERSONAL,
  openSignIn: () => {},
  openRegistration: () => {},
  loanProducts: [],
  finInfo: {},
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
  productList: state.MarketplaceReducer.productListFiltered,
  categoryList: state.MarketplaceReducer.categoryListFiltered,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  finInfo: state.MarketplaceReducer.finInfo,
  bankingType: state.MarketplaceReducer.bankingType,
  applicantData: state.ApplicationReducer.response,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStoreFilterProduct: storeFilterProduct,
      doUpdateVaultProductList: updateVaultProductList,
      doUpdateProductToVault: updateProductToVault,
      doGetProductFromVault: getProductFromVault,
      doGetLoanProduct: getLoanProduct,
      doStoreFilterBankingType: storeFilterBankingType,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceLayout);
