import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  toggleBackFlag,
  getApplication,
  getContract,
  updateApplication,
  updateContract,
} from "Redux/Actions/ApplicationAction";
import STRINGS from "../../Constants/Strings";
import {
  HTTP_STATUS,
  S_EMAIL,
  S_INSTNT_TXN_ID,
  S_MSISDN,
  S_SSO,
} from "../../Communication/Constants";
import AppConstants from "../../Constants/AppConstants";
import withApplicantHOC from "../../Hoc/ApplicantHOC/ApplicantHOC";
import JointApplicant from "./JointApplicant";
import getAccount from "../../Redux/Actions/GetAccountAction";
import {
  getProductFromVault,
  updateProductToVault,
} from "../MarketPlace/Action/MarketPlaceAction";
import {
  getApplicantType,
  addGlobalVault,
  getApplicationProperty,
  deleteApplicationSlot,
  setInSessionJointApplicant,
  addJointApplicants,
} from "./Actions/JointApplicantAction";
import {
  getProductIdByAge,
  getProductIdInActiveProduct,
  isNewMember,
  showJoint,
} from "../../Utils/LogicUtilities";
import {
  getJointMemberships,
  getSingularMemberships,
  isScanningAllowed,
} from "../../Utils/CommonUtilities";
import Membership from "../Membership/Membership";
import membership from "../Membership/Membership";
import { searchMember } from "../../Redux/Actions/ApplicantAction";

class JointApplicantContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      jointAccounts: [],
      jointExistingAccounts: [],
      jointInSession: false,
      inSessionGlobalVault: [],
      ownershipList: [],
      ownership: null,
      survivorList: [],
      survivor: null,
      origUdfList: {},
      ssoLogin: false,
      showSingularMembership: false,
      showJointMembeership: false,
      membership: null,
      jointMembershipSelected: false,
      addJointMembers: false,
      enterJointMembers: false,
      dataIssue: null,
      jointMembership: [],
      singularMembership: [],
      selectedApplicantId: null,
    };
  }

  componentDidMount() {
    const {
      doGetApplicantType,
      handleBack,
      getActiveProduct,
      products,
      actionBack,
      continuehandler,
      finInfo,
      doGetApplication,
      doGetContract,
      doGetAccountDetails,
      applicantData,
      applicationCompleteList,
    } = this.props;
    console.log("JointApplicantContiner mount", actionBack);
    const sso = sessionStorage.getItem(S_SSO);
    if (sso === "yes") {
      this.setState({ ssoLogin: true });
    }
    const activeProduct = getActiveProduct(products);
    if (
      showJoint(finInfo, applicantData, applicationCompleteList, activeProduct)
    ) {
      doGetAccountDetails();
      doGetApplicantType();
      this.getJointApplicants();
      if (activeProduct.applicationId) {
        doGetContract(
          activeProduct.applicationId,
          activeProduct.type,
          activeProduct.doximProductId,
          null,
          (res) => {
            console.log("contract", res);
            if (res) {
              if (
                activeProduct.type === AppConstants.PRODUCT_TYPE.LENDING_PRODUCT
              ) {
                this.setState({
                  // ownership: res.data.intendedUse,
                  survivor: res.data.lpudfDetails.udf5,
                  origUdfList: res.data.lpudfDetails,
                });
              } else {
                this.setState({
                  ownership: res.data.intendedUse,
                  survivor: res.data.udfList?.udf5,
                  origUdfList: res.data.udfList,
                });
              }
            }
          }
        );
      }
    } else if (actionBack) {
      handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
    } else {
      continuehandler(null, AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { finInfo } = this.props;
    if (prevProps.finInfo !== finInfo) {
      this.setState({
        ownershipList: finInfo.config?.jointOwnership,
        survivorList: finInfo.config?.jointSurvivorship,
      });
    }
  }

  getJointApplicants = () => {
    const {
      doGetApplicationProperty,
      getActiveProduct,
      products,
      finInfo,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (
      finInfo?.config?.jointOwnership &&
      finInfo?.config?.jointOwnership.length > 0
    ) {
      this.setState({
        ownershipList: finInfo.config.jointOwnership,
      });
    }
    if (
      finInfo?.config?.jointSurvivorship &&
      finInfo?.config?.jointSurvivorship.length > 0
    ) {
      this.setState({
        survivorList: finInfo.config.jointSurvivorship,
      });
    }
    doGetApplicationProperty(
      { applicationId: activeProduct.applicationId },
      (res) => {
        const jointAccounts = res.slots?.filter(
          (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
        );
        if (res.slots?.length > 1) {
          this.setState({
            jointAccounts,
            jointInSession: res.slots[1].extra.inSession, // all joints has the same value
            page: 2,
          });
        }
        return jointAccounts;
      }
    );
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  handleChange = (event) => {
    console.log(
      "handleChange",
      event.currentTarget.name,
      event.currentTarget.value
    );
    if (event.currentTarget.name === "ownership") {
      this.setState({
        ownership: event.currentTarget.value,
      });
    }
    if (event.currentTarget.name === "survivor") {
      this.setState({
        survivor: event.currentTarget.value,
      });
    }
    if (event.currentTarget.name === "membership") {
      this.setState({
        membership: event.currentTarget.value,
      });
    }
  };

  handleCreateApplicationWithSelectedMembership = (param) => {
    const { selectedApplicantId } = this.state;
    console.log("handleCreateApplicationWithSelectedMembership");
    if (selectedApplicantId) {
      this.handleCreateNewMembership(param, selectedApplicantId);
    } else {
      console.error("no selected applicant");
    }
  };

  handleCreateNewMembership = (param, applicantId) => {
    const {
      products,
      doCreateNewApplicant,
      applicantData,
      doGetBundleProductRelation,
      doUploadQuestionnaire,
      getActiveProduct,
      updateVaultProductList,
      doGetCompleteApplicant,
      doUpdateApplicant,
      doUpdateProductToVault,
      finInfo,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    let bundleProductId = activeProduct.productId;
    if (activeProduct.productList && activeProduct.productList.length > 0) {
      bundleProductId = getProductIdByAge(
        applicantData.member.dob,
        activeProduct.productList
      );
    }
    doCreateNewApplicant(
      {
        applicantId,
        createNewApplicant: !applicantId,
        firstName: applicantData.member.name,
        middleName: applicantData.member.middleName,
        surname: applicantData.member.surname,
        dateOfBirth: applicantData.member.dob,
        city: applicantData.address.currentAddressCity,
        state: applicantData.address.currentAddressProvince,
        postalCode: applicantData.address.currentAddressPostalCode,
        fingerprint:
          activeProduct.fingerprint ||
          param.fingerprint ||
          window.instnt.fingerprint_txt,
        branch: applicantData.member.branch,
        bundleProductId,
        address: applicantData.address.currentAddressLine1,
        currentAddressUnitType: applicantData.address.currentAddressUnitType,
        currentAddressUnitNumber:
          applicantData.address.currentAddressUnitNumber,
        currentAddressStreetType:
          applicantData.address.currentAddressStreetType,
        currentAddressStreetDir: applicantData.address.currentAddressStreetDir,
        currentAddressStreetNumber:
          applicantData.address.currentAddressStreetNumber,
        currentAddressStreetName:
          applicantData.address.currentAddressStreetName,
        reasonOpened: applicantData.member.reasonOpened,
        instntTxnId:
          activeProduct.instntTxnId || sessionStorage.getItem(S_INSTNT_TXN_ID),
        mailingSameAsCivic: applicantData.address.mailingSameAsCivic,
        benefit: applicantData.member.benefit,
        rep: applicantData.member.rep,
        idType1: applicantData.member.idType1,
        idCountry1: finInfo.idCountry1,
        idDetail1: applicantData.member.idDetail1,
        idProvince1: applicantData.member.idProvince1,
        expiryDate1: applicantData.member.expiryDate1,
        issueDate1: applicantData.member.issueDate1,
        citizenship: applicantData.regulatory.citizenship,
        mailStmt: applicantData.regulatory.mailStmt,
        eStmt: applicantData.regulatory.eStmt,
        mailingPreferenceStatement:
          applicantData.contact.mailingPreferenceStatement,
        creditCheckConsentType: applicantData.member.creditCheckConsentType,
        useAddressCellPhoneOnly: applicantData.address.useAddressCellPhoneOnly,
        referralCode: "",
        placeOfIssuance1: applicantData.member.placeOfIssuance1,
        doVerification: true,
        email: sessionStorage.getItem(S_EMAIL),
        msisdn: sessionStorage.getItem(S_MSISDN),
        addressLine3: applicantData.address.currentAddressLine3,
        mailingAddressLineType1: applicantData.address.mailingAddressLineType1,
        mailingAddressLine1: applicantData.address.mailingAddressLine1,
        mailingAddressLine2: applicantData.address.mailingAddressLine2,
        mailingAddressLine3: applicantData.address.mailingAddressLine3,
        mailingCity: applicantData.address.mailingCity,
        mailingProvince: applicantData.address.mailingProvince,
        mailingPostalCode: applicantData.address.mailingPostalCode,
        mailingCountry: applicantData.address.mailingCountry,
        mailingUnitNumber: applicantData.address.mailingUnitNumber,
        mailingUnitType: applicantData.address.mailingUnitType,
        mailingStreetType: applicantData.address.mailingStreetType,
        mailingStreetDir: applicantData.address.mailingStreetDir,
        mailingStreetNumber: applicantData.address.mailingStreetNumber,
        mailingStreetName: applicantData.address.mailingStreetName,
      },
      (response) => {
        if (response.status === HTTP_STATUS.OK) {
          const updateRequest = {
            address: applicantData.address,
            employment: applicantData.employment,
            regulatory: applicantData.regulatory,
          };
          if (!applicantId) {
            updateRequest.member = {
              intendedUseOfMembership:
                applicantData.member.intendedUseOfMembership,
            };
          }
          doUpdateApplicant(updateRequest, response.data.applicantId, () => {
            doGetCompleteApplicant(response.data.applicantId, () => {
              const updatedVaultProductList = products;
              const activeProduct = getActiveProduct(updatedVaultProductList);
              const idx = updatedVaultProductList.findIndex(
                (x) => x === activeProduct
              );

              updatedVaultProductList[idx].applicationId =
                response.data.applicationId;
              updatedVaultProductList[idx].applicantId =
                response.data.applicantId;
              if (updatedVaultProductList[0].questionnaire) {
                doUploadQuestionnaire({
                  ...updatedVaultProductList[0].questionnaire,
                  applicationId: response.data.applicationId,
                });
              }
              if (
                response.data.doximProducts &&
                response.data.doximProducts.length !== 0
              ) {
                if (response.data.doximProducts.length === 1) {
                  updatedVaultProductList[idx].doximProductId =
                    response.data.doximProducts[idx].id;
                } else {
                  // search registered contract
                  const registeredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type ===
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (registeredContract) {
                    updatedVaultProductList[0].contractProductId = registeredContract
                      ? registeredContract.id
                      : null;
                  }
                  const nonRegisteredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type !==
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (nonRegisteredContract) {
                    updatedVaultProductList[0].doximProductId =
                      nonRegisteredContract.id;
                  } else {
                    updatedVaultProductList[idx].doximProductId =
                      response.data.doximProducts[idx].id;
                    console.error("UNHANDLED MULTI PRODUCTS", response.data);
                  }
                }
              }

              doGetBundleProductRelation(
                {
                  bundleProductId: getProductIdInActiveProduct(
                    updatedVaultProductList[idx],
                    applicantData
                  ),
                  demandProductId: updatedVaultProductList[idx].productId,
                  applicationId: updatedVaultProductList[idx].applicationId,
                },
                (getBundleProductRelationRes) => {
                  if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
                    console.log(
                      "getBundleProductRelationRes",
                      getBundleProductRelationRes
                    );
                    updatedVaultProductList[idx].bundleProducts =
                      getBundleProductRelationRes.data;
                    doUpdateProductToVault(updatedVaultProductList, () => {
                      this.processJoints(param);
                    });
                  }
                }
              );
            });
          });
        }
      }
    );
  };

  toggleJointSelection = (choice) => {
    console.log("toggleJointSelection", choice);
    this.setState({ jointMembershipSelected: choice });
    if (!choice) {
      this.setState({ addJointMembers: false, enterJointMembers: false });
    }
  };
  handleContinue = (param) => {
    const {
      continuehandler,
      products,
      doDeleteApplicationSlot,
      getActiveProduct,
      doUpdateContract,
      finInfo,
      doGetApplicationProperty,
      membershipsData,
      doCreateNewApplicant,
      applicantData,
      doGetBundleProductRelation,
      doUploadQuestionnaire,
      applicationCompleteList,
    } = this.props;
    const {
      applicants,
      isApplicantPresent,
      isAddApplicant,
      applicationId,
      applicantId,
      jointAppVaultProducts,
    } = param;
    const {
      jointAccounts, // old list of joint
      jointInSession, // old settings
      inSessionGlobalVault,
      ownership,
      survivor,
      origUdfList,
      membership,
      jointMembershipSelected,
      addJointMembers,
      page,
    } = this.state;

    console.log(
      "handleContinue",
      jointMembershipSelected,
      addJointMembers,
      membership,
      page
    );
    console.log("applicants", applicants);
    console.log("isApplicantPresent", isApplicantPresent);
    console.log(products);
    console.log("jointAccounts", jointAccounts);
    console.log("isAddApplicant", isAddApplicant);
    console.log("ownership", ownership);
    console.log("addJointMembers", addJointMembers);
    console.log("membership", membership);

    const activeProduct = getActiveProduct(products);
    console.log("activeProduct", activeProduct);
    if (page === 1) {
      if (finInfo.jointMembership) {
        if (jointMembershipSelected) {
          const jointMembership = getJointMemberships(
            membershipsData,
            applicationCompleteList
          );

          this.setState({
            addJointMembers: true,
            page: 2,
            enterJointMembers: activeProduct.applicationId
              ? true
              : jointMembership.length === 0,
            jointMembership,
          });
        } else {
          const singularMembership = getSingularMemberships(
            membershipsData,
            applicationCompleteList
          );
          if (singularMembership.length > 0) {
            console.log("showing singular", singularMembership);
            this.setState({
              showSingularMembership: true,
              singularMembership,
              page: 2,
            });
          } else {
            if (activeProduct.applicationId) {
              this.processJoints(param);
            } else {
              this.handleCreateNewMembership(param);
            }
          }
        }
      } else {
        this.processJoints(param);
      }
    } else {
      if (finInfo.jointMembership) {
        //always going here as page 2 is for jointMembership
        if (activeProduct.applicationId) {
          this.processJoints(param);
        } else {
          if (membership) {
            if (membership === "NEW") {
              this.handleCreateNewMembership(param);
            } else {
              this.handleCreateApplicationWithSelectedMembership(param);
            }
          } else {
            console.error("membership not selected");
          }
        }
      } else {
        this.processJoints(param);
      }
    }
  };

  processJoints = (param) => {
    const {
      continuehandler,
      products,
      doDeleteApplicationSlot,
      getActiveProduct,
      doUpdateContract,
      finInfo,
      doGetApplicationProperty,
      membershipsData,
      doCreateNewApplicant,
      applicantData,
      doGetBundleProductRelation,
      doUploadQuestionnaire,
    } = this.props;
    const {
      applicants,
      isApplicantPresent,
      isAddApplicant,
      applicationId,
      applicantId,
      jointAppVaultProducts,
    } = param;
    const {
      jointAccounts, // old list of joint
      jointInSession, // old settings
      inSessionGlobalVault,
      ownership,
      survivor,
      origUdfList,
      membership,
      jointMembershipSelected,
      addJointMembers,
    } = this.state;

    console.log(
      "processJoints",
      jointMembershipSelected,
      addJointMembers,
      membership
    );
    console.log("applicants", applicants);
    console.log("isApplicantPresent", isApplicantPresent);
    console.log(products);
    console.log("jointAccounts", jointAccounts);
    console.log("isAddApplicant", isAddApplicant);
    console.log("ownership", ownership);

    const activeProduct = getActiveProduct(products);

    doGetApplicationProperty(
      { applicationId: activeProduct.applicationId },
      (res) => {
        console.log(res);
        if (res.created) {
          if (isAddApplicant) {
            this.addApplicants(
              activeProduct.applicantId,
              activeProduct.applicationId,
              applicants,
              isApplicantPresent,
              jointAppVaultProducts,
              jointInSession
            );
            if (
              activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT
            ) {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: ownership,
                  demandProductUDFDetails: {
                    ...origUdfList,
                    udf5: survivor,
                  },
                }
              );
            } else {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: ownership,
                  udfList: {
                    ...origUdfList,
                    udf5: survivor,
                  },
                }
              );
            }
          } else {
            // delete all joint since joint is set to NO
            jointAccounts.forEach((joint) => {
              doDeleteApplicationSlot({
                applicationId: activeProduct.applicationId,
                slotId: joint.id,
              });
            });
            if (
              activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT
            ) {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: finInfo.singleSignerIntendedUse,
                  demandProductUDFDetails: {
                    ...origUdfList,
                    udf5: "",
                  },
                },
                () => {
                  continuehandler(
                    inSessionGlobalVault,
                    AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
                  );
                }
              );
            } else {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: finInfo.singleSignerIntendedUse,
                  udfList: {
                    ...origUdfList,
                    udf5: "",
                  },
                },
                () => {
                  continuehandler(
                    inSessionGlobalVault,
                    AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
                  );
                }
              );
            }
          }
        }
      }
    );
  };

  handleBack = () => {
    const { handleBack, doToggleBackFlag } = this.props;
    doToggleBackFlag(true);
    handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
  };

  togglePage = (value) => {
    if (value === 1) {
      this.setState({
        addJointMembers: false,
        page: value,
        showSingularMembership: false,
      });
    } else {
      this.setState({ page: value });
    }
  };

  addToGlobalVault(
    raw,
    jointAppVaultProducts,
    jointType,
    applicantId,
    applicationId
  ) {
    console.log("addToGlobalVault", raw);
    const { doAddGlobalVault, applicantData } = this.props;
    const validMobileNo =
      AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
      raw.mobilePhone.replace(
        AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
        ""
      );
    const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${validMobileNo}`;
    if (jointType === STRINGS.JOIN_APPLICANT.IN_SESSION) {
      const inSessionVault = {
        type: jointType,
        vaultProducts: jointAppVaultProducts,
        mainApplicantId: applicantId,
        mailingPreferenceStatement:
          applicantData.contact.mailingPreferenceStatement,
        reasonOpened: applicantData.member.reasonOpened,
        jointApplicants: {
          email: raw.emailAddress,
          msisdn: validMobileNo,
          status: STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING,
          signer: raw.signer,
        },
      };
      this.state.inSessionGlobalVault.push(inSessionVault);
      return;
    }

    console.log(vKey);
    const isMobile = isScanningAllowed();
    jointAppVaultProducts[0].slotId = raw.id;
    jointAppVaultProducts[0].branch = applicantData.member.branch;
    jointAppVaultProducts[0].eStmt = applicantData.regulatory.eStmt;
    jointAppVaultProducts[0].mailStmt = applicantData.regulatory.mailStmt;
    jointAppVaultProducts[0].mailingPreferenceStatement =
      applicantData.contact.mailingPreferenceStatement;
    jointAppVaultProducts[0].reasonOpened = applicantData.member.reasonOpened;
    jointAppVaultProducts[0].debitProductId = null;
    jointAppVaultProducts[0].enableDebitCard = false;
    jointAppVaultProducts[0].isDesktop =
      !isMobile && isNewMember(applicantData);
    doAddGlobalVault(
      {
        vaultKey: vKey,
        body: {
          type: jointType,
          vaultProducts: jointAppVaultProducts,
          // eslint-disable-next-line object-shorthand
          applicantId: applicantId,
          // eslint-disable-next-line object-shorthand
          jointApplicants: {
            email: raw.emailAddress,
            msisdn: validMobileNo,
            status: STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING,
            signer: raw.signer,
          },
        },
      },
      (globalVaultRes) => {
        console.log(globalVaultRes);
      }
    );
  }

  addApplicants(
    applicantId,
    applicationId,
    applicants,
    isApplicantPresent,
    jointAppVaultProducts,
    oldJointInSession
  ) {
    console.log("addApplicants");
    const {
      doDeleteApplicationSlot,
      doAddJointApplicants,
      continuehandler,
      doGetApplicationProperty,
      products,
      getActiveProduct,
    } = this.props;
    const { jointAccounts, inSessionGlobalVault } = this.state;
    const activeProduct = getActiveProduct(products);
    let jointType = STRINGS.JOIN_APPLICANT.IN_SESSION;

    if (isApplicantPresent !== "" && !isApplicantPresent) {
      jointType = STRINGS.JOIN_APPLICANT.OUT_OF_SESSION;
    }

    const jointApplicantRequests = [];
    for (const joint of applicants) {
      console.log("joint", joint);
      const request = {
        applicationId,
        jointApplicants: [],
      };
      const jointApplicants = [];
      const joinInfo = {
        joinInfo: {
          joinType: joint.applicantType,
          signer: joint.signer,
        },
        extra: {
          inSession: isApplicantPresent,
          email: joint.emailAddress,
          msisdn:
            AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
            joint.mobilePhone.replace(
              AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
              ""
            ),
        },
      };
      console.log("joinInfo", joinInfo);
      jointApplicants.push(joinInfo);
      request.jointApplicants = jointApplicants;
      if (joint.id) {
        // joint would already have an id if already added
        const currentJointInfo = jointAccounts.find(
          (item) => item.id === joint.id
        );
        console.log("currentJointInfo", currentJointInfo);
        if (
          joinInfo.extra.email !== currentJointInfo.extra.email ||
          joinInfo.extra.msisdn !== currentJointInfo.extra.msisdn ||
          joinInfo.joinInfo.joinType !== currentJointInfo.joinInfo.joinType ||
          joinInfo.joinInfo.signer !== currentJointInfo.joinInfo.signer
        ) {
          doDeleteApplicationSlot({ applicationId, slotId: joint.id });
          jointApplicantRequests.push(request);
        } else if (isApplicantPresent !== oldJointInSession) {
          // we can't update the in session flag so we need to delete and add
          doDeleteApplicationSlot({ applicationId, slotId: joint.id });
          jointApplicantRequests.push(request);
        }
      } else {
        jointApplicantRequests.push(request);
      }
      this.addToGlobalVault(
        joint,
        jointAppVaultProducts,
        jointType,
        applicantId,
        applicationId
      );
    }

    // delete joints removed
    for (const joint of jointAccounts) {
      const jointExist = applicants.find((item) => item.id === joint.id);
      if (!jointExist) {
        doDeleteApplicationSlot({ applicationId, slotId: joint.id });
      }
    }

    if (jointApplicantRequests.length > 0) {
      doAddJointApplicants(jointApplicantRequests, (res) => {
        console.log(res);
        doGetApplicationProperty(
          { applicationId: activeProduct.applicationId },
          (res) => {
            const jointAccounts = res.slots.filter(
              (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
            );
            if (res.slots.length > 1) {
              this.setState({
                jointAccounts,
                jointInSession: res.slots[1].extra.inSession, // all joints has the same value
              });
            }
            const notInvited = jointAccounts.find((item) => !item.inviteDetail);
            if (notInvited) {
              // user has to fix data issue
            } else {
              continuehandler(
                inSessionGlobalVault,
                AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
              );
            }
          }
        );
      });
    } else {
      const notInvited = applicants.find((item) => !item.inviteDetail);
      if (notInvited) {
        // user has to fix data issue
      } else {
        continuehandler(
          inSessionGlobalVault,
          AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
        );
      }
    }
  }

  addJointAccount(applicationId, isApplicantPresent, request, joint) {
    console.log("addJointAccount", isApplicantPresent, request, joint);
    const {
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doGetApplicationProperty,
    } = this.props;
    doAddApplicationSlot(request, (response) => {
      if (response.status === HTTP_STATUS.OK) {
        const body = {
          email: joint.emailAddress,
          msisdn:
            AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
            joint.mobilePhone.replace(
              AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
              ""
            ),
        };
        const inviteReq = {
          // eslint-disable-next-line object-shorthand
          body: body,
          // eslint-disable-next-line object-shorthand
          applicationId: applicationId,
          slotId: response.data.slots[0].id,
        };
        if (!isApplicantPresent) {
          console.log("inviting joint", inviteReq);
          doinviteApplicationSlot(inviteReq, (inviteRes) => {
            if (inviteRes.status !== HTTP_STATUS.OK) {
              doGetApplicationProperty({ applicationId }, (res) => {
                // wait to get reducer updated
              });
            }
          });
        } else {
          doGetApplicationProperty({ applicationId }, (res) => {
            // wait to get reducer updated
          });
        }
      } else {
        // eslint-disable-next-line spaced-comment
        //hasError = false;
      }
    });
  }

  selectMembership = (event) => {
    console.log("selectMembership", event.target.value);
    const { doSearchMember, applicationCompleteList } = this.props;
    const membership = event.target.value;
    if (membership === "NEW") {
      this.setState({ membership, dataIssue: null, jointExistingAccounts: [] });
    } else if (membership) {
      const appCompleted = applicationCompleteList.find(
        (x) => x.memberNumber === membership
      );
      if (appCompleted) {
        const jointExistingAccounts = [];
        appCompleted.slots.forEach((x) => {
          if (x.type === "co") {
            jointExistingAccounts.push({
              firstName: "",
              lastName: "",
              email: x.inviteDetail.email,
              phoneNumber: x.inviteDetail.msisdn,
            });
          }
        });
        this.setState({
          membership,
          dataIssue: null,
          jointExistingAccounts,
          selectedApplicantId: appCompleted.applicantId,
        });
      } else {
        doSearchMember({ membershipNumber: membership }, (res) => {
          if (res?.data?.applicantId) {
            this.setState({
              membership,
              dataIssue: null,
              jointExistingAccounts: [],
              selectedApplicantId: res?.data?.applicantId,
            });
          } else {
            this.setState({
              membership,
              dataIssue: "Joint data issue. Applicant id missing.",
              jointExistingAccounts: [],
            });
          }
        });
      }
    } else {
      this.setState({
        membership,
        dataIssue: "Please select an option.",
        jointExistingAccounts: [],
      });
    }
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      continuehandler,
      doGetProductFromVault,
      products,
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doGetApplicantType,
      applicantTypes,
      doAddGlobalVault,
      doGetApplicationProperty,
      getActiveProduct,
      account,
      finInfo,
      membershipsData,
      applicantData,
    } = this.props;
    const {
      jointAccounts,
      jointInSession,
      ownershipList,
      ownership,
      survivorList,
      survivor,
      ssoLogin,
      showSingularMembership,
      jointMembershipSelected,
      addJointMembers,
      enterJointMembers,
      membership,
      dataIssue,
      jointMembership,
      singularMembership,
      jointExistingAccounts,
      page,
    } = this.state;
    return (
      <div>
        {!showSingularMembership && (
          <JointApplicant
            finInfo={finInfo}
            continuehandler={continuehandler}
            handleBack={this.handleBack}
            handleContinue={this.handleContinue}
            getDataFromServer={getDataFromServer}
            steps={steps}
            activeStepID={activeStepID}
            doGetProductFromVault={doGetProductFromVault}
            doAddApplicationSlot={doAddApplicationSlot}
            doinviteApplicationSlot={doinviteApplicationSlot}
            doGetApplicantType={doGetApplicantType}
            doGetApplicationProperty={doGetApplicationProperty}
            doAddGlobalVault={doAddGlobalVault}
            products={products}
            applicantTypes={applicantTypes}
            getActiveProduct={getActiveProduct}
            jointAccounts={jointAccounts}
            jointInSession={jointInSession}
            ownershipList={ownershipList}
            ownership={ownership}
            survivorList={survivorList}
            survivor={survivor}
            handleChange={this.handleChange}
            account={account}
            ssoLogin={ssoLogin}
            toggleJointSelection={this.toggleJointSelection}
            jointMembershipSelected={jointMembershipSelected}
            addJointMembers={addJointMembers}
            enterJointMembers={enterJointMembers}
            membershipsData={membershipsData}
            membership={membership}
            selectMembership={this.selectMembership}
            dataIssue={dataIssue}
            jointMembership={jointMembership}
            jointExistingAccounts={jointExistingAccounts}
            page={page}
            togglePage={this.togglePage}
            contKycFlag={!isNewMember(applicantData)}
          />
        )}
        {showSingularMembership && (
          <Membership
            steps={steps}
            activeStepID={activeStepID}
            membershipsData={membershipsData}
            membership={membership}
            selectMembership={this.selectMembership}
            handleContinue={this.handleContinue}
            dataIssue={dataIssue}
            singularMembership={singularMembership}
            handleBack={this.handleBack}
            page={page}
            togglePage={this.togglePage}
            contKycFlag={!isNewMember(applicantData)}
          />
        )}
      </div>
    );
  }
}

JointApplicantContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doAddApplicationSlot: PropTypes.func.isRequired,
  doinviteApplicationSlot: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  doGetProductFromVault: PropTypes.func.isRequired,
  doAddGlobalVault: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doDeleteApplicationSlot: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  globalVault: PropTypes.object,
  doToggleBackFlag: PropTypes.func.isRequired,
  finInfo: PropTypes.object,
  doUpdateContract: PropTypes.func.isRequired,
  applicationCompleteList: PropTypes.arrayOf(PropTypes.any),
};
JointApplicantContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  products: [],
  globalVault: {},
  finInfo: {},
  applicationCompleteList: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAccountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicantType: (callback) => dispatch(getApplicantType(callback)),
    doAddGlobalVault: (callback) => dispatch(addGlobalVault(callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doDeleteApplicationSlot: (request, callback) =>
      dispatch(deleteApplicationSlot(request, callback)),
    dosetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    doAddJointApplicants: (request, callback) =>
      dispatch(addJointApplicants(request, callback)),
    doGetApplication: (applicationId, callback) =>
      dispatch(getApplication(applicationId, callback)),
    doGetContract: (
      applicationId,
      applicationType,
      productId,
      waitMessage,
      callback
    ) =>
      dispatch(
        getContract(
          applicationId,
          applicationType,
          productId,
          waitMessage,
          callback
        )
      ),
    doUpdateContract: (
      applicationId,
      applicationType,
      productId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateContract(
          applicationId,
          applicationType,
          productId,
          payLoad,
          callback
        )
      ),
    doSearchMember: (params, callback) =>
      dispatch(searchMember(params, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantTypes: state.JoinApplicantReducer.applicantTypes,
  globalVault: state.JoinApplicantReducer.globalVault,
  applicantData: state.ApplicationReducer.response,
  finInfo: state.MarketplaceReducer.finInfo,
  account: state.AboutYouReducer.account,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  actionBack: state.ApplicationReducer.actionBack,
  membershipsData: state.ApplicationReducer.membershipsData,
});

const JointApplicantWrapper = withApplicantHOC(JointApplicantContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JointApplicantWrapper);
