import STRINGS from "Constants/Strings";
import FIInformation from "Constants/FIInformation";

const AppConstants = {
  PRODUCT_CATEGORY: {
    SAVINGS: "Savings Accounts",
    CHEQUING: "Chequing Accounts",
  },
  SAGA_WAIT: "-SAGA",
  PREFIX: {
    DOLLAR: "$",
  },
  BANKING_TYPE: {
    PERSONAL: "personal",
    BUSINESS: "business",
    DESCRIPTION: {
      personal: "Personal Banking",
      business: "Business Banking",
    },
  },
  REGISTRATION: {
    OtpTo: "msisdn",
    EXTENSION_FORMAT: "###",
    NUMBERFORMAT: "(###) ###-####",
    NUMBERPREFIX: "1",
    EMAILPATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    MOBILENOPATTERN: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    CONTACT_NO_PATTERN: /[- )(]/g,
  },
  MODALTYPE: {
    SUCCESS: "Success",
    FAILURE: "Error",
    INFORMATION: "Info",
    CUSTOM: "Custom",
    WELCOME_BACK: "Great to see you!",
    ANOTHER_PRODUCT: "You are on a roll...",
    OVERDRAFT_TERMS_AND_CONDITIONS: "Overdraft Terms and Conditions",
  },

  SIGNIN: {
    NUMBERPREFIX: "1",
    USERNAME_PATTERN: "/^[0-9]{10}$/",
    EMAILPATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  HEADER: {
    matchRoutePattern: "([w-]+)",
  },
  GETTINGSTARTED: {
    VAULT_API_BODY: {
      residencyStatus: "",
      jointAccountDetails: [],
      branch: "",
      isCardRequired: false,
      isOverdraftProtectionRequired: false,
    },
    EMAIL: "email",
    PAPER: "paper",
    EMAIL_VALUE: "electronically delivered",
    PAPER_VALUE: "printed and mail",
  },
  // Application steps key and value pair irrespective of the order.
  APPLICATIONSTEP: {
    TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
    GETTINGSTARTED: "GETTING_STARTED",
    DOC_VERIFICATION: "DOC_VERIFICATION",
    ABOUTYOU: "ABOUT_YOU",
    EMPLOYMENT: "EMPLOYMENT_AND_INCOME",
    COMPLIANCE: "COMPLIANCE",
    ACCOUNT_INFORMATION: "ACCOUNT_INFORMATION",
    CONFIRM: "CONFIRM_ACCOUNT_CREATE",
    FUND_METHOD: "FUND_METHOD",
    BENEFICIARIES: "BENEFICIARIES",
    SPOUSAL_INFO: "SPOUSAL_INFO",
    OVERDRAFT_PROTECTION: "OVERDRAFT_PROTECTION",
    DEBIT_CARD: "DEBIT_CARD",
    LOAN_DETAILS: "LOAN_DETAILS",
    PROPERTY_DETAILS: "PROPERTY_DETAILS",
    SECURITY_VEHICLE: "SECURITY_VEHICLE",
    INCOME: "INCOME",
    EXPENSE: "EXPENSE",
    ASSET: "ASSET",
    LIABILITIES: "LIABILITIES",
    DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
    FEES: "FEES",
    COST_OF_BORROWING: "COST_OF_BORROWING",
    LOAN_SUMMARY: "LOAN_SUMMARY",
    LOAN_ADJUDICATION_RESULTS: "LOAN_ADJUDICATION_RESULTS",
    BEFORE_WE_START: "BEFORE_WE_START",
    DISBURSEMENT: "DISBURSEMENT",
    REPAYMENT: "REPAYMENT",
    JOINT_APPLICANT: "JOINT_APPLICANT",
    JOINT_APPLICANT_STATUS: "JOINT_APPLICANT_STATUS",
    ONLINE_BANKING: "ONLINE_BANKING",
    SIGNATURE: "SIGNATURE",
    VERIFICATION_CODE: "VERIFICATION_CODE",
    LOAN_PURPOSE: "LOAN_PURPOSE",
    MEMBERSHIP: "MEMBERSHIP",
    BUSINESS_EXIST: "BUSINESS_EXIST",
    BUSINESS_CLIENT: "BUSINESS_CLIENT",
    BUSINESS_KYC: "BUSINESS_KYC",
    BUSINESS_TYPE: "BUSINESS_TYPE",
    BUSINESS_ELIGIBILITY: "BUSINESS_ELIGIBILITY",
    BUSINESS_START: "BUSINESS_START",
    BUSINESS_INFO_ADDRESS: "BUSINESS_INFO_ADDRESS",
    BUSINESS_INFO_MAIL: "BUSINESS_INFO_MAIL",
    BUSINESS_INFO: "BUSINESS_INFO",
    BUSINESS_LOOKUP: "BUSINESS_LOOKUP",
    BUSINESS_CONTACT: "BUSINESS_CONTACT",
    BUSINESS_DETAILS: "BUSINESS_DETAILS",
    BUSINESS_OPERATIONS: "BUSINESS_OPERATIONS",
    BUSINESS_TAX: "BUSINESS_TAX",
    BUSINESS_COMPLIANCE: "BUSINESS_COMPLIANCE",
    BUSINESS_MEMBER_COMPLIANCE: "BUSINESS_MEMBER_COMPLIANCE",
    BUSINESS_MEMBER_TAX: "BUSINESS_MEMBER_TAX",
    BUSINESS_OVERDRAFT: "BUSINESS_OVERDRAFT",
    BUSINESS_CHEQUE: "BUSINESS_CHEQUE",
    BUSINESS_DEBIT: "BUSINESS_DEBIT",
    BUSINESS_ONLINE: "BUSINESS_ONLINE",
    BUSINESS_EMPLOYMENT: "BUSINESS_EMPLOYMENT",
    BUSINESS_REVIEW: "BUSINESS_REVIEW",
    BUSINESS_INDIVIDUAL: "BUSINESS_INDIVIDUAL",
    BUSINESS_MEMBER: "BUSINESS_MEMBER",
    BUSINESS_MEMBER_REVIEW: "BUSINESS_MEMBER_REVIEW",
    BUSINESS_ABOUT_YOU: "BUSINESS_ABOUT_YOU",
    BUSINESS_CONFIRM: "BUSINESS_CONFIRM",
    BUSINESS_UPLOAD: "BUSINESS_UPLOAD",
    BUSINESS_DOC_REVIEW: "BUSINESS_DOC_REVIEW",
    TAX_INFO: "TAX_INFO",
    GIC_INFO: "GIC_INFO",
    THIRD_PARTY: "THIRD_PARTY",
  },
  JOINT_APPLICANT: {
    NUMBERFORMAT: "(###) ###-####",
    NUMBERPREFIX: "1",
    EMAILPATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    MOBILENOPATTERN: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    CONTACT_NO_PATTERN: /[- )(]/g,
    INVITE_APPLY_URL: "/inviteapply",
    TYPE: "co",
    FLOW: [
      {
        index: 0,
        componentName: "TERMS_AND_CONDITIONS",
      },
      {
        index: 1,
        componentName: "ABOUT_YOU",
      },
      {
        index: 2,
        componentName: "VERIFICATION_CODE",
      },
      {
        index: 3,
        componentName: "EMPLOYMENT_AND_INCOME",
        validateStep: true,
      },
      {
        index: 4,
        componentName: "TAX_INFO",
        validateStep: true,
      },
      {
        index: 5,
        componentName: "COMPLIANCE",
        validateStep: true,
      },
      {
        index: 6,
        componentName: "DEBIT_CARD",
        validateStep: true,
      },
      {
        index: 7,
        componentName: "CONFIRM_ACCOUNT_CREATE",
      },
    ],
  },
  COMPONENTSTEPTYPE: {
    APPLICANT: "Applicant",
    APPLICATION: "Application",
  },
  COMPLIANCE: {
    VAULT_API_BODY: {
      applicantId: "",
      applicationId: "",
      applicationStep: "",
    },
    JURISDICTION_TYPE: {
      DOMESTIC: "Domestic",
      FOREIGN: "Foreign",
    },
  },
  FUND_ACCOUNT: {
    FUND_AMOUNT_CUR: [
      // { Id: 1, Name: "$20" },
      // { Id: 2, Name: "$30" },
      // { Id: 3, Name: "$40" },
      { Id: 4, Name: "$50" },
      { Id: 5, Name: "$100" },
      { Id: 6, Name: "Other" },
    ],
    FUND_STEPS: [
      { Id: 1, Name: STRINGS.FUND_ACCOUNT.METHOD },
      { Id: 2, Name: STRINGS.FUND_ACCOUNT.AMOUNT },
    ],
    FUND_METHODS: [
      { Id: 1, Name: STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_CREDIT_CARD },
      {
        Id: 2,
        Name: STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_DIRECT_DEPOSIT,
      },
      // {
      //   Id: 3,
      //   Name: STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_INTERAC,
      // },
    ],
  },
  IDTypes: {
    PASSPORT: {
      id: 1,
      desc: "Passport",
    },
    LICENSE: {
      id: 2,
      desc: "License",
    },
  },
  DATEOFBIRTH: {
    FORMAT: "####-##-##",
    MASK: ["Y", "Y", "Y", "Y", "M", "M", "D", "D"],
  },
  EMPLOYMENTANDINCOME: {
    TIN_FORMATE: "###-###-###",
    NUMBERFORMAT: "(###) ###-####",
    PHONEFORMAT: "(999) 999-9999",
    SSNLENGTH: 9,
    SALARY_LENGTH: 12,
    CONTACT_NO_PATTERN: /[- )(]/g,
    SALARY_PATTERN: /[$,]/g,
    SALARY_PREFIX: "$",
  },
  TAX_INFO: {
    SIN_FORMAT: "###-###-###",
    SSN_FORMAT: "###########",
    SSN_OTHER_FORMAT: "#######################",
    SSN_USA_FORMAT: "#########",
    NUMBER_FORMAT: "(###) ###-####",
    SSN_LENGTH: 11,
    SSN_OTHER_LENGTH: 24,
    SSN_USA_LENGTH: 9,
    SALARY_LENGTH: 12,
    CONTACT_NO_PATTERN: /[- )(]/g,
    SALARY_PATTERN: /[$,]/g,
    SALARY_PREFIX: "$",
    COUNTRY_USA: "USA",
    COUNTRY_US: "US",
    COUNTRY_AMERICAN: "American",
    COUNTRY_UNITED_STATES: "United States",
  },
  APPLICATIONDEFAULTNAME: {
    APP_DEFAULT_NAME: "FI APP",
  },
  NODE_NAME: "LINK",

  ABOUT_YOU: {
    CA_POSTALMASK: [/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/],
    US_POSTALMASK: [/\d/, /\d/, /\d/, /\d/, /\d/],
    CA_POSTALPATTERN: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]( )\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    US_POSTALPATTERN: /^\d{5}$/,
    DOBPATTERN: /^(19|20)\d\d[- .](0[1-9]|1[012])[- .](0[1-9]|[12][0-9]|3[01])$/,
    EDIT_MODE_INPUTLIST: ["firstName", "lastName", "dateOfBirth"],
    ADDRESS_LIMIT: 30,
  },
  MARKETPLACE: {
    HEADER: {
      TITLE1: `${FIInformation.FI_NAME}`,
      TITLE2: ` `,
      TITLE3:
        "Review the financial solutions available and add those you are interested in.",
      TITLE4: `  `,
      SIGNINREGEBTN: "SIGN IN / REGISTER",
      SIGNIN_BTN: "Sign in",
      REGISTER_BTN: "New? Register",

      BUSINESS_TITLE1: ` Welcome to ${FIInformation.FI_APPLICATION_NAME} _BANKING_TYPE_`,
      BUSINESS_TITLE2: ` An Online _BANKING_TYPE_ Banking Marketplace`,
      BUSINESS_TITLE3:
        "Your online one-stop-shop for all your _BANKING_TYPE_ banking needs.",
      BUSINESS_TITLE4: `Simply add your banking products to your cart and "Proceed" to checkout.`,
      BUSINESS_SIGNINREGEBTN: "SIGN IN / REGISTER",
      BUSINESS_SIGNIN_BTN: "Sign in",
      BUSINESS_REGISTER_BTN: "New? Register",
    },
    GALLERY: {
      TITLE: "Personal Banking",
    },
  },
  DASHBOARD: {
    HEADER: {
      TITLE1: ` Welcome`,
      TITLE2_UNKNOWN: `To ${FIInformation.FI_APPLICATION_NAME}`,
      TITLE2_KNOWN: `Welcome back to ${FIInformation.FI_APPLICATION_NAME}`,
    },
    PRODUCT_STATUS: {
      IS_COMPLETED: "IsCompleted",
      IN_REVIEW: "InReview",
      IS_REJECTED: "IsRejected",
      IN_PROGRESS: "InProgress",
    },
  },
  BENEFICIARY: {
    beneficiaryId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    beneficiaryRelation: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    province: "",
    mobileNo: "",
    businessArea: "",
    businessPhone: "",
    sin: "",
    percentEntitlement: 0,
    country: "",
    beneficiaryType: "",
    editMode: true,
    showEditAndDeletBtn: false,
    showSaveAndCancelBtn: false,
    errors: {
      firstNameErrorList: [],
      lastNameErrorList: [],
      dateOfBirthErrorList: [],
      beneficiaryRelationErrorList: [],
      address1ErrorList: [],
      cityErrorList: [],
      provinceErrorList: [],
      postalCodeErrorList: [],
      mobileNoErrorList: [],
      countryErrorList: [],
      sinErrorList: [],
      beneficiaryTypeErrorList: [],
    },
  },
  RELATIONSHIP: {
    SELF: "self",
    JOINT: "joint",
  },
  FIRSTNAMEPATTERN: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  LASTNAMEPATTERN: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  SIGNATURE: {
    STEPS: [
      { Id: 1, Name: STRINGS.SIGNATURE.CONTRACT },
      { Id: 2, Name: STRINGS.SIGNATURE.SIGN },
    ],
  },
  PRODUCT_TYPE: {
    REGISTERED_CONTRACT: "registeredContract",
    TERM_DEPOSIT: "termDeposit",
    DEMAND_PRODUCT: "demandproduct",
    LENDING_PRODUCT: "lendingproduct",
  },
  ASSET: {
    TYPE: "Asset",
  },
  LIABILITIES: {
    TYPE: "Liability",
  },
  INCOME: {
    TYPE: "Income",
  },
  EXPENSE: {
    TYPE: "Expense",
  },
  LOANS: {
    PURPOSE: {
      PRE_APPROVE: 50,
      DEBT_CONSOLIDATION: 28,
    },
    SALARY_LENGTH: 12,
    SALARY_PATTERN: /[$,]/g,
    SALARY_PREFIX: "$",
    REVOLVING: "Revolving",
  },
  TEMPLATE: {
    NAME: "_NAME_",
    PRODUCT: "_PRODUCT_",
    PROVINCE: "_PROVINCE_",
  },
  LOAN_CATEGORY: {
    PERSONAL: "personal",
    AUTO: "auto",
    LOC: "loc",
    HELOC: "heloc",
    MORTGAGE: "mortgage",
  },
  LOAN_SUB_CATEGORY: {
    MORTGAGE_PRE_APPROVE: "pre-approve",
    MORTGAGE_PRE_QUAL: "pre-qual",
  },
  ACCOUNT_TYPE: {
    CHEQUING: "chequing",
    SAVINGS: "savings",
    OTHER: "other",
  },
  SESSION: {
    OTHER_ACCOUNTS: "S_OTHER_ACCOUNTS",
    INVERITE_ACCOUNTLIST_REQUESTGUID: "S_INVERITE_ACCOUNTLIST_REQUEST_GUID",
    DEBIT_CARD_ALREADY_LOADED: "S_DEBIT_CARD_ALREADY_LOADED",
    MEMBER_INFO_WELCOME_SHOWN: "S_MEMBER_INFO_WELCOME_SHOWN",
    APPLIED_PRODUCTS: "S_APPLIED_PRODUCTS",
    OLB_APPLICATION_ID: "S_OLB_APPLICATION_ID",
    OLB_PRODUCT_ID: "S_OLB_PRODUCT_ID",
    CENTRAL_MESSAGE: "S_CENTRAL_MESSAGE",
  },
  PRODUCT_CODE: {
    PC: "PC",
  },
  SECURITY_TYPE: {
    VEHICLE: "Vehicle",
    REAL_ESTATE: "Real Estate",
  },

  // Begin Business
  BUSINESS_MARKETPLACE: {
    HEADER: {
      TITLE1: ` Welcome to ${FIInformation.FI_NAME}`,
      TITLE2: ` Online Business Banking Marketplace`,
      TITLE3: "Your online one-stop-shop for all your business banking needs.",
      TITLE4:
        "Simply select the business banking products you would like to apply for and we'll add them to your cart.",
      SIGNINREGEBTN: "SIGN IN / REGISTER",
    },
    GALLERY: {
      TITLE: "Business Banking",
    },
  },
  BUSINESS_DASHBOARD: {
    HEADER: {
      TITLE2: ` Your Online Business Banking Marketplace`,
    },
    PRODUCT_STATUS: {
      IS_COMPLETED: "IsCompleted",
      IN_REVIEW: "InReview",
      IS_REJECTED: "IsRejected",
      IN_PROGRESS: "InProgress",
    },
  },

  BUSINESS_DETAILS: {
    NUMBERFORMAT: "#######",
  },
  BUSINESS_TAX: {
    US_TIN_FORMATE: "##-#######",
    US_TIN_PATTERN: /^(?:\d{2}[-\s]?\d{7})$/,
  },
  BUSINESS_LOOKUP: {
    BUSINESS_NUMBER_LENGTH: 8,
  },
  CORE_BANKING_TYPE: {
    RFS: "RFS",
    DNA: "DNA",
    OVATION: "OVATION",
  },
  KYC: {
    NAME: "name",
    DOB: "dob",
    SIN: "sin",
  },
};

export default AppConstants;
