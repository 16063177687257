import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessEmployment from "./BusinessEmployment";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import {
  getApplicant,
  updateApplication,
} from "../../Redux/Actions/ApplicationAction";
import { doCreateApplication } from "../AboutYou/Actions/AboutYouActions";
import updateApplicantData from "../../Redux/Actions/UpdateApplicantAction";
import { HTTP_STATUS, S_PERSONAL } from "../../Communication/Constants";

class BusinessEmploymentContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { doGetApplicant } = this.props;

    const personalId = sessionStorage.getItem(S_PERSONAL);
    console.log("personalId", personalId);
    if (personalId && personalId !== "null") {
      doGetApplicant({ id: personalId, isPersonalData: true });
    }
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      doUpdateApplicant,
      doGetApplicant,
      personalData,
      applicantData,
      doUpdateApplication,
      getActiveProduct,
      products,
      finInfo,
    } = this.props;
    if (personalData?.id) {
      doUpdateApplicant(data.applicant, personalData.id, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          doGetApplicant({ id: personalData.id, isPersonalData: true });
          continuehandler(
            data.vault,
            AppConstants.APPLICATIONSTEP.BUSINESS_EMPLOYMENT
          );
        }
      });
    } else {
      const activeProduct = getActiveProduct(products);
      const employmentInfo = `Employment status: ${
        finInfo.config.businessEmployment.find(
          (item) => item.code === data.vault?.employmentStatus
        )?.description
      }<br/>Employment position: ${data.vault?.position}<br/>Employer: ${
        data.vault?.employerName
      }`;
      const applicationComments = `Applicant employment info: ${employmentInfo}`;

      doUpdateApplication(
        activeProduct.applicationId,
        {
          applicationId: activeProduct.applicationId,
          applicationComments,
        },
        (res) => {
          console.log(res);
          if (res.status === HTTP_STATUS.OK) {
            continuehandler(
              data.vault,
              AppConstants.APPLICATIONSTEP.BUSINESS_EMPLOYMENT
            );
          }
        }
      );
    }
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
      personalData,
      applicantData,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    return (
      <>
        <BusinessEmployment
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          finInfo={finInfo}
          activeProduct={activeProduct}
          personalData={personalData}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessEmploymentContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessEmploymentContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  personalData: state.ApplicationReducer.personalData,
  finInfo: state.MarketplaceReducer.finInfo,
  applicantData: state.ApplicationReducer.response,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicant: (payLoad, callback) =>
      dispatch(getApplicant(payLoad, callback)),
    doUpdateApplicant: (request, applicantId, callback) =>
      dispatch(updateApplicantData(request, applicantId, callback)),
    doUpdateApplication: (applicationId, payLoad, callback) =>
      dispatch(updateApplication(applicationId, payLoad, callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessEmploymentContainer);

const BusinessEmploymentWrapper = withBusinessHOC(BusinessEmploymentContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessEmploymentWrapper);
