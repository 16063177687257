import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";
import { toggleEditFlag } from "Redux/Actions/ApplicationAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessConfirm from "./BusinessConfirm";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { VAULT_DASHBOARD } from "../../Constants/Routes";
import PopupModal from "../Common/PopupModal/PopupModal";
import STRINGS from "../../Constants/Strings";
import { HTTP_STATUS } from "../../Communication/Constants";
import { doConfirmApplication } from "../ConfirmAccountCreate/Actions/ConfirmAccountCreateAction";

class BusinessConfirmContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
    };
  }

  componentDidMount() {
    const { processTemplate, history, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    const vaultStep = processTemplate?.flow?.find(
      (x) => x.componentName === activeProduct?.applicationStep?.componentName
    );
    console.log("vaultStep", vaultStep);
    if (
      vaultStep &&
      vaultStep.componentName === AppConstants.APPLICATIONSTEP.BUSINESS_CONFIRM
    ) {
      history.push(VAULT_DASHBOARD);
    }
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      applicationCompleteList,
      products,
      getActiveProduct,
      doConfirmApp,
    } = this.props;

    continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_CONFIRM);
  };

  /**
   *
   * @param {string} redirectToPage
   * handleEditPage makes URL navigation
   * using key as value
   */

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEditConfirm } = this.props;
    console.log(redirectToPage);
    doToggleEditConfirm(true);
    history.push(redirectToPage);
  };

  handlePopupModalBtnClick = () => {
    const { removeCurrentActiveApplication } = this.props;
    removeCurrentActiveApplication(this.handleExit);
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      finInfo,
      processFlow,
      applicationCompleteList,
      doGetAcoountDetails,
    } = this.props;
    const { showModal, type, modal } = this.state;
    const activeProduct = getActiveProduct(products);

    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={this.handlePopupModalBtnClick}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <BusinessConfirm
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          handleEditPage={this.handleEditPage}
          activeProduct={activeProduct}
          finInfo={finInfo}
          processFlow={processFlow}
          applicationCompleteList={applicationCompleteList}
          doGetAcoountDetails={doGetAcoountDetails}
        />
      </>
    );
  }
}

BusinessConfirmContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doToggleEdit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessConfirmContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  doToggleEdit: () => {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  finInfo: state.MarketplaceReducer.finInfo,
  processFlow: state.ApplicationReducer.processTemplate.flow,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
    doConfirmApp: (confirmRequest, callback) =>
      dispatch(doConfirmApplication(confirmRequest, callback)),
  };
};

const BusinessConfirmWrapper = withBusinessHOC(BusinessConfirmContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessConfirmWrapper);
