/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";
import { COMMON_BUCKET_URL } from "../../Communication/ApiUrls";
import PopupModal from "../Common/PopupModal/PopupModal";
import LinkTermComponent from "../TermsAndConditions/LinkTermComponent";
import Button from "react-bootstrap/Button";
import { FileEarmarkArrowDown, Printer } from "react-bootstrap-icons";
import { isNil } from "lodash";
import html2pdf from "html2pdf.js";
import axios from "axios";
import { termsAndConditionStyle } from "./OverdraftProtection.style";
import {
  downloadTermsAndConditions,
  printTermsAndConditions,
} from "../../Utils/CommonUtilities";

class OverdraftProtection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needProtection: "",
      needProtectionError: "",
      origUdfList: {},
      isAcceptedTerms: false,
      showModal: false,
      modal: {
        title: null,
        description: "",
        description2: "",
      },
      modalType: AppConstants.MODALTYPE.INFORMATION,
      termsAndConditionsUrl: null,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    getDataFromServer(
      AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  setFormData = (response) => {
    const { finInfo } = this.props;
    const { hasOverdraftProtection, udfList } = response;
    this.setState({
      origUdfList: udfList,
      needProtection: hasOverdraftProtection,
    });
    if (finInfo.overdraftTermsAndConditions) {
      if (
        udfList[finInfo.overdraftTermsAndConditionsFlag] &&
        udfList[finInfo.overdraftTermsAndConditionsFlag] === "Yes"
      ) {
        this.setState({
          isAcceptedTerms: true,
        });
      }
    }
  };

  verifyData = () => {
    let valid = true;
    const { activeProduct, finInfo } = this.props;
    const { needProtection, origUdfList, isAcceptedTerms } = this.state;

    const error = checkErrors(
      needProtection,
      VALIDATIONS.OVERDRAFT_PROTECTION.overdraftProtection
    );

    if (error.length) {
      valid = false;
      this.setState({ needProtectionError: error });
    }

    if (
      !isAcceptedTerms &&
      needProtection &&
      finInfo.overdraftTermsAndConditions
    ) {
      valid = false;
      this.setState({
        termsConditionError:
          STRINGS.OVERDRAFT_PROTECTION.ERROR.TERMS_AND_CONDITIONS,
      });
    }

    if (valid) {
      const { submitRequest } = this.props;
      let request = {
        udfList: { ...origUdfList },
        hasOverdraftProtection: needProtection,
      };
      if (finInfo.overdraftTermsAndConditions) {
        if (needProtection) {
          request.udfList[finInfo.overdraftTermsAndConditionsFlag] = "Yes";
        } else {
          request.udfList[finInfo.overdraftTermsAndConditionsFlag] = "No";
        }
      }
      if (activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT) {
        request = {
          demandProductUDFDetails: { ...origUdfList },
          hasOverdraftProtection: needProtection,
        };
        if (finInfo.overdraftTermsAndConditions) {
          if (needProtection) {
            request.demandProductUDFDetails[
              finInfo.overdraftTermsAndConditionsFlag
            ] = "Yes";
          } else {
            request.demandProductUDFDetails[
              finInfo.overdraftTermsAndConditionsFlag
            ] = "No";
          }
        }
      }
      submitRequest(request);
    }
  };

  // handle need protection click
  needProtectionClick = (value) => {
    this.setState({ needProtection: value, needProtectionError: "" });
  };

  handleChecked = () => {
    const { isAcceptedTerms } = this.state;
    this.setState({
      isAcceptedTerms: !isAcceptedTerms,
      termsConditionError: "",
    });
  };

  linkClickHandler = (fileName, name) => {
    // if filename is valid
    if (fileName) {
      // First reinitialize modal state
      this.setState({ showModal: false }, () => {
        // Initialize variables
        const modal = {};

        // Set data into modal variable
        modal.title = "";
        modal.description = (
          <object
            type="text/html"
            aria-label="Default"
            data={`${COMMON_BUCKET_URL}${fileName}`}
          />
        );

        // set values to state variables
        this.setState({
          showModal: true,
          modal,
          modalType: name,
          termsAndConditionUrl: `${COMMON_BUCKET_URL}${fileName}`,
        });
      });
    }
  };

  fetchHtmlContent = async (url) => {
    const response = await axios.get(url, { responseType: "text/html" });
    return response.data;
  };

  render() {
    const {
      needProtectionError,
      needProtection,
      isAcceptedTerms,
      termsConditionError,
      showModal,
      modalType,
      modal,
      termsAndConditionUrl,
    } = this.state;

    const { steps, activeStepID, handleBack, finInfo } = this.props;

    const overdraftProtectionComponent = (
      <div className="[ overdraft-protection-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.OVERDRAFT_PROTECTION.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.OVERDRAFT_PROTECTION.OVERDRAFT_PROTECTION_REQUIRED}
            </div>
          </div>

          <div className="[ row ] [ custom-radios ]">
            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  needProtection !== "" && needProtection
                    ? "[ btn custom-radio-selected ]"
                    : "[ btn custom-radio-unselected ]"
                }
                onClick={() => this.needProtectionClick(true)}
              >
                {STRINGS.COMMON.CHOICE.YES}
              </button>
            </div>

            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  needProtection !== "" && needProtection === false
                    ? "[ btn custom-radio-selected ]"
                    : "[ btn custom-radio-unselected ]"
                }
                onClick={() => this.needProtectionClick(false)}
              >
                {STRINGS.COMMON.CHOICE.NO}
              </button>
            </div>

            <div className="[ error-msg ] ">{needProtectionError[0]}</div>
            <p className="[ col-12 overdraft-hint ]">
              <small>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      STRINGS.OVERDRAFT_PROTECTION
                        .OVERDRAFT_PROTECTION_HINT_TEXT,
                  }}
                />
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      STRINGS.OVERDRAFT_PROTECTION
                        .OVERDRAFT_PROTECTION_HINT_TEXT1,
                  }}
                />
              </small>
            </p>
          </div>

          {finInfo.overdraftTermsAndConditions && needProtection && (
            <div>
              <div className="[ drawer-content-container ] [ mb-3 ]">
                <div className="[ terms-and-conditions__links-container ]">
                  <div className="[ alert alert-info ]" role="alert">
                    <h4 className="[ alert-heading ]">
                      {STRINGS.MEMBERCREATION.AGREE_TERMS}
                    </h4>
                    <div className="[ divLine ]" />

                    {finInfo.overdraftTermsAndConditionsDocument.length > 0 && (
                      <LinkTermComponent
                        linkTermsArray={
                          finInfo.overdraftTermsAndConditionsDocument
                        }
                        linkClickHandler={this.linkClickHandler}
                      />
                    )}
                  </div>
                </div>
                <div className="[ divider ]" />
                <div className="[ mb-3 ]">
                  <div className="[ row ] [ align-items-center ]">
                    <div className="[ col-12 ] ">
                      <div className=" [ checkbox custom-control custom-checkbox ]">
                        <input
                          type="checkbox"
                          checked={isAcceptedTerms}
                          onChange={this.handleChecked}
                          name="termscondition"
                          id="termsCheckbox"
                          className="[ custom-control-input ]"
                        />
                        <label
                          htmlFor="termsCheckbox"
                          className="[ custom-control-label ]"
                        >
                          <span className="[ form-checkbox__text ]">
                            {finInfo.overdraftTermsAndConditions}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="[ error-msg ] [ col-12 ]">
                      {termsConditionError}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.verifyData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    const overdraftTermsAndConditionsActionContent = (
      <div style={{ display: "flex", gap: "1rem" }}>
        <button
          type="button"
          className="[ btn btn-sm ] [ tertiary-btn ]"
          aria-label="Print file"
          onClick={() =>
            printTermsAndConditions(termsAndConditionUrl, "frameOverdraft")
          }
        >
          <span className="fas fa-print"></span>
        </button>
        <button
          type="button"
          className="[ btn btn-sm ] [ tertiary-btn ]"
          aria-label="Download file"
          onClick={() => downloadTermsAndConditions(termsAndConditionUrl)}
        >
          <span className="fas fa-file-download"></span>
        </button>
      </div>
    );

    return (
      <>
        <iframe
          id="frameOverdraft"
          src={termsAndConditionUrl}
          style={{ display: "none" }}
          title="Overdraft"
        />
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            description2={modal.description2}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            showPopupModalButtons={!finInfo.enableTermsPrintAndDownload}
            showPopupHeaderCloseButton={!!finInfo.enableTermsPrintAndDownload}
            showPopupFooterActionButtons={!!finInfo.enableTermsPrintAndDownload}
            popupFooterActionContent={
              finInfo.enableTermsPrintAndDownload
                ? overdraftTermsAndConditionsActionContent
                : null
            }
          />
        )}
        <AuthBody
          actionComponent={overdraftProtectionComponent}
          handleBack={handleBack}
          memberCreation
        />
      </>
    );
  }
}

OverdraftProtection.propTypes = {
  submitRequest: PropTypes.func,
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  handleBack: PropTypes.func.isRequired,
  activeProduct: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType(PropTypes.object),
};
OverdraftProtection.defaultProps = {
  submitRequest: () => {},
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  activeProduct: {},
  finInfo: {},
};

export default OverdraftProtection;
