import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessMemberCompliance from "./BusinessMemberCompliance";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { updateProductInformation } from "../../Redux/Actions/ProductInformationAction";
import { HTTP_STATUS, S_PERSONAL } from "../../Communication/Constants";
import {
  getApplicant,
  getCompleteApplicant,
} from "../../Redux/Actions/ApplicationAction";
import updateApplicantData from "../../Redux/Actions/UpdateApplicantAction";
import {
  getAccountPurposeList,
  getIndicateWhoList,
} from "../Compliance/Actions/ComplianceActions";
import { isNewMember } from "../../Utils/LogicUtilities";

class BusinessMemberComplianceContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      personalData,
      continuehandler,
      actionBack,
      handleBack,
      dogetAccountPurposeList,
      dogetIndicateWhoList,
      accountPurposeState,
      doGetCompleteApplicant,
    } = this.props;
    const personalId = sessionStorage.getItem(S_PERSONAL);
    console.log("personalId", personalId);
    if (personalId && personalId !== "null") {
      doGetCompleteApplicant({ id: personalId, isPersonalData: true });
      if (accountPurposeState?.accountPurposeState?.length === 0) {
        dogetAccountPurposeList();
      }
      if (accountPurposeState?.indicateWhoList?.length === 0) {
        dogetIndicateWhoList();
      }
    } else {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_COMPLIANCE);
      } else {
        continuehandler(
          null,
          AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_COMPLIANCE
        );
      }
    }
  }

  handleChange = () => {};

  handleContinue = (formdata) => {
    console.log("handleContinue", formdata);
    const {
      continuehandler,
      getActiveProduct,
      products,
      personalData,
      doUpdateApplicant,
      doGetCompleteApplicant,
      finInfo,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    let jurisdictionType = "";
    if (formdata.PEPDomestic) {
      jurisdictionType = AppConstants.COMPLIANCE.JURISDICTION_TYPE.DOMESTIC;
    } else if (formdata.PEPForeign) {
      jurisdictionType = AppConstants.COMPLIANCE.JURISDICTION_TYPE.FOREIGN;
    }

    const thirdPartyData = {
      name: formdata.thirdPartyName,
      relationship: formdata.thirdPartyRelationship,
    };

    const PEPData = {
      fundsSource: formdata.anySourceOfFunds,
      hio: formdata.HIO ? formdata.HIO : false,
      jurisdiction: formdata.anyJurisdiction,
      jurisdictionType,
      name: formdata.indicateName,
      officeHeld: formdata.anyOfficeHeld,
      relationship: formdata.anyRelationship,
    };
    const dispatchObject = {
      member: {
        pep: formdata.areYouPEPorHIO,
        intendedUseOfMembership: formdata.businessRelation,
      },
      regulatory: {
        memberHasThirdParty: formdata.isThirdPartyAccount,
        memberIsPedp: formdata.areYouPEPorHIO
          ? formdata.memberPEPDomestic
          : false,
        memberIsPefp: formdata.areYouPEPorHIO
          ? formdata.memberPEPForeign
          : false,
        memberIsHio: formdata.areYouPEPorHIO ? formdata.memberHIO : false,
        memberOfficeHeld: formdata.areYouPEPorHIO
          ? formdata.memberOfficeHeld
          : "",
        memberJurisdiction: formdata.areYouPEPorHIO
          ? formdata.memberJurisdiction
          : "",
        memberFundsSource: formdata.areYouPEPorHIO
          ? formdata.memberFundsSource
          : "",
      },

      thirdParties: formdata.isThirdPartyAccount ? [thirdPartyData] : [],

      peps: formdata.isAnyoneConsideredPEPHIO ? [PEPData] : [],
    };

    if (personalData?.id) {
      doUpdateApplicant(dispatchObject, personalData.id, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          doGetCompleteApplicant(
            {
              id: personalData?.id,
              isPersonalData: true,
            },
            () =>
              continuehandler(
                {
                  ...activeProduct,
                  businessRelation: formdata.businessRelation,
                },
                AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_COMPLIANCE
              )
          );
        }
      });
    } else {
      continuehandler(
        { ...activeProduct, ...dispatchObject },
        AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER_COMPLIANCE
      );
    }
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      finInfo,
      accountPurposeState,
      personalData,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    return (
      <>
        <BusinessMemberCompliance
          accountPurposeArray={accountPurposeState.accountPurposeList}
          indicateWhoArray={accountPurposeState.indicateWhoList}
          handleContinue={this.handleContinue}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
          finInfo={finInfo}
          personalData={personalData}
          contKycFlag={!isNewMember(personalData)}
        />
      </>
    );
  }
}

BusinessMemberComplianceContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessMemberComplianceContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  doximProducts: state.ApplicationReducer.doximProducts,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  finInfo: state.MarketplaceReducer.finInfo,
  accountPurposeState: state.ComplianceReducer,
  personalData: state.ApplicationReducer.personalData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dogetAccountPurposeList: () => dispatch(getAccountPurposeList()),
    dogetIndicateWhoList: () => dispatch(getIndicateWhoList()),
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetApplicant: (payLoad, callback) =>
      dispatch(getApplicant(payLoad, callback)),
    doUpdateApplicant: (request, applicantId, callback) =>
      dispatch(updateApplicantData(request, applicantId, callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetCompleteApplicant: (payLoad, callback) =>
      dispatch(getCompleteApplicant(payLoad, callback)),
    doUpdateProductInformation: (
      requestBody,
      applicationId,
      productType,
      doximProductId,
      callback
    ) =>
      dispatch(
        updateProductInformation(
          requestBody,
          applicationId,
          productType,
          doximProductId,
          callback
        )
      ),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessMemberComplianceContainer);

const BusinessComplianceWrapper = withBusinessHOC(
  BusinessMemberComplianceContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessComplianceWrapper);
