import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

// Components
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import PopupModal from "../Common/PopupModal/PopupModal";

// Constants
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";

// Styles
import { QuestionCircle } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class BusinessDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      hasBusinessOperate: false,
      tradeName: "",
      hasCertificateOfIncorporation: false,
      hasMasterBusinessLicense: false,
      extension: "",
      businessEmail: "",
      businessPhoneNumber: "",
      businessPhoneExtension: "",
      errors: {
        businessNumberErrorList: [],
        incorporationNumberErrorList: [],
        jurisdictionIncorporationErrorList: [],
        businessRegistrationDateErrorList: [],
        legalBusinessNameErrorList: [],
      },

      formIsValid: true,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_DETAILS,
      (response) => {}
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { applicantData } = this.props;

    if (applicantData && applicantData !== prevProps.applicantData) {
      this.setFormData(applicantData);
    }
  }

  setFormData = (data) => {
    const { activeProduct } = this.props;
    this.setState({
      hasBusinessOperate: !!data.business.tradeName,
      tradeName: data.business.tradeName,
      hasCertificateOfIncorporation:
        activeProduct.hasCertificateOfIncorporation,
      hasMasterBusinessLicense: activeProduct.hasMasterBusinessLicense,
    });
  };

  handleContinue = () => {
    const { handleContinue, applicantData } = this.props;
    const {
      tradeName,
      hasCertificateOfIncorporation,
      hasMasterBusinessLicense,
    } = this.state;
    let valid = this.verifyData();

    if (valid) {
      let applicantComments = "";
      if (applicantData.isSoleProprietor) {
        applicantComments = `<p>Has Master Business License: ${
          hasMasterBusinessLicense ? "Yes" : "No"
        }</p>`;
        if (
          applicantData.applicantComments.search(
            "<p>Has Master Business License:.*</p>"
          ) > -1
        ) {
          applicantComments = applicantData.applicantComments
            .split(new RegExp("<p>Has Master Business License:.+?</p>"))
            .join(applicantComments);
        } else {
          applicantComments =
            applicantData.applicantComments + applicantComments;
        }
      } else {
        applicantComments = `<p>Has Certificate of Incorporation: ${
          hasCertificateOfIncorporation ? "Yes" : "No"
        }</p>`;
        if (
          applicantData.applicantComments.search(
            "<p>Has Certificate of Incorporation:.*</p>"
          ) > -1
        ) {
          applicantComments = applicantData.applicantComments
            .split(new RegExp("<p>Has Certificate of Incorporation:.+?</p>"))
            .join(applicantComments);
        } else {
          applicantComments =
            applicantData.applicantComments + applicantComments;
        }
      }
      handleContinue({
        applicant: {
          contact: {},
          applicantComments,
          business: {
            tradeName,
          },
        },
        vault: { hasCertificateOfIncorporation, hasMasterBusinessLicense },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_DETAILS);
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    // Capitalize only the first letter of each word for "tradeName", preserving other capitalizations
    if (eventName === "tradeName") {
      eventValue = eventValue
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  handleBusinessOperateChoice = (value) => {
    if (value) {
      this.setState({
        hasBusinessOperate: value,
      });
    } else {
      this.setState({
        hasBusinessOperate: value,
        tradeName: "",
      });
    }
  };

  handleIsCertificateChoice = (value) => {
    this.setState({
      hasCertificateOfIncorporation: value,
    });
  };

  handleIsMasterBusinessLicenseChoice = (value) => {
    this.setState({
      hasMasterBusinessLicense: value,
    });
  };

  verifyData = () => {
    let formIsValid = true;
    // const { businessEmail, businessPhoneNumber, errors } = this.state;
    // const businessEmailRes = checkErrors(businessEmail, VALIDATIONS.BUSINESS_CONTACT.EMAIL);

    // const mobileRes = checkErrors(
    //   businessPhoneNumber,
    //   VALIDATIONS.BUSINESS_CONTACT.BUSINESS_PHONE_NO
    // );

    // this.setState({
    //   errors: {
    //     ...errors,
    //     businessEmailErrorList: businessEmailRes,
    //     businessPhoneNumberErrorList: mobileRes,
    //   },
    // });

    // if (businessEmailRes.length > 0 || mobileRes.length > 0) {
    //   formIsValid = false;
    // }
    return formIsValid;
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      modal: {
        title: STRINGS.BUSINESS_DETAILS.HELP.MORE_INFO_TRADE_NAME_TITLE,
        description:
          STRINGS.BUSINESS_DETAILS.HELP.MORE_INFO_TRADE_NAME_DESCRIPTION,
      },
    });
  };

  render() {
    const {
      steps,
      activeStepID,
      activeProduct,
      applicantData,
      finInfo,
    } = this.props;
    const {
      hasBusinessOperate,
      tradeName,
      hasCertificateOfIncorporation,
      noCertificateOfIncorporation,
      hasMasterBusinessLicense,
      showHelp,
      modal,
      errors,
    } = this.state;

    const tradeComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_DETAILS.BUSINESS_TRADE_NAME}
          </div>
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_DETAILS.FORM.TRADE_NAME_LABEL +
                " " +
                STRINGS.BUSINESS_DETAILS.FORM.TRADE_NAME_LABEL_EXTENSION}
            </div>
            <input
              type="text"
              id="tradeName"
              name="tradeName"
              className={"[ form-control ]"}
              placeholder={
                STRINGS.BUSINESS_DETAILS.FORM.TRADE_NAME_LABEL_PLACEHOLDER
              }
              value={tradeName}
              onChange={this.handleChange}
            />
            <div className="[ error-msg ]"></div>
          </div>
          <p className="[ info-message ]">
            {STRINGS.BUSINESS_DETAILS.FORM.TRADE_NAME_DESCRIPTION1}
          </p>
          <p className="[ info-message ]">
            {STRINGS.BUSINESS_DETAILS.FORM.TRADE_NAME_DESCRIPTION2}
          </p>
        </div>
      </div>
    );

    const mblComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_DETAILS.FORM.MASTER_BUSINESS_LICENSE}
          </div>
          <p className="[ info-message ]">
            {STRINGS.BUSINESS_DETAILS.FORM.MASTER_BUSINESS_LICENSE_DESCRIPTION}
          </p>
        </div>
      </div>
    );

    const certificateComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_DETAILS.FORM.CERTIFICATE_INCORPORATION}
          </div>
          <p className="[ info-message ]">
            {
              STRINGS.BUSINESS_DETAILS.FORM
                .CERTIFICATE_INCORPORATION_DESCRIPTION
            }
          </p>
        </div>
      </div>
    );

    const noCertificateComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_DETAILS.FORM.CERTIFICATE_INCORPORATION}
          </div>
          <p className="[ info-message ]">
            {
              STRINGS.BUSINESS_DETAILS.FORM
                .NO_CERTIFICATE_INCORPORATION_DESCRIPTION
            }
          </p>
        </div>
      </div>
    );

    const actionComponent = (
      <div className="[ business-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_CONTACT.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_DETAILS.FORM.BUSINESS_NUMBER}
                </div>
                <input
                  type="text"
                  id="businessNumber"
                  name="businessNumber"
                  className={"[ form-control ]"}
                  placeholder={`${STRINGS.BUSINESS_DETAILS.FORM.BUSINESS_NUMBER_PLACEHOLDER}`}
                  value={
                    applicantData?.member?.businessNumber &&
                    applicantData?.member?.businessNumber?.length > 4
                      ? `****` +
                        applicantData?.member?.businessNumber.substring(
                          applicantData?.member?.businessNumber.length - 4
                        )
                      : applicantData?.member?.businessNumber
                  }
                  onChange={this.handleChange}
                  disabled={true}
                />
                <div className="[ error-msg ]">
                  {errors?.businessNumberErrorList[0]}
                </div>
              </div>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 col-lg-4 ]">
                    <div className="[ form-label ]">
                      {STRINGS.BUSINESS_DETAILS.FORM.INCORPORATION_NUMBER}
                    </div>
                    <input
                      type="text"
                      id="incorporationNumber"
                      name="incorporationNumber"
                      className={"[ form-control ]"}
                      placeholder={`${STRINGS.BUSINESS_DETAILS.FORM.INCORPORATION_NUMBER_PLACEHOLDER}`}
                      value={
                        applicantData?.business?.incorporationNumber &&
                        applicantData?.business?.incorporationNumber?.length > 4
                          ? `****` +
                            applicantData?.business?.incorporationNumber.substring(
                              applicantData?.business?.incorporationNumber
                                .length - 4
                            )
                          : applicantData?.business?.incorporationNumber
                      }
                      onChange={this.handleChange}
                      disabled={true}
                    />
                    <div className="[ error-msg ]">
                      {errors?.incorporationNumberErrorList[0]}
                    </div>
                  </div>
                  <div className="[ col-12 col-lg-4 ]">
                    <div className="[ form-label ]">
                      {STRINGS.BUSINESS_DETAILS.FORM.JURISDICTION_INCORPORATION}
                    </div>

                    <input
                      type="text"
                      id="jurisdictionIncorporation"
                      name="jurisdictionIncorporation"
                      className={"[ form-control ]"}
                      placeholder={
                        STRINGS.BUSINESS_DETAILS.FORM
                          .JURISDICTION_INCORPORATION_PLACEHOLDER
                      }
                      value={
                        finInfo?.config?.businessJurisdiction.find(
                          (x) =>
                            x.code === applicantData?.member?.jurisdictionProv1
                        )?.description || "Federal"
                      }
                      onChange={this.handleChange}
                      disabled={true}
                    />
                    <div className="[ error-msg ]">
                      {errors?.jurisdictionIncorporationErrorList[0]}
                    </div>
                  </div>
                  <div className="[ col-12 col-lg-4 ]">
                    <div className="[ form-label ]">
                      {STRINGS.BUSINESS_DETAILS.FORM.BUSINESS_REGISTRATION_DATE}
                    </div>

                    <input
                      type="text"
                      id="businessRegistrationDate"
                      name="businessRegistrationDate"
                      className={"[ form-control ]"}
                      placeholder={
                        STRINGS.BUSINESS_DETAILS.FORM
                          .BUSINESS_REGISTRATION_DATE_PLACEHOLDER
                      }
                      value={applicantData?.business?.founded}
                      onChange={this.handleChange}
                      disabled={true}
                    />
                    <div className="[ error-msg ]">
                      {errors?.businessRegistrationDateErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_DETAILS.FORM.LEGAL_BUSINESS_NAME}
                </div>

                <input
                  type="text"
                  name="legalBusinessName"
                  id="legalBusinessName"
                  className={
                    errors?.legalBusinessNameErrorList &&
                    errors?.legalBusinessNameErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={
                    STRINGS.BUSINESS_DETAILS.FORM
                      .LEGAL_BUSINESS_NAME_PLACEHOLDER
                  }
                  value={applicantData?.member?.company}
                  onChange={this.handleChange}
                  disabled={true}
                />
                <div className="[ error-msg ]">
                  {errors?.legalBusinessNameErrorList[0]}
                </div>
              </div>

              {/* Business Operate Trade Name */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {
                    STRINGS.BUSINESS_DETAILS.FORM
                      .BUSINESS_OPERATE_TRADE_NAME_LABEL
                  }
                  <QuestionCircle
                    className="question-icon"
                    size={20}
                    onClick={() =>
                      this.handleOpenHelp(
                        STRINGS.BUSINESS_DETAILS.HELP.TRADE_NAME
                      )
                    }
                  />
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        hasBusinessOperate
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="hasBusinessOperate"
                      onClick={(event) =>
                        this.handleBusinessOperateChoice(true, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        !hasBusinessOperate
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="hasBusinessOperate"
                      onClick={(event) =>
                        this.handleBusinessOperateChoice(false, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {hasBusinessOperate && tradeComponent}

              {applicantData?.isSoleProprietor && (
                <>
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {
                        STRINGS.BUSINESS_DETAILS.FORM
                          .BUSINESS_MASTER_LICENSE_LABEL
                      }
                    </div>
                    <div className="[ row ] [ custom-radios ]">
                      <div className="[ col-6 ]">
                        <button
                          type="submit"
                          className={
                            hasMasterBusinessLicense
                              ? "[ btn custom-radio-selected ]"
                              : "[ btn custom-radio-unselected ]"
                          }
                          name="hasMasterBusinessLicense"
                          onClick={(event) =>
                            this.handleIsMasterBusinessLicenseChoice(
                              true,
                              event
                            )
                          }
                        >
                          {STRINGS.COMMON.CHOICE.YES}
                        </button>
                      </div>

                      <div className="[ col-6 ]">
                        <button
                          type="submit"
                          className={
                            !hasMasterBusinessLicense
                              ? "[ btn custom-radio-selected ] "
                              : "[ btn custom-radio-unselected ] "
                          }
                          name="hasMasterBusinessLicense"
                          onClick={(event) =>
                            this.handleIsMasterBusinessLicenseChoice(
                              false,
                              event
                            )
                          }
                        >
                          {STRINGS.COMMON.CHOICE.NO}
                        </button>
                      </div>
                    </div>
                  </div>

                  {hasMasterBusinessLicense && mblComponent}
                </>
              )}

              {!applicantData?.isSoleProprietor && (
                <>
                  {/* <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {
                        STRINGS.BUSINESS_DETAILS.FORM
                          .CERTIFICATE_INCORPORATION_LABEL
                      }
                    </div>
                    <div className="[ row ] [ custom-radios ]">
                      <div className="[ col-6 ]">
                        <button
                          type="submit"
                          className={
                            hasCertificateOfIncorporation
                              ? "[ btn custom-radio-selected ]"
                              : "[ btn custom-radio-unselected ]"
                          }
                          name="hasCertificateOfIncorporation"
                          onClick={(event) =>
                            this.handleIsCertificateChoice(true, event)
                          }
                        >
                          {STRINGS.COMMON.CHOICE.YES}
                        </button>
                      </div>

                      <div className="[ col-6 ]">
                        <button
                          type="submit"
                          className={
                            !hasCertificateOfIncorporation
                              ? "[ btn custom-radio-selected ] "
                              : "[ btn custom-radio-unselected ] "
                          }
                          name="hasCertificateOfIncorporation"
                          onClick={(event) =>
                            this.handleIsCertificateChoice(false, event)
                          }
                        >
                          {STRINGS.COMMON.CHOICE.NO}
                        </button>
                      </div>
                    </div>
                  </div> */}

                  {hasCertificateOfIncorporation && certificateComponent}
                  {!hasCertificateOfIncorporation && noCertificateComponent}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
        {showHelp && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={modal.title}
            description={modal.description}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            toggleModal={this.handleCloseHelp}
          />
        )}
      </>
    );
  }
}

BusinessDetails.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessDetails.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessDetails;
